import { ICONS } from "../../../assets/icons";

export const overviewData = [
  {
    id: 1,
    label: "Sent",
    icon: ICONS?.sentOverview,
    count: 0,
    data: [],
    color: "#B49FF9",
  },
  {
    id: 2,
    label: "Delivered",
    icon: ICONS?.deliveredOverview,
    count: 0,
    data: [],
    color: "#02D1C5",
  },
  {
    id: 3,
    label: "Read",
    icon: ICONS?.readOverview,
    count: 0,
    data: [],
    color: "#1778F2",
  },
  {
    id: 4,
    label: "Bounced",
    icon: ICONS?.bouncedOverview,
    count: 0,
    data: [],
    color: "#FF543E",
  },
];

export const performanceData = [
  {
    id: 1,
    label: "Clicks",
    icon: ICONS?.performanceClicks,
    count: 0,
    value: 9,
    color: "#9747FF",
    key: "clicks",
  },
  {
    id: 2,
    label: "Reactions",
    icon: ICONS?.performanceReactions,
    count: 0,
    value: 8,
    color: "#F6A723",
    key: "reactions",
  },
  {
    id: 3,
    label: "Leads",
    icon: ICONS?.performanceLeads,
    count: 0,
    value: 10,
    color: "#3E93FF",
    key: "leads",
  },
  {
    id: 4,
    label: "Opt-out",
    icon: ICONS?.performanceBlcoked,
    count: 0,
    value: -2,
    color: "#FF543E",
    key: "opt_out",
  },
];

export const performanceLeads = [
  {
    id: 1,
    label: "Clicks",
    icon: ICONS?.performanceClicks,
    count: 0,
    value: 9,
    color: "#9747FF",
    key: "clicks",
  },
  {
    id: 2,
    label: "Leads",
    icon: ICONS?.performanceLeads,
    count: 0,
    value: 10,
    color: "#3E93FF",
    key: "leads",
  },
  {
    id: 3,
    label: "Conversions",
    icon: ICONS?.leadsGreen,
    count: 0,
    value: null,
    color: "#00D998",
    key: "conversions",
  },
];
