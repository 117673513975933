import React from 'react';
import BroadcastTrigger from './broadcast';
import EditMessages from './EditMessages';

const NodeEditMode = ({ node, onChange, onTriggerClick }) => {
  return (
    <>
      {['TEXT', 'MEDIA', 'INETRACTIVE']?.includes(node?.component_type) ? (
        <div>
          <EditMessages
            node={node}
            onTriggerClick={onTriggerClick}
            onChange={onChange}
          />
        </div>
      ) : (
        <BroadcastTrigger node={node} onChange={onChange} />
      )}
    </>
  );
};

export default NodeEditMode;

// import React from 'react';

// // assets
// import IconContainer from 'assets/customSVG/sequence/actions/IconContainer';

// // hooks
// import useToggle from '../../../../../hooks/useToggle';

// // helpers
// import { cn } from 'helper/cn';

// // components
// import BroadcastTrigger from './broadcast';
// import EditMessages from './EditMessages';
// import TemplateMessage from './TemplateMessage';
// import { Popover } from 'antd';
// import {
//   ACTIONS_LIST,
//   NODE_TYPES_CONVERTION,
// } from '../../../constants/triggers';

// const NodeEditMode = ({ node, onChange, onTriggerClick }) => {
//   const [isHeaderTypeChange, setIsHeaderTypeChange] = useToggle(false);

//   const MessageTypesList = (
//     <div className='w-full min-w-full flex flex-col gap-5 p-4'>
//       {ACTIONS_LIST?.map((t) => {
//         return (
//           <div
//             className='w-full flex items-center gap-2.5 cursor-pointer'
//             onClick={() => {
//               onTriggerClick({ data: t, node: node });
//               setIsHeaderTypeChange(false);
//             }}
//           >
//             <IconContainer color={t?.bg}>
//               <t.icon color={t?.color} />
//             </IconContainer>
//             <p>{t.label}</p>
//           </div>
//         );
//       })}
//     </div>
//   );

//   const title = 'text-sm weight-semibold text-[var(--font-600)] pb-2.5';

//   return (
//     <div className='w-full max-w-[450px] h-auto rounded-2xl border border-gray-200'>
//       <div className='p-2.5 bg-[#04B8A2] rounded-t-2xl rounded-b-md'>
//         <p className=''>Send Message</p>
//       </div>
//       <div className='px-3.5 bg-white'>
//         <div className='py-5 border-b border-gray-200'>
//           <h5 className={cn('', title)}>Message Type</h5>
//           <Popover
//             open={isHeaderTypeChange}
//             content={MessageTypesList}
//             trigger={['click']}
//             className='border border-gray-200 p-1 text-base rounded-md'
//             arrow={false}
//             onOpenChange={(open) => {
//               setIsHeaderTypeChange(open);
//             }}
//           >
//             <div className='text-sm weight-semibold text-[#2D3036] py-2.5 px-4'>
//               <p>{NODE_TYPES_CONVERTION[node?.component_type]}</p>
//             </div>
//           </Popover>
//         </div>
//         {node?.component_type ? (
//           ['CUSTOM']?.includes(node?.component_type) ? (
//             // <TemplateMessage
//             //   node={node}
//             //   onTriggerClick={onTriggerClick}
//             //   onChange={onChange}
//             // />
//             <>templa</>
//           ) : ['TEXT', 'MEDIA', 'INETRACTIVE']?.includes(
//               node?.component_type
//             ) ? (
//             <div>
//               <EditMessages
//                 node={node}
//                 onTriggerClick={onTriggerClick}
//                 onChange={onChange}
//               />
//             </div>
//           ) : (
//             <></>
//           )
//         ) : (
//           <BroadcastTrigger node={node} onChange={onChange} />
//         )}
//       </div>
//     </div>
//   );
// };

// export default NodeEditMode;
