import React from 'react';

const Close = ({ width = 24, height = 24, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.7057 7.70568C17.3159 7.31594 16.6841 7.31594 16.2943 7.70568C15.9051 8.09489 15.9045 8.72572 16.293 9.11568L18.17 11H9C8.44771 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H18.17L16.2946 14.8754C15.9051 15.2649 15.9043 15.8966 16.2925 16.2875C16.6826 16.6804 17.3181 16.6819 17.7096 16.2904L21.2929 12.7071C21.6834 12.3166 21.6834 11.6834 21.2929 11.2929L17.7057 7.70568ZM4 6C4 5.44772 4.44772 5 5 5H11C11.5523 5 12 4.55228 12 4C12 3.44772 11.5523 3 11 3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H11C11.5523 21 12 20.5523 12 20C12 19.4477 11.5523 19 11 19H5C4.44772 19 4 18.5523 4 18V6Z'
        fill={color}
      />
    </svg>
  );
};

export default Close;
