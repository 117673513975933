import React from "react";
import "./newRadio.css";
import { cn } from "helper/cn";

const NewRadioButton = ({
  title,
  onClick,
  selected,
  value,
  data,
  rootClassName,
  ...props
}) => {
  return (
    <div className={cn("new-round", rootClassName)}>
      <input
        type="checkbox"
        id={data?.id}
        name={data?.name}
        value={data?.id}
        checked={selected}
        onChange={(e) => onClick(e, data)}
      />
      <label htmlFor="checkbox" {...props}></label>
    </div>
  );
};

export default NewRadioButton;
