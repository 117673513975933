import React from "react";

const Favourite = ({ color, applyFill }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 15 15"
        fill={applyFill ? color : "none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.3638 2.22209L8.2437 3.99644C8.3637 4.24344 8.6837 4.48035 8.9536 4.5257L10.5485 4.7929C11.5684 4.9643 11.8083 5.7103 11.0734 6.4462L9.8335 7.6964C9.6236 7.9081 9.5086 8.3164 9.5736 8.6087L9.9285 10.1562C10.2085 11.3812 9.5636 11.855 8.4887 11.2148L6.9939 10.3226C6.7239 10.1613 6.2789 10.1613 6.004 10.3226L4.50913 11.2148C3.43925 11.855 2.78932 11.3761 3.06929 10.1562L3.42425 8.6087C3.48925 8.3164 3.37426 7.9081 3.16428 7.6964L1.92442 6.4462C1.1945 5.7103 1.42947 4.9643 2.44936 4.7929L4.04419 4.5257C4.30916 4.48035 4.62912 4.24344 4.74911 3.99644L5.629 2.22209C6.109 1.2593 6.8889 1.2593 7.3638 2.22209Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Favourite;
