import React from "react";

const Filters = ({ color }) => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.38122 9.42175C4.30751 7.87133 2.8297 6.16596 2.02278 5.20722C1.773 4.91044 1.69115 4.69325 1.64194 4.31067C1.47343 3.00067 1.38918 2.34567 1.77329 1.92283C2.15742 1.5 2.8367 1.5 4.19528 1.5H13.8047C15.1633 1.5 15.8426 1.5 16.2267 1.92283C16.6108 2.34567 16.5266 3.00067 16.3581 4.31067C16.3088 4.69326 16.227 4.91045 15.9772 5.20722C15.1692 6.16718 13.6884 7.87558 11.6105 9.42792C11.4225 9.56842 11.2986 9.79725 11.2756 10.0512C11.0698 12.3267 10.8799 13.573 10.7618 14.2035C10.5711 15.2214 9.12767 15.8338 8.355 16.3802C7.89508 16.7055 7.33692 16.3183 7.27732 15.8148C7.16369 14.8551 6.94968 12.9053 6.71606 10.0512C6.69508 9.79492 6.57072 9.56342 6.38122 9.42175Z"
          stroke={color}
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Filters;
