// export const convertingDateAndTime = (data) => {
//   let date = data?.split("T")[0];
//   let Time = data?.split("T")[1];
//   let properDate = new Date(
//     date?.split("-")[0],
//     date?.split("-")[1] - 1,
//     date?.split("-")[2],
//     Time?.split(":")[0],
//     Time?.split(":")[1]
//   );
//   return properDate;
// };
import moment from "moment";

export const formatLocalDate = (date) => {
  const currentTime = moment();
  return moment(date)
    .set({
      hour: currentTime.hour(),
      minute: currentTime.minute(),
      second: currentTime.second(),
      millisecond: currentTime.millisecond(),
    })
    .format("YYYY-MM-DD HH:mm:ss.SSSSSS000");
};

export const formatLocalEndDate = (date) => {
  return moment(date)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format("YYYY-MM-DD HH:mm:ss.SSSSSS000");
};

export const formatDate = (date) => {
  return date?.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDateFilter = (date) => {
  if (!date) return "Invalid date";
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) return "Invalid date"; 

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
