import React, { useEffect, useRef, useState } from 'react';
import { Modal, Popover } from 'antd';

// api
import { whatsappBusinessNumbers } from '../../../../../../api/Api';

// assets
import { images } from '../../../../../../assets/images';

// helper
import { cn } from '../../../../../../helper/cn';

// redux
import {
  useAspDispatch,
  useAspSelector,
} from '../../../../../../test/jest-redux-hooks';
import { update } from '../../../../../../reduxToolkit/SequenceSlice';

// hooks
import useToggle from 'hooks/useToggle';
import { useFileUploader } from '../../../../../../hooks/useFileUploader';

// components
import Loader from '../../../../../../components/commonComponents/Loader/Index';
import { ICONS } from '../../../../../../assets/icons';
import TemplateListContainer from '../../../../../../components/commonComponents/templates/TemplateListContainer';
import Header from '../../../../../../components/commonComponents/modals/Header';
import Tabs from '../Tabs';

const SEND_SETTINGS = [
  {
    id: 1,
    label: 'Send Now',
  },
  {
    id: 2,
    label: 'Send Later',
  },
];

export default function New({ data, open, onFilesSelected }) {
  const { currentBrand } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [isLoading, setIsLoading] = useToggle(false);
  const [isOpen, setIsOpen] = useToggle(false);
  const [isOpenModal, setIsOpenModal] = useToggle(false);
  const [list, setList] = useState([]);
  const dropFilesref = useRef();

  const {
    uploading,
    setUploading,
    selectedFile,
    selectedFileFormat,
    setSelectedFile,
    responseFile,
    uploadFiles,
    handleDropFiles,
    error,
    setError,
    clearFiles,
    setSelectedFileFormat,
    setResponseFile,
  } = useFileUploader();

  useEffect(() => {
    if (dropFilesref?.current?.focus()) {
      dropFilesref?.current?.addEventListener('drop', handleDrop);

      return () => {
        dropFilesref?.current?.removeEventListener('drop', handleDrop);
      };
    }
  }, []);

  useEffect(() => {
    if (currentBrand) getwhatsappBusinessNumbers(currentBrand?.brand_id);
  }, [currentBrand]);

  const getwhatsappBusinessNumbers = async (brand_id) => {
    setIsLoading(true);
    try {
      const res = await whatsappBusinessNumbers(brand_id);
      setList(res?.data?.wapp_business_num);
      count = res?.data?.wapp_business_num_count;
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFile)
      handleUpdate({
        primaryKey: 'trigger',
        secondaryKey: 'selected',
        key: 'data',
        data: {
          file: selectedFile,
        },
      });
  }, [selectedFile]);

  const selectOption = (option) => {
    handleUpdate({
      primaryKey: 'trigger',
      secondaryKey: 'selected',
      key: 'data',
      data: { sendFrom: option },
      // key: 'sendFrom',
      // value: option,
    });
  };

  const isOptionSelected = (option) => {
    return option?.id === data?.sendFrom?.id;
  };

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const res = await uploadFiles(event);
      handleUpdate({
        primaryKey: 'trigger',
        secondaryKey: 'selected',
        key: 'data',
        data: {
          fileUrl: res,
        },
      });
    }
  };
  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      const res = await handleDropFiles(newFiles, ['.xlsx']);
      handleUpdate({
        primaryKey: 'trigger',
        secondaryKey: 'selected',
        key: 'data',
        data: {
          fileUrl: res,
        },
      });
    }
  };

  const handleUpdate = ({
    primaryKey = null,
    secondaryKey = null,
    data = null,
    key,
    value,
  }) => {
    dispatch(
      update({
        primaryKey: primaryKey,
        secondaryKey: secondaryKey,
        key: key,
        value: value,
        data: data,
      })
    );
  };

  const handleCanceltemplateModal = () => setIsOpenModal(false);

  return (
    <div>
      <div className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)] '>
        <div className='flex flex-col gap-0.5'>
          <h5>Send From</h5>
          <p>Select a WhatsApp number you would like to send messages.</p>
        </div>
        <Popover
          open={isOpen}
          placement='bottomLeft'
          arrow={false}
          content={
            <ul
              className={`w-full min-w-[27rem] m-0 list-none rounded-2xl flex-col gap-1.5 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-2.5 ${
                isOpen ? 'flex' : 'hidden'
              }`}
            >
              {list?.map((option) => {
                return (
                  <li
                    key={option?.id}
                    className='w-full h-[80px] flex items-center justify-between px-2.5 py-1.5 border-b border-[var(--border-100)] last:border-0 cursor-pointer'
                    onClick={() => {
                      selectOption(option);
                      setIsOpen(false);
                      handleUpdate({
                        primaryKey: 'trigger',
                        secondaryKey: 'selected',
                        key: 'sendFrom',
                        value: option,
                      });
                    }}
                  >
                    <div className='flex flex-col gap-2.5'>
                      <div className='flex items-center justify-between gap-5'>
                        <p>{option?.send_from_phone_name}</p>
                      </div>
                      <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-1.5 text-sm weight-medium text-[#616874] pr-3.5'>
                          {option?.country_code}
                          {option?.send_from_phone_number}
                        </div>
                        <div className='flex items-center gap-1.5 text-xs weight-semibold text-[var(--contentText)]  border-x border-[var(--border-100)] px-3.5'>
                          <p className='size-2.5 bg-[var(--primary)] rounded-sm'></p>
                          <p>High</p>
                        </div>
                        <div className='flex items-center gap-1.5 text-xs weight-semibold text-[var(--contentText)] px-3.5'>
                          {option?.daily_messaging_limit}
                        </div>
                      </div>
                    </div>
                    {isOptionSelected(option) && (
                      <img
                        src={ICONS?.Selected}
                        alt='selected'
                        className='size-5'
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          }
        >
          <div
            className='w-full h-12 flex items-center justify-between border boder-[var(--border-50)] px-5 py-1 rounded-lg cursor-pointer'
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {data?.sendFrom?.send_from_phone_name ? (
              <div
                className={`flex-grow flex items-center gap-2.5 text-[var(--contentText)]`}
              >
                <p>{data?.sendFrom?.send_from_phone_name}</p>
                <p>
                  {' '}
                  {data?.sendFrom?.country_code}
                  {data?.sendFrom?.send_from_phone_number}
                </p>
              </div>
            ) : (
              <div className='w-full flex items-center font-md weight-medium text-[var(--font-300)]'>
                Please select
              </div>
            )}

            <div
              className={`transition-transform duration-200 ease-linear cursor-pointer ${
                isOpen ? '-rotate-180' : 'rotate-0'
              }`}
            >
              <div
                className={`border rounded-t-1.5 border-transparent border-t-[#777] translate-y-[25%]`}
              ></div>
            </div>
          </div>
        </Popover>
      </div>

      <div
        ref={dropFilesref}
        className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)]'
        onDrop={handleDrop}
        onDragOver={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <div className='flex flex-col gap-0.5'>
          <h5>Upload Audience</h5>
          <p>
            Upload a audience list to tailor your message to specific groups.
          </p>
        </div>
        {uploading ? (
          <div className='w-full h-32 bg-[var(--BG-50)] flex flex-col items-center justify-center border border-dashed rounded-2xl cursor-pointer'>
            <Loader Width={25} Height={25} />
          </div>
        ) : data?.fileUrl ? (
          <div>{data?.file?.name}</div>
        ) : (
          <div className=''>
            <input
              type='file'
              hidden
              id='browse'
              onChange={handleFileChange}
              accept='.xlsx'
            />
            <label
              htmlFor='browse'
              className={cn(
                'w-full h-32 bg-[var(--BG-50)] flex flex-col items-center justify-center gap-1 border border-dashed rounded-2xl cursor-pointer',
                error ? 'border-[var(--fontRed)]' : 'border-[var(--border-100)]'
              )}
            >
              <img src={images?.File} alt='upload image' />
              <p className='text-center'>
                Click or Drag & Drop the file to upload <br /> .xlsv
              </p>
            </label>
          </div>
        )}
        {error && (
          <p className='text-[var(--fontRed)] text-sm weight-medium'>
            Unsupported format
          </p>
        )}
      </div>

      <div className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)] '>
        <div className='flex flex-col gap-0.5'>
          <h5>Select Template</h5>
          <p>Select the approved templates to broadcast your audience.</p>
        </div>
        <div
          className='w-full h-auto py-1 flex items-center justify-center border border-[]'
          onClick={() => {
            setIsOpenModal(true);
          }}
        >
          Choose template
        </div>
      </div>

      <div className='flex flex-col gap-3 py-5 border-b border-[var(--border-100)] '>
        <div className='flex flex-col gap-0.5'>
          <h5>Send Settings</h5>
          <p>When should we send the broadcast?</p>
        </div>
        <Tabs tabs={SEND_SETTINGS} selected={{ id: 1 }} />
      </div>
      <Modal
        open={isOpenModal}
        centered
        footer={null}
        closable={false}
        mask={true}
        wrapClassName={'bg-[#00000095]'}
        onCancel={handleCanceltemplateModal}
        destroyOnClose
        width={'60vw'}
        style={{
          width: '100%',
          borderRadius: 20,
          boxShadow: '0px 10px 24px 0px rgba(79, 92, 128, 0.15)',
        }}
      >
        <Header
          title='Choose template'
          titleSize='largest'
          rootStyle='!px-[1.5vw] !py-[1vw]'
          handleCancel={handleCanceltemplateModal}
          dataTestId='templateCancel'
        />

        <TemplateListContainer className='h-[58vh]' />
      </Modal>
    </div>
  );
}
