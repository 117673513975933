import React, { useCallback, useState } from 'react';

// helper
import { cn } from 'helper/cn';

// hooks
import useToggle from '../../../../../../hooks/useToggle';

// redux
import { useAspDispatch } from '../../../../../../test/jest-redux-hooks';

// components
import Alert from '../../../../../../components/commonComponents/modals/Alert';
import { INITIAL_NEW_BROADCAST_DATA } from '../../../../constants/sequence';
import Icons from '../../Icons';
import Existing from './Existing';
import New from './New';

export default function BroadcastTrigger({ node, onChange }) {
  const {
    id,
    label,
    is_trigger,
    component_type,
    type,
    trigger_type,
    order,
    data,
    settings,
    status,
    coordinates,
  } = node;
  const { defaults } = settings;
  const { x, y } = coordinates;

  const dispatch = useAspDispatch();

  const [showConfirm, setShowConfirm] = useToggle();
  const [selectedBroadcast, setSelectedBroadcast] = useState(null);
  const [broadcastData, setBroadcastData] = useState({
    send: null,
    file: null,
    template: null,
    settings: null,
  });

  const typeStyles =
    'w-1/2 h-full min-h-9 flex items-center justify-center text-sm weight-medium rounded-lg cursor-pointer';

  const selected = useCallback(
    (value) =>
      value
        ? '!border text-[#6564CA] border-[#6564CA]'
        : '!border border-[#E8E8EA] text-[var(--contentText)]',
    [data]
  );

  const onSelectType = (type) => {
    // if (!defaults?.isExisting && !chackNewBroadcastChanges()) {
    //   setShowConfirm(true);
    // } else {
    onChange({
      type: 'UPDATE_NODE',
      id: id,
      primaryKey: 'settings',
      secondaryKey: 'defaults',
      data: {
        isExisting: type === 'existing' ? true : false,
      },
    });
    // }
  };

  const chackNewBroadcastChanges = useCallback(() => {
    const newData = {
      isExisting: data?.isExisting,
      file: data?.data?.file,
      fileUrl: data?.data?.fileUrl,
      sendFrom: data?.data?.sendFrom,
      template: data?.data?.template,
      settings: data?.data?.settings,
    };

    const compare =
      JSON.stringify(newData) === JSON.stringify(INITIAL_NEW_BROADCAST_DATA);

    return compare;
  }, [data]);

  const handleYes = () => {
    setShowConfirm(false);
    onChange({
      type: 'UPDATE_NODE',
      id: id,
      key: 'data',
      data: initial_data,
    });
    onChange({
      type: 'UPDATE_NODE',
      id: id,
      primaryKey: 'settings',
      secondaryKey: 'defaults',
      key: 'isEditMode',
      data: false,
    });
    onChange({
      type: 'ADD_NODE',
    });
  };

  const handleBroadcastData = ({ key, value }) => {
    const data = { ...broadcastData };
    data[key] = value;
    setBroadcastData(data);
  };

  return (
    <div className='w-full min-w-[450px] h-full rounded-2xl overflow-hidden select-none'>
      <div className=''>
        <div
          className={cn(
            'flex items-center justify-between gap-5 px-5 py-2.5 bg-[#04B8A2] text-white'
          )}
        >
          <div className='flex items-center gap-5'>
            <Icons
              keyProp={node?.label?.toLowerCase()}
              bgProp={'transparent'}
            />
            <p className='capitalize'>{node?.label}</p>
          </div>
        </div>

        <div className='w-full px-6 pt-6 pr-1 bg-white'>
          <div className='w-full max-h-[400px] pr-6 flex flex-col gap-5 overflow-y-scroll listScroll'>
            <p>Select or create broadcast for your sequence.</p>
            <div className='w-full h-9 flex items-center'>
              <div
                className={cn(
                  typeStyles,
                  'border-r-0 rounded-r-none',
                  selected(node?.isExisting)
                )}
                onClick={() => {
                  onSelectType('existing');
                }}
              >
                Existing
              </div>
              <div
                className={cn(
                  typeStyles,
                  'rounded-l-none border-l-0',
                  selected(!node?.isExisting)
                )}
                onClick={() => {
                  onSelectType('new');
                }}
              >
                Create New
              </div>
            </div>
            {node?.isExisting ? (
              <div className='w-full'>
                <Existing
                  data={node}
                  onChange={onChange}
                  selectedBroadcast={selectedBroadcast}
                  setSelectedBroadcast={setSelectedBroadcast}
                />
              </div>
            ) : (
              <div>
                <New data={node} onChange={onChange} />
              </div>
            )}
          </div>
        </div>

        <div className='w-full h-fit flex justify-end px-6 py-2.5 bg-white'>
          <button
            className='w-fit h-8 bg-[var(--primary)] text-white px-4 py-1 rounded-md'
            onClick={() => {
              handleYes();
            }}
          >
            Ok
          </button>
        </div>
      </div>
      <Alert
        open={showConfirm}
        setOpen={setShowConfirm}
        content='changes'
        yesContent='Ok'
        noContent='Cancel'
        handleNo={() => setShowConfirm(false)}
        handleYes={handleYes}
      />
    </div>
  );
}

/// demo
const initial_data = {
  type: 'template',
  category: 'MARKETING',
  template: [
    {
      type: 'HEADER',
      format: 'IMAGE',
      example: {
        header_handle: [
          'https://storage.googleapis.com/assets-preprod-gainwix/temp/media_1732084198987.jpg',
        ],
      },
    },
    {
      text: 'This is your first marketing template\n Grab cool offers using code {{custom-variable1}}',
      type: 'BODY',
      example: {
        body_text: [['OFFERSUMMER2000']],
      },
    },
    {
      type: 'BUTTONS',
      buttons: [
        {
          id: 'a70ebac28df923ec6b3c',
          text: 'Interested',
          type: 'QUICK_REPLY',
        },
        {
          id: '3004834cf5ea68852b70',
          text: 'Not Interested',
          type: 'QUICK_REPLY',
        },
        {
          id: '4e240bb9fb52e1e57942',
          text: 'Send More like This',
          type: 'QUICK_REPLY',
        },
      ],
    },
  ],
};
