import { createSlice } from '@reduxjs/toolkit';

import {
  INITIAL_EXISTING_BROADCAST_DATA,
  INITIAL_NEW_BROADCAST_DATA,
} from 'modules/campaigns/constants/sequence';

const initialNode = {
  id: '1',
  label: 'Select Trigger',
  is_trigger: true,
  isEditMode: true,
  component_type: null,
  node_type: 'trigger',
  order: 1,
  data: {},
  settings: {},
  status: 0,
  coordinates: {
    x: 500,
    y: 50,
  },
};

const initialEdge = {
  from: '1',
  to: '3',
  colors: { from: '#6940F2', to: '#6940F2' },
};

const initialState = {
  nodes: [
    {
      id: '1',
      label: 'Select Trigger',
      is_trigger: true,
      component_type: null,
      type: 'TRIGGER',
      trigger_type: null,
      order: 1,
      data: null,
      settings: {
        defaults: {
          isEditMode: false,
          isExpand: false,
          isSelected: false,
        },
      },
      status: 'NEW',
      coordinates: {
        x: 500,
        y: 50,
      },
    },
  ],
  edges: [{ from: '1', to: '3', colors: { from: '#6940F2', to: '#6940F2' } }],
  undoStack: [],
  redoStack: [],
  view: { scale: 1, offsetX: 0, offsetY: 0 },
};

export const sequenceSlice = createSlice({
  name: 'Sequence',
  initialState,
  reducers: {
    update: (state, action) => {
      const {
        primaryKey = null,
        secondaryKey = null,
        data = null,
        key,
        value,
      } = action.payload;
      if (!data) {
        if (primaryKey && !secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [key]: value,
          };
        } else if (primaryKey && secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [secondaryKey]: {
              ...state[primaryKey][secondaryKey],
              [key]: value,
            },
          };
        } else {
          state[key] = value;
        }
      } else if (data) {
        if (primaryKey && secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [secondaryKey]: {
              ...state[primaryKey][secondaryKey],
              [key]: { ...state[primaryKey][secondaryKey][key], ...data },
            },
          };
        } else if (primaryKey && !secondaryKey && key) {
          state[primaryKey] = {
            ...state[primaryKey],
            [key]: { ...state[primaryKey][key], ...data },
          };
        } else if (!primaryKey && !secondaryKey && key) {
          state[key] = {
            ...state[key],
            [key]: { ...state[key], ...data },
          };
        }
      }
    },

    saveStateToUndo: (state, action) => {
      undoStack.push({ nodes: [...state.nodes], edges: [...state.edges] });
      redoStack = [];
    },

    undo: (state, action) => {
      // const { nodes, edges } = action.payload;
      if (state.undoStack.length > 0) {
        const lastState = state.undoStack.pop();
        redoStack.push({ nodes: [...state.nodes], edges: [...edges] });
        state.nodes = lastState.nodes;
        state.edges = lastState.edges;
      }
    },

    redo: (state, action) => {
      // const { nodes, edges } = action.payload;
      if (state.redoStack.length > 0) {
        const lastState = state.redoStack.pop();
        undoStack.push({ nodes: [...state.nodes], edges: [...edges] });
        state.nodes = lastState.nodes;
        state.edges = lastState.edges;
      }
    },

    updateView: () => {},

    // nodes
    addNode: (state, action) => {
      state.nodes = [...state.nodes, action.payload];
    },

    updateNode: (state, action) => {
      state.nodes = [...state.nodes, action.payload];
    },

    updateBroadCastType: (state, action) => {
      const { type, key, value } = action.payload;
      if (type === 'existing') {
        state.trigger = {
          isOpen: state?.trigger?.isOpen,
          isExisting: true,
          selected: {
            ...state.trigger.selected,
            data: {
              isExisting: true,
              isOpen: state?.trigger?.selected?.isOpen,
              isExpand: state?.trigger?.selected?.isExpand,
              ...INITIAL_EXISTING_BROADCAST_DATA,
            },
          },
        };
      }

      if (type === 'new') {
        state.trigger = {
          isOpen: state?.trigger?.isOpen,
          isExisting: false,
          selected: {
            ...state.trigger.selected,
            data: {
              isExisting: false,
              isOpen: state?.trigger?.selected?.isOpen,
              isExpand: state?.trigger?.selected?.isExpand,
              ...INITIAL_NEW_BROADCAST_DATA,
            },
          },
        };
      }
    },

    updateBroadcastData: (state, action) => {
      const { type, key, value } = action.payload;
      state[primaryKey] = {
        ...state[primaryKey],
        [key]: value,
      };
    },

    reset: (state, action) => {
      state.nodes = [];
      state.trigger = {
        selected: null,
        isExisting: true,
      };
    },
  },
});

export const { update, undo, redo, updateBroadCastType, reset } =
  sequenceSlice.actions;

export default sequenceSlice.reducer;
