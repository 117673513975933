import React from 'react';
import { Popover } from 'antd';

// assets
import IconContainer from 'assets/customSVG/sequence/actions/IconContainer';

// helpers
import { cn } from 'helper/cn';

// constants
import {
  ALLOWED_BODY_NODE_TYPES,
  ALLOWED_BUTTONS_NODE_TYPES,
  ALLOWED_FOOTER_NODE_TYPES,
  ALLOWED_HEADER_NODE_TYPES,
  BUTTON_TYPES,
  FOOTER_TYPES,
  MEDIA_TYPES,
  NONE_TYPE,
} from '../../../constants/sequence';
import {
  ACTIONS_LIST,
  NODE_TYPES_CONVERTION,
} from '../../../constants/triggers';

// components
import BodyMessage from './BodyMessage';
import Tabs from './Tabs';
import useToggle from '../../../../../hooks/useToggle';
import DefaultReplay from './DefaultReplay';
import HeaderMessage from './HeaderMessage';
import Header from '../../../../../components/commonComponents/modals/Header';
import TemplateListContainer from '../../../../../components/commonComponents/templates/TemplateListContainer';

const EditMessages = ({ type, node, onTriggerClick, onChange }) => {
  const [isHeaderTypeChange, setIsHeaderTypeChange] = useToggle(false);

  const MessageTypesList = (
    <div className='w-full min-w-full flex flex-col gap-5 p-4'>
      {ACTIONS_LIST?.map((t) => {
        return (
          <div
            className='w-full flex items-center gap-2.5 cursor-pointer'
            onClick={() => {
              onTriggerClick({ data: t, node: node });
              setIsHeaderTypeChange(false);
            }}
          >
            <IconContainer color={t?.bg}>
              <t.icon color={t?.color} />
            </IconContainer>
            <p>{t.label}</p>
          </div>
        );
      })}
    </div>
  );

  const title = 'text-sm weight-semibold text-[var(--font-600)] pb-2.5';

  const HEADER_TYPES = []?.includes(node?.component_type)
    ? [NONE_TYPE, ...MEDIA_TYPES]
    : MEDIA_TYPES;

  return (
    <div className='w-full max-w-[450px] h-auto rounded-2xl border border-gray-200'>
      <div className='p-2.5 bg-[#04B8A2] rounded-t-2xl rounded-b-md'>
        <p className=''>Send Message</p>
      </div>
      <div className='px-3.5 bg-white'>
        <div className='py-5 border-b border-gray-200'>
          <h5 className={cn('', title)}>Message Type</h5>
          <Popover
            open={isHeaderTypeChange}
            content={MessageTypesList}
            trigger={['click']}
            className='border border-gray-200 p-1 text-base rounded-md'
            arrow={false}
            onOpenChange={(open) => {
              setIsHeaderTypeChange(open);
            }}
          >
            <div className='text-sm weight-semibold text-[#2D3036] py-2.5 px-4'>
              <p>{NODE_TYPES_CONVERTION[node?.component_type]}</p>
            </div>
          </Popover>
        </div>

        <div className='flex flex-col gap-5 py-5'>
          {ALLOWED_HEADER_NODE_TYPES?.includes(node?.component_type) && (
            <HeaderMessage
              labelClassName={title}
              node={node}
              onChange={onChange}
            />
          )}

          {ALLOWED_BODY_NODE_TYPES?.includes(node?.component_type) && (
            <BodyMessage
              labelClassName={title}
              value={node?.data?.text?.body || ''}
              // node={node}
              onChange={(value) => {
                onChange({
                  type: 'UPDATE_NODE',
                  id: node?.id,
                  key: 'data',
                  data: {
                    type: node?.component_type?.toLowerCase(),
                    [`${node?.component_type?.toLowerCase()}`]: {
                      body: value,
                    },
                  },
                });
              }}
            />
          )}

          {ALLOWED_FOOTER_NODE_TYPES?.includes(node?.component_type) && (
            <div className=''>
              <h5 className={cn('pb-2.5', title)}>Footer</h5>
              <Tabs tabs={FOOTER_TYPES} />
            </div>
          )}

          {ALLOWED_BUTTONS_NODE_TYPES?.includes(node?.component_type) && (
            <div className=''>
              <h5 className={cn('pb-2.5', title)}>Button</h5>
              <Tabs tabs={BUTTON_TYPES} />
            </div>
          )}
        </div>

        <div className=''>
          <DefaultReplay />
        </div>

        <div className='flex items-center justify-end mb-10'>
          <button
            className='px-3.5 py-0.5 text-sm weight-semibold bg-[var(--primary)] text-white border border-[var(--primary)] rounded-md'
            onClick={() => {
              onChange({
                type: 'UPDATE_NODE',
                id: node?.id,
                primaryKey: 'settings',
                secondaryKey: 'defaults',
                key: 'isEditMode',
                data: false,
              });

              onChange({
                type: 'ADD_NODE',
              });
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMessages;

// import { Popover } from 'antd';
// import React from 'react';

// // assets
// import IconContainer from 'assets/customSVG/sequence/actions/IconContainer';

// // helpers
// import { cn } from 'helper/cn';

// // constants
// import {
//   ALLOWED_BODY_NODE_TYPES,
//   ALLOWED_BUTTONS_NODE_TYPES,
//   ALLOWED_FOOTER_NODE_TYPES,
//   ALLOWED_HEADER_NODE_TYPES,
//   BUTTON_TYPES,
//   FOOTER_TYPES,
//   MEDIA_TYPES,
//   NONE_TYPE,
// } from '../../../constants/sequence';
// import { ACTIONS_LIST } from '../../../constants/triggers';

// // components
// import useToggle from '../../../../../hooks/useToggle';
// import BodyMessage from './BodyMessage';
// import DefaultReplay from './DefaultReplay';
// import HeaderMessage from './HeaderMessage';
// import Tabs from './Tabs';

// const EditMessages = ({ type, node, onTriggerClick, onChange }) => {
//   const title = 'text-sm weight-semibold text-[var(--font-600)] pb-2.5';

//   const HEADER_TYPES = []?.includes(node?.component_type)
//     ? [NONE_TYPE, ...MEDIA_TYPES]
//     : MEDIA_TYPES;

//   return (
//     <>
//       <div className='flex flex-col gap-5 py-5'>
//         {ALLOWED_HEADER_NODE_TYPES?.includes(node?.component_type) && (
//           <HeaderMessage
//             labelClassName={title}
//             node={node}
//             onChange={onChange}
//           />
//         )}

//         {ALLOWED_BODY_NODE_TYPES?.includes(node?.component_type) && (
//           <BodyMessage
//             labelClassName={title}
//             value={node?.data?.text?.body || ''}
//             // node={node}
//             onChange={(value) => {
//               onChange({
//                 type: 'UPDATE_NODE',
//                 id: node?.id,
//                 key: 'data',
//                 data: {
//                   type: node?.component_type?.toLowerCase(),
//                   [`${node?.component_type?.toLowerCase()}`]: {
//                     body: value,
//                   },
//                 },
//               });
//             }}
//           />
//         )}

//         {ALLOWED_FOOTER_NODE_TYPES?.includes(node?.component_type) && (
//           <div className=''>
//             <h5 className={cn('pb-2.5', title)}>Footer</h5>
//             <Tabs tabs={FOOTER_TYPES} />
//           </div>
//         )}

//         {ALLOWED_BUTTONS_NODE_TYPES?.includes(node?.component_type) && (
//           <div className=''>
//             <h5 className={cn('pb-2.5', title)}>Button</h5>
//             <Tabs tabs={BUTTON_TYPES} />
//           </div>
//         )}
//       </div>

//       <div className=''>
//         <DefaultReplay />
//       </div>

//       <div className='flex items-center justify-end mb-10'>
//         <button
//           className='px-3.5 py-0.5 text-sm weight-semibold bg-[var(--primary)] text-white border border-[var(--primary)] rounded-md'
//           onClick={() => {
//             onChange({
//               type: 'UPDATE_NODE',
//               id: node?.id,
//               primaryKey: 'settings',
//               secondaryKey: 'defaults',
//               key: 'isEditMode',
//               data: false,
//             });

//             onChange({
//               type: 'ADD_NODE',
//             });
//           }}
//         >
//           OK
//         </button>
//       </div>
//     </>
//   );
// };

// export default EditMessages;
