export const VIEW_TYPES = [
  {
    id: 1,
    label: "Overview",
    key: "overview",
    disabled: false,
  },
  {
    id: 2,
    label: "Execution",
    key: "execution",
    disabled: false,
  },
];
