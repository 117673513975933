import { isValidHttpsUrl } from "helper/checkUrls";

import { getCTAButtons } from "components/commonComponents/templates/Buttons/utils";

export const checkOptout = (buttons) => {
  let optoutFilter = [];
  buttons?.map((b) => {
    if (b.format === "CUSTOM" && b.text === "Stop promotions")
      optoutFilter.push(b);
  });
  return optoutFilter?.length === 0 ? true : false;
};

export const chekButtonsLength = (buttons, type) =>
  buttons?.filter((b) => b.type === type);

const buttonValidatations = (ctaButtons, type) => {
  return ctaButtons?.filter((b) => {
    if (type === "URL")
      return (
        b?.text &&
        b?.example?.[0] &&
        isValidHttpsUrl(b?.example?.[0]) &&
        b?.text?.length <= 25 &&
        b?.example?.[0]?.length <= 2000
      );
    if (type === "PHONE_NUMBER")
      return (
        b?.text &&
        b?.text.length <= 25 &&
        b?.phone_number &&
        b?.phone_number.slice(3).length <= 20
      );
    if (type === "COPY_CODE")
      return (
        b?.example && b?.example?.[0] !== "" && b?.example?.[0]?.length <= 15
      );
    if (format === "CUSTOM") return b?.text !== "" && b?.text?.length <= 25;
    if (format === "OPT-OUT") return b?.text !== "" && b?.text?.length <= 25;
  });
};

const customButtonValidatations = (customButtons, format) => {
  return customButtons?.filter((b) => {
    if (b?.format === format) return b.text !== "" && b.text?.length < 25;
  });
};

export const isButtonsValid = (buttons, ctaButtons, customButtons) => {
  const urlButtons =
    chekButtonsLength(buttons, "URL")?.length > 0
      ? buttonValidatations(ctaButtons, "URL")?.length ===
        getCTAButtons(ctaButtons, "URL")?.length
        ? true
        : false
      : true;

  const phonenumberButtons =
    chekButtonsLength(buttons, "PHONE_NUMBER")?.length > 0
      ? buttonValidatations(ctaButtons, "PHONE_NUMBER")?.length ===
        getCTAButtons(ctaButtons, "PHONE_NUMBER")?.length
        ? true
        : false
      : true;

  const copyButtons =
    chekButtonsLength(buttons, "COPY_CODE")?.length > 0
      ? buttonValidatations(ctaButtons, "COPY_CODE")?.length ===
        getCTAButtons(ctaButtons, "COPY_CODE")?.length
        ? true
        : false
      : true;

  const isCustom =
    chekButtonsLength(buttons, "QUICK_REPLY")?.length > 0
      ? customButtonValidatations(customButtons, "CUSTOM")?.length ===
        getCTAButtons(customButtons, "CUSTOM")?.length
        ? true
        : false
      : true;

  return urlButtons && phonenumberButtons && copyButtons && isCustom
    ? true
    : false;
};
