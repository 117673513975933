import React from 'react';

export default function Redo({
  width = 21,
  height = 12,
  color = '#D1D3D8',
  strokeWidth = 2,
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M13.6821 7.31836H19.6821M19.6821 7.31836V1.31836M19.6821 7.31836L16.6822 4.31834C15.4235 3.05966 13.8198 2.2025 12.074 1.85523C10.3282 1.50796 8.51858 1.68619 6.87404 2.36738C5.22951 3.04857 3.8239 4.20212 2.83496 5.68216C1.84603 7.16221 1.31819 8.90227 1.31819 10.6823'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
