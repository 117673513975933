import React from "react";

export default function VariableComponent({
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  variables,
}) {
  return (
    <div className="w-full h-[2.23vw] flex items-center gap-[2vw]">
      <label className="w-[10vw] font-md weight-bold text-[var(--font-600)]">
        {name}
      </label>
      <div
        className={`w-[33vw] h-full border-[0.08vw] px-[0.5vw] py-[0.3vw] rounded-[0.4vw] flex items-center ${
          value ? "border-[var(--card-border)]" : "border-red-500"
        }`}
        data-testid="add_variable_name"
        data-id={`variable_name_input click_${name}`}
      >
        <input
          type={name !== "{{mobile-number}}" ? "text" : "number"}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder ?? "Enter sample content"}
          className="w-full font-md weight-small"
          data-testid="add_variable_names"
          data-id={`variable_name_${name}`}
          // data-id={`${variables}_${name}`}
        />
      </div>
    </div>
  );
}
