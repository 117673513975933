import React, { useRef, useState } from "react";

// antd
import { Skeleton } from "antd";

// components
import ConversationListCard from "./CustomerPhoneNumberCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { ICONS } from "../../../../assets/icons";

const CustomerPhoneNumber = ({
  primaryLoading,
  loading,
  searchKey,
  conversationList,
  selectedPerson,
  onSelectPerson,
  onFavoriteClick,
  onResolvedClick,
  onConversationStarChange,
  onCloseConversation,
  count = 0,
  loadNextPage,
  favorite,
  resolved,
}) => {
  const conversationContainerRef = useRef();

  return (
    <div className="w-full h-[80vh] overflow-y-scroll  border-[var(--BG-25)]">
      <Skeleton
        rootClassName="w-full px-[1vw]"
        loading={loading}
        paragraph={{ rows: 15 }}
        active
      >
        <div
          className=" h-full  overflow-hidden overflow-y-scroll listScroll"
          id="scrollableDiv"
          ref={conversationContainerRef}
        >
          <InfiniteScroll
            dataLength={conversationList?.length}
            next={() => {
              if (conversationList?.length < count && !loading) loadNextPage();
            }}
            hasMore={conversationList?.length < count}
            scrollableTarget="scrollableDiv"
          >
            {conversationList?.length > 0 ? (
              conversationList?.map((item) => {
                return (
                  <div className="overflow-hidden m-10">
                    <ConversationListCard
                      key={item?.to_number}
                      item={item}
                      selectedPerson={selectedPerson}
                      onSelectPerson={onSelectPerson}
                      onFavoriteClick={onFavoriteClick}
                      onResolvedClick={onResolvedClick}
                      onConversationStarChange={onConversationStarChange}
                      onCloseConversation={onCloseConversation}
                      favorite={favorite}
                      conversationList={conversationList}
                      resolved={resolved}
                    />
                  </div>
                );
              })
            ) : (
              <div className="w-full min-w-[19vw] h-full flex-column items-center justify-center  mt-20">
                <img src={ICONS?.emptyLoader} alt="emptyLoader" />
                <span className="new-header3 pt-20">
                  You have no conversations
                </span>
                <span className="w-100 p-10 inbox12px-400 text-[#8796AF] text-center">
                  Begin your conversation by broadcasting messages to your
                  customers.
                </span>
              </div>
            )}
          </InfiniteScroll>
        </div>
      </Skeleton>
    </div>
  );
};

export default CustomerPhoneNumber;
