import React from 'react';
import { TRIGGERS_ICONS } from '../../constants/sequence';
import { cn } from '../../../../helper/cn';

const Icons = ({ keyProp, bgProp, colorProp, className }) => {
  const value = TRIGGERS_ICONS[keyProp];

  if (!value) return null;

  const { icon: Icon, color } = value;

  return (
    <div
      className={cn(
        'w-full h-full flex items-center justify-center rounded-md',
        className
      )}
      style={{
        background: bgProp ? bgProp : value?.bg ? value?.bg : 'transparent',
      }}
    >
      <Icon color={colorProp ? colorProp : color} />
    </div>
  );
};

export default Icons;
