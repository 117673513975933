import { updateCommonReducerData, updateCommonReducerValue } from "../helpers";
import {
  addNode,
  updateNode,
  updateNodesCoordination,
} from "../helpers/sequence/node";

export const INITIAL_STATE = {
  nodes: [
    {
      id: 1,
      label: "Select Trigger",
      is_trigger: true,
      component_type: null,
      type: "TRIGGER",
      trigger_type: null,
      order: 1,
      data: null,
      settings: {
        defaults: {
          isEditMode: false,
          isExpand: false,
          isSelected: false,
        },
      },
      status: "NEW",
      coordinates: {
        x: 200,
        y: 300,
      },
    },
  ],
  edges: [{ from: "1", to: "3", colors: { from: "#6940F2", to: "#6940F2" } }],
  undoStack: [],
  redoStack: [],
  view: { scale: 1, offsetX: 0, offsetY: 0 },
};

export function sequenceReducer(state, action) {
  const {
    type,
    primaryKey,
    secondaryKey,
    key,
    value,
    data = null,
    id,
  } = action;

  switch (type) {
    case "UPDATE":
      if (!data)
        return updateCommonReducerValue({
          state,
          primaryKey,
          secondaryKey,
          key,
          value,
          id,
        });
      else if (data)
        return updateCommonReducerData({
          state,
          primaryKey,
          secondaryKey,
          key,
          data,
          id,
        });

    case "ADD_NODE":
      return {
        ...state,
        nodes: addNode({ nodes: state.nodes, type: key, data: data }),
      };

    case "UPDATE_NODE":
      const updatedNodes = updateNode({
        nodes: state.nodes,
        id,
        data,
        primaryKey,
        secondaryKey,
        key,
      });

      return {
        ...state,
        nodes: updatedNodes,
      };

    case "UPDATE_NODE_COORDINATES":
      return {
        ...state,
        nodes: updateNodesCoordination({ nodes: state.nodes, id, data }),
      };

    case "reset":
      return INITIAL_STATE;
  }
}
