import noData from "../images/home/no_campaign_data.svg";

import BrandAuthenticationIcon from "./Authentication.svg";
import CampaignBackIcon from "./Back.svg";
import BrandCreditIcon from "./CreditIcon.svg";
import CampaignDeliveredIcon from "./Deliver.svg";
import BrandPlanIcon from "./FreePlan.svg";
import BrandMarketingIcon from "./Marketing.svg";
import MessagePreviewIcon from "./MessagePreview.svg";
import CampaignOpenedIcon from "./Opened.svg";
import WhatsappProfileIcon from "./Profile.svg";
import CampaignProspectsIcon from "./Prospects.svg";
import BrandServiceIcon from "./Service.svg";
import BrandImageIcon from "./User_1.svg";
import BrandUtilityIcon from "./Utility.svg";
import wabaNotConnected from "./WABA_not_connected.svg";
import CampaignShareIcon from "./campaignShare.svg";
import CampaignSpentIcon from "./campaignspent.svg";
import BroadcastCsvIcon from "./download.svg";
import emptyImage from "./empty.svg";
import leftLayout from "./leftLayout.png";
import leftLayoutApton from "./leftLayoutApton.png";
import noCampaign from "./no-campaign.png";
import templatePreviewBottom from "./template-preview-bottom.svg";
import BrandUserIcon from "./user2.svg";
import BrandUser_2Image from "./user_3.svg";
import BroadcastWhatsappImage from "./whatsapplogo.svg";

import broadcast from "./campaigns/broadcast.svg";
import file from "./campaigns/file.svg";
import flag from "./campaigns/flag.svg";
import sequence from "./campaigns/sequence.svg";
import sms from "./campaigns/sms.svg";
import whatsapp from "./campaigns/whatsapp.svg";
import about1 from "./home/about1.svg";
import about2 from "./home/about2.svg";
import about3 from "./home/about3.svg";
import aboutBg from "./home/aboutBg.svg";
import whatsappInactiveBg from "./home/whatsappInactiveBg.svg";

export const images = {
  // leftLayout:
  //   "https://storage.googleapis.com/asp-v1-images/assets/ui/leftlayout.svg",
  BackgroundImage:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/image_1.svg",
  ContentImage:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/content.svg",
  WhatsappCsvIcon:
    "https://storage.googleapis.com/asp-v1-images/assets/ui/whastappcsv.svg",

  leftLayout: leftLayout,
  leftLayoutApton: leftLayoutApton,

  noCampaign: noCampaign,

  CampaignProspectsIcon: CampaignProspectsIcon,
  CampaignDeliveredIcon: CampaignDeliveredIcon,
  CampaignOpenedIcon: CampaignOpenedIcon,
  CampaignShareIcon: CampaignShareIcon,
  CampaignSpentIcon: CampaignSpentIcon,

  CampaignBackIcon: CampaignBackIcon,

  BroadcastWhatsappImage: BroadcastWhatsappImage,

  MessagePreviewIcon: MessagePreviewIcon,
  WhatsappProfileIcon: WhatsappProfileIcon,

  BroadcastCsvIcon: BroadcastCsvIcon,

  //brand
  BrandImageIcon: BrandImageIcon,
  BrandUserIcon: BrandUserIcon,
  BrandUser_2Image: BrandUser_2Image,
  BrandPlanIcon: BrandPlanIcon,
  BrandMarketingIcon: BrandMarketingIcon,
  BrandUtilityIcon: BrandUtilityIcon,
  BrandAuthenticationIcon: BrandAuthenticationIcon,
  BrandServiceIcon: BrandServiceIcon,
  BrandCreditIcon: BrandCreditIcon,
  BrandPlanIcon: BrandPlanIcon,

  // template
  TemplatePreviewBottom: templatePreviewBottom,

  // campaign
  NoData: noData,

  // home
  AboutBg: aboutBg,
  About1: about1,
  About2: about2,
  About3: about3,
  WhatsappInactiveBg: whatsappInactiveBg,

  // campaigns
  WhatsApp: whatsapp,
  Sms: sms,
  Flag: flag,
  Broadcast: broadcast,
  Sequence: sequence,
  File: file,

  // contact
  emptyImage: emptyImage,
  WABANotConnected: wabaNotConnected,
};
