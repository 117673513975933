import React, { useEffect, useState } from "react";

// assets
import { images } from "assets/images/index";
import { ICONS } from "assets/icons";

import { connectWhatsapp } from "../constants/index";

const WhatAppBusiness = () => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedText, setSelectedText] = useState({
    subtitle: "",
    description: "",
    button: "",
  });
  const [apiSuccess, setApiSuccess] = useState(false);

  const handleDropDown = () => {
    setIsContentVisible(!isContentVisible);
  };

  // const handleClick = (id, subtitle, description, button) => {
  //   setSelectedId(id);
  //   setSelectedText({ subtitle, description, button });
  // };
  const handleClick = ({ id, subtitle, description, button }) => {
    setSelectedId(id);
    setSelectedText({ subtitle, description, button });
  };

  const current = 3;
  const percentage = (current / 5) * 100;

  const handleButtonClick = () => {
    switch (selectedId) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      case 5:
        break;
      default:
    }
  };

  const getButtonStyle = () => {
    if (apiSuccess) {
      return "bg-[#05805C24] text-[#05805C]";
    }
    return selectedId === 1
      ? "bg-[#1778F2] text-white"
      : "bg-[#F6A72329] text-[#F6A723]";
  };

  useEffect(() => {
    if (isContentVisible && connectWhatsapp?.length > 0) {
      const firstItem = connectWhatsapp[0];
      setSelectedId(firstItem.id);
      setSelectedText({
        subtitle: firstItem.subtitle,
        description: firstItem.description,
        button: firstItem.button,
      });
    }
  }, [isContentVisible, connectWhatsapp]);

  return (
    // <div
    //   className={`${
    //     isContentVisible ? "h-[28vw]" : "h-[6.5vw]"
    //   } bg-white rounded-[1vw] pl-[1vw] py-[1.5vw] pr-[0.5vw] transition-all duration-400 ease-in-out`}
    // >
    <div
      className={` bg-white rounded-[1vw] pl-[2vw] py-[1.5vw] pr-[1vw] transition-all duration-400 ease-in-out`}
    >
      <div
        className={`flex justify-between items-center ${
          isContentVisible && "pb-[1vw] border-b-[1px]"
        }`}
      >
        <div>
          <div className="flex items-center mb-[0.4vw]">
            <img src={ICONS?.whatsApp} className="w-[25px] h-[25px]" />
            <p className="text-[1.15rem] font-[500] pl-2">
              Congratulations! Your WhatsApp business API is live and ready to
              use 🎉
            </p>
          </div>
          <p className="text-[#616874] text-[1rem]">
            Let's set up your GainWix account together in easy steps.
          </p>
        </div>
        <div className="flex items-center mr-7">
          <div className="relative inline-block mr-4">
            {!apiSuccess ? (
              <div className="p-2 rounded-full border-[0.35vw] border-gray-200">
                <div
                  className="absolute inset-0 rounded-full"
                  style={{
                    background: `conic-gradient(#05805C ${percentage}%, transparent ${percentage}%, transparent 100%)`,
                    WebkitMask: "radial-gradient(transparent 60%, black 61%)",
                    mask: "radial-gradient(transparent 60%, black 61%)",
                  }}
                />
                <div className="flex items-center justify-center h-full">
                  <p className="font-semibold text-gray-600 text-sm">
                    {current}/{5}
                  </p>
                </div>
              </div>
            ) : (
              <div className="p-1.5 rounded-full border-[0.35vw] border-[#F6A723]">
                <img
                  src={ICONS?.homeAlert}
                  alt="homeWarning"
                  className="w-[2vw] h-[2vw]"
                />
              </div>
            )}
          </div>
          <img
            src={ICONS?.BroadcastLightChevronDown}
            className={`w-[25px] h-[25px] pointer transition-transform duration-500 ease-in-out ${
              isContentVisible ? "rotate-180" : "rotate-0"
            }`}
            onClick={handleDropDown}
          />
        </div>
      </div>
      {isContentVisible && (
        <div
          className={`py-5 p-2 transition-opacity duration-400 ease-in-out flex flex-row justify-center opacity-100 translate-y-0`}
          style={{ transition: "opacity 0.4s, transform 0.5s" }}
        >
          <div className="bg-[#F9F9FC] rounded-xl p-4 w-[22vw]">
            {connectWhatsapp?.map((data) => (
              <div
                key={data.id}
                className={`flex flex-row items-center p-3.5 pointer ${
                  selectedId === data?.id &&
                  "bg-white border border-[#E8E8EA] rounded-lg font-[500]"
                }`}
                onClick={() => handleClick(data)}
              >
                <img
                  src={apiSuccess ? ICONS?.homeNotComplete : ICONS?.homeSuccess}
                  alt="icon"
                  className="w-[1.5vw] h-[1.5vw] mr-2"
                />

                <p className="text-[0.8rem] pl-1 text-[#2D3036]">
                  {data?.title}
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center w-[70%] text-center px-8">
            <img src={ICONS?.homeWhatsapp} alt="homeWhatsapp" />
            <p className="text-[1.125rem] font-[600]">
              {selectedText?.subtitle}
            </p>
            <p className="text-[0.9rem] font-[400] text-[#616874] pt-2">
              {selectedText?.description}
            </p>
            <div className="flex-row items-center pt-4">
              <button
                className={`pointer font-[500] text-[0.9rem] py-2 px-3 rounded-md flex-row items-center ${getButtonStyle()}`}
                onClick={handleButtonClick}
              >
                {selectedText?.button}
                {selectedId !== 1 && (
                  <span className="pl-1.5">
                    <img src={ICONS?.accountsArrow} />
                  </span>
                )}
              </button>
              <button className="text-[#616874] font-[500] text-[0.9rem] pl-6 flex-row items-center">
                Watch Demo
                <span className="pl-1.5">
                  <img src={ICONS?.accountsArrow} />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatAppBusiness;
