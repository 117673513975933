import React from 'react';

const Instagram = ({ width = 22, height = 22, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_18989_125690)'>
        <path
          d='M15.0502 0.200195H6.95019C5.15998 0.200195 3.44309 0.911354 2.17722 2.17722C0.911354 3.44309 0.200195 5.15998 0.200195 6.95019V15.0502C0.200195 16.8404 0.911354 18.5573 2.17722 19.8232C3.44309 21.089 5.15998 21.8002 6.95019 21.8002H15.0502C16.8404 21.8002 18.5573 21.089 19.8232 19.8232C21.089 18.5573 21.8002 16.8404 21.8002 15.0502V6.95019C21.8002 5.15998 21.089 3.44309 19.8232 2.17722C18.5573 0.911354 16.8404 0.200195 15.0502 0.200195ZM19.7752 15.0502C19.7752 17.6557 17.6557 19.7752 15.0502 19.7752H6.95019C4.3447 19.7752 2.2252 17.6557 2.2252 15.0502V6.95019C2.2252 4.3447 4.3447 2.2252 6.95019 2.2252H15.0502C17.6557 2.2252 19.7752 4.3447 19.7752 6.95019V15.0502Z'
          fill={color}
        />
        <path
          d='M10.9996 5.6002C9.56748 5.6002 8.19397 6.16913 7.18127 7.18182C6.16858 8.19452 5.59965 9.56803 5.59965 11.0002C5.59965 12.4324 6.16858 13.8059 7.18127 14.8186C8.19397 15.8313 9.56748 16.4002 10.9996 16.4002C12.4318 16.4002 13.8053 15.8313 14.818 14.8186C15.8307 13.8059 16.3996 12.4324 16.3996 11.0002C16.3996 9.56803 15.8307 8.19452 14.818 7.18182C13.8053 6.16913 12.4318 5.6002 10.9996 5.6002ZM10.9996 14.3752C10.1049 14.3741 9.24705 14.0182 8.61435 13.3855C7.98164 12.7528 7.62572 11.895 7.62465 11.0002C7.62465 9.13855 9.13935 7.6252 10.9996 7.6252C12.8599 7.6252 14.3746 9.13855 14.3746 11.0002C14.3746 12.8605 12.8599 14.3752 10.9996 14.3752Z'
          fill={color}
        />
        <path
          d='M16.8038 5.91526C17.2012 5.91526 17.5234 5.59311 17.5234 5.19571C17.5234 4.79832 17.2012 4.47616 16.8038 4.47616C16.4064 4.47616 16.0843 4.79832 16.0843 5.19571C16.0843 5.59311 16.4064 5.91526 16.8038 5.91526Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_18989_125690'>
          <rect
            width='21.6'
            height='21.6'
            fill={color}
            transform='translate(0.200195 0.200195)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
