import React from "react";
import NewNode from "./NewNode";
import ExistingNode from "./ExistingNode";
import NodeDetails from "./NodeDetails";
import TriggerList from "../triggers/TriggerList";
import AddIcon from "../../../../../assets/customSVG/AddIcon";
import Icons from "../Icons";
import { cn } from "../../../../../helper/cn";
import { color } from "chart.js/helpers";

const Defaults = {
  broadcast: {
    bg: "#04B8A2",
    color: "white",
  },
};

const Node = ({
  id,
  type,
  is_trigger,
  component_type,
  trigger_type,
  label,
  order,
  data,
  settings,
  isEditMode,
  status,
  onChange,
}) => {
  return (
    <div>
      {data ? (
        // <ExistingNode
        //   id={id}
        //   type={type}
        //   is_trigger={is_trigger}
        //   component_type={component_type}
        //   trigger_type={trigger_type}
        //   label={label}
        //   order={order}
        //   data={data}
        //   settings={settings}
        //   isEditMode={isEditMode}
        //   status={status}
        // />
        <div className="relative flex flex-col items-center justify-center gap-2.5">
          <div
            className={cn(
              "group size-16 flex items-center justify-center rounded-2xl border-[3px] border-[#6940F2] group-hover:transition-all ease-linear duration-150",
              "bg-[#6940F216] hover:bg-[#6940F2]"
            )}
          >
            <div className="bg-white rounded-[30px] size-9 flex items-center justify-center">
              <Icons
                keyProp={label?.toLowerCase()}
                bgProp={"white"}
                colorProp={"#04B8A2"}
              />
            </div>
          </div>
          <div className="w-fit h-fit !text-xs text-center text-nowrap text-[#2D3036] weight-medium absolute -bottom-7">
            <div className="w-full flex items-center gap-1.5 text-lg -ml-2.5">
              <p className="min-w-6 min-h-6 w-6 h-6 p-1  flex items-center justify-center border border-[#2D3036] rounded-md">
                {id}
              </p>
              <p className="">{label}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex flex-col items-center justify-center gap-2.5">
          <div
            className={cn(
              "group size-16 flex items-center justify-center rounded-2xl border-[3px] border-[#6940F2] group-hover:transition-all ease-linear duration-150",
              "bg-[#6940F216] hover:bg-[#6940F2]"
            )}
          >
            <AddIcon
              width={50}
              height={50}
              stroke=""
              strokeWidth="2"
              rect={false}
              className="stroke-[#6940F2] group-hover:stroke-[#ffffff] group-hover:transition-all ease-linear duration-150 group-hover:scale-110 group-hover:rotate-180"
            />
          </div>
          <div className="w-fit h-fit !text-xs text-center text-nowrap text-[#2D3036] weight-medium absolute -bottom-5">
            <p className="">{label}</p>
          </div>
        </div>
        // <NewNode
        //   id={id}
        //   is_trigger={is_trigger}
        //   component_type={component_type}
        //   trigger_type={trigger_type}
        //   type={type}
        //   label={label}
        //   order={order}
        //   data={data}
        //   settings={settings}
        //   isEditMode={isEditMode}
        //   status={status}
        //   onChange={onChange}
        // />
      )}
    </div>
  );
};

export default Node;
