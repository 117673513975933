import React from 'react';

export default function ZoomIn({
  width = 19,
  height = 20,
  color = '#616874',
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M15.1223 14.3209C14.7253 13.9369 14.0922 13.9474 13.7082 14.3443C13.3242 14.7412 13.3347 15.3743 13.7317 15.7583L15.1223 14.3209ZM17.2048 19.1183C17.6017 19.5023 18.2348 19.4918 18.6188 19.0949C19.0028 18.698 18.9923 18.0649 18.5954 17.6809L17.2048 19.1183ZM7.9001 12.3996C7.9001 12.9519 8.34781 13.3996 8.9001 13.3996C9.45238 13.3996 9.9001 12.9519 9.9001 12.3996H7.9001ZM9.9001 6.39961C9.9001 5.84732 9.45238 5.39961 8.9001 5.39961C8.34781 5.39961 7.9001 5.84732 7.9001 6.39961H9.9001ZM5.9001 8.39961C5.34781 8.39961 4.9001 8.84732 4.9001 9.39961C4.9001 9.95189 5.34781 10.3996 5.9001 10.3996V8.39961ZM11.9001 10.3996C12.4524 10.3996 12.9001 9.95189 12.9001 9.39961C12.9001 8.84732 12.4524 8.39961 11.9001 8.39961V10.3996ZM15.7801 9.43961C15.7801 13.2172 12.7177 16.2796 8.9401 16.2796V18.2796C13.8223 18.2796 17.7801 14.3218 17.7801 9.43961H15.7801ZM8.9401 16.2796C5.16247 16.2796 2.1001 13.2172 2.1001 9.43961H0.100098C0.100098 14.3218 4.0579 18.2796 8.9401 18.2796V16.2796ZM2.1001 9.43961C2.1001 5.66198 5.16247 2.59961 8.9401 2.59961V0.599609C4.0579 0.599609 0.100098 4.55741 0.100098 9.43961H2.1001ZM8.9401 2.59961C12.7177 2.59961 15.7801 5.66198 15.7801 9.43961H17.7801C17.7801 4.55741 13.8223 0.599609 8.9401 0.599609V2.59961ZM13.7317 15.7583L17.2048 19.1183L18.5954 17.6809L15.1223 14.3209L13.7317 15.7583ZM9.9001 12.3996V9.39961H7.9001V12.3996H9.9001ZM9.9001 9.39961V6.39961H7.9001V9.39961H9.9001ZM5.9001 10.3996H8.9001V8.39961H5.9001V10.3996ZM8.9001 10.3996H11.9001V8.39961H8.9001V10.3996Z'
        fill={color}
      />
    </svg>
  );
}
