import React from "react";

const Delivered = ({ color }) => {
  return (
    <div>
      <svg
        width="15"
        height="10"
        viewBox="0 0 15 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.00061 6.01752L3.39535 8.52632L4.09599 7.79229M10.5796 1L6.43119 5.3459M4.42166 6.01752L6.8164 8.52632L14.0006 1"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default Delivered;
