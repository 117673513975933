import React, { useCallback, useEffect, useReducer } from 'react';
import {
  INITIAL_STATE,
  templateReducer,
} from '../../../../channels/reducers/templateReducer';
import {
  addLineBreake,
  replaceFormating,
  replaceVariablesForPreview,
} from '../../../../inbox/helper';
import { cn } from '../../../../../helper/cn';
import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getCTAButtons,
} from '../../../../../components/commonComponents/templates/Buttons/utils';
import CarouselCardPreview from '../../../../../components/commonComponents/templates/CarouselCardPreview';
import { ICONS } from '../../../../../assets/icons';

const Template = ({ category, categoryType, data }) => {
  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );

  const { custom, authentication, carousel } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  useEffect(() => {
    templateDispatch({
      type: 'update_template',
      data: {
        category: category,
        subcategory_type: categoryType,
        components: data?.template,
      },
    });
  }, [data.template]);

  const showFooter = useCallback(() => {
    return getCTAButtons(buttons, 'OPT_OUT')?.length < 1 ? true : false;
  }, [buttons]);

  const btnStyle =
    'w-full flex items-center justify-center gap-2 py-1 font-sm weight-medium border border-[var(--border-50)] cursor-default';

  return (
    <div className='w-full min-w-72 h-full relative'>
      <div className='w-full h-full overflow-y-scroll flex flex-col gap-2.5'>
        <div className='w-full h-auto'>
          {category === 'AUTHENTICATION' ? (
            <div className='min-h-16 bg-white rounded-2.5 py-2 overflow-y-scroll'>
              <div className='font-md weight-small px-2 break-words bg-white'>
                {authentication?.body}
              </div>
              <p className='w-full font-sm weight-small bg-white px-2 text-[var(--font-600)] pt-2'>
                {authentication?.content?.expiryTimeForCode
                  ? `This code expires in ${
                      authentication?.content?.expiryTime >= 1 &&
                      authentication?.content?.expiryTime <= 90
                        ? authentication?.content?.expiryTime
                        : 0
                    } Minutes.`
                  : ''}
              </p>
              <div className='h-auto px-2 bg-white rounded-b-lg mt-2.5 text-[#007AFF]'>
                {authentication?.buttons?.map((b, i) => {
                  return (
                    <div key={`authentication-preview-button-${i}`}>
                      <button className={`${btnStyle}`}>{b?.text}</button>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className='min-h-16 bg-white rounded-lg py-2 overflow-y-scroll'>
              {/* header preview */}
              <div className='w-full px-2 bg-white rounded-md h-full overflow-hidden'>
                {header?.format === 'TEXT' && (
                  <div className='h-full text-base weight-semibold break-words'>
                    {header?.example?.header_text?.[0] !== 0
                      ? replaceVariables(
                          header?.text,
                          header?.example?.header_text
                        )
                      : header?.text}
                    {/* {header?.text} */}
                  </div>
                )}
                {header?.format === 'IMAGE' &&
                  header?.example?.header_handle?.length > 0 && (
                    <img
                      src={header?.example?.header_handle?.[0]}
                      className='w-full h-full max-h-32 object-cover rounded'
                    />
                  )}
                {header?.format === 'VIDEO' &&
                  header?.example?.header_handle?.length > 0 && (
                    <video
                      className='object-cove rounded'
                      controls
                      autoplay='autoplay'
                      // loop
                    >
                      <source
                        src={header?.example?.header_handle?.[0]}
                        type='video/mp4'
                      />
                    </video>
                  )}
                {header?.format === 'DOCUMENT' &&
                  header?.example?.header_handle?.length > 0 && (
                    <iframe
                      title='cover'
                      className='imagePreview'
                      src={header?.example?.header_handle?.[0]}
                      type='application/pdf'
                      width='100%'
                      height='150px'
                    ></iframe>
                  )}
              </div>
              {/* body preview */}
              <p
                className='text-sm weight-small px-2 pt-2 break-words bg-white'
                dangerouslySetInnerHTML={{
                  __html:
                    bodyVariables?.length > 0
                      ? replaceVariablesForPreview(
                          addLineBreake(replaceFormating(body)),
                          bodyVariables
                        )
                      : addLineBreake(replaceFormating(body)),
                }}
              ></p>
              {/* footer preview */}
              {footer?.text && showFooter() && (
                <p className='w-full text-sm weight-small bg-white px-2 text-[var(--font-600)] pt-2'>
                  {footer?.text}
                </p>
              )}
              {!showFooter() && (
                <p className='w-full text-sm weight-small bg-white px-2 text-[var(--font-600)] pt-2'>
                  <>Not interested? Tap Stop promotions</>
                </p>
              )}
              {/* buttons preview */}
              <div className='h-auto px-2 bg-white rounded-b-2.5 mt-2.5 text-[#007AFF] flex flex-col gap-2.5'>
                {buttons.map((b, i) => {
                  return (
                    <div className='' key={`preview-button-${i}`}>
                      {allowedCtaButtonTypes.includes(b?.type) && (
                        <div>
                          {b.type === 'URL' && (
                            <button className={`${btnStyle}`}>{b.text}</button>
                          )}
                          {b.type === 'PHONE_NUMBER' && (
                            <button className={`${btnStyle}`}>{b.text}</button>
                          )}

                          {b.type === 'COPY_CODE' && (
                            <button className={`${btnStyle}`}>
                              <span>Copy Offer Code</span>
                            </button>
                          )}
                        </div>
                      )}
                      {allowedCustomButtonTypes?.includes(b?.type) && (
                        <div className={`${btnStyle}`}>
                          <p>{b.text}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className='flex items-center justify-end mt-2.5'>
                <button className='text-sm'>Next Step</button>
              </div>
            </div>
          )}
        </div>
        <div className='w-full h-auto'>
          {category === 'CAROUSEL' && (
            <div className='min-h-16 flex gap-2.5 overflow-x-scroll pb-2 listScroll px-4'>
              {carousel?.cards?.map((c) => {
                return (
                  <div className='w-full min-w-48 min-h-[13rem]'>
                    <CarouselCardPreview data={c} carousel={carousel} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Template;
