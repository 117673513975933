import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
  useCallback,
} from "react";

// API
import { templateLists } from "../../../modules/channels/api/Api";

// assets
import { ICONS } from "assets/icons";
import VideoSvg from "assets/customSVG/VideoSvg";

// helper
import { getLanguageName } from "helper/setLanguageCode";
import { capitalizeFirstLetter } from "helper/capitalizeFirstLetter";

// utils
import InfiniteScroll from "react-infinite-scroll-component";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";

// hooks
import useToggle from "hooks/useToggle";
import { useSearch } from "hooks/useSearch";
import { useFileUploader } from "hooks/useFileUploader";
import { useTemplateVariables } from "hooks/templates/useTemplateVariables";

// reducers
// import {
//   INITIAL_STATE,
//   templateReducer,
// } from "hooks/templates/templateReducers";

// components
import Loader from "../Loader/Index";
import TemplateCard from "./TemplateCard";
import TemplatePreview from "./TemplatePreview";
import VariablesPreviewCard from "./VariablesPreviewCard";
import NoData from "components/commonComponents/Nodata/index";
import {
  INITIAL_STATE,
  templateReducer,
} from "../../../modules/channels/reducers/templateReducer";
import { formatCustomDataToAPI } from "../../../modules/channels/utils/common";
import { formatCarouelDataToAPI } from "../../../modules/channels/utils/carousel";
import SearchBar from "../searchBar";
import { Popover } from "antd";
import { SORT } from "../../../modules/campaigns/constants/campaignList";
import RadioButton from "../../form/radio/RadioButton";
import { cn } from "../../../helper/cn";
import CheckBoxSqure from "../checkbox/CheckBoxSqure";
import {
  CATEGORY_TYPE_INBOX,
  LANGUAGE_INBOX,
} from "../../../modules/inbox/constants";
import ClearFilters from "../../../assets/customSVG/ClearFilters";

let limit = 10;
let count = 0;
let page = 1;

export default function TemplateListContainer({
  isSending,
  className,
  sendTemplate,
  changeMedia = true,
  changeVariable = true,
  previewSelect,
  open,
  setSelectTemplate,
  setOpenTemplateList,
}) {
  const { currentBrand } = useAspSelector((state) => state?.app);
  const dispatch = useAspDispatch();

  const inputRef = useRef();

  const { uploading, selectedFile, setSelectedFile, uploadFiles, error } =
    useFileUploader();

  const [loading, setLoading] = useToggle(true);
  const [isLoadingNextPage, setIsLoadingNextPage] = useToggle(false);
  const { searchKey, setSearchKey, onChange: onSearch } = useSearch("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [cards, setCards] = useState([]);
  const [categoryChange, setCategoryChange] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const templateListContainerRef = useRef(null);

  // selected template
  const [useTemplates, setUseTemplates] = useToggle(false);

  const { variables, updateVariables, getVariablesFromStr } =
    useTemplateVariables();

  const [templateData, templateDispatch] = useReducer(
    templateReducer,
    INITIAL_STATE
  );

  const { id, custom, authentication, carousel } = templateData;

  const {
    header,
    headerVariable,
    body,
    bodyVariables,
    footer,
    buttons,
    buttonsOrder,
    ctaButtons,
    customButtons,
  } = custom;

  useEffect(() => {
    setCategoryChange(CATEGORY_TYPE_INBOX);
  }, []);

  useEffect(() => {
    page = 1;
    if (limit > 0)
      fetchTemplateLists(true, currentBrand?.brand_id, {
        page: page,
        limit: limit,
        name: searchKey,
        status: "ACTIVE",
        category: categoryId && categoryId.length > 0 ? categoryId : [2, 3],
      });
  }, [currentBrand, categoryId]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      page = 1;
      if (limit > 0)
        fetchTemplateLists(true, currentBrand?.brand_id, {
          page: page,
          limit: limit,
          name: searchKey,
          status: "ACTIVE",
          category: categoryId && categoryId.length > 0 ? categoryId : [2, 3],
        });
    }, 500);

    return () => clearTimeout(timeOut);
  }, [searchKey]);

  useEffect(() => {
    templateDispatch({
      type: "update_template",
      data: selectedTemplate,
    });
  }, [selectedTemplate]);

  useEffect(() => {
    setCards(carousel?.cards);
  }, [carousel?.cards]);

  const fetchTemplateLists = async (load, brandId, payload) => {
    if (brandId) {
      if (load) setLoading(true);
      if (!load) setIsLoadingNextPage(true);

      await templateLists(brandId, payload)
        .then((res) => {
          if (res && res.data?.templates?.length > 0) {
            if (page === 1) setTemplates(res?.data?.templates);
            else
              setTemplates([
                ...new Set([...templates, ...res?.data?.templates]),
              ]);
            count = res?.data?.template_count;
          } else {
            setTemplates([]);
          }
        })
        .finally(() => {
          if (load) setLoading(false);
          if (!load) setIsLoadingNextPage(false);
        });
    } else {
      setTemplates([]);
    }
  };

  const onCategoryChange = (values) => {
    const updatedCategory = categoryChange.map((item) => {
      if (item?.id === values?.id) {
        return { ...item, isSelected: !item?.isSelected }; // Toggle isSelected
      }
      return item;
    });
    const filterValues = updatedCategory?.filter(
      (item) => item?.isSelected === true && item.id
    );
    // Extracting only `id`
    const ids = filterValues?.map((item) => item?.category_id);

    setCategoryId(ids);

    setCategoryChange(updatedCategory);
  };

  const clearType = () => {
    const res = categoryChange?.map((t) => {
      return { ...t, isSelected: false };
    });

    setCategoryChange(res);
    setCategoryId([2, 3]);
  };

  const loadNextPage = () => {
    page += 1;
    fetchTemplateLists(false, currentBrand?.brand_id, {
      page: page,
      limit: limit,
      name: searchKey,
      status: "ACTIVE",
      category: categoryId && categoryId.length > 0 ? categoryId : [2, 3],
    });
  };

  const updateTemplate = (type, key, value) => {
    if (selectedTemplate)
      if (["HEADER", "BODY", "FOOTER", "BUTTONS"].includes(type)) {
        const filterType = selectedTemplate?.components?.filter(
          (c) => c.type === type
        );

        const filterWithoutType = selectedTemplate?.components?.filter(
          (c) => c.type !== type
        );

        const updatedData = {
          ...selectedTemplate,
          components: [
            ...filterWithoutType,
            {
              ...filterType?.[0],
              [key]: value,
            },
          ],
        };
        setSelectedTemplate(updatedData);
      } else {
        setSelectedTemplate({ ...selectedTemplate, [key]: value });
      }
  };

  // get the file name from media file
  const fileName = (url) => {
    const names = url?.split("/");
    return names?.[names.length - 1];
  };

  const errorStyle = "text-[var(--fontRed)] font-md weight-400 my-[0.5vw]";

  const onDataChange = ({
    type,
    primaryKey,
    secondaryKey,
    updateKey,
    id,
    key,
    value,
    text,
    data,
  }) => {
    const payload = {
      type: type,
      key: key,
      value: value,
    };

    if (primaryKey) payload["primaryKey"] = primaryKey;
    if (secondaryKey) payload["secondaryKey"] = secondaryKey;
    if (updateKey) payload["updateKey"] = updateKey;
    if (id) payload["id"] = id;
    if (text) payload["text"] = text;
    if (data) payload["data"] = data;

    templateDispatch(payload);
  };

  const currentCategory = useCallback(() => {
    return templateData?.categoryType;
  }, [templateData]);

  return (
    <div className="w-full h-full min-h-[70vh] max-h-[73vh] p-[1.5vw]">
      <div className="flex items-center justify-between mb-[1vw]">
        {useTemplates ? (
          <div className="flex items-center gap-[0.6vw]">
            <button
              className="cursor-pointer"
              onClick={() => {
                setUseTemplates(false);
                setSelectedFile(null);
              }}
            >
              <img
                src={ICONS?.backArrow}
                alt="back icons"
                className="size-[1.5vw] mt-1"
              />
            </button>
            <p className="main-header2">{selectedTemplate?.name}</p>
            <p
              className={`px-[0.6vw] py-[0.2vw] rounded-[0.25vw]
              ${
                selectedTemplate?.category === "MARKETING"
                  ? "marketing-title"
                  : "utility-title"
              }`}
            >
              {capitalizeFirstLetter(selectedTemplate?.category)}
            </p>
            <p
              className={`px-[0.6vw] py-[0.2vw] language-title  rounded-[0.25vw] bg-[#E7F4FC] text-[var(--dashboardprimary)]`}
            >
              {getLanguageName(selectedTemplate?.language)}
            </p>
          </div>
        ) : (
          <div className="w-100 flex-row align-center gap-3 space-between">
            <h4 className="new-header3">
              {`${count || 0} Existing templates`}
            </h4>

            <div className="flex-row align-center gap-2.5">
              <SearchBar
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                // className="!w-[15vw]"
                onChange={(val) => {
                  onSearch(val);
                }}
                search="search-template"
                searchCancel="searchCancel"
                expandable={true}
              />

              {/* <Popover
                trigger={["click"]}
                placement="bottom"
                arrow={false}
                content={
                  <div className="min-w-56 px-3.5 py-2.5">
                    {SORT?.map((section) => (
                      <div key={section?.heading}>
                        <h3 className="new-header1 mb-2 pt-5">
                          {section.heading}
                        </h3>
                        {section?.options?.map((s) => (
                          <div
                            key={s.id}
                            // className={cn(
                            //   "w-full h-9 flex items-center gap-2.5 py-1 ",
                            //   !["all-campaigns"]?.includes(goalType) &&
                            //     !s?.isSelected
                            //     ? "cursor-not-allowed opacity-50"
                            //     : "cursor-pointer opacity-100"
                            // )}
                            // onClick={() => {
                            //   if (["all-campaigns"]?.includes(goalType)) {
                            //     onSortChange(section?.id, s);
                            //   }
                            // }}
                            className="flex-row align-center gap-2 pointer pl-10"
                          >
                            <div className="w-5 h-9 flex-row items-center justify-center mt-1">
                              <RadioButton
                                value={s?.isSelected}
                                selected={s?.isSelected}
                                onChange={(e) => {}}
                                rootClassName={
                                  s?.isSelected && "size-3.5 new-radio"
                                }
                              />
                            </div>
                            {s?.icon && <img src={s?.icon} alt={s.label} />}
                            <p>{s?.label}</p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                }
              >
                <div className="group/sort flex items-center gap-2.5 bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
                  <div className="campaign-filters">{"Newest First"}</div>
                  <div
                    className={cn(
                      "transition-transform duration-200 ease-linear",
                      // group-hover/sort:-rotate-180,
                      false ? "-rotate-180" : "rotate-0"
                      // isLoading ? "cursor-not-allowed" : "cursor-pointer"
                    )}
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    ></div>
                  </div>
                </div>
              </Popover> */}
              <Popover
                trigger={["click"]}
                placement="bottom"
                arrow={false}
                content={
                  <div className="min-w-56 px-3.5 py-2.5">
                    <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 new-left-layout">
                      <div className="text-[var(--font-400)]">Type</div>
                      <div
                        className={cn(
                          "text-[#F96056]",
                          categoryId?.length > 0
                            ? "cursor-pointer opacity-100"
                            : "cursor-not-allowed opacity-50"
                        )}
                        onClick={() => {
                          if (categoryId?.length > 0) clearType();
                        }}
                      >
                        Clear
                      </div>
                    </div>
                    {categoryChange?.map((s) => {
                      return (
                        <div
                          className={cn(
                            "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer new-body-text p-10 mt-10"
                          )}
                          onClick={() => {
                            onCategoryChange(s);
                          }}
                          key={s?.id}
                        >
                          <CheckBoxSqure
                            value={s?.isSelected}
                            onChange={() => {
                              // Optionally handle checkbox changes directly
                            }}
                            className="size-4"
                          />
                          {s?.icon && (
                            <img src={s?.icon} alt={s.label} className="w-4" />
                          )}
                          <p>{s?.label}</p>
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <div className="group/sort flex items-center gap-2.5 bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
                  <div className="campaign-filters">{"Category (All)"}</div>
                  <div
                    className={cn(
                      "transition-transform duration-200 ease-linear",
                      // group-hover/sort:-rotate-180,
                      false ? "-rotate-180" : "rotate-0"
                      // isLoading ? "cursor-not-allowed" : "cursor-pointer"
                    )}
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    ></div>
                  </div>
                </div>
              </Popover>
              {/* <Popover
                trigger={["click"]}
                placement="bottom"
                arrow={false}
                content={
                  <div className="min-w-56 h-[45vh] px-3.5 py-2.5">
                    <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 new-left-layout">
                      <div className="text-[var(--font-400)]">Language</div>
                      <div
                        className={cn(
                          "text-[#F96056]"
                          // selectedStatusId?.length > 0
                          //   ? "cursor-pointer opacity-100"
                          //   : "cursor-not-allowed opacity-50"
                        )}
                        // onClick={() => {
                        //   if (selectedStatusId?.length > 0) clearStatus();
                        // }}
                      >
                        Clear
                      </div>
                    </div>
                    <div className="h-[40vh] overflow-scroll">
                      {LANGUAGE_INBOX?.map((s) => {
                        return (
                          <div
                            className={cn(
                              "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer new-body-text p-10 mt-10"
                            )}
                            // onClick={() => {
                            //   onStatusChange(s);
                            // }}
                            key={s?.id}
                          >
                            <CheckBoxSqure
                              value={s?.isSelected}
                              onChange={(e) => {}}
                              className="size-4"
                            />
                            {s?.icon && (
                              <img
                                src={s?.icon}
                                alt={s.label}
                                className="w-4"
                              />
                            )}
                            <p>{s?.label}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              >
                <div className="group/sort flex items-center gap-2.5 bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
                  <div className="campaign-filters">{"Language (All)"}</div>
                  <div
                    className={cn(
                      "transition-transform duration-200 ease-linear",
                      // group-hover/sort:-rotate-180,
                      false ? "-rotate-180" : "rotate-0"
                      // isLoading ? "cursor-not-allowed" : "cursor-pointer"
                    )}
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    ></div>
                  </div>
                </div>
              </Popover> */}
              {/* <div
                className={cn(
                  "group/status flex items-center gap-2.5 hover:bg-[var(--BG-25)] px-2.5 py-2.5 rounded-[0.4vw]",
                   clearType()
                    ? "cursor-pointer opacity-100"
                    : "cursor-not-allowed opacity-50"
                )}
                onClick={() => {
                  clearType();
                }}
              >
                <ClearFilters
                  width={16}
                  height={16}
                  color={clearType() ? "var(--contentText)" : "var(--font-600)"}
                />
              </div> */}
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div className="w-full h-full min-h-[50vh] max-h-[73vh] flex items-center justify-center">
          <Loader Width={30} Height={30} loaderBg="white" />
        </div>
      )}
      {!loading && (
        <div className="">
          <div className="min-w-full flex gap-[1vw]">
            <div className="w-full h-[58vh] pr-[0.2vw] !flex-row align-center">
              {useTemplates ? (
                <div className="w-full flex-row space-between">
                  <div className="w-60">
                    <div className="w-full h-[40vh] px-[0.5vw]">
                      <div>
                        <div className="w-full flex items-center gap-[1vw] py-[0.5vw] border-b-[0.08vw] border-[var(--border-50)]">
                          <div className="w-[40%] popover-title">Variable</div>
                          <div className="w-[60%] popover-title">
                            Add variable content
                          </div>
                        </div>
                        {bodyVariables?.length > 0 ? (
                          <div className="w-full h-[35vh] py-[0.5vw] overflow-y-scroll listScroll ">
                            {bodyVariables?.map((v) => {
                              return (
                                <div>
                                  <VariablesPreviewCard
                                    key={v?.id}
                                    data={v}
                                    className={"h-[6.7vh] pr-[0.5vw]"}
                                    changeVariableText={changeVariable}
                                    onChange={(value) => {
                                      if (currentCategory() === "CAROUSEL") {
                                        onDataChange({
                                          type: "update_bodyVariables",
                                          updateKey: "carousel",
                                          // id: data?.id,
                                          key: value?.key,
                                          value: value?.value,
                                          // text: value?.text,
                                        });
                                      } else {
                                        onDataChange({
                                          type: "update_variable",
                                          key: value?.key,
                                          value: value.value,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="w-full h-[35vh] flex-center new-header2">
                            No variables found on this template.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="w-full h-[15vh] pt-[1vw] border-t-[0.08vw] border-[var(--border-50)]">
                      <h3 className="new-header3">Select Media File</h3>
                      <p className="sidebar-sub-header-font-400 mt-[0.3vw]">
                        Upload a media for this template
                      </p>
                      {currentCategory() === "CAROUSEL" ? (
                        <div className="w-full flex items-center gap-5 mt-10 overflow-x-xscroll">
                          {carousel?.cards?.map((c) => {
                            const header = c?.components?.filter(
                              (com) => com.type === "HEADER"
                            );

                            return (
                              <div
                                className="relative size-20 rounded-md pointer carousel-image-hover"
                                onClick={() => {
                                  setSelectedCard(c);
                                }}
                              >
                                {header?.[0]?.format === "IMAGE" && (
                                  <img
                                    src={
                                      c?.image ||
                                      header?.[0]?.example?.header_handle
                                    }
                                    alt="card images"
                                    className="size-20 rounded-md"
                                  />
                                )}
                                {header?.[0]?.format === "VIDEO" && (
                                  <video
                                    className="w-full h-full object-cover rounded-[0.4vw]"
                                    // controls
                                    // autoplay='autoplay'
                                    loop
                                  >
                                    <source
                                      src={
                                        c?.image ||
                                        header?.[0]?.example?.header_handle
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                )}
                                <span
                                  className="absolute top-0.5 right-0.5 bg-white text-[var(--contentText)] font-medium weight-semibold rounded-md px-0.5"
                                  onClick={() => {
                                    inputRef.current.value = null;
                                    inputRef?.current?.click();
                                  }}
                                  data-testid="edit-carousel-image"
                                >
                                  <img
                                    src={ICONS?.carouselEdit}
                                    alt="carouselEdit"
                                  />
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      ) : ["IMAGE", "VIDEO", "DOCUMENT"]?.includes(
                          header?.format
                        ) ? (
                        <div className="mt-[0.7vw]">
                          <div className="flex items-center gap-[0.8vw]">
                            <div>
                              {header?.format === "IMAGE" && (
                                <img
                                  src={header?.example?.header_handle?.[0]}
                                  alt="preview images"
                                  className="size-[4vw] font-xs weight-medium rounded-[0.5vw]"
                                />
                              )}
                              {header?.format === "VIDEO" && (
                                <div className="h-[4vw] flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] rounded-[0.5vw] cursor-pointer">
                                  <VideoSvg
                                    color="var(--white)"
                                    width={"3vw"}
                                    height={"3vw"}
                                  />
                                </div>
                              )}
                              {header?.format === "DOCUMENT" && (
                                <iframe
                                  title="cover"
                                  src={header?.example?.header_handle?.[0]}
                                  type="application/pdf"
                                  width="100%"
                                  height="100px"
                                ></iframe>
                              )}
                            </div>
                            <div className="w-full">
                              <p className="w-3/4 new-header2 truncate">
                                {selectedFile
                                  ? selectedFile?.name
                                  : fileName(
                                      header?.example?.header_handle?.[0]
                                    )}
                              </p>
                              {changeMedia && (
                                <button className="w-[7vw] change-media-title  px-[0.6vw] py-[0.4vw] rounded-[0.37vw] mt-[0.4vw]">
                                  <div className="flex items-center justify-center">
                                    <input
                                      type="file"
                                      accept={
                                        header?.format === "IMAGE"
                                          ? ".jpg, .png"
                                          : header?.format === "VIDEO"
                                          ? ".mp4"
                                          : header?.format === "DOCUMENT" &&
                                            ".pdf"
                                      }
                                      onChange={async (e) => {
                                        const res = await uploadFiles(
                                          e,
                                          header?.format
                                        );

                                        onDataChange({
                                          type: "header",
                                          updateKey: "mediaUrl",
                                          value: res,
                                        });
                                      }}
                                      id="uploadImg"
                                      hidden
                                    />

                                    <label
                                      for="uploadImg"
                                      className="w-full flex-center"
                                    >
                                      {uploading ? (
                                        <Loader
                                          Width={20}
                                          Height={20}
                                          loaderBg="white"
                                        />
                                      ) : (
                                        <div className="">Choose media</div>
                                      )}
                                    </label>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>

                          <>
                            {header?.format === "IMAGE" && error && (
                              <div className={`${errorStyle}`}>
                                Image size should be less than 5MB
                              </div>
                            )}

                            {header?.format === "VIDEO" && error && (
                              <div className={`${errorStyle}`}>
                                Video size should be less than 16MB
                              </div>
                            )}

                            {header?.format === "DOCUMENT" && error && (
                              <div className={`${errorStyle}`}>
                                Document size should be less than 100MB
                              </div>
                            )}
                          </>
                        </div>
                      ) : (
                        <div className="w-full h-[10vh] flex-center sidebar-sub-header-font-400">
                          No media files found on this template.
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`w-[28%] h-[58vh]`}
                    style={{ transform: "translate(0px, -35px)" }}
                  >
                    {selectedTemplate ? (
                      // <TemplatePreview data={selectedTemplate} drawerType='medium' />
                      <TemplatePreview
                        header={header}
                        headerVariable={headerVariable}
                        body={body}
                        bodyVariables={bodyVariables}
                        footer={footer}
                        buttons={buttons}
                        buttonsOrder={buttonsOrder}
                        ctaButtons={ctaButtons}
                        customButtons={customButtons}
                        selectedTemplate={selectedTemplate}
                        useTemplates={useTemplates}
                        drawerType="medium"
                        carousel={carousel}
                        authentication={authentication}
                        category={selectedTemplate?.subcategory_type}
                      />
                    ) : (
                      <div className="w-full h-full bg-[var(--BG-25)] flex items-center justify-center rounded-[1vw]">
                        <NoData
                          contentClassName={"max-w-[65%]"}
                          icon={ICONS?.NoTemplateSelected}
                          content={"No template is selected for preview"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  className={`w-full h-full overflow-hidden overflow-y-scroll`}
                  id="scrollableDiv"
                  ref={templateListContainerRef}
                >
                  {templates?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={templates?.length}
                      next={() => {
                        loadNextPage();
                      }}
                      hasMore={templates?.length < count}
                      scrollableTarget="scrollableDiv"
                      height={isLoadingNextPage ? "53vh" : "58vh"}
                      className="w-full !pr-[0.2vw] listScroll grid md:grid-cols-3 grid-cols-2 flex-1 gap-x-10 gap-y-5"
                    >
                      {templates?.map((t) => {
                        return (
                          <div className="w-100  py-[0.5vw]  last:pb-[0.1vw] flex-row">
                            <TemplateCard
                              data={t}
                              selected={
                                t?.message_template_id ===
                                  selectedTemplate?.message_template_id &&
                                t?.name === selectedTemplate?.name &&
                                t?.language === selectedTemplate?.language &&
                                t?.category === selectedTemplate?.category
                              }
                              handlePreviewSelect={(value) => {
                                setSearchKey("");
                                setSelectedTemplate(value);
                              }}
                              useTemplate={() => setUseTemplates(true)}
                              className={"hover:bg-slate-100"}
                              previewSelect="previewSelectInbox"
                              useThisTemplate="useThisTemplateInbox"
                              header={header}
                              headerVariable={headerVariable}
                              body={body}
                              bodyVariables={bodyVariables}
                              selectedTemplate={selectedTemplate}
                              useTemplates={useTemplates}
                              carousel={carousel}
                              authentication={authentication}
                              category={selectedTemplate?.subcategory_type}
                            />
                          </div>
                        );
                      })}
                      {isLoadingNextPage && (
                        <div className="w-full h-[5vh] flex items-center justify-center">
                          <Loader Width={30} Height={30} loaderBg="white" />
                        </div>
                      )}
                    </InfiniteScroll>
                  ) : (
                    <div className="h-auto flex justify-center mt-20 pt-20">
                      <div className="flex-row h-10 mt-20">
                        <img
                          src={ICONS?.emptySearch}
                          alt="emptySearch"
                          className="w-14"
                        />
                        <div className="flex-column gap-3 pl-10">
                          <span className="new-header3 ">
                            Sorry, there are no matching results
                          </span>
                          <span className="no-search-body">
                            Use different filters or{" "}
                            <span
                              className="text-[#6940F2] pointer"
                              onClick={() => {
                                setSearchKey("");
                              }}
                            >
                              reset to default.
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {useTemplates && (
            <div
              className={`w-full  flex items-center justify-end gap-[0.6vw] ${
                isSending ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <button
                className=" button-title px-[0.5vw] py-[0.3vw] rounded-[0.37vw] bg-[var(--primary)] text-[var(--white)]"
                onClick={() => {
                  if (!isSending) {
                    let datas = null;

                    if (currentCategory() === "CAROUSEL") {
                      const cardsObject = carousel?.cards?.map((card) => {
                        const key = card?.name
                          ?.replaceAll(" ", "_")
                          ?.toLowerCase();

                        return { key: `${key}_media`, value: card?.image };
                      });

                      const covertIntoSingleObject = Object.fromEntries(
                        cardsObject.map((item) => [item?.key, item?.value])
                      );

                      datas = {
                        bodyVariables: carousel?.bodyVariables || [],
                        headerVariable: custom?.bodyVariables || [],
                        ...covertIntoSingleObject,
                      };
                    } else {
                      datas = {
                        bodyVariables: custom?.bodyVariables || [],
                        headerVariable: [custom?.headerVariable] || [],
                      };

                      if (!["TEXT", "NONE"]?.includes(header?.format))
                        datas["media_url"] =
                          header?.example?.header_handle?.[0];
                    }

                    if (open) {
                      setOpenTemplateList(false);
                      setSelectTemplate(selectedTemplate);
                    }
                    if (sendTemplate)
                      sendTemplate({
                        ...datas,
                        message_template_id:
                          selectedTemplate?.message_template_id,
                        subcategory_type: selectedTemplate?.subcategory_type,
                      });
                  }
                }}
                data-testid="submit-template-inbox"
              >
                {isSending ? (
                  <div className="w-full flex items-center justify-center">
                    <Loader Width={120} Height={25} loaderBg="white" />
                  </div>
                ) : (
                  <>{open ? "Use Template" : "Submit Template"}</>
                )}
              </button>
            </div>
          )}
        </div>
      )}
      <input
        type="file"
        accept={
          header?.format === "IMAGE" || carousel?.header === "IMAGE"
            ? ".jpg, .png, .jpeg"
            : header?.format === "VIDEO" || carousel?.header === "VIDEO"
            ? ".mp4"
            : ".jpg, .png, .jpeg"
        }
        ref={inputRef}
        onChange={async (e) => {
          const res = await uploadFiles(
            e,
            header?.format || carousel?.header,
            null,
            {
              brand_id: currentBrand?.brand_id,
              template_id: id,
            }
          );

          if (res)
            await onDataChange({
              type: "carousel",
              updateKey: "update_card",
              id: selectedCard?.id,
              key: "image",
              value: res,
            });
        }}
        hidden
      />
    </div>
  );
}
