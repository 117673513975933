import AspApi from "api/ApiConfig";

export const weekCalender = async (brandId, startDate, endDate, query) => {
  let qpm = [];
  if (query?.search) qpm.push(`search=${query?.search}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "&" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/campaign_broadcast/broadcasts/calendar?brand_id=${brandId}&start_date=${startDate}&end_date=${endDate}${qpmString}`
  );  
  return res;
};
