import React from "react";
import { ICONS } from "../../../../../assets/icons/index";

const MessageStatus = ({ status,  style = {} }) => {
  switch (status) {
    case "DELIVERED":
    case 4:
      return (
        <span
          style={{
            color: "var(--a)",
            fontWeight: "600",
            fontSize: "0.75vw",
            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.DeliveredIcon}
            alt="CampaignCompleteIcon"
            className="pr-5"
            style={{ width: "20px", height: "20px" }}
          />
          Delivered
        </span>
      );
    case "SENT":
    case 3:
      return (
        <span
          style={{
            color: "var(--channelToggleSwitch)",
            fontWeight: "600",
            fontSize: "0.75vw",

            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.sentIcon}
            alt="CampaignActiveIcon"
            className="pr-5"
            style={{ width: "20px", height: "20px" }}
          />
          Sent
        </span>
      );

    case "READ":
    case "CLICKED":
    case 5:
      return (
        <span
          style={{
            color: "var(--informationBlue",
            fontWeight: "600",
            fontSize: "0.75vw",

            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.OpenedIcon}
            alt="CampaignCompleteIcon"
            className="pr-5"
            style={{ width: "20px", height: "20px" }}
          />
          Opened
        </span>
      );
    case "REPLIED":
    case 6:
      return (
        <span
          style={{
            color: "var(--primary)",
            fontWeight: "600",
            fontSize: "0.75vw",

            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.LeadIcon}
            alt="CampaignActiveIcon"
            className="pr-5"
            style={{ width: "20px", height: "20px" }}
          />
          Lead
        </span>
      );
    case "BOUNCED":
    case -1:
      return (
        <span
          style={{
            color: "var(--fontRed)",
            fontWeight: "600",
            fontSize: "0.75vw",

            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.BouncedIcon}
            alt="CampaignActiveIcon"
            className="pr-5"
            style={{ width: "20px", height: "20px" }}
          />
          Bounced
        </span>
      );
    case "SENDING":
    case 2:
      return (
        <span
          style={{
            color: "var(--channelToggleSwitch)",
            fontWeight: "600",
            fontSize: "0.75vw",

            ...style,
          }}
          className="flex-row align-center"
        >
          <img
            src={ICONS?.SendingIcon}
            alt="CampaignActiveIcon"
            className="pr-5"
            style={{ width: "20px", height: "20px" }}
          />
          Sending
        </span>
      );

    default:
      return "--";
  }
};

export default MessageStatus;
