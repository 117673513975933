import React, { useState } from "react";

//
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

// helpers
import { cn } from "../../../helper/cn";
import { CAMPAIGN_COLORS } from "../../../helper/colors";

const indecatorsType = ["Colors", "Emojis"];

export default function ColorsAndEmojiIndegators({ value, onChange }) {
  const [selectedIndecator, setSelectedIndecator] = useState("Colors");

  const handleChangeIndecator = (data) => setSelectedIndecator(data);

  const handleSelectData = (data) => {
    onChange({ type: selectedIndecator, data: data });
  };

  return (
    <div className="w-full p-4">
      <div className="text-sm weight-medium flex items-center gap-5 mb-6">
        {indecatorsType?.map((i) => {
          return (
            <div
              onClick={() => {
                handleChangeIndecator(i);
              }}
              className={cn(
                "hover:cursor-pointer border-b-2 transition-colors ease-linear duration-150 pb-0.5",
                selectedIndecator === i
                  ? "text-[var(--primary)] border-[var(--primary)]"
                  : "border-transparent"
              )}
            >
              {i}
            </div>
          );
        })}
      </div>
      {selectedIndecator === "Colors" && (
        <div className="grid grid-cols-10 gap-2.5">
          {CAMPAIGN_COLORS?.map((c) => {
            return (
              <div
                className="size-8 rounded-md cursor-pointer"
                style={{
                  background: c,
                }}
                onClick={() => {
                  handleSelectData(c);
                }}
              ></div>
            );
          })}
        </div>
      )}
      {selectedIndecator === "Emojis" && (
        <Picker
          data={data}
          onEmojiSelect={(emoji) => {
            if (!emoji?.native) return;
            handleSelectData(emoji?.native);
          }}
          theme={"green"}
          searchPosition="none"
          previewPosition="none"
        />
      )}
    </div>
  );
}
