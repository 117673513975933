const variableRegEx = /{{(.*?)}}/g;

export const removeDoubleStyles = (str) => {
  if (str)
    return str
      ?.replace(/\*\*(.*?)\*\*/g, "*$1*")
      ?.replace(/__(.*?)__/g, "_$1_")
      ?.replace(/~~(.*?)~~/g, "~$1~");

  return "";
};

export const replaceFormating = (str) => {
  if (str?.length > 0) {
    const filterBold = str?.match(/\*\*(.*?)\*\*/g);
    const filterItalic = str?.match(/\__(.*?)_\_/g);
    const filterStrik = str?.match(/\~~(.*?)~\~/g);

    if (filterBold?.length > 0)
      str = str?.replaceAll(/\*\*(.*?)\*\*/g, `<b>$1</b>`);
    else str = str?.replaceAll(/\*(.*?)\*/g, `<b>$1</b>`);
    if (filterItalic?.length > 0)
      str = str?.replaceAll(/\__(.*?)_\_/g, `<i>_$1_</i>`);
    else str = str?.replaceAll(/_(.*?)_/g, `<i>$1</i>`);
    if (filterStrik?.length > 0)
      str = str?.replaceAll(/\~\~(.*?)\~\~/g, `<s>$1</s>`);
    else str = str?.replaceAll(/~(.*?)~/g, `<s>$1</s>`);

    return str?.replaceAll(/\\/g, "");
  }

  return str;
};

export const addLineBreake = (str) =>
  str?.length > 0 ? str?.replaceAll(/\n/g, "<br/>") : str;

export const checkMultipleNewLines = (str) =>
  str?.length > 0 ? str?.match(/\n{2,}(?!\S)/) : str;

export const checkMaxNewLines = (str) =>
  str?.length > 0 ? str?.match(/\n{2,}(?!\S)/) : str;

export const checkVariable = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const replaceVariables = (str, value) => {
  const res = checkVariable(str);

  res?.map((r, i) => {
    str = str?.replaceAll(r, value?.[i]);
  });

  return str;
};

export const replaceVariablesForPreview = (str, variables) => {
  const res = checkVariable(str);

  res?.map((r, i) => {
    str = str?.replaceAll(r, variables?.[i]?.value);
  });

  return str;
};

export function formatPhoneNumber(countryCode, number) {
  if (!countryCode || !number) return "";

  // Remove any non-numeric characters for consistent formatting
  const cleanNumber = number.replace(/\D/g, "");

  // Add spaces after every 3rd digit (for the first three groups)
  const formattedNumber = cleanNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "$1 $2 $3"
  );

  // Return formatted with the country code
  return `${countryCode} ${formattedNumber}`;
}
