import React from 'react';

const Assignee = ({ width = 24, height = 20, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_19016_134396)'>
        <path
          d='M23.4589 11.4328V14.1313C23.4599 14.7188 23.2268 15.2827 22.8108 15.6977C22.3958 16.1137 21.8309 16.3469 21.2433 16.3458H19.68C19.7344 17.0505 19.4941 17.7458 19.0154 18.2656C18.5378 18.7854 17.865 19.0843 17.1583 19.0894H12.0851C11.666 19.0832 11.3291 18.7422 11.3291 18.3231C11.3291 17.904 11.666 17.563 12.0851 17.5568H17.1583C17.7037 17.5558 18.1464 17.1131 18.1475 16.5677V8.50548C17.828 0.469856 6.34213 0.469856 6.0229 8.50548V15.5795C6.02187 16.0017 5.67982 16.3447 5.25663 16.3458H2.92701C2.33946 16.3468 1.77453 16.1136 1.35956 15.6976C0.943566 15.2827 0.710378 14.7187 0.711429 14.1312V11.4328C0.712456 10.2105 1.70366 9.21922 2.92703 9.2182H4.49038C4.22127 -1.81143 19.9524 -1.80539 19.6802 9.2182H21.2436C22.4669 9.21922 23.4579 10.2105 23.4589 11.4328ZM16.7957 7.8882V13.6238C16.7967 14.1332 16.3858 14.5472 15.8763 14.5503H13.255L12.0635 16.1434L10.872 14.5503H8.24963C7.74118 14.5472 7.33031 14.1332 7.33031 13.6238V7.8882C7.33031 7.37872 7.74118 6.96478 8.24963 6.96273H15.8764C16.3858 6.96478 16.7967 7.37872 16.7957 7.8882ZM10.7785 10.0319C10.3655 10.0452 10.0379 10.3842 10.0379 10.7982C10.0379 11.2111 10.3655 11.5501 10.7785 11.5644C11.1914 11.5501 11.518 11.2111 11.518 10.7982C11.518 10.3852 11.1914 10.0463 10.7785 10.0319ZM13.3484 10.0319C12.9355 10.0452 12.6078 10.3842 12.6078 10.7982C12.6078 11.2111 12.9355 11.5501 13.3484 11.5644C13.7613 11.5501 14.088 11.2111 14.088 10.7982C14.088 10.3852 13.7613 10.0463 13.3484 10.0319Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_19016_134396'>
          <rect
            width='23.14'
            height='18.4068'
            fill={color}
            transform='translate(0.515625 0.796875)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Assignee;
