import { ICONS } from "../../../assets/icons/index";

export const goalTypes = [
  {
    id: 1,
    icon: "",
    label: "Awareness",
    key: "awareness",
    isSelected: false,
  },
  // {
  //   id: 2,
  //   icon: "",
  //   label: "Sales",
  //   key: "sales",
  //   isSelected: false,
  // },
  {
    id: 3,
    icon: "",
    label: "Leads",
    key: "leads",
    isSelected: false,
  },
  {
    id: 4,
    icon: "",
    label: "Engagement",
    key: "engagement",
    isSelected: false,
  },
  // {
  //   id: 5,
  //   icon: "",
  //   label: "Branding",
  //   key: "branding",
  //   isSelected: false,
  // },
  // {
  //   id: 6,
  //   icon: "",
  //   label: "Support",
  //   key: "support",
  //   isSelected: false,
  // },
];

export const CAMPAIGN_TYPE = [
  {
    id: 2,
    icon: ICONS?.timeSpecififed,
    label: "Time Specified",
    key: "time_specified",
    status: "time_specified",
    isSelected: false,
  },
  {
    id: 3,
    icon: ICONS?.perpetual,
    label: "Perpetual",
    key: "perpetual",
    status: "perpetual",
    isSelected: false,
  },
];

export const STATUS = [
  {
    id: 1,
    icon: ICONS?.scheduled,
    label: "Scheduled",
    key: "scheduled",
    status: 10,
    isSelected: false,
  },
  {
    id: 2,
    icon: ICONS?.runningCampaign,
    label: "Running",
    key: "running",
    status: 11,
    isSelected: false,
  },
  {
    id: 3,
    icon: ICONS?.completed,
    label: "Completed",
    key: "completed",
    status: 12,
    isSelected: false,
  },
];

// export const SORT = [
//   {
//     id: 1,
//     icon: '',
//     label: 'Newest first',
//     key: 'newest',
//     isSelected: false,
//   },
//   {
//     id: 2,
//     icon: '',
//     label: 'Oldest first',
//     key: 'oldest',
//     isSelected: false,
//   },
//   {
//     id: 3,
//     icon: '',
//     label: 'Ascending',
//     key: 'ascending',
//     isSelected: false,
//   },
//   {
//     id: 4,
//     icon: '',
//     label: 'Descending',
//     key: 'descending',
//     isSelected: false,
//   },
// ];

export const SORT = [
  {
    id: 1,
    heading: "Sort By",
    options: [
      {
        id: 1,
        icon: ICONS?.newestFirst,
        label: "Newest First",
        key: "newest",
        status: "desc",
        isSelected: true,
      },
      {
        id: 2,
        icon: ICONS?.oldestFirst,
        label: "Oldest First",
        key: "oldest",
        status: "asc",
        isSelected: false,
      },
    ],
  },
  {
    id: 2,
    heading: "Order By",
    options: [
      {
        id: 3,
        icon: ICONS?.ascending,
        label: "Ascending",
        key: "ascending",
        status: "asc",
        isSelected: false,
      },
      {
        id: 4,
        icon: ICONS?.descending,
        label: "Descending",
        key: "descending",
        status: "desc",
        isSelected: false,
      },
    ],
  },
  // {
  //   id: 3,
  //   heading: "Others",
  //   options: [
  //     {
  //       id: 3,
  //       icon: ICONS?.starred,
  //       label: "Starred",
  //       key: "Starred",
  //       status: "str",
  //       isSelected: false,
  //     },
  //   ],
  // },
];
