import { bodyTextLimit } from '../components/templates';

import { checkMultipleNewLines } from './common';
import {
  checkVariablesAreValid,
  getReplaceVariablesAndCount,
  replaceCustomVariableCount,
} from './variables';

export const isBodyValid = (body, bodyVariables) => {
  const isBodyVariable = bodyVariables?.filter((v) => v.value === '');

  const checkLine = checkMultipleNewLines(body);
  const newLineLength = checkLine?.length > 0 ? false : true;

  const { count, value: bodyVariableCount } = getReplaceVariablesAndCount(body);

  const replacedCount = replaceCustomVariableCount(body);
  const isVariablesAreInvalid = checkVariablesAreValid(replacedCount);

  const isBody =
    body?.length > 0
      ? bodyVariableCount?.length !== 0 &&
        bodyVariableCount?.length <= bodyTextLimit &&
        !isVariablesAreInvalid
        ? bodyVariableCount?.length !== 0 &&
          bodyVariableCount?.length <= bodyTextLimit &&
          (bodyVariables?.length === 0 ? true : false)
          ? true
          : bodyVariableCount?.length !== 0 &&
            bodyVariableCount?.length <= bodyTextLimit &&
            (isBodyVariable?.length > 0 ? false : true)
          ? true
          : bodyVariableCount?.length !== 0 &&
            bodyVariableCount?.length <= bodyTextLimit &&
            newLineLength
          ? false
          : bodyVariableCount?.length !== 0 &&
            bodyVariableCount?.length <= bodyTextLimit
          ? true
          : false
        : false
      : false;

  return isBody && newLineLength;
};
