import { ICONS } from "../../../assets/icons/index";
import React from "react";

const EditDelete = ({ onEditClick, onDeleteClick, showEdit = true }) => {
  return (
    <div className="flex-column p-[15px] mb-3.5">
      {showEdit && (
        <div
          className="flex-row align-center  tags-border m-5 pointer"
          onClick={onEditClick}
        >
          <img src={ICONS?.crmTagEdit} alt="" className=" mb-10" />
          <span className="pl-10 text-[0.8vw] text-[var(--contentText)] font-500 mb-10">
            Edit
          </span>
        </div>
      )}
      <div
        className="flex-row align-center pr-10  pointer"
        onClick={onDeleteClick}
      >
        <img src={ICONS?.RedDeleteIcon} alt="" className="mt-10" />
        <span className="text-[0.8vw] text-[--fontRed] font-500 mt-10">
          Delete
        </span>
      </div>
    </div>
  );
};

export default EditDelete;
