import React, { useEffect, useState } from "react";

import { LeftList as calenderLeftList } from "../../../constant/app/calender/calender";
// import { CalendarList } from '../../../constant/app/campaign/campaign';
import LeftList from "../../../components/commonComponents/leftListLayout/Index";
import TabView from "../../../components/commonComponents/TabView/TabView";
import Calendar from "../../../components/commonComponents/calendar/OverviewCalender";
import WeekCalendar from "../../../components/commonComponents/calendar/WeekCalendar";

import useCalendar from "../../../hooks/useCalender";

import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import { updateGlobalLoader } from "../../../reduxToolkit/appSlice";
import HeaderSwitchingButtons from "../components/HeaderSwitchingButtons";
import { useNavigate } from "react-router-dom";
import { weekCalender } from "../api/Api";

function calender() {
  const {
    calendarRows,
    selectedDate,
    getNextMonth,
    getPrevMonth,
    getPrevWeek,
    getNextWeek,
    shortMonthName,
    monthNamesArr,
  } = useCalendar();
  const [pageNavigate, setPageNavigate] = useState("Week View");
  const navigate = useNavigate();
  const [weekDates, setWeekDates] = useState([]);
  const [startOfWeek, setStartOfWeek] = useState(null);
  const [endOfWeek, setEndOfWeek] = useState(null);
  const [weekcalenderList, setWeekCalenderList] = useState(null);
  const { currentBrand } = useAspSelector((state) => state.app);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loaderUpdated, setLoaderUpdated] = useState(false);
  const [searchisFilter, setSearchIsFilter] = useState("");

  const dispatch = useAspDispatch();

  const handlePrevMonth = () => getPrevMonth();
  const handleNextMonth = () => getNextMonth();
  const handlePrevWeek = () => getPrevWeek();
  const handleNextWeek = () => getNextWeek();

  const campaignFilter = (item) => {
    setPageNavigate(item?.name);
    navigate("/user/calendar");
  };

  function formatDate(date) {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (pageNavigate === "Week View") {
      const startDateFormatted = formatDate(weekDates[0]);
      const endDateFormatted = formatDate(weekDates[weekDates.length - 1]);

      setStartDate(startDateFormatted);
      setEndDate(endDateFormatted);
    } else {
      for (const array of Object.values(calendarRows)) {
        const hasNoPrevMonth = array.some(
          (item) => !item.classes.includes("in-prev-month")
        );

        if (hasNoPrevMonth) {
          const firstNonPrevMonthDate = array?.find(
            (item) => !item.classes.includes("in-prev-month")
          );
          setStartDate(firstNonPrevMonthDate?.date);
          break;
        }
      }
      for (const array of Object.values(calendarRows).reverse()) {
        for (let i = array.length - 1; i >= 0; i--) {
          const item = array[i];

          if (!item.classes.includes("in-next-month")) {
            setEndDate(item.date);

            return;
          }
        }
      }
      // setStartDate(calendarRows[1][0].date);
      // setEndDate(calendarRows[6][6].date);
    }
  }, [weekDates, calendarRows, pageNavigate]);

  const calenderWeekData = (brandId, startDate, endDate) => {
    if (
      brandId &&
      startDate !== "NaN-NaN-NaN" &&
      endDate !== "NaN-NaN-NaN" &&
      startDate !== null &&
      endDate !== null
    ) {
      if (!loaderUpdated) {
        dispatch(updateGlobalLoader(true));
        setLoaderUpdated(true);
      }
      // dispatch(updateGlobalLoader(true));
      weekCalender(brandId, startDate, endDate, { search: searchisFilter })
        .then((res) => {
          if (res && res?.data?.broadcasts) {
            setWeekCalenderList(res?.data);
          }
        })
        .finally(() => {
          dispatch(updateGlobalLoader(false));
        });
    } else {
      setLoaderUpdated(false);
    }
  };

  useEffect(() => {
    calenderWeekData(currentBrand?.brand_id, startDate, endDate, {
      search: searchisFilter,
    });
    return () => {
      setLoaderUpdated(false);
    };
  }, [currentBrand?.brand_id, startDate, endDate, searchisFilter]);

  const onFilterChange = (status) => {
    let updatedStatus;
    if (selectedStatus?.includes(status)) {
      const filtered = selectedStatus?.filter((s) => s !== status);
      setSelectedStatus(filtered);
      updatedStatus = filtered;
    } else {
      const newStatus = [...selectedStatus, status];
      setSelectedStatus(newStatus);
      updatedStatus = newStatus;
    }

    weekCalender(currentBrand?.brand_id, startDate, endDate, {
      status: updatedStatus,
    }).then((res) => {
      if (res && res?.data?.broadcasts) {
        setWeekCalenderList(res?.data);
      }
    });
  };

  const calendarSearch = (value) => {
    setSearchIsFilter(value);
  };

  return (
    <div className="flex-row w-100 h-100">
      <LeftList
        list={calenderLeftList}
        activeNav={pageNavigate}
        onSelect={(item) => campaignFilter(item)}
        LeftListClick="calendar-leftList"
      />
      <div className="app-rightLayout ">
        {/* <HeaderSwitchingButtons
          sticky={true}
          handlePrevMonth={handlePrevMonth}
          handleNextMonth={handleNextMonth}
          shortMonthName={shortMonthName}
          selectedDate={selectedDate}
          monthNamesArr={monthNamesArr}
        /> */}
        <HeaderSwitchingButtons
          sticky={true}
          handlePrevMonth={handlePrevMonth}
          handleNextMonth={handleNextMonth}
          handlePrevWeek={handlePrevWeek}
          handleNextWeek={handleNextWeek}
          selectedDate={selectedDate}
          shortMonthName={shortMonthName}
          monthNamesArr={monthNamesArr}
          weekDates={weekDates}
          setWeekDates={setWeekDates}
          setStartOfWeek={setStartOfWeek}
          setEndOfWeek={setEndOfWeek}
          startOfWeek={startOfWeek}
          endOfWeek={endOfWeek}
          pageNavigate={pageNavigate}
          onFilterChange={onFilterChange}
          selectedStatus={selectedStatus}
          calendarSearch={calendarSearch}
          searchisFilter={searchisFilter}
          setSearchIsFilter={setSearchIsFilter}
          onChange={(value) => setSearchIsFilter(value)}
        />
        {pageNavigate === "Week View" && (
          <WeekCalendar
            weekDates={weekDates}
            pageNavigate={pageNavigate}
            weekcalenderList={weekcalenderList}
            selectedStatus={selectedStatus}
          />
        )}

        {pageNavigate === "Month View" && (
          <Calendar
            calendarDataProp={calendarRows}
            pageNavigate={pageNavigate}
            startDate={startDate}
            endDate={endDate}
            weekcalenderList={weekcalenderList}
            selectedStatus={selectedStatus}
          />
        )}
      </div>
    </div>
  );
}

export default calender;
