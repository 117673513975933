import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '../../../../assets/customSVG/AddIcon';
import useTags from '../../../../hooks/useTags';
import { setFilters } from '../../../../reduxToolkit/crmSlice';
import {
  crmFontColor,
  filterKeys,
  mainFilters,
  primaryColor,
} from '../../constants';
import { convertName } from '../../helpers';
import Modal from '../commonComponents/ParentPositionedModel';
import Button from '../commonComponents/button';
import FilterModelContent from './mainFilters/FilterModelContent';
import RenderFilters from './mainFilters/RenderFilters';

export const MainFilter = ({ onFilterChange }) => {
  const [active, setActive] = useState(false);
  const { tagList } = useSelector((store) => store.crm);

  const [operator, setOperator] = useState({
    isModalOpen: false,
    operator: 'and',
  }); // and | or.
  const [selectedFilter, setSelectedFilter] = useState({
    filterName: 'name',
    field: '',
    fieldValue: '',
  });

  const dispatch = useDispatch();
  const { filters } = useSelector((store) => store.crm);

  useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  const selectedTags = useMemo(() => {
    if (selectedFilter.filterName === 'tags') {
      if (!Array.isArray(selectedFilter.fieldValue)) return [];
      return selectedFilter.fieldValue.map((item) => item.id);
    }
    return [];
  }, [selectedFilter.fieldValue]);
  const { tags } = useTags(selectedTags);

  function handleClick() {
    setActive(!active);
    setOperator((prev) => {
      return { ...prev, operator: 'and' };
    });
  }

  const handleChangeFilter = (item) => {
    if (['dropdown', 'tags'].includes(mainFilters[item].props.type)) {
      setSelectedFilter({
        filterName: item,
        field: '',
        fieldValue: [],
        dropdownOpen: null,
      });
      return;
    }
    setSelectedFilter({ filterName: item, field: '', fieldValue: '' });
  };

  // useEffect(() => {
  //   localStorage.setItem("filters", JSON.stringify(filters));
  // }, [filters]);

  return (
    <div className='mx-[1vw] border-b border-b-[var(--border-50)]'>
      <div
        className={`flex py-[1.3vh] text-[${crmFontColor}] flex-col gap-[.5vw]`}
      >
        {/* This shows all the applied filters */}
        <RenderFilters
          filters={filters || []}
          operator={operator}
          setOperator={setOperator}
          handleAddNewFilter={handleClick}
        />
        <Modal
          open={active}
          onOpenChange={handleClick}
          rootclassName={
            'max-w-[35vw] crm-dropdown rounded-2xl overflow-hidden'
          }
          Button={
            <div className='flex items-center cursor-pointer max-w-max'>
              <AddIcon stroke='var(--textBlack)' width='24' height='24' />
              <span className='text-[.8vw] font-[500]'>Add Filter</span>
            </div>
          }
          sideBarMainClassName='p-[0vw_!important]'
          ModelSideNav={
            <div className='space-y-[1vw] max-h-[50vh] overflow-scroll scroll_visible *:w-[8vw] *:py-[.4vw] text-[.75vw] *:ps-[.5vw] px-[.8vw] py-[1vw]'>
              {filterKeys.map(({ name: filterName }) => {
                return (
                  <p
                    key={filterName}
                    className={`w-full ${
                      selectedFilter.filterName === filterName
                        ? `bg-[${primaryColor}] text-white`
                        : 'hover:bg-gray-200'
                    }  rounded-md capitalize cursor-pointer duration-200 `}
                    onClick={() => handleChangeFilter(filterName)}
                    data-testid="Filter_keys"
                  >
                    {convertName(filterName)}
                  </p>
                );
              })}
            </div>
          }
        >
          <div className='grid grid-rows-[6fr,1fr] h-full scroll_visible'>
            {/* Body */}
            <div className='space-y-[.5vw] max-h-[43vh] overflow-scroll scroll_visible *:w-[5vw]- px-[1vw] pt-[1vw]'>
              {Object.entries(
                mainFilters?.[selectedFilter?.filterName]?.filters || {}
              ).map(([filterKey, val]) => {
                const props = mainFilters?.[selectedFilter?.filterName].props;
                const isOnlyCheckBox = props.check.includes(filterKey);

                return (
                  <FilterModelContent
                    filterKey={filterKey}
                    type={props?.type}
                    // Key={filterKey}
                    key={filterKey}
                    tagList={tagList}
                    inputType={props?.inputType}
                    propsData={props?.data}
                    onlySelectFields={props?.check}
                    isOnlyCheckBox={isOnlyCheckBox}
                    selectedFilter={selectedFilter}
                    setSelectedFilter={setSelectedFilter}
                  />
                );
              })}
            </div>

            {/* Footer */}
            <div className='flex justify-end sticky bottom-0 bg-white gap-[.5vw] w-full border-t border-t-gray-200 py-[.5vw] pe-[1vw]'>
              <Button
                onClick={handleClick}
                label='Cancel'
                classNames='text-black'
                color='white'
              />
              <Button
                disabled={
                  !selectedFilter.fieldValue ||
                  selectedFilter.fieldValue.length === 0
                }
                onClick={() => {
                  if (
                    !selectedFilter.fieldValue &&
                    selectedFilter.fieldValue.length === 0
                  ) {
                    return;
                  }

                  setActive(false);
                  setOperator((prev) => {
                    return { ...prev, operator: 'and' };
                  });
                  setSelectedFilter({
                    filterName: 'name',
                    field: '',
                    fieldValue: '',
                  });
                  dispatch(
                    setFilters({
                      key: selectedFilter.filterName,
                      filter: {
                        [selectedFilter.field]: selectedFilter.fieldValue,
                      },
                      value: {
                        selectedFilter: selectedFilter.filterName,
                        selectedField: selectedFilter.field,
                        fieldValue: selectedFilter.fieldValue,
                      },
                      operator: operator.operator,
                    })
                  );
                }}
                label='Apply'
                classNames='text-[.5]'
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
