import React from "react";

const SendInbox = ({color}) => {
  return (
    <div>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7438 6.3763C11.801 6.25911 11.8307 6.13044 11.8307 6.00005C11.8307 5.86967 11.801 5.74099 11.7438 5.6238C11.6602 5.46526 11.5302 5.33592 11.3713 5.25297C11.2556 5.19056 11.1374 5.13273 11.0171 5.07964L1.66381 0.803802C1.53381 0.743802 1.40465 0.684636 1.29465 0.646303C1.19465 0.611303 0.992146 0.546303 0.767979 0.609636C0.515479 0.681303 0.309646 0.869636 0.214646 1.11797C0.129646 1.33714 0.172146 1.5488 0.196313 1.6538C0.222979 1.76797 0.267979 1.90547 0.312979 2.04297L1.21798 4.8138C1.29048 5.0363 1.32715 5.14797 1.39465 5.23047C1.45505 5.30245 1.53196 5.35877 1.61881 5.39464C1.71715 5.4338 1.83298 5.4338 2.06381 5.4338H6.10048C6.40881 5.4338 6.65881 5.68714 6.65881 6.00047C6.65881 6.31297 6.40881 6.5663 6.10048 6.5663H2.07465C1.84381 6.5663 1.72881 6.5663 1.63048 6.60547C1.54401 6.64122 1.46741 6.69724 1.40715 6.7688C1.33881 6.85047 1.30215 6.96214 1.22881 7.18464L0.316313 9.9538C0.271313 10.0913 0.225479 10.2288 0.199646 10.3438C0.174646 10.4488 0.131313 10.6605 0.216313 10.8805C0.311313 11.1288 0.516313 11.318 0.769646 11.3888C0.994646 11.453 1.19631 11.3888 1.29715 11.353C1.40715 11.3146 1.53715 11.2546 1.66715 11.1946L11.0171 6.9213C11.1446 6.86297 11.2721 6.80464 11.3713 6.74797C11.5304 6.6648 11.6603 6.53516 11.7438 6.3763Z"
          fill={color}
        />
      </svg>
    </div>
  );
};

export default SendInbox;
