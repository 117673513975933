import Favourite from "../../../assets/customSVG/inbox/Favourite";
import Resolved from "../../../assets/customSVG/inbox/Resolved";
import { ICONS } from "assets/icons";

export const ATTACHMENTS_TYPE = [
  {
    id: 1,
    label: "IMAGE",
    format: ".jpg, .png",
    icon: ICONS?.step3EmojiIcon,
  },
  {
    id: 2,
    label: "VIDEO",
    format: ".mp4",
    icon: ICONS?.step3EmojiIcon,
  },
  {
    id: 3,
    label: "DOCUMENT",
    format: ".pdf",
    icon: ICONS?.step3EmojiIcon,
  },
];

export const INLINE_STYLES = [
  {
    label: <img src={ICONS?.Bold} alt="" className="size-[0.8vw]" />,
    style: "BOLD",
  },
  {
    label: <img src={ICONS?.Italic} alt="" className="size-[0.8vw]" />,
    style: "ITALIC",
  },
  {
    label: <img src={ICONS?.StrikeThrough} alt="" className="size-[1vw]" />,
    style: "STRIKETHROUGH",
  },
];

export const CONVERSATION_LIST = [
  {
    id: 1,
    label: "Open",
    type: "opened",
    icon: ICONS?.inboxOpen,
  },
  {
    id: 2,
    label: "Close",
    type: "opened",
    icon: ICONS?.resolved,
  },
];

export const CATEGORY_TYPE_INBOX = [
  {
    id: 1,
    label: "Marketing",
    icon: ICONS?.marketingIcon,
    isSelected: false,
    category_id: 2,
  },
  {
    id: 2,
    label: "Utility",
    icon: ICONS?.utilityIcon,
    isSelected: false,
    category_id: 3,
  },
];

export const CONTACT_FILTERS = [
  {
    id: 1,
    label: "Close Conversation",
    type: "close",
    icon: Resolved,
  },
  {
    id: 2,
    label: "Favorite",
    type: "favorite",
    icon: Favourite,
  },
];

export const LANGUAGE_INBOX = [
  {
    id: 1,
    label: "Tamil",
  },
  {
    id: 2,
    label: "English",
  },
  {
    id: 3,
    label: "English_US",
  },
  {
    id: 4,
    label: "English_UK",
  },
  {
    id: 5,
    label: "Bengali",
  },
  {
    id: 6,
    label: "Hindi",
  },
  {
    id: 7,
    label: "Telugu",
  },
  {
    id: 8,
    label: "Kannada",
  },
  {
    id: 9,
    label: "Malayalam",
  },
  {
    id: 10,
    label: "Marathi",
  },
];
