import React from "react";
import { Outlet } from "react-router-dom";

// components
import SideBar from "../components/SideBar";

export default function Campaigns() {
  return (
    <div className="flex-row campaignWrapper">
      <SideBar />
      <div className="campaign-rightLayout">
        <Outlet />
      </div>
    </div>
  );
}
