import React from "react";

const TemplateStatus = ({ status }) => {
  const backendStatusMapping = {
    IN_REVIEW: "In-review",
    PENDING: "In-review",
    APPROVED: "Active",
    NEW: "Draft",
    SUBMITTED: "Draft",
    TEMPLATE_LOCKED: "Active",
    META_DELETE_ERROR: "Active",
    PAM: "Active",
    10: "Active",
  };

  const cardStyle =
    "w-fit h-fit rounded-[0.25vw] px-[0.65vw] py-[0.2vw] weight-medium flex items-center justify-center";

  if (status) {
    switch (status) {
      case "APPROVED":
      case "TEMPLATE_LOCKED":
      case "META_DELETE_ERROR":
      case 5:
      case 10:
        return (
          <div
            className={cardStyle}
            style={{
              background: "var(--primaryBackground)",
              color: "var(--primary)",
            }}
          >
            Active
          </div>
        );
      case "META_FAILED":
      case 4:
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "var(--lightRed)",
              color: "var(--fontRed)",
            }}
          >
            Meta Failed
          </div>
        );
      case "IN_REVIEW":
      case "PENDING":
      case 3:
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "#1778F229",
              color: "#1778F2",
            }}
          >
            In Review
          </div>
        );
      case "REJECTED":
      case "PRM":
      case 2:
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "var(--lightRed)",
              color: "var(--fontRed)",
            }}
          >
            Rejected
          </div>
        );
      case "DRAFT":
      case "NEW":
      case "SUBMITTED":
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "var(--BG-25)",
              color: "var(--darkgrey)",
            }}
          >
            Draft
          </div>
        );
      case "PAUSED":
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "#E6E7FD",
              color: "#6366F1",
            }}
          >
            Paused
          </div>
        );
      case "DISABLED":
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "#FFDFDE",
              color: "#FD3832",
            }}
          >
            Disabled
          </div>
        );
      case "TAMPERED":
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "#FFEBE0",
              color: "#FF833E",
            }}
          >
            Tampered
          </div>
        );
      case "PAM":
        return (
          <div
            className={cardStyle}
            style={{
              backgroundColor: "#FFEBE0",
              color: "#FF833E",
            }}
          >
            Active - Needs attention
          </div>
        );
      default:
        break;
    }
  }

  return (
    <div className="p-5 br-10 flex-row align-center justify-center">
      {status ? backendStatusMapping[status] : "Status not found"}
    </div>
  );
};

export default TemplateStatus;
