import React, { useCallback } from "react";

// constants
import { copyCode, oneTabAutoFill, zeroTabAutoFill } from "../../constant";

// helper
import { cn } from "../../../../helper/cn";
import { textOnly } from "../../../../helper/validation";

// hooks
import useToggle from "../../../../hooks/useToggle";

// components
import Select from "components/commonComponents/select/index";
import RadioOption from "../../../../components/form/RadioButton/radioOption";
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";

const EXPIRY_TIME = [
  {
    id: 1,
    label: "1 Minutes",
    value: 1,
  },
  {
    id: 2,
    label: "2 Minutes",
    value: 2,
  },
  {
    id: 3,
    label: "3 Minutes",
    value: 3,
  },
  {
    id: 4,
    label: "5 Minutes",
    value: 5,
  },
  {
    id: 5,
    label: "10 Minutes",
    value: 10,
  },
];

const EXPIRY_TIME_LABEL = {
  1: "1 Minutes",
  2: "2 Minutes",
  3: "3 Minutes",
  5: "5 Minutes",
  10: "10 Minutes",
};

export default function AuthenticationTemplate({ data, dispatch, onChange }) {
  const [expiryTimeError, setExpiryTimeError] = useToggle(false);

  const onDeliverySetupChange = useCallback(
    (value) => {
      onChange({
        type: "update",
        primaryKey: "authentication",
        secondaryKey: "codeDeliverySetup",
        key: "codeDeliverySetup",
        value: value,
      });
    },
    [data]
  );

  const onContentsChange = useCallback(
    (key, value) => {
      let bodyText = data?.body;
      if (key === "securityRecommendation" && value)
        bodyText =
          "{{otp-number}} is your verification code. For your security, do not share this code.";
      else if (key === "securityRecommendation" && !value)
        bodyText = "{{otp-number}} is your verification code.";

      onChange({
        type: "athentication",
        updateKey: "content",
        key: key,
        value: value,
        text: bodyText,
      });
    },
    [data]
  );

  const onButtonsChange = useCallback(
    (id, key, value) => {
      onChange({
        type: "athentication",
        updateKey: "update_buttons",
        id: id,
        key: key,
        value: value,
      });
    },
    [data]
  );

  const updateExpiry = useCallback(
    (type) => {
      let value = data?.content?.expiryTime;
      if (type === "increese" && data?.content?.expiryTime < 90)
        if (value >= 1) value = data?.content?.expiryTime + 1;
        else value = 1;
      else if (type === "decreese" && data?.content?.expiryTime > 1)
        if (value <= 90) value = data?.content?.expiryTime - 1;
        else value = 90;

      onContentsChange("expiryTime", +value);
      if (+value < 1) setExpiryTimeError(true);
      else if (+value > 90) setExpiryTimeError(true);
      else setExpiryTimeError(false);
    },
    [data]
  );

  const updateMessageValidity = useCallback(
    (key, value) => {
      onChange({
        type: "update",
        primaryKey: "authentication",
        secondaryKey: "messageValidity",
        key,
        value,
      });
    },
    [data]
  );

  // style
  const headerStyle =
    "font-normal weight-bold mb-[0.3vw] text-[var(--contentText)]";
  const subHeader =
    "weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.7vw]";
  const title = "font-md weight-bold text-[var(--contentText)]";
  const subTitle = "font-sm weight-medium text-[var(--font-400)] mt-[0.25vw]";

  return (
    <div className="w-full">
      <div className="">
        <h2 className={cn(headerStyle)}>Code delivery setup </h2>
        <p className={`${subHeader} font-md`}>
          Choose how customers send the code from WhatsApp to your app.
        </p>
        <div className="flex flex-col gap-[0.7vw] mt-[0.5vw]">
          {/* <div
            className={`w-full px-[1.2vw] py-[0.6vw] gap-[1vw] rounded-[0.8vw] ${
              zeroTabAutoFill.includes(data?.codeDeliverySetup)
                ? '!bg-[#DCFCE7]'
                : ''
            }`}
            onClick={() => {
              onDeliverySetupChange('zero-tap');
            }}
          >
            <div className='flex-row !gap-[1vw]'>
              <RadioOption
                value='zero-tap'
                selectedValue={data?.codeDeliverySetup}
                onChange={() => {
                  onDeliverySetupChange('zero-tap');
                }}
                id='zero-tap'
                name='zero-tap'
                style={{
                  width: '1.2vw',
                  height: '1.2vw',
                  margin: '0.15vw 0 0',
                }}
              />
              <div className='flex-column'>
                <h3 className={cn(headerStyle)}>Zero-tap auto-fill</h3>
                <p className={`${subHeader} font-sm !pb-0`}>
                  This is recommended as the easiest option for your customers.
                  Zero-tap will automatically send code without requiring your
                  customer to tap a button. An auto-fill or copy code message
                  will be sent if zero-tap and auto-fill aren't possible.
                </p>
              </div>
            </div>
            {zeroTabAutoFill.includes(data?.codeDeliverySetup) && (
              <div
                className={`flex gap-[1vw] ml-[1.8vw] mt-[0.5vw] px-[0.5vw] py-[0.3vw] cursor-pointer bg-white rounded-[0.5vw]`}
              >
                <div className='w-[2.5vw] h-[0.8vw] mt-1'>
                  <CheckBoxSqure
                    value={data?.zeroTabTerms}
                    onChange={(value) => {
                      dispatch({
                        type: 'update_athentication',
                        updateType: 'codeDeliverySetup',
                        key: 'zeroTabTerms',
                        value: value,
                      });
                    }}
                  />
                </div>
                <label htmlFor='custom' className='cursor-pointer'>
                  <p className={`${subTitle} font-sm !mt-0`}>
                    By selecting zero-tap, I understand that use of zero-tap
                    authentication is subject to the WhatsApp Business Terms of
                    Service. It's user's responsibility to ensure that its
                    customers expect that the code will be automatically filled
                    in on their behalf when they choose to receive the zero-tap
                    code through WhatsApp. Learn more and review best practices.
                  </p>
                </label>
              </div>
            )}
          </div>
          <div
            className={`w-full px-[1.2vw] py-[0.4vw] rounded-[0.8vw] flex items-center gap-[1vw] cursor-pointer ${
              oneTabAutoFill.includes(data?.codeDeliverySetup)
                ? '!bg-[#DCFCE7]'
                : ''
            }`}
            onClick={() => onDeliverySetupChange('one-tap')}
          >
            <RadioOption
              value='one-tap'
              selectedValue={data?.codeDeliverySetup}
              onChange={() => {
                onDeliverySetupChange('one-tap');
              }}
              id='one-tap'
              name='one-tap'
              style={{
                width: '1.2vw',
                height: '1.2vw',
                margin: '0.15vw 0 0',
              }}
            />
            <div className=''>
              <h2 className={cn(headerStyle)}>One-tap auto-fill</h2>
              <p className={`${subHeader} font-sm !pb-0`}>
                The code sends to your app when customers tap the button.A copy
                code message will be sent if auto - fill isn't possible.
              </p>
            </div>
          </div> */}
          <div
            className={`w-full px-[1.2vw] py-[0.4vw] rounded-[0.5vw] flex gap-[0.8vw]  ${
              copyCode.includes(data?.codeDeliverySetup) ? "!bg-[#DCFCE7]" : ""
            }`}
            // onClick={() => onDeliverySetupChange('copy-code')}
          >
            <RadioOption
              value="copy-code"
              selectedValue={data?.codeDeliverySetup}
              onChange={() => {
                onDeliverySetupChange("copy-code");
              }}
              id="copy-code"
              name="copy-code"
              style={{
                width: "1vw",
                height: "1vw",
                margin: "0.2vw 0 0",
              }}
            />
            <div className="">
              <h2 className={`${title}`}>Copy code</h2>
              <p className={`${subTitle} font-sm !pb-0`}>
                Basic authentication with quick setup. Your customers copy and
                paste the code into your app.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 pt-6 border-t border-[var(--border-100)]">
        <h2 className={cn(headerStyle)}>Content</h2>
        <p className={`${subHeader} font-md`}>
          Content for authentication message templates can't be edited. You can
          add additional content from the options below.
        </p>
        <div className="flex flex-col gap-4 pl-6 pt-4">
          <div className="flex gap-3">
            <div className="size-4 mt-0.5">
              <CheckBoxSqure
                value={data?.content?.securityRecommendation}
                onChange={(value) => {
                  onContentsChange("securityRecommendation", value);
                }}
                dataTestId="Add security recommendation"
              />
            </div>
            <div className="flex flex-col gap-1">
              <h5 className={`${title}`}>Add security recommendation</h5>
            </div>
          </div>
          <div className="flex gap-3">
            <div className="size-4 mt-0.5">
              <CheckBoxSqure
                value={data?.content?.expiryTimeForCode}
                onChange={(value) => {
                  onContentsChange("expiryTimeForCode", value);
                }}
                dataTestId="expiry time"
              />
            </div>
            <div className="flex flex-col gap-0.5">
              <h5 className={`${title}`}>Add expiry time for the code</h5>
              <p className={`${subTitle}`}>
                After the code has expired, the auto-fill button will be
                disabled.
              </p>
            </div>
          </div>
          {data?.content?.expiryTimeForCode && (
            <div>
              <label className={cn("weight-medium", title)}>
                Expires In{" "}
                <span className="text-[var(--font-600)] weight-medium">
                  (Minutes)
                </span>
              </label>
              <div
                className={cn(
                  "w-11/12 flex items-center border rounded-[0.4vw] p-[0.5vw] font-sm mt-2.5",
                  expiryTimeError
                    ? "border-[var(--fontRed)]"
                    : "border-[var(--border-100)]"
                )}
              >
                <div className="flex-1">
                  <input
                    type="text"
                    className="w-full outline-none border-none text-[var(--font-600)]"
                    value={data?.content?.expiryTime}
                    onChange={(e) => {
                      const { value } = e.target;
                      const replaced = value.match(/\d+/g);
                      onContentsChange("expiryTime", +replaced);
                      if (+replaced < 1) setExpiryTimeError(true);
                      else if (+replaced > 90) setExpiryTimeError(true);
                      else setExpiryTimeError(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowUp") updateExpiry("increese");
                      if (e.key === "ArrowDown") updateExpiry("decreese");
                    }}
                    min={1}
                    max={90}
                    required
                  />
                </div>
                <div className="px-3.5">Minutes</div>
                <div className="flex flex-col items-center">
                  <div
                    className={`transition-transform duration-200 ease-linear cursor-pointer rotate-180`}
                    onClick={() => {
                      updateExpiry("increese");
                    }}
                    data-testid="increase"
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    ></div>
                  </div>
                  <div
                    className={`transition-transform duration-200 ease-linear cursor-pointer`}
                    onClick={() => {
                      updateExpiry("decreese");
                    }}
                    data-testid="decrease"
                  >
                    <div
                      className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className={cn(
                  "font-sm weight-medium pt-[0.4vw]",
                  expiryTimeError ? "text-[var(--fontRed)]" : ""
                )}
              >
                <sup className="text-red-400">*</sup>Expires in should be
                between 1 to 90 minutes
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-8 pt-6 border-t border-[var(--border-100)]">
        <h2 className={cn(headerStyle)}>Buttons</h2>
        <p className={`${subHeader} font-md`}>
          You can customise the button text for both auto-fill and copy code.
          Even when zero-tap is turned on, buttons are still needed for the
          backup code delivery method.
        </p>
        <div className="mt-2.5">
          {data?.buttons?.map((b) => {
            return (
              <div className="flex flex-col gap-2.5">
                <label className={`${title}`}>{b?.label}</label>
                <div className="flex items-center p-2.5 border border-[var(--border-100)] rounded-md">
                  <input
                    type="text"
                    placeholder={b?.placeholder}
                    className="flex flex-1 text-[0.85vw] weight-medium text-[var(--textBlack)]"
                    value={b?.text}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (b?.otp_type === "COPY_CODE") {
                        const res = textOnly(value);
                        onButtonsChange(b?.id, "text", res);
                      } else {
                        onButtonsChange(b?.id, "text", value);
                      }
                    }}
                    data-testid="buttons-copy-code"
                    maxLength={25}
                  />
                  <p className="text-[0.85vw] weight-semibold text-[var(--textBlack)]">
                    {b?.text?.length > 0 ? b?.text?.length : 0}/25
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="my-8 pt-6 border-t border-[var(--border-100)]">
        <h2 className={cn(headerStyle)}>Message validity period</h2>
        <p className={`${subHeader} font-md`}>
          It's recommended to set a custom validity period that your
          authentication message must be delivered by before it expires. If a
          message is not delivered within this time frame, you will not be
          charged and your customer will not see the message.
        </p>
        <div className="flex gap-3">
          <div className="mt-1">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value={data?.messageValidity?.validity}
                checked={data?.messageValidity?.validity}
                className="sr-only peer"
                onChange={(e) => {
                  updateMessageValidity("validity", e.target.checked);
                }}
                hidden
                data-testid="valid-period-checkbox"
              />
              <div className="relative w-9 h-5 bg-[var(--BG-100)] rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-[var(--primary)]"></div>
            </label>
          </div>
          <div className="flex flex-col gap-0.5">
            <h5 className={`${title}`}>
              Set custom validity period for your message
            </h5>
            <p className={`${subTitle}`}>
              If you don't set a custom validity period, the standard 30 days
              WhatsApp message validity period will be applied.
            </p>
          </div>
        </div>
        {data?.messageValidity?.validity && (
          <div className="my-5">
            <Select
              rootClassName="border-[var(--card-border)] mt-5"
              placeholder="Select Category"
              options={EXPIRY_TIME}
              value={EXPIRY_TIME_LABEL[data?.messageValidity?.period]}
              position="top"
              onChange={(value) => {
                updateMessageValidity("period", value?.value);
              }}
              dataTestId="valid minutes"
              optionClick="valid option"
            />
          </div>
        )}
      </div>
    </div>
  );
}
