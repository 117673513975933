export const INITIAL_STATE = {
  information: {
    first_name: "",
    last_name: "",
    primary_location: "",
    contact_origin: null,
    gender: null,
    age_range_code: null,
    origins: [],
    last_contacted_at: "",
    province: "",
    country: "",
    contact_source: "",
    dob: null,
  },
  phone_numbers: [],
  address: {
    primary_address_line_1: "",
    primary_address_line_2: "",
    primary_zip_code: "",
    primary_country: "",
    primary_state: "",
    primary_city: "",
    primary_location_id: "",
    primary_country_id: null,
    primary_province_id: null,
    primary_city_id: null,

    secondary_address_line_1: "",
    secondary_address_line_2: "",
    secondary_zip_code: "",
    secondary_country: "",
    secondary_state: "",
    secondary_city: "",
    location_id: "",
  },

  // address: [],
  opted_out: {},
  person_contacts: [],
  emails: [],
  social_profile: [
    {
      id: 1,
      label: "Facebook",
      contact_type: "FACEBOOK_PROFILE",
      contact_id: "",
      url: "",
    },
    {
      id: 2,
      label: "Instagram",
      contact_type: "INSTAGRAM_PROFILE",
      contact_id: "",
      url: "",
    },
    {
      id: 3,
      label: "LinkedIn",
      contact_type: "LINKEDIN_PROFILE",
      contact_id: "",
      url: "",
    },
    {
      id: 4,
      label: "Twiiter_X",
      contact_type: "TWITTER_PROFILE",
      contact_id: "",
      url: "",
    },
    {
      id: 5,
      label: "TikTok",
      contact_type: "TIKTOK_PROFILE",
      contact_id: "",
      url: "",
    },
  ],
  tags: [],
  profession: [],
};

export const GENDER = {
  0: "Others",
  1: "Male",
  2: "Female",
};

export const AGE_RANGE = {
  1: "2 - 17",
  2: "18 - 24",
  3: "25 - 34",
  4: "35 - 44",
  5: "45 - 54",
  6: "54 - 64",
  7: "65+",
};

export const ORIGINS = {
  1: "Other",
  2: "Facebook Ads",
  3: "Facebook Reels",
  4: "Facebook Post",
  5: "Instagram Post",
  6: "Linkedin Ads",
  7: "Twitter Ads",
  8: "Website",
  9: "",
};

export const singleContactReducer = (state, action) => {
  const { type, id, key, value, data } = action;

  const newContact = {
    contact_id: "",
    contact_category: "",
    contact_category_name: "",
    contact_source: "",
    contact_source_name: "",
    contact_type: "MOBILE_PHONE",
    contact_type_name: "Mobile phone number",
    email: "",
    phone_country_code: "",
    phone_number: null,
    priority: 2,
  };

  const newContactEmail = {
    contact_id: "",
    contact_category: "",
    contact_category_name: "",
    contact_source: "",
    contact_source_name: "",
    contact_type: "EMAIL",
    contact_type_name: "Email address",
    email: null,
    phone_country_code: "",
    phone_number: null,
    priority: 2,
  };

  switch (type) {
    case "update_info":
      return {
        ...state,
        information: { ...state?.information, [key]: value },
        address: { ...state?.address, [key]: value },
        opted_out: {
          ...state?.opted_out,
          [key]: value?.opted_out?.[key],
          [`${key}_last_update`]:
            value?.opted_out?.[`${key}_last_update`] ||
            state?.opted_out[`${key}_last_update`],
        },
      };

    case "update_phone_number":
      if (key === "add") {
        return {
          ...state,
          person_contacts: [...state?.person_contacts, newContact],
        };
      }

      if (key === "update") {
        const res = state?.person_contacts?.map((p) => {
          if (p?.id === id) {
            return { ...p, ...value };
          } else {
            return { ...p };
          }
        });
        return {
          ...state,
          person_contacts: res,
        };
      }

      if (key === "update_priority_phonenumber") {
        const res = state?.person_contacts?.map((p) => {
          if (p?.id === id) {
            return { ...p, ...value, priority: 1 };
          } else if (p?.contact_type === "MOBILE_PHONE") {
            return { ...p, priority: 2 };
          } else {
            return { ...p };
          }
        });

        return {
          ...state,
          person_contacts: res,
        };
      }

      return { ...state };

    case "update_email":
      if (key === "add_email") {
        return {
          ...state,
          person_contacts: [...state?.person_contacts, newContactEmail],
        };
      }

      if (key === "updated_email") {
        const res = state?.person_contacts?.map((p) => {
          if (p?.id === id) {
            return { ...p, ...value };
          } else {
            return { ...p };
          }
        });

        return {
          ...state,
          person_contacts: res,
        };
      }

      if (key === "update_priority_email") {
        const res = state.person_contacts?.map((p) => {
          if (p?.id === id) {
            return { ...p, ...value, priority: 1 };
          } else if (p?.contact_type === "EMAIL") {
            return { ...p, priority: 2 };
          } else {
            return { ...p };
          }
        });

        return {
          ...state,
          person_contacts: res,
        };
      }

      return { ...state };

    case "profession":
      if (key === "update_profession") {
        const res = state?.profession?.map((p) => {
          if (p?.id === id) {
            return { ...p, ...value };
          } else {
            return p;
          }
        });

        return {
          ...state,
          profession: res,
        };
      }

      if (key === "update_professions_primary") {
        const res = state?.profession?.map((p) => {
          if (p?.id === id) {
            return { ...p, ...value, priority: 1 };
          } else {
            return { ...p, priority: 2 };
          }
        });

        return {
          ...state,
          profession: res,
        };
      }

    case "update_contact": {
      const { id, value } = action;
      const updatedContacts = state.person_contacts.map((contact) =>
        contact.id === id ? { ...contact, ...value } : contact
      );

      return {
        ...state,
        person_contacts: updatedContacts,
      };
    }

    case "update_social_profile":
      if (key === "update")
        return {
          ...state,
          social_profile: state.social_profile?.map((p) => {
            if (p?.id === id) return { ...p, url: value };
            return p;
          }),
        };

    case "update_data":
      const primaryAddress =
        data?.addresses?.find((addr) => addr.priority === 1) ||
        data?.addresses?.[0];
      const secondaryAddress =
        data?.addresses?.find((addr) => addr.priority === 2) ||
        data?.addresses?.[1];

      const socialMediaData = (type) => {
        return data?.person_contacts?.filter((d) => d.contact_type === type);
      };

      return {
        ...state,
        information: {
          ...state?.information,
          first_name: data?.first_name,
          last_name: data?.last_name,
          age_range_code: data?.age_range_code,
          gender: data?.gender,
          contact_origin: data?.contact_origin,
          last_contacted_at: data?.last_contacted_at,
          province: data?.province,
          country: data?.country,
          country: data?.country,
          contact_source: data?.contact_source,
          dob: data?.dob,
        },
        opted_out: {
          ...state?.opted_out,
          whatsapp: data?.opted_out?.whatsapp,
          whatsapp_last_update: data?.opted_out?.whatsapp_last_update,
          email: data?.opted_out?.email,
          email_last_update: data?.opted_out?.email_last_update,
          sms: data?.opted_out?.sms,
          sms_last_update: data?.opted_out?.sms_last_update,
        },
        phone_numbers: data?.phone_numbers,
        // address: data?.address,
        address: {
          ...state?.address,
          // Primary Address Fields
          primary_address_line_1: primaryAddress?.address_line_1 || "",
          primary_address_line_2: primaryAddress?.address_line_2 || "",
          primary_zip_code: primaryAddress?.postal_code || "",
          primary_country: primaryAddress?.country || "",
          primary_state: primaryAddress?.province || "",
          primary_city: primaryAddress?.city || "",
          primary_location_id: primaryAddress?.location_id || "",
          primary_contact_person_location_id:
            primaryAddress?.contact_person_location_id || "",
          primary_location_id: primaryAddress?.location_id || "",
          primary_country_id: primaryAddress?.country_id || "",
          primary_province_id: primaryAddress?.province_id || "",
          primary_city_id: primaryAddress?.city_id || "",

          // Secondary Address Fields
          secondary_address_line_1: secondaryAddress?.address_line_1 || "",
          secondary_address_line_2: secondaryAddress?.address_line_2 || "",
          secondary_zip_code: secondaryAddress?.postal_code || "",
          secondary_country: secondaryAddress?.country || "",
          secondary_state: secondaryAddress?.province || "",
          secondary_city: secondaryAddress?.city || "",
          secondary_location_id: secondaryAddress?.location_id || "",
          secondary_contact_person_location_id:
            secondaryAddress?.contact_person_location_id || "",
          secondary_location_id: secondaryAddress?.location_id || "",
        },

        person_contacts: data?.person_contacts || [],
        tags: data?.tags || [],
        profession:
          data?.profession?.length === 2
            ? data?.profession?.sort((d1, d2) => d1?.priority - d2?.priority)
            : data?.profession?.length === 1
            ? [
                {
                  ...data?.profession[0],
                  priority: 1,
                },
                {
                  id: 2,
                  priority: 2,
                  name: "",
                },
              ]
            : [
                {
                  id: 1,
                  priority: 1,
                  name: "",
                },
                {
                  id: 2,
                  priority: 2,
                  name: "",
                },
              ],

        social_profile: state?.social_profile?.map((s) => {
          const data = socialMediaData(s?.contact_type);
          return { ...s, ...data?.[0], url: data?.[0]?.social_profile?.url };
        }),
      };

    case "reset":
      return INITIAL_STATE;

    default:
      break;
  }
};
