import GoalTarget from "../../../../assets/customSVG/campaigns/GoalTarget";
import { ICONS } from "../../../../assets/icons";
import { Tooltip } from "antd";
import React from "react";
import GaugeChart from "react-gauge-chart";
import { goalTypeConvertion } from "../../constants/campaigns";

//helper
import { formatCount } from "./../../helper/ThousandsCount";

const GaugeCharts = ({ goalInfo, campaignOverviewData }) => {
  const metric_type = goalInfo?.metric_type;
  // const achievedPercent =
  //   metric_type === "NUMBER"
  //     ? goalInfo?.goal_achieved || 0
  //     : (goalInfo?.goal_achieved || 0) / 100;
  // const achievedPercent =
  //   metric_type === "NUMBER"
  //     ? Math.min(goalInfo?.goal_achieved || 0, 100) / 100
  //     : (goalInfo?.goal_achieved || 0) / 100;

  const achievedPercent =
    Math.min(goalInfo?.goal_achieved || 0, goalInfo?.goal_target || 0) /
      goalInfo?.goal_target || 0;

  const target = goalInfo?.goal_achieved > goalInfo?.goal_target;

  const colors = [
    goalInfo?.progress_status === "not started" ||
    goalInfo?.progress_status === "No data found"
      ? "#E8E8EA"
      : goalInfo?.progress_status === "completed"
      ? "#1778F2"
      : goalInfo?.progress_status === "needs attention"
      ? "#EDAB40"
      : goalInfo?.progress_status === "at risk"
      ? "red"
      : "#25C277",
    "#E8E8EA",
  ];

  const goalColor =
    goalInfo?.progress_status === "not started" ||
    goalInfo?.progress_status === "No data found"
      ? "#A9B1C1"
      : goalInfo?.progress_status === "completed"
      ? "#1778F2"
      : goalInfo?.progress_status === "needs attention"
      ? "#EDAB40"
      : goalInfo?.progress_status === "at risk"
      ? "red"
      : goalInfo?.progress_status === "No data found"
      ? "#A9B1C1"
      : "#25C277";

  return (
    <div
      style={{ position: "relative", width: "100%" }}
      className="flex align-center justify-center"
    >
      <Tooltip
        title={
          <div className="flex-row align-center gap-2">
            <span
              style={{
                backgroundColor: "var(--new-primary)",
                height: "8px",
                width: "8px",
                borderRadius: "50%",
              }}
            />
            <div className="flex items-center">
              <span className="text-[0.8rem] font-[400]">
                Total
                <span className="px-1">
                  {
                    goalTypeConvertion[
                      campaignOverviewData?.campaign_goal_type_id
                    ]
                  }
                </span>
                :
              </span>
              <span className="text-[0.8rem] font-[600] pl-1">
                {/* {(achievedPercent * 100).toFixed(2)}% */}
                {/* {metric_type === "NUMBER"
                  ? achievedPercent
                  : `${(achievedPercent * 100).toFixed(2)}%`} */}
                {goalInfo?.goal_achieved} {metric_type === "PERCENTAGE" && "%"}
              </span>
            </div>
          </div>
        }
      >
        <div className="w-100">
          <GaugeChart
            id="gauge-chart"
            nrOfLevels={0}
            colors={colors}
            arcWidth={0.2}
            percent={achievedPercent}
            arcsLength={[achievedPercent, 1 - achievedPercent]}
            needleColor="#464A4F"
            lineCap="round"
            arcPadding={0}
            responsive={true}
            // className="!h-[10vh] border overflow-hidden"
          />
        </div>
      </Tooltip>
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: "16%",
          transform: "translateY(-50%)",
        }}
        className="chart-edge-count"
      >
        0
      </div>

      <div
        style={{
          position: "absolute",
          top: "100%",
          right: "12%",
          transform: "translateY(-50%)",
        }}
        className="chart-edge-count"
      >
        {formatCount(goalInfo?.goal_target)}
        {metric_type === "PERCENTAGE" && "%"}
      </div>
      <div
        style={{
          position: "absolute",
          top: "100%",
          left: "52%",
          transform: "translate(-50%, -50%)",
          fontSize: "1.16rem",
          fontWeight: "600",
          color: goalColor,
        }}
        className="flex-row align-center gap-1 capitalize"
      >
        {/* {Math.round(achievedPercent * 100)}% */}

        {goalInfo?.progress_status === "not started" ||
        goalInfo?.progress_status === "No data found" ? (
          <div className="w-2 h-2 rounded-full bg-[#A9B1C1]"></div>
        ) : goalInfo?.progress_status === "needs attention" ? (
          <div className="w-2 h-2 rounded-sm bg-[#EDAB40]"></div>
        ) : goalInfo?.progress_status === "completed" ? (
          <div className="w-2 h-2 rounded-full bg-[#1778F2]"></div>
        ) : (
          <GoalTarget
            color={goalColor}
            className={`${
              goalInfo?.progress_status === "at risk" ? "rotate-180" : ""
            }`}
          />
        )}

        {goalInfo?.progress_status}
      </div>
    </div>
  );
};

export default GaugeCharts;
