import React from 'react';

const QRCode = ({
  width = 22,
  height = 22,
  color = 'white',
  strokeWidth = 1.8,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.3003 18.2002H18.2003'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.30029 3.7998H4.70029C4.20324 3.7998 3.80029 4.20275 3.80029 4.6998V8.2998C3.80029 8.79686 4.20324 9.1998 4.70029 9.1998H8.30029C8.79735 9.1998 9.20029 8.79686 9.20029 8.2998V4.6998C9.20029 4.20275 8.79735 3.7998 8.30029 3.7998Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.8003 18.2002H14.6003'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5 15.5L18.2 15.5'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.8003 15.5L12.8003 18.2'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.2002 12.7998L18.2002 15.4998'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.3003 12.7998L18.2003 12.7998'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.8003 12.7998H14.6003'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.30029 12.7998H4.70029C4.20324 12.7998 3.80029 13.2027 3.80029 13.6998V17.2998C3.80029 17.7969 4.20324 18.1998 4.70029 18.1998H8.30029C8.79735 18.1998 9.20029 17.7969 9.20029 17.2998V13.6998C9.20029 13.2027 8.79735 12.7998 8.30029 12.7998Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.3003 3.7998H13.7003C13.2032 3.7998 12.8003 4.20275 12.8003 4.6998V8.2998C12.8003 8.79686 13.2032 9.1998 13.7003 9.1998H17.3003C17.7973 9.1998 18.2003 8.79686 18.2003 8.2998V4.6998C18.2003 4.20275 17.7973 3.7998 17.3003 3.7998Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default QRCode;
