import React, { useEffect, useState } from "react";

// assets
import { ICONS } from "assets/icons";
import List from "assets/customSVG/List";

// helper
import { replaceWord } from "../../../channels/components/templates/index";
import { replaceVariables } from "../../helper";
import { addLineBreake, replaceFormating } from "helper/templates";

// hooks
import { useTemplate } from "hooks/templates/useTemplate";

// utils
import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getFirtstTypeOfButton,
} from "components/commonComponents/templates/Buttons/utils";
import { formatCarouelDataFromAPI } from "../../../channels/utils/carousel";

export default function ConversationCard({ data }) {
  const { singleTemplate, updateSingleTemplate } = useTemplate({
    limit: 0,
    statu: null,
    category: null,
  });

  const [headerObj, setHeaderObj] = useState(null);
  const [bodyObj, setBodyObj] = useState({
    type: "BODY",
    text: "",
  });

  const [carousel, setCarousel] = useState(null);

  const [footerObj, setFooterObj] = useState(null);
  const [buttons, setButtons] = useState([]);

  const [buttonsOrder, setButtonsOrder] = useState("");
  const [ctaButtons, setCtaButtons] = useState(null);
  const [customButtons, setCustomButtons] = useState(null);

  useEffect(() => {
    if (
      data &&
      (data?.message?.template?.length > 0 || data?.message?.length > 0)
    )
      getData(data);

    // updateSingleTemplate(data)
  }, [data]);

  const getData = (data) => {
    let currentData = [];
    let header = [];
    let body = [];
    let footer = [];
    let buttons = [];
    if (data?.message?.template) {
      header = data?.message?.template?.filter((c) => c.type === "HEADER");
      body = data?.message?.template?.filter((c) => c.type === "BODY");
      footer = data?.message?.template?.filter((c) => c.type === "FOOTER");
      buttons = data?.message?.template?.filter((c) => c.type === "BUTTONS");
    } else if (data?.message) {
      header = data?.message?.filter((c) => c.type === "HEADER");
      body = data?.message?.filter((c) => c.type === "BODY");
      footer = data?.message?.filter((c) => c.type === "FOOTER");
      buttons = data?.message?.filter((c) => c.type === "BUTTONS");
    }

    const { carousel } = formatCarouelDataFromAPI(data);

    const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
      return {
        id: i + 1,
        format:
          b.type === "QUICK_REPLY"
            ? b.text !== "Stop promotions"
              ? "CUSTOM"
              : "OPT_OUT"
            : b.type,
        ...b,
      };
    });

    if (header?.length > 0) setHeaderObj(header?.[0]);
    else setHeaderObj(null);

    if (body?.length > 0) {
      const updatesStr = replaceVariables(
        body?.[0]?.text,
        body?.[0]?.example?.body_text?.[0]
      );
      const formatedText = replaceFormating(updatesStr);
      const lineBrn = addLineBreake(formatedText);
      setBodyObj({ ...body?.[0], text: lineBrn });
    } else setBodyObj(null);

    if (footer?.length > 0) setFooterObj(footer?.[0]);
    else setFooterObj(null);

    setCarousel(carousel);

    if (updatedButtons?.length > 0) {
      const order = getFirtstTypeOfButton(updatedButtons);
      const ctaButtons = updatedButtons?.filter((b) =>
        allowedCtaButtonTypes.includes(b.type)
      );
      const customButtonsWithoutOptout = updatedButtons?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text !== "Stop promotions"
      );
      const customButtonsWithOptout = updatedButtons?.filter(
        (b) =>
          allowedCustomButtonTypes.includes(b.type) &&
          b.text === "Stop promotions"
      );

      const customButtons = [
        ...customButtonsWithoutOptout,
        ...customButtonsWithOptout,
      ];

      if (order === "ctaButtons" && ctaButtons?.length > 0)
        setButtonsOrder("ctaButtons");
      if (order === "custom" && customButtons?.length > 0)
        setButtonsOrder("custom");

      setCtaButtons(ctaButtons);
      setCustomButtons(customButtons);

      setButtons(updatedButtons);
    } else {
      setButtons([]);
      setCtaButtons(null);
      setCustomButtons(null);
    }
  };
  const total =
    buttonsOrder === "ctaButtons"
      ? [...ctaButtons, ...customButtons]
      : buttonsOrder === "custom"
      ? [...customButtons, ...ctaButtons]
      : [];

  const buttonsData = total?.length <= 3 ? total : total.slice(0, 2);

  const btnStyle =
    "w-full flex items-center justify-center gap-[0.5vw] py-[0.5vw] inbox14px-400 rounded-[0.5vw] cursor-default";

  return (
    <div className="text-[var(--contentText)]">
      {/* header */}
      {headerObj && (
        <div className="w-full rounded-[0.4vw] h-full overflow-hidden mb-[0.5vw]">
          {headerObj?.format === "TEXT" && (
            <div className="h-full sidebar-header  break-words">
              {headerObj?.example && headerObj?.example?.header_text?.[0] !== 0
                ? replaceWord(headerObj?.text, headerObj?.example?.header_text)
                : headerObj?.text}
            </div>
          )}
          {headerObj?.format === "IMAGE" &&
            headerObj?.example?.header_handle?.length > 0 && (
              <div className="w-full  h-full">
                <img
                  src={headerObj?.example?.header_handle?.[0]}
                  className="w-full h-full object-cover rounded-[0.4vw]"
                />
              </div>
            )}
          {headerObj?.format === "VIDEO" &&
            headerObj?.example?.header_handle?.length > 0 && (
              <video
                className="w-full object-cover rounded-[0.4vw] max-h-[10vw]"
                // autoplay='autoplay'
                controls
                loop
                // width='250vw'
                // height='10vh'
              >
                <source
                  src={headerObj?.example?.header_handle?.[0]}
                  type="video/mp4"
                />
              </video>
            )}
          {headerObj?.format === "DOCUMENT" &&
            headerObj?.example?.header_handle?.length > 0 && (
              <iframe
                title="cover"
                className=" max-h-[6vw]"
                src={headerObj?.example?.header_handle?.[0]}
                type="application/pdf"
                width="100%"
                height="150px"
              ></iframe>
            )}
        </div>
      )}
      <div
        className={`${
          ["IMAGE", "VIDEO", "DOCUMENT"].includes(headerObj?.format)
            ? "w-full"
            : "w-full"
        }`}
      >
        {/* body */}
        {bodyObj && (
          <div
            className={`new-body-text break-words w-100`}
            dangerouslySetInnerHTML={{
              __html: bodyObj?.text,
            }}
          ></div>
        )}

        {/* footer */}
        {footerObj && (
          <div className="new-sidebar mt-[0.5vw]">{footerObj?.text}</div>
        )}
        {data?.status === "BOUNCED" && (
          <div className="flex-row align-center justify-end gap-1 text-[0.8rem] font-[500] text-[#FF543E] ptb-5">
            <img src={ICONS?.inboxBounced} alt="" />
            Unable to send the message!
          </div>
        )}
        {/* buttons */}
        {buttonsData?.length > 0 && (
          <div className="h-auto pt-[0.7vw] rounded-b-[0.7vw] text-[#007AFF]">
            {buttonsData?.map((b, i) => {
              return (
                <div
                  className="min-h-[2vh] border-t-[0.08vw] border-t-[#fff]"
                  key={`preview-button-${i}`}
                >
                  {allowedCtaButtonTypes.includes(b?.type) && (
                    <div className="">
                      {b.type === "URL" && (
                        <div className={`${btnStyle}`}>
                          <img
                            src={ICONS?.nounLink}
                            alt="shareBtn"
                            className="inbox14px-400"
                          />
                          {b.text}
                        </div>
                      )}
                      {b.type === "PHONE_NUMBER" && (
                        <div className={`${btnStyle}`}>
                          <img
                            src={ICONS?.call}
                            alt="shareBtn"
                            className="inbox14px-400"
                          />
                          {b.text}
                        </div>
                      )}

                      {b.type === "COPY_CODE" && (
                        <div className={`${btnStyle}`}>
                          <img
                            src={ICONS?.CopyLeft}
                            alt="shareBtn"
                            className="inbox14px-400"
                          />
                          <span>Copy Offer Code</span>
                        </div>
                      )}
                    </div>
                  )}
                  {allowedCustomButtonTypes?.includes(b?.type) && (
                    <div className={`${btnStyle}`}>
                      <img
                        src={ICONS?.shareLink}
                        alt="shareBtn"
                        className="size-[0.8vw]"
                      />
                      <p>{b.text}</p>
                    </div>
                  )}
                </div>
              );
            })}
            {total?.length > 3 && (
              <button
                className="w-full h-[2vw] flex items-center justify-center gap-[0.5vw] inbox14px-400 border-t-[0.08vw] border-[var(--border-50)]"
                onClick={() => {
                  // toggleDrawer();
                }}
              >
                <List color="#007AFF" />
                See all options
              </button>
            )}
          </div>
        )}
      </div>
      {/* <div className=''>{JSON.stringify(carousel)}</div> */}
    </div>
  );
}
