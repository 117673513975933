import React, { useState } from "react";

// assets
import Chevron from "assets/customSVG/Chevron";

// utils
import { useLocation, useNavigate } from "react-router-dom";

// redux
import { useAspSelector } from "test/jest-redux-hooks";
import {
  carouselDiscard,
  templateDiscard,
} from "../../../modules/channels/api/Api";
import { Modal, Skeleton, Tooltip } from "antd";
import NewAlert from "../modal/alert/NewAlert";
import { ICONS } from "../../../assets/icons";

import { getRandomColorsBassedOnLetters } from "../../../helper/colors";

export default function SubSideBar({
  isListShow = true,
  list,
  activeNav = "",
  onSelect,
  color,
  className,
  numberDetails,
  loading,
  listCounts,
}) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const isSubmenuOpen = useAspSelector((state) => state.Channel.isSubmenuOpen);
  const { currentBrand, currentUser } = useAspSelector((state) => state.app);
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const templateDetails = useAspSelector((state) => state?.Template);
  const location = useLocation();
  const [hoveredNav, setHoveredNav] = useState(null);

  const firstLetter = currentUser?.first_name?.slice(0, 1).toUpperCase() || "";
  const colors = getRandomColorsBassedOnLetters(firstLetter);

  const [toggle, setToggle] = useState(false);
  const [secondaryToggle, setSecondaryToggle] = useState(true);

  const handleItemClick = (item) => {
    if (item.subMenu) {
      onSelect(item);

      if (
        location.pathname ===
          "/user/channels/whatsapp/edit-template/template-details" &&
        (templateDetails?.details?.status === "PAM" ||
          templateDetails?.details?.status === "APPROVED" ||
          templateDetails?.details?.status === "REJECTED" ||
          templateDetails?.details?.status === "PRM")
      ) {
        {
          setDiscardAlertOpen(true);
        }
      } else {
        item.link && navigate(item.link);
      }

      if (selectedItem === item) {
        setSelectedItem(null);
        setToggle(true);
      } else {
        setSelectedItem(item);
        setToggle(false);
      }
    } else {
      if (
        location.pathname ===
          "/user/channels/whatsapp/edit-template/template-details" &&
        (templateDetails?.details?.status === "PAM" ||
          templateDetails?.details?.status === "APPROVED" ||
          templateDetails?.details?.status === "REJECTED" ||
          templateDetails?.details?.status === "PRM")
      ) {
        setDiscardAlertOpen(true);
      } else {
        onSelect(item);
        item.link && navigate(item.link);
        setSelectedItem(item);
        setToggle(false);
      }
    }
  };

  const discardCarousel = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
    };

    // carouselDiscard(templateDetails?.id, payLoad).then((res) => {
    //   if (res?.status === 200) {
    //     setDiscardAlertOpen(false);
    //     navigate("/user/channels/whatsapp/message-templates");
    //   }
    // });

    if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType === "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      carouselDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    } else if (
      location.pathname ===
        "/user/channels/whatsapp/edit-template/template-details" &&
      templateDetails?.categoryType !== "CAROUSEL"
    ) {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
      };

      templateDiscard(templateDetails?.id, payLoad).then((res) => {
        if (res?.status === 200) {
          setDiscardAlertOpen(false);
          navigate("/user/channels/whatsapp/message-templates");
        }
      });
    }
  };

  return (
    <div
      className={`w-[15vw] h-full border-[0.08vw] border-[var(--layoutBorder)] bg-[var(--background)] px-[1.2vw] py-[0.3vw] ${className}`}
    >
      {list?.map((item) => {
        return (
          <>
            {item.type === "divider" ? (
              <div className='w-full contents-[""] border border-[var(--border-50)]'></div>
            ) : (
              <div className="flex-column justify-center" key={item?.id}>
                <div
                  className={`w-full font-normal weight-medium my-[0.5vw] tracking-wide text-[var(--text-black)] cursor-pointer flex items-center justify-between ${
                    !isListShow
                      ? " !bg-[#0ACD95] weight-medium rounded-[0.6vw] py-[0.4vw]"
                      : "py-[0.7vw]"
                  }`}
                  onClick={() => {
                    if (isListShow) setToggle(!toggle);
                    // else handleItemClick(item);
                  }}
                >
                  <div
                    className={`${
                      isListShow
                        ? "sidebar-header flex-row align-center"
                        : "!text-[var(--white)]"
                    }`}
                    // onClick={() => handleItemClick(item)}
                  >
                    {item?.icon && (
                      <img src={item?.icon} alt="icon" className="w-20 mr-10" />
                    )}
                    {item?.name}
                  </div>
                  {isSubmenuOpen === false && item?.icon1 && isListShow && (
                    <Chevron
                      className={`transition-transform duration-300 ease-linear ${
                        toggle ? "rotate-0" : "rotate-180"
                      }`}
                      color={activeNav === item?.name ? "white" : "#0ACD95"}
                      width={"1.1vw"}
                      height={"1.1vw"}
                    />
                  )}
                </div>

                {isSubmenuOpen === false && !toggle && isListShow && (
                  <div className="flex-column gap-[0.7vw] font-normal weight-normal mr-10">
                    {item?.subMenu?.map((subItem) => {
                      return (
                        <Tooltip
                          title={
                            subItem?.key === "resolved" &&
                            listCounts?.resolved > 0
                              ? `Resolved : ${listCounts?.resolved}`
                              : subItem?.key === "favorited" &&
                                listCounts?.favourite > 0
                              ? `Favorite : ${listCounts?.favourite}`
                              : subItem?.key === "unread" &&
                                listCounts?.unread > 0
                              ? `Unread : ${listCounts?.unread}`
                              : ""
                          }
                        >
                          <div
                            className={`w-95 b-radius-6 px-[1vw] py-[0.4vw]  flex-row align-center cursor-pointer gap-2.5 ${
                              activeNav === subItem?.name
                                ? "sidebar-sub-header-active ptb-10"
                                : "hover:bg-slate-100 sidebar-sub-header hover:text-[var(--contentBlack)] hover:weight-medium mr-10 ptb-10"
                            }`}
                            key={subItem?.id}
                            onClick={() => handleItemClick(subItem)}
                            onMouseEnter={() => setHoveredNav(subItem?.name)}
                            onMouseLeave={() => setHoveredNav(null)}
                            data-id="channels-sidebar-click"
                          >
                            {subItem?.icon1 && (
                              <img
                                src={subItem?.icon1}
                                alt="icon"
                                className="w-10"
                              />
                            )}
                            {subItem?.icon && (
                              <subItem.icon
                                color={
                                  activeNav === subItem?.name
                                    ? "var(--new-primary)" // Active nav color
                                    : hoveredNav === subItem?.name
                                    ? "var(--textBlack)" // Apply hover color only if not active
                                    : "#616874" // Default color
                                }
                                applyFill={
                                  activeNav === subItem?.name ?? "var(--a)"
                                  // Default fill
                                }
                                fill={
                                  activeNav === subItem?.name
                                    ? "var(--new-primary)"
                                    : "none"
                                }
                                fillColor={
                                  activeNav === subItem?.name
                                    ? "var(--new-primary)"
                                    : "none"
                                }
                                stroke={
                                  activeNav === subItem?.name
                                    ? "white"
                                    : "var(--font-600)"
                                }
                                lineColor={
                                  activeNav === subItem?.name
                                    ? "#D8FDD2"
                                    : "var(--font-600)"
                                }
                              />
                            )}
                            {subItem?.assignee !== undefined && (
                              <span
                                style={{
                                  color: colors.color,
                                  backgroundColor: colors.backgroundColor,
                                }}
                                className="px-1.5 py-0.8 rounded-full"
                              >
                                {firstLetter}
                              </span>
                            )}
                            <div className="w-100 flex-row align-center space-between">
                              <span>
                                {subItem?.name}
                                {subItem?.send_from_phone_name &&
                                  numberDetails?.[0]?.send_from_phone_name}
                              </span>

                              {subItem?.key && (
                                <span className="bg-[#6940F2] text-[white] text-[0.65rem] font-[600] py-0.45 px-2 rounded-lg">
                                  {subItem?.key === "resolved" &&
                                    listCounts?.resolved > 0 &&
                                    (listCounts?.resolved > 99
                                      ? "99+"
                                      : listCounts?.resolved)}

                                  {subItem?.key === "favorited" &&
                                    listCounts?.favourite > 0 &&
                                    (listCounts?.favourite > 99
                                      ? "99+"
                                      : listCounts?.favourite)}
                                  {subItem?.key === "unread" &&
                                    listCounts?.unread > 0 &&
                                    (listCounts?.unread > 99
                                      ? "99+"
                                      : listCounts?.unread)}
                                </span>
                              )}
                            </div>
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                )}
                {location?.pathname === "/user/inbox" && (
                  <>
                    <div className="flex-row align-center sidebar-header  pt-10">
                      <img
                        src={ICONS?.sidebarChevronDown}
                        alt="icon"
                        className="w-3 mr-10 pointer"
                        onClick={() => {
                          setSecondaryToggle(!secondaryToggle);
                        }}
                      />
                      <span>Channels</span>
                    </div>
                    {secondaryToggle && (
                      <Skeleton
                        active
                        style={{
                          width: "20vw",
                          padding: "20px",
                        }}
                        loading={loading}
                        // showSkeletonApi={showSkeletonApi}
                        paragraph={{ rows: 2 }}
                        className=".ant-skeleton-paragraph >li +li"
                      >
                        {numberDetails?.map((numberItem, index) => {
                          return (
                            <div
                              key={index}
                              className={`w-[91.5%] b-radius-6 flex-row align-center px-[1vw] py-[0.7vw] pl-[15px] mt-20 gap-2 pointer ${
                                activeNav === numberItem?.send_from_phone_name
                                  ? "sidebar-sub-header-active"
                                  : "sidebar-sub-header hover:bg-slate-100"
                              }`}
                              onClick={() => {
                                handleItemClick(numberItem);
                              }}
                            >
                              <Tooltip
                                title={`${
                                  numberItem?.send_from_phone_name || ""
                                } - ${
                                  numberItem?.send_from_phone_number || ""
                                }`}
                              >
                                <div className="flex flex-row gap-2.5 truncate w-100 items-center">
                                  <img
                                    src={ICONS?.whatsappInbox}
                                    alt="whatsApp"
                                    className="h-4 w-4" // Adjust the size as needed
                                  />
                                  <span className="truncate">
                                    {numberItem?.send_from_phone_name} -{" "}
                                    {numberItem?.send_from_phone_number}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          );
                        })}
                      </Skeleton>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        );
      })}

      <Modal
        // open={isDuplicateModal}
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}

        // onCancel={handleCancel}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>
                You have made some changes in the template. How would you like
                to proceed.
              </p>
            </div>
          }
          // title={"Restore Template?"}
          noName="Continue Editing"
          yesName="Discard"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel();
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
