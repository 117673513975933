import React, { useEffect, useState } from "react";
import "../../styles/Broadcast.css";

import CampaignHeader from "../campaigns/CampaignHeader";
import SettingBroad from "./SettingBroad";

import { BroadSettingList } from "../../../../constant/app/campaign/Broadcast";

import { ICONS } from "../../../../assets/icons";
import { Button } from "../../../../components/form/Button/Button";
import BroadcastSegments from "./BroadcastSegments";
import BroadcastMessage from "./BroadcastMessage";
import BroadcastReview from "./BroadcastReview";
import {
  broadCastCreateApi,
  broadcastSingleApi,
  createActivateApi,
  draftApi,
  templateList,
} from "../../api/Api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  updateGlobalLoader,
  updateToggleToast,
} from "../../../../reduxToolkit/appSlice";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../test/jest-redux-hooks";
import Define_Audience from "./Define_Audience";

import { setDateCombine } from "../../../../reduxToolkit/CampaignSlice";
import {
  clearIndividualErrors,
  setCallEnabled,
  setDelayValue,
  setDelayValue1,
  setIndividualError,
  setSaveBroadcastDatas,
  setSelectedTime1,
  setUpdatedInputValue,
} from "../../../../reduxToolkit/broadcastSlice";
import { whatsappBusinessNumbers } from "../../../channels/api/Api";
import moment from "moment";
import BroadcastMessageView from "./BroadcastView/BroadcastMessageView";

import Loader from "../../../../components/commonComponents/Loader/Index";
import useToggle from "../../../../hooks/useToggle";
import {
  formatTimeWithAmPm,
  getTimeString,
} from "../../../../helper/timeUtils";

const BroadcastSetting = () => {
  const [selectedId, setSelectedId] = useState(0);
  const [selectedItems, setSelectedItems] = useState(
    Array(BroadSettingList.length).fill(false)
  );

  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [numberDetails, setNumberDetails] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [selectedIndexSegments, setSelectedIndexSegments] = useState(false);
  const [segmentSettings, setSegmentSettings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [response, setResponse] = useState([]);
  const [response1, setResponse1] = useState([]);

  const [startPopoverVisible, setStartPopoverVisible] = useState(false);
  const [selectedId1, setSelectedId1] = useState(false);
  const [selectedId2, setSelectedId2] = useState(false);
  const [selectedId3, setSelectedId3] = useState(false);
  const [error, setError] = useState("");
  const [broadcastId, setBroadcastId] = useState(null);
  const [campaignDetail, setCampaignDetail] = useState([]);
  const [isBroadcastViewOpen, setIsBroadcastViewOpen] = useState(false);

  const [contactsRemaining1, setContactsRemaining1] = useState(null);
  const [contactsRemaining2, setContactsRemaining2] = useState(null);

  const [contactsRemainingMerge1, setContactsRemainingMerge1] = useState(null);
  const [contactsRemainingMerge2, setContactsRemainingMerge2] = useState(null);
  const [TimePick, setTimePick] = useState(false);

  const [totalContact1, setTotalContact1] = useState(null);
  const [totalContact2, setTotalContact2] = useState(null);
  const [totalContact, setTotalContact] = useState(null);
  const [nextClicked, setNextClicked] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [loading, setLoading] = useToggle(false);
  const [indexRem, setIndexRem] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hours, setHours] = useState("12");
  const [minutes, setMinutes] = useState("00");
  const [periods, setPeriod] = useState("AM");

  // id --------

  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const campaignDetails = location?.state?.data;

  const { tabList } = useAspSelector((state) => state.Campaign);
  const { updatedInputValue } = useAspSelector((state) => state.broadcast);
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const handleCloseBroadcastView = () => {
    setIsBroadcastViewOpen(true);
  };

  // useEffect(() => {
  //   const list = tabList?.filter((item) => item?.tabName === "campaignName");
  //   if (list?.length > 0) {
  //     setCampaignDetail(list[0]?.allData);
  //   }
  // }, [tabList]);

  const fetchWhatsappBusinessNumbers = (brandId) => {
    if (brandId) {
      whatsappBusinessNumbers(brandId).then((res) => {
        if (res && res.data?.wapp_business_num?.length > 0) {
          setNumberDetails(res?.data?.wapp_business_num);
        } else {
          setNumberDetails([]);
        }
      });
    } else {
      setNumberDetails([]);
    }
  };

  useEffect(() => {
    fetchWhatsappBusinessNumbers(currentBrand?.brand_id);
  }, [currentBrand]);

  // STEP 1---------------------------------------------------------------------------

  const [broadCasts, setBroadCasts] = useState({
    name: "",
    settings: {
      channel: "whatsapp",
    },
  });

  const handleCreateCampaign = (key, value) => {
    let prev = { ...broadCasts };
    prev[key] = value;
    setBroadCasts(prev);
  };

  const resetSegmentsExceptFirst = (dataName) => {
    const updatedResponse = response?.map((data, index) => {
      const updatedSegments =
        dataName === "send_now"
          ? [{ id: 1, start: 0, end: 0, delay: 0 }]
          : [{ id: 1, start: 0, end: 0, schedule_on: "" }];

      return {
        ...data,
        id: index + 1,
        segments: updatedSegments,
      };
    });

    if (broadCasts?.settings?.segment_settings) {
      const updatedSegmentSettings = broadCasts.settings.segment_settings.map(
        (segment, index) => ({
          ...segment,
          id: index + 1,
          segments:
            dataName === "send_now"
              ? [{ id: 1, start: 0, end: 0, delay: 0 }]
              : [{ id: 1, start: 0, end: 0, schedule_on: "" }],
        })
      );

      const updatedBroadcasts = {
        ...broadCasts,
        settings: {
          ...broadCasts.settings,
          segment_settings: updatedSegmentSettings,
        },
      };
      setBroadCasts(updatedBroadcasts);
    }

    setResponse1(updatedResponse);
    setResponse(updatedResponse);
    setContactsRemaining1(totalContact1);
    setContactsRemaining2(totalContact2);
    setContactsRemainingMerge1(totalContact);
  };

  const handleTimeClick = () => {
    resetSegmentsExceptFirst();

    dispatch(setSelectedTime1({ type: "clear" }));
    setTimePick(false);
    setStartPopoverVisible(false);
    const newSelectedTime = getTimeString(hours, minutes, periods);

    if (!newSelectedTime) {
      return;
    }
    const currentTime = new Date();
    const selectedDateTime = new Date(`${selectedDate} ${newSelectedTime}`);
    const timeDifference = selectedDateTime.getTime() - currentTime.getTime();

    if (selectedDate && timeDifference < 60 * 60 * 1000) {
      setError(
        "Scheduled time should be at least 60 minutes ahead of the current time"
      );
      return;
    }
    if (timeDifference >= 3600 * 1000) {
      const timeWithAmPm = formatTimeWithAmPm(newSelectedTime);
      setSelectedTime(timeWithAmPm);

      setResponse1((prevResponse) => {
        const updatedResponse = prevResponse?.map((data) => {
          if (data.id === 1) {
            const updatedSegments = data?.segments?.map((segment) => {
              if (segment.id === 1) {
                return {
                  ...segment,
                  schedule_on: `${selectedDate} ${newSelectedTime}`,
                };
              }
              return segment;
            });

            return {
              ...data,
              segments: updatedSegments,
            };
          } else {
            const updatedSegments = data?.segments?.map((segment) => {
              if (segment.id === 1) {
                return {
                  ...segment,
                  schedule_on: "",
                };
              }
              return segment;
            });

            return {
              ...data,
              segments: updatedSegments,
            };
          }
        });

        return updatedResponse;
      });

      setResponse((prevResponse) => {
        const updatedResponse = prevResponse?.map((data) => {
          if (data.id === 1) {
            const updatedSegments = data?.segments?.map((segment) => {
              if (segment.id === 1) {
                return {
                  ...segment,
                  schedule_on: `${selectedDate} ${newSelectedTime}`,
                };
              }
              return segment;
            });

            return {
              ...data,
              segments: updatedSegments,
            };
          } else {
            const updatedSegments = data?.segments?.map((segment) => {
              if (segment.id === 1) {
                return {
                  ...segment,
                  schedule_on: "",
                };
              }
              return segment;
            });

            return {
              ...data,
              segments: updatedSegments,
            };
          }
        });

        return updatedResponse;
      });

      setBroadCasts((prevState) => {
        const updatedSegmentSettings =
          prevState?.settings?.segment_settings?.map((data) => {
            if (data.id === 1) {
              const updatedSegments = data?.segments?.map((segment) => {
                if (segment.id === 1) {
                  return {
                    ...segment,
                    schedule_on: `${selectedDate} ${newSelectedTime}`,
                  };
                }
                return segment;
              });

              return {
                ...data,
                segments: updatedSegments,
              };
            } else {
              const updatedSegments = data?.segments?.map((segment) => {
                if (segment.id === 1) {
                  return {
                    ...segment,
                    schedule_on: "",
                  };
                }
                return segment;
              });

              return {
                ...data,
                segments: updatedSegments,
              };
            }
          });

        return {
          ...prevState,
          settings: {
            ...prevState.settings,
            segment_settings: updatedSegmentSettings,
            send_later_dt:
              selectedIndex === "send_later"
                ? `${selectedDate} ${newSelectedTime}`
                : "send_now",
          },
        };
      });

      // setBroadCasts((prevState) => ({
      //   ...prevState,
      //   settings: {
      //     ...prevState.settings,
      //     send_later_dt:
      //       selectedIndex === "send_later"
      //         ? `${selectedDate} ${newSelectedTime}`
      //         : "send_now",
      //   },
      // }));

      setError("");
    } else {
    }
  };

  const handleTime = () => {
    setTimePick(!TimePick);
  };

  const handleDateSelection = (date) => {
    setSelectedTime(null);
    const formattedDate = moment(date).format("YYYY-MM-DD");

    const startDate = campaignDetails?.starting_date_and_time;
    const endDate = campaignDetails?.ending_date_and_time;
    const datePart = moment(startDate?.split("T")[0], "YYYY-MM-DD");
    const endDatePart = moment(endDate?.split("T")[0], "YYYY-MM-DD");
    if (moment(formattedDate).isBefore(datePart, "day")) {
      return;
    }
    if (moment(formattedDate).isAfter(endDatePart, "day")) {
      return;
    }

    setSelectedDate(formattedDate);

    setBroadCasts((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        send_later_dt:
          selectedIndex === "send_later"
            ? `${formattedDate} ${selectedTime}`
            : "send_now",
      },
    }));

    setStartPopoverVisible(false);
  };

  const handleClick = (id) => {
    setSelectedRole((prevSelectedRole) => {
      if (prevSelectedRole) {
        setBroadCasts((prevState) => ({
          ...prevState,
          settings: {
            ...prevState.settings,
            send_from_phone_number_id:
              prevSelectedRole.send_from_phone_number_id,
            send_from_phone_number: prevSelectedRole.send_from_phone_number,
            send_from_phone_name: prevSelectedRole.send_from_phone_name,
            country_code: prevSelectedRole.country_code,
          },
        }));
        setSelectedItemId(id);
      }
      return prevSelectedRole;
    });
  };

  const handleLabelClick = (dataName) => {
    setSelectedIndex(dataName);
    setSelectedDate(null);
    setSelectedTime(null);

    if (dataName === "send_now") {
      setError(false);
    }
    resetSegmentsExceptFirst(dataName);

    setBroadCasts((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        send_setting: dataName,
        send_later_dt:
          dataName === "send_now"
            ? "send_now"
            : `${selectedDate}${selectedTime}`,
      },
    }));
  };

  const handleLabelSegmentsClick = (dataName) => {
    setSelectedIndexSegments(dataName);
    setBroadCasts((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        segment_mode: dataName,
        segment_settings: segmentSettings,
      },
    }));
  };
  const handleNextClick = () => {
    setNextClicked((prevClicked) => {
      const newClicked = [...prevClicked];
      if (selectedId < newClicked.length - 1) {
        newClicked[selectedId + 1] = true;
      }
      return newClicked;
    });
    if (selectedId === 0) {
      setLoader(true);
    }

    if (selectedId) {
      dispatch(setUpdatedInputValue(updatedInputValue));
    }
    setContactsRemaining1(contactsRemaining1);
    setContactsRemaining2(contactsRemaining2);

    if (selectedId === 0) {
      let campaignPayload = { ...broadCasts };
      const broadcastIdToUse = location?.state?.item?.id || broadcastId;

      if (broadcastIdToUse) {
        campaignPayload = {
          ...campaignPayload,
          broadcast_id: broadcastIdToUse,
        };
      }

      const dateTime = campaignPayload?.settings?.send_later_dt;
      dispatch(setDateCombine(dateTime));
      broadCastCreateApi(currentBrand?.brand_id, id, campaignPayload).then(
        (createCampaignResponse) => {
          if (createCampaignResponse?.status === 200) {
            setLoader(false);
            const newBroadcastId = createCampaignResponse?.data?.id;

            setBroadcastId(newBroadcastId);
            setSelectedId((prevId) =>
              prevId < BroadSettingList.length - 1 ? prevId + 1 : prevId
            );
          } else {
            setLoader(false);
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: "Broadcast name already exists",
                  status: "Error",
                  duration: "",
                },
              ])
            );
          }
        }
      );
    } else {
      setSelectedId((prevId) =>
        prevId < BroadSettingList.length - 1 ? prevId + 1 : prevId
      );
    }

    setSelectedItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[selectedId] = true;
      return newItems;
    });
  };

  useEffect(() => {
    if (location.state && location.state.item) {
      dispatch(updateGlobalLoader(true));

      const campaignId = location?.state?.item?.campaign_id;
      const broadcastId = location?.state?.item?.id;
      const brandId = currentBrand?.brand_id;
      broadcastSingleApi(brandId, campaignId, broadcastId)
        .then((res) => {
          if (res && res.data?.broadcast) {
            dispatch(updateGlobalLoader(false));
            const broadcastData = res?.data?.broadcast;

            setBroadCasts(broadcastData);
            setSelectedIndex(broadcastData?.settings?.send_setting);
            setSelectedIndexSegments(
              broadcastData?.settings?.segment_mode === "individual"
                ? "individual"
                : "merge"
            );
            const { send_later_dt: sendLaterDt } = broadcastData?.settings;
            if (sendLaterDt !== "send_now") {
              const date = sendLaterDt?.split(" ")?.[0];
              const time = sendLaterDt?.split(" ")?.[1];
              const amPm = sendLaterDt?.split(" ")?.[2];
              const timeWithAMPM = `${time} ${amPm}`;

              setSelectedTime(timeWithAMPM);
              setSelectedDate(date);
            }
            setBroadCasts(res?.data?.broadcast);
            setSelectedIndex(res?.data?.broadcast?.settings?.send_setting);
            setSelectedIndexSegments(
              broadcastData?.settings?.segment_mode === "merge"
                ? "merge"
                : "individual"
            );
          } else {
            setBroadCasts([]);
          }
        })
        .finally(() => {
          dispatch(updateGlobalLoader(false));
        });
    }
  }, [location.state, currentBrand]);

  // STEP 1 COMPLETION---------------------------------------------------------------------------
  const contacts_count = response?.reduce((accumulator, currentItem) => {
    return accumulator + (currentItem?.valid_count || 0);
  }, 0);

  const remaining1 = response?.[0]?.valid_count;
  const remaining2 = response?.[1]?.valid_count;

  const template_id = selectedTemplate?.message_template_id;
  const template_name = selectedTemplate?.name;
  const template_category = selectedTemplate?.category;
  const template_subcategory_type = selectedTemplate?.subcategory_type;
  const country_code = selectedRole?.country_code;

  const createPayload = () => {
    const segmentsMapper = (part) => ({
      broadcast_part_id: part.broadcastPartId,
      segments:
        selectedIndexSegments === "merge" && part.id === 2
          ? selectedIndex === "send_now"
            ? [{ id: 1, start: 0, end: 0, delay: 0 }]
            : [
                {
                  id: 1,
                  start: 0,
                  end: 0,
                  schedule_on: "",
                },
              ]
          : [...part.segments],
    });

    const updatedSegmentSettings =
      selectedIndexSegments === "individual"
        ? response1.map(segmentsMapper)
        : response.map(segmentsMapper);

    const sendLaterDt =
      selectedIndex === "send_later"
        ? `${selectedDate} ${selectedTime}`
        : "send_now";

    return {
      brand_id: currentBrand?.brand_id,
      broadcast_id: broadcastId,
      settings: {
        channel: "whatsapp",
        country_code: location?.state?.item
          ? broadCasts?.settings?.country_code
          : country_code,
        send_from_phone_number: location?.state?.item
          ? broadCasts?.settings?.send_from_phone_number
          : selectedRole?.send_from_phone_number,
        send_from_phone_number_id: location?.state?.item
          ? broadCasts?.settings?.send_from_phone_number_id
          : selectedRole?.send_from_phone_number_id,
        template_name: location?.state?.item
          ? broadCasts?.settings?.template_name || template_name
          : template_name,
        template_category: location?.state?.item
          ? broadCasts?.settings?.template_category || template_category
          : template_category,
        template_subcategory_type: location?.state?.item
          ? broadCasts?.settings?.template_subcategory_type ||
            template_subcategory_type
          : template_subcategory_type,
        template_id: template_id,
        send_setting: selectedIndex,
        send_later_dt: sendLaterDt,
        segment_mode: selectedIndexSegments,
        segment_settings: updatedSegmentSettings,
        contactsRemaining1:
          selectedIndexSegments === "merge"
            ? contactsRemainingMerge1
            : contacts_count,
        contactsRemaining1Individual:
          selectedIndexSegments === "individual" || contactsRemaining1
            ? contactsRemaining1
            : remaining1,
        contactsRemaining2: contactsRemaining2
          ? contactsRemaining2
          : remaining2,
      },
    };
  };

  useEffect(() => {
    const newPayLoad = createPayload();

    dispatch(setSaveBroadcastDatas(newPayLoad));

    if (selectedId === 3) {
      draftApi(newPayLoad);
    }
  }, [
    selectedId,
    contactsRemaining1,
    contactsRemaining2,
    contactsRemainingMerge1,
  ]);

  const individualCounts = response1
    .map((item) => item.segments.map((segment) => segment?.end))
    .flat()
    .reduce((acc, value) => acc + value, 0);

  const handleCreateTask = (item) => {
    setLoading(true);
    dispatch(setCallEnabled(true));

    let updatedSegmentSettings;

    if (selectedIndexSegments === "individual") {
      updatedSegmentSettings = response1.map((part) => ({
        broadcast_part_id: part.broadcastPartId,
        segments: part.segments.map((segment) => ({ ...segment })),
      }));
    } else {
      updatedSegmentSettings = response.map((part) => ({
        broadcast_part_id: part.broadcastPartId,
        segments:
          part.id === 2 && selectedIndexSegments === "merge"
            ? []
            : part.segments.map((segment) => ({ ...segment })),
      }));
    }

    const contact3 = contacts_count - contactsRemainingMerge1;

    const sendLaterDt =
      selectedIndex === "send_later"
        ? `${selectedDate} ${selectedTime}`
        : "send_now";
    const payLoad = {
      brand_id: currentBrand?.brand_id,
      settings: {
        channel: "whatsapp",
        country_code: location?.state?.item
          ? broadCasts?.settings?.country_code
          : country_code,
        send_from_phone_number: location?.state?.item
          ? broadCasts?.settings?.send_from_phone_number
          : selectedRole?.send_from_phone_number,
        send_from_phone_number_id: location?.state?.item
          ? broadCasts?.settings?.send_from_phone_number_id
          : selectedRole?.send_from_phone_number_id,
        template_name: location?.state?.item
          ? broadCasts?.settings?.template_name || template_name
          : template_name,
        template_category: location?.state?.item
          ? broadCasts?.settings?.template_category || template_category
          : template_category,
        template_subcategory_type: location?.state?.item
          ? broadCasts?.settings?.template_subcategory_type ||
            template_subcategory_type
          : template_subcategory_type,
        template_id: template_id,
        send_setting: selectedIndex,
        send_later_dt: sendLaterDt,
        segment_mode: selectedIndexSegments,
        segment_settings: updatedSegmentSettings,
        contacts_count:
          selectedIndexSegments === "merge" ? contact3 : individualCounts,
        contactsRemaining1:
          selectedIndexSegments === "merge"
            ? contactsRemainingMerge1
            : contactsRemaining1,
        contactsRemaining1Individual:
          selectedIndexSegments === "individual"
            ? contactsRemaining1
            : remaining1,
        contactsRemaining2: contactsRemaining2 ? contactsRemaining2 : 0,
      },
    };

    if (broadcastId && contacts_count) {
      dispatch(setUpdatedInputValue({}));
      setContactsRemaining1(null);
      setContactsRemaining2(null);
      dispatch(setDelayValue({}));
      dispatch(setDelayValue1(null));
    }

    createActivateApi(broadcastId, payLoad).then((createCampaignResponse) => {
      if (createCampaignResponse?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Broadcast activated successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
        // navigate(`/user/campaign/time_specified/${id}`, { state: { item } });
        navigate(`/user/campaign/view/${id}/execution`, {
          state: { item, data: campaignDetails },
        });
        window?.location?.reload();
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content:
                createCampaignResponse?.response?.data?.error ||
                "Broadcast activation failed",
              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
  };

  const handleDraft = (item) => {
    dispatch(setUpdatedInputValue({}));
    dispatch(setDelayValue1(null));
    dispatch(setIndividualError({}));
    dispatch(setCallEnabled(true));

    const payLoad = createPayload();

    if (indexRem && broadcastId) {
      setContactsRemaining1(contactsRemaining1);
      setContactsRemaining2(contactsRemaining2);
    }
    draftApi(payLoad).then((createCampaignResponse) => {
      if (createCampaignResponse?.status === 200) {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: "Broadcast saved successfully",
              status: "Success",
              duration: "",
            },
          ])
        );
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content:
                createCampaignResponse?.response?.data?.error ||
                "Unable to save Broadcast  ",

              status: "Error",
              duration: "",
            },
          ])
        );
      }
    });
    navigate(`/user/campaign/view/${id}/execution`, {
      state: { item, data: campaignDetails },
    });

    // navigate(`/user/campaign/time_specified/${id}`, { state: { item } });
  };

  const templateId = broadCasts?.settings?.template_id;

  const fetchSingleTemplateLists = (brandId, templateId) => {
    if (brandId) {
      templateList(brandId, templateId)
        .then((res) => {
          if (res?.status === 200) {
            setSelectedTemplate(res?.data?.wapp_template);
          }
        })
        .finally(() => {});
    } else {
      setSelectedTemplate([]);
    }
  };

  useEffect(() => {
    if (templateId) {
      fetchSingleTemplateLists(currentBrand?.brand_id, templateId);
      if (broadCasts && location?.state?.item) {
        setSelectedId3(true);
      }
    }
  }, [currentBrand, templateId]);

  useEffect(() => {
    if (selectedId === 0 && !indexRem) {
      dispatch(clearIndividualErrors());
    }
  }, []);

  const handleIndex = (index) => {
    if (index > selectedId && !nextClicked[selectedId + 1]) {
      return;
    }
    if (selectedId === 2) {
      setIndexRem(true);
    }
    if (index < selectedId) {
      setSelectedId(index);
      return;
    }
    if (
      selectedId === 0 &&
      (!broadCasts?.name?.trim() ||
        Object.keys(broadCasts.settings).length < 6 ||
        broadCasts.settings.send_later_dt.includes("null") ||
        broadCasts.settings.send_later_dt.includes("00:00:00"))
    ) {
      return;
    }
    if (selectedId === 1 && !selectedId1) {
      return;
    }
    if (selectedId === 2 && !selectedId2) {
      return;
    }
    if (selectedId === 3 && !selectedId3 && !location?.state?.item) {
      return;
    }
    dispatch(setUpdatedInputValue(updatedInputValue));
    setSelectedId(index);
    setNextClicked((prevClicked) => {
      const newClicked = [...prevClicked];
      for (let i = index + 1; i < newClicked.length; i++) {
        newClicked[i] = false;
      }
      return newClicked;
    });
  };

  return (
    <div className="w-100">
      <CampaignHeader campaignDetails={campaignDetails} />
      <div className="BroadcastSetting-container w-100 m-10 ">
        <div className="BroadcastSetting-wrapper flex-row align-center w-100 space-between">
          <div className="flex-row align-center">
            {BroadSettingList?.map((data, index) => (
              <div
                className=" pointer flex-row align-center"
                key={data.id}
                onClick={() => handleIndex(index)}
              >
                <div className="flex-row align-center">
                  <img
                    src={
                      selectedItems[index] ||
                      index === selectedId ||
                      index === selectedId - 1
                        ? ICONS?.BroadcastRoundGreenTick
                        : data?.icon
                    }
                    alt="img"
                  />

                  <p
                    style={{
                      color:
                        selectedItems[index] ||
                        index === selectedId ||
                        index === selectedId - 1
                          ? "var(--primary)"
                          : "var(--font-400)",
                    }}
                  >
                    {data?.name}
                  </p>
                </div>

                {index !== 4 && (
                  <img
                    src={
                      selectedItems[index] ||
                      index === selectedId ||
                      index === selectedId - 1
                        ? ICONS?.chevronGreen
                        : ICONS?.chevronRightIcon
                    }
                    alt="img"
                    className="pl-10 pr-10 chevronImage"
                  />
                )}
              </div>
            ))}
          </div>

          <div className="flex-row align-center flex-end">
            {selectedId !== 0 && (
              <div className="button pointer">
                <Button
                  className="Draft-btn"
                  size={"medium"}
                  label="Draft"
                  onClick={() => handleDraft(campaignDetails)}
                  data-testid="draft-button"
                />
              </div>
            )}

            {selectedId < BroadSettingList?.length - 1 ? (
              <button
                onClick={handleNextClick}
                data-testid="next-button"
                loading={loader}
                disabled={
                  error ||
                  (selectedId === 0 &&
                    (!broadCasts?.name?.trim() ||
                      Object.keys(broadCasts?.settings)?.length < 6 ||
                      broadCasts?.settings?.send_later_dt?.includes("null"))) ||
                  (selectedId === 1 && selectedId1 === false) ||
                  (selectedId === 2 && selectedId2 === false) ||
                  (selectedId === 3 &&
                    selectedId3 === false &&
                    !location?.state?.item)
                }
                style={{
                  backgroundColor:
                    error ||
                    (selectedId === 0 &&
                      (!broadCasts?.name?.trim() ||
                        Object.keys(broadCasts?.settings)?.length < 6 ||
                        broadCasts?.settings.send_later_dt?.includes(
                          "null"
                        ))) ||
                    (selectedId === 1 && selectedId1 === false) ||
                    (selectedId === 2 && selectedId2 === false) ||
                    (selectedId === 3 && selectedId3 === false)
                      ? "var(--lightGrey)"
                      : "var(--primary)",
                  color:
                    error ||
                    (selectedId === 0 &&
                      (!broadCasts?.name?.trim() ||
                        Object.keys(broadCasts?.settings)?.length < 6 ||
                        broadCasts?.settings.send_later_dt?.includes(
                          "null"
                        ))) ||
                    (selectedId === 1 && selectedId1 === false) ||
                    (selectedId === 2 && selectedId2 === false) ||
                    (selectedId === 3 && selectedId3 === false)
                      ? "var(--darkgrey)"
                      : "white",
                  padding: "5px 5px 5px 10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor:
                    (selectedId === 0 &&
                      (!broadCasts?.name?.trim() ||
                        Object.keys(broadCasts?.settings)?.length < 6 ||
                        broadCasts?.settings?.send_later_dt?.includes(
                          "null"
                        ))) ||
                    (selectedId === 1 && selectedId1 === false) ||
                    (selectedId === 2 && selectedId2 === false) ||
                    (selectedId === 3 && selectedId3 === false)
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                {loader ? (
                  <div
                    style={{ width: "40px", padding: "0 5px" }}
                    className="flex-row align-center justify-center"
                  >
                    <Loader Width={15} Height={15} loaderBg="white" />
                  </div>
                ) : (
                  <span className="next flex-row align-center">
                    Next
                    <img
                      src={ICONS?.chevronRightIconWhite}
                      alt="chevronRightIconWhite"
                    />
                  </span>
                )}
              </button>
            ) : (
              <Button
                label={"Activate"}
                size={"medium"}
                onClick={() => handleCreateTask(campaignDetails)}
                loading={loading}
                data-testid="activate-click"
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="Broadcast-main w-70 align-center flex-row"
        style={{
          background: "var(--Primary-White, #fff)",
          boxShadow: " 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1)",
          margin: "auto",
          padding: "20px 20px 20px 20px",
        }}
      >
        {selectedId === 0 && (
          <SettingBroad
            handleCreateCampaign={handleCreateCampaign}
            handleClick={handleClick}
            setSelectedRole={setSelectedRole}
            selectedRole={selectedRole}
            selectedItemId={selectedItemId}
            numberDetails={numberDetails}
            handleLabelClick={handleLabelClick}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            handleDateSelection={handleDateSelection}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            broadCasts={broadCasts}
            broadcastId={broadcastId}
            TimePick={TimePick}
            setTimePick={setTimePick}
            startPopoverVisible={startPopoverVisible}
            setStartPopoverVisible={setStartPopoverVisible}
            hours={hours}
            minutes={minutes}
            periods={periods}
            handleTimeClick={handleTimeClick}
            setHours={setHours}
            setMinutes={setMinutes}
            setPeriod={setPeriod}
            campaignDetail={campaignDetails}
            error={error}
            handleTime={handleTime}
          />
        )}

        {selectedId == 1 && (
          <Define_Audience
            response={response}
            setResponse={setResponse}
            response1={response1}
            setResponse1={setResponse1}
            selectedIndex={selectedIndex}
            broadCasts={broadCasts}
            broadcastId={broadcastId}
            setSelectedId1={setSelectedId1}
            selectedId1={selectedId1}
            setIndexRem={setIndexRem}
            indexRem={indexRem}
            setSelectedIndexSegments={setSelectedIndexSegments}
            setBroadCasts={setBroadCasts}
          />
        )}

        {selectedId === 2 && (
          <BroadcastSegments
            handleLabelSegmentsClick={handleLabelSegmentsClick}
            selectedIndexSegments={selectedIndexSegments}
            setSelectedIndexSegments={setSelectedIndexSegments}
            numberDetails={numberDetails}
            segmentSettings={segmentSettings}
            setSegmentSettings={setSegmentSettings}
            selectedIndex={selectedIndex}
            response={response}
            setResponse={setResponse}
            response1={response1}
            setResponse1={setResponse1}
            broadCasts={broadCasts}
            setSelectedId2={setSelectedId2}
            indexRem={indexRem}
            setIndexRem={setIndexRem}
            contactsRemaining1={contactsRemaining1}
            contactsRemaining2={contactsRemaining2}
            contactsRemainingMerge1={contactsRemainingMerge1}
            contactsRemainingMerge2={contactsRemainingMerge2}
            setContactsRemainingMerge1={setContactsRemainingMerge1}
            setContactsRemainingMerge2={setContactsRemainingMerge2}
            setContactsRemaining1={setContactsRemaining1}
            setContactsRemaining2={setContactsRemaining2}
            selectedId2={selectedId2}
            totalContact1={totalContact1}
            setTotalContact1={setTotalContact1}
            totalContact2={totalContact2}
            setTotalContact2={setTotalContact2}
            totalContact={totalContact}
            setTotalContact={setTotalContact}
          />
        )}
        {selectedId === 3 &&
          (location?.state?.item &&
          !isBroadcastViewOpen &&
          broadCasts?.settings?.template_name !== null ? (
            <BroadcastMessageView
              segment={broadCasts}
              broadcasts={broadCasts}
              onClose={handleCloseBroadcastView}
              setSelectedId3={setSelectedId3}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              data
            />
          ) : (
            <BroadcastMessage
              isTemplateSelected={isTemplateSelected}
              setIsTemplateSelected={setIsTemplateSelected}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              broadCasts={broadCasts}
              selectedRole={selectedRole}
              setSelectedId3={setSelectedId3}
              response={response}
            />
          ))}

        {selectedId === 4 && (
          <BroadcastReview
            broadCasts={broadCasts}
            selectedTemplate={selectedTemplate}
            segmentSettings={segmentSettings}
            selectedRole={selectedRole}
            response={response}
            response1={response1}
            selectedIndexSegments={selectedIndexSegments}
          />
        )}
      </div>
    </div>
  );
};

export default BroadcastSetting;
