import React, { useCallback, useEffect, useReducer, useState } from "react";

// images
import noData from "assets/images/inbox/noData.svg";

// consts
import {
  conversationTypes,
  filetrTypes,
} from "../../../../constant/app/inbox/Inbox";
import { conversationMessageList } from "constant/app/inbox/Inbox";
import { FEATURE_FLAG_STARED_LIST } from "../../constants/FeatureFlag";

// hooks
import useToggle from "hooks/useToggle";
import { useSearch } from "hooks/useSearch";
import { useSelect } from "hooks/useSelect";

// antd
import {
  ClosedConverstationNumbersList,
  OpenedConverstationNumbersList,
  conversations,
  conversationsView,
  inboxCustomerJourney,
  inboxFavourite,
  inboxList,
  inboxListCounts,
  inboxResolved,
  inboxResolvedReopen,
} from "../../api/Api";
import { useAspSelector } from "../../../../test/jest-redux-hooks";

// components
import SearchBar from "../../../../components/commonComponents/searchBar/index";
import CustomerPhoneNumber from "./CustomerPhoneNumber";
import ConversationsTypes from "../conversation/ConversationsType";
import ConversationContainer from "./ConversationContainer";
import Profile from "../profile";
import NoData from "components/commonComponents/Nodata/index";
import { getRandomColorsBassedOnLetters } from "../../../../helper/colors";
import { Popover } from "antd";
import { CATEGORY_TYPE_INBOX, CONVERSATION_LIST } from "../../constants";
import { cn } from "../../../../helper/cn";
import RadioButton from "../../../../components/form/radio/RadioButton";
import { ICONS } from "../../../../assets/icons";
import RightSideBar from "./RightSideBar";
import {
  crmProfessions,
  crmSingleContactEdit,
  crmSingleContactView,
} from "../../../crm/api/Api";
import {
  INITIAL_STATE,
  singleContactReducer,
} from "../../../crm/reducers/singleContactReducer";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";
import AllConversations from "../../../../assets/customSVG/inbox/AllConversations";

const cleanParams = (value) =>
  value?.replaceAll("-", "")?.replaceAll(" ", "")?.trim();

let count = 0;
let phoneNumberCount = 0;
let limit = 10;
let page = 1;
let converstationLimit = 20;
let converstationPage = 1;

export default function Conversation({
  primaryLoading,
  loading,
  setLoading,
  selectedPhoneNumber,
  activeTabName,
  activeTabItem,
  setNumberListLoading,
}) {
  const CONVERSATION_TYPES = FEATURE_FLAG_STARED_LIST
    ? conversationTypes
    : conversationTypes?.slice(0, 2);
  const { currentBrand, currentUser, toggleToast } = useAspSelector(
    (state) => state?.app
  );

  const { searchKey, setSearchKey, onChange } = useSearch("");

  const [conversationList, setConversationList] = useState([]);
  const [selectedConversationType, setSelectedConversationType] =
    useSelect(null);
  const [selectedFilterType, setSelectedFilterType] = useSelect(null);
  const [selectedFilterTypeValue, setSelectedFilterTypeValue] = useSelect(null);
  const [selectedPerson, setSelectedPerson] = useSelect(null);

  // conversations
  const [alert, setAlert] = useToggle(false);
  const [isConversationPrimary, setIsConversationPrimary] = useToggle(false);
  const [isConversationSecondary, setIsConversationSecondary] =
    useToggle(false);
  const [conversation, setConversation] = useState([]);
  const [favorite, setFavorite] = useState(null);
  const [resolved, setResolved] = useState(null);
  const [lastReplied, setLastReplied] = useState(null);
  const [online, setOnline] = useState(false);
  const [watsappCount, setWatsappCount] = useState(null);

  // Right navigation with CRM DATAS
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isSideNavOpenIcon1, setIsSideNavOpenIcon1] = useState(false);
  const [isSideNavOpenIcon2, setIsSideNavOpenIcon2] = useState(false);
  const [contactPopover, setContactPopover] = useState(false);
  const [journeyLists, setJourneyLists] = useState([]);
  const [profession, setProfession] = useState([]);
  const [data, viewDispatch] = useReducer(singleContactReducer, INITIAL_STATE);

  // Template

  const [openTemplateList, setOpenTemplateList] = useToggle(false);

  const firstLetter = currentUser?.first_name?.slice(0, 1).toUpperCase() || "";
  const colors = getRandomColorsBassedOnLetters(firstLetter);

  const crmListView = (brandId, id) => {
    if (brandId && id) {
      crmSingleContactView(
        currentBrand?.brand_id,
        selectedPerson?.contact_person_id
      ).then((res) => {
        if (res && res?.data) {
          const contactData = res?.data?.overview?.[0];
          viewDispatch({ type: "update_data", data: contactData });
        }
      });
    }
  };

  useEffect(() => {
    setSelectedConversationType(conversationTypes);
    setSelectedFilterType(filetrTypes);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (alert) setAlert(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [alert]);

  const conversationType = selectedConversationType
    ?.filter((item) => item?.isSelected === true)
    ?.map((item) => item.msg?.toLowerCase());

  const countryCode = activeTabItem?.country_code?.replace("+", "") || "";
  const phoneNumberSpace =
    activeTabItem?.send_from_phone_number?.replace(" ", "") || "";
  const filterValues = selectedFilterTypeValue
    ?.filter((item) => item?.type !== "favorited")
    .map((item) => item?.type);

  const filterValuesFavorite = selectedFilterTypeValue
    ?.filter((item) => item?.type === "favorited")
    .map((item) => item?.type);

  // INBOX - LIST
  const fetchInboxList = async (load, brandId, limit, page) => {
    const payLoad = {
      brandId: brandId,
      limit: limit,
      page: page,
      conversation: conversationType,
      search: searchKey,
      businessPhoneNumber: countryCode + phoneNumberSpace,
      inbox_filter:
        (activeTabName !== "All Conversations" &&
          activeTabName !== "Favorited" &&
          !activeTabItem?.send_from_phone_number &&
          activeTabName.toLowerCase()) ||
        (activeTabItem?.send_from_phone_number &&
          filterValues?.length > 0 &&
          filterValues),
      favourite:
        (filterValuesFavorite?.length > 0 || activeTabName === "Favorited") &&
        true,
      user_id:
        (activeTabName === "Favorited" ||
          filterValues?.length > 0 ||
          filterValuesFavorite?.length > 0) &&
        currentUser?.id,
    };

    // if (load) setLoading(true);
    try {
      const res = await inboxList(payLoad);
      setNumberListLoading(false);
      // page === 1
      //   ? setConversationList(res?.data?.phone_number_list)
      //   : setConversationList([
      //       ...conversationList,
      //       ...res?.data?.phone_number_list,
      //     ]);
      setConversationList(res?.data?.phone_number_list);
      phoneNumberCount = res?.data?.phone_numbers_count;
    } catch (e) {
      console.warn(e);
    } finally {
      if (load) setLoading(false);
    }
  };

  useEffect(() => {
    if (currentBrand?.brand_id && selectedPhoneNumber) {
      fetchInboxList(
        true,
        currentBrand?.brand_id,
        converstationLimit,
        converstationPage,
        conversationType,
        activeTabName,
        selectedFilterType,
        searchKey
      );
    }
  }, [
    selectedPhoneNumber,
    searchKey,
    activeTabName,
    // favorite,
    selectedFilterType,
    selectedConversationType,
  ]);

  // useEffect(() => {
  //   const intervel = setInterval(() => {
  //     if (
  //       selectedPerson &&
  //       selectedPerson?.from_number &&
  //       selectedPerson?.to_number
  //     ) {
  //       getConversations(
  //         "debounce",
  //         false,
  //         currentBrand?.brand_id,
  //         watsappCount,
  //         page,
  //         selectedPerson?.id
  //       );
  //     }
  //   }, [4000]);

  //   return () => clearInterval(intervel);
  // }, [selectedPerson, limit, page]);

  const loadNextPage = () => {
    converstationLimit += 20;
    if (currentBrand?.brand_id && selectedPhoneNumber) {
      fetchInboxList(
        true,
        currentBrand?.brand_id,
        converstationLimit,
        converstationPage,
        conversationType,
        activeTabName,
        searchKey
      );
    }
  };

  // FAVORITE _ CLICK

  const onFavoriteClick = (item) => {
    setFavorite(favorite === item.id ? null : item.id);
    // setSelectedPerson(item);

    const payLoad = {
      brand_id: currentBrand?.brand_id,
      contact_id: item?.contact_id,
      user_id: currentUser?.id,
      is_favourite: favorite !== null ? false : true,
    };

    inboxFavourite(payLoad).then((res) => {
      if (res?.status === 200) {
        fetchInboxList(
          true,
          currentBrand?.brand_id,
          converstationLimit,
          converstationPage,
          conversationType,
          activeTabName,
          selectedFilterType,
          searchKey
        );
        setSelectedPerson({
          ...selectedPerson,
          is_favourite: res?.data?.favourite?.is_favourite,
        });
      }
    });
  };

  //  RESOLVED_CLICKs

  const lastWindow =
    conversation[conversation?.length - 1]?.message?.type === "window";

  const onResolvedClick = useCallback(
    (item) => {
      const payLoad = {
        brand_id: currentBrand?.brand_id,
        contact_conversation_id: item?.id,
        user_id: currentUser?.id,
      };

      const isReopen = item?.resolve_status === true;
      const apiCall = isReopen ? inboxResolved : inboxResolvedReopen;

      apiCall(payLoad)
        .then((res) => {
          if (res?.status === 200) {
            // Use functional update for resolvedState
            setResolved((prevResolved) =>
              prevResolved === item.id ? null : item.id
            );

            // Fetch inbox list with latest parameters
            fetchInboxList(
              true,
              currentBrand?.brand_id,
              converstationLimit,
              converstationPage,
              conversationType,
              activeTabName,
              selectedFilterType,
              searchKey
            );

            // Update selected person's resolve status
            const updatedResolveStatus = res?.data?.ccr
              ? res?.data?.ccr?.status
              : res?.data?.resolved;

            setSelectedPerson((prev) => ({
              ...prev,
              resolve_status: updatedResolveStatus,
            }));

            // Handle template list based on conditions
            if (isReopen && res?.data?.ccr) {
              setOpenTemplateList(!!lastWindow);
            }
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
    [
      currentBrand,
      currentUser,
      converstationLimit,
      converstationPage,
      conversationType,
      activeTabName,
      selectedFilterType,
      searchKey,
      lastWindow,
    ]
  );

  useEffect(() => {
    let intervalId;

    if (currentBrand?.brand_id && selectedPhoneNumber) {
      setLoading(false);
      intervalId = setInterval(() => {
        fetchInboxList(
          true,
          currentBrand?.brand_id,
          converstationLimit,
          converstationPage,
          conversationType,
          activeTabName,
          selectedFilterType,
          searchKey
        );
      }, 2000);
    }

    return () => {
      // Clear the interval immediately when dependencies change
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    currentBrand?.brand_id,
    selectedPhoneNumber,
    converstationLimit,
    converstationPage,
    conversationType,
    activeTabName,
    selectedFilterType,
    loading,
    searchKey,
  ]);

  // fetch the conversation b/w selected customer number

  const getConversations = async (type, load, brandId, limit, page, item) => {
    if (type !== "debounce" && load) setIsConversationPrimary(true);
    if (type !== "debounce" && !load) setIsConversationSecondary(true);
    const payLoad = {
      brandId: brandId,
      limit: limit,
      page: page,
      contact_conversation_id: item,
    };
    try {
      const res = await conversationsView(payLoad);

      const reverse = res?.data?.wapp_conversation?.reverse();
      // if (type === 'scroll') setConversation([...reverse, ...conversation]);
      if (type === "scroll") setConversation(reverse);
      if (type === "select") setConversation(reverse);
      count = res?.data?.wapp_conversation_count;
      const wapp_conversation_count = res?.data?.wapp_conversation_count;
      const last_replied = res?.data?.last_replied_at;
      setLastReplied(last_replied);
      setWatsappCount(res?.data?.wapp_conversation_count);
      return wapp_conversation_count;
    } catch (e) {
      console.warn(e);
    } finally {
      if (type !== "debounce" && load) setIsConversationPrimary(false);
      if (!load) setIsConversationSecondary(false);
    }
  };

  const onConversationTypeChange = async (values) => {
    const updatedOptions = conversationTypes?.map((o) => {
      if (o?.id === values?.id) return { ...o, isSelected: true };
      return { ...o, isSelected: false };
    });

    setNumberListLoading(true);

    setSelectedConversationType(updatedOptions);
    setConversationList([]);
    phoneNumberCount = 0;
    converstationPage = 1;
    converstationLimit = 20;
    setSelectedPerson(null);

    // fetchInboxList(
    //   true,
    //   currentBrand?.brand_id,
    //   converstationLimit,
    //   converstationPage,
    //   values?.type,
    //   activeTabName,
    //   searchKey
    // ).then((res) => {
    //   if (res?.statu === 200) {
    //     setLoading(false);
    //   }
    // });
  };

  const onFilterChange = (values) => {
    const filters = selectedFilterType?.map((item) => {
      if (item?.id === values?.id)
        return { ...item, isSelected: !item?.isSelected };
      return item;
    });

    const filterValues = filters?.filter((item) => item?.isSelected && item);

    setSelectedFilterType(filters);
    setSelectedFilterTypeValue(filterValues);
  };

  const clearType = () => {
    const res = selectedFilterType?.map((t) => {
      return { ...t, isSelected: false };
    });

    setSelectedFilterType(res);
    setSelectedFilterTypeValue(null);
  };

  const onConversationStarChange = (value) => {};

  const onCloseConversation = (value) => {};

  // handle select the customer

  function formatRelativeTime(timestamp) {
    const currentTime = new Date();
    const pastTime = new Date(timestamp);
    const diffMs = currentTime - pastTime;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffMinutes < 60) {
      return `${diffMinutes} min`;
    }

    if (diffHours < 24) {
      return `${diffHours} hr`;
    }

    return pastTime.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  // Usage

  const [newdate1, setNewdate1] = useState("");

  const c = conversationList?.find(
    (item) => item?.id === selectedPerson?.id && item
  )?.message?.text?.body;

  useEffect(() => {
    if (c) {
      setTimeout(() => {
        const lastSent = conversationList?.[0]?.last_send_at; // Replace with your timestamp source
        if (lastSent) {
          const updatedDate = formatRelativeTime(lastSent);
          setNewdate1(updatedDate);
        }
      }, 10);
      // return () => {
      //   clearTimeout(interval);
      // };
    }
  }, [conversationList, c]);

  useEffect(() => {
    if (newdate1 === "0 min" && c) {
      onSelectPerson(selectedPerson);
    }
  }, [newdate1, selectedPerson, c]);

  const [scrollTop, setScrollTop] = useState(false);

  const onSelectPerson = async (item) => {
    setScrollTop(true);

    // After 5 seconds, reset scrollTop to false
    setTimeout(() => {
      setScrollTop(false);
    }, 5000);

    setConversation([]);
    setIsSideNavOpen(false);
    setIsSideNavOpenIcon2(false);
    setIsSideNavOpenIcon1(false);

    const currentTime = new Date();
    const givenDate = new Date(lastReplied);

    const timeDifference = Math.abs(currentTime - givenDate) / (1000 * 60);

    if (timeDifference <= 10) {
      setOnline(true);
    } else {
      setOnline(false);
    }

    // page = 1;
    count = 0;
    // limit = 100;
    let dynamicLimit = 100;

    setSelectedPerson(item);
    const initialCount = await getConversations(
      "select",
      true,
      currentBrand?.brand_id,
      dynamicLimit,
      page,
      item?.id
    );

    if (initialCount !== undefined && initialCount !== 0) {
      await getConversations(
        "select",
        true,
        currentBrand?.brand_id,
        initialCount,
        page,
        item?.id
      );
    }
  };

  // CRM

  useEffect(() => {
    if (selectedPerson) {
      crmListView(currentBrand?.brand_id, selectedPerson?.contact_person_id);
    }

    if (isSideNavOpen) {
      professions();
    }
  }, [selectedPerson, isSideNavOpen, currentBrand]);

  const professions = () => {
    crmProfessions().then((res) => {
      if (res && res.data?.professions?.length > 0) {
        const updatedData = res?.data?.professions.map((p) => {
          return { ...p, label: p.name };
        });
        setProfession(updatedData);
      }
    });
  };

  const customerJourney = () => {
    const payLoad = {
      brand_id: currentBrand?.brand_id,
      contact_conversation_id: selectedPerson?.id,
      page: page,
      limit: limit,
    };
    inboxCustomerJourney(payLoad).then((res) => {
      if (res && res?.data?.customer_journey?.length > 0) {
        setJourneyLists(res?.data?.customer_journey);
      }
    });
  };

  const handleToggleSideNav = (type) => {
    setIsSideNavOpen((prev) => {
      const newState = prev === type ? null : type;

      // Handle the icon state based on the toggled state and type
      if (type === "contact") {
        setIsSideNavOpenIcon1(newState === "contact");
        setIsSideNavOpenIcon2(false);
        if (newState === "contact") {
          crmListView(
            currentBrand?.brand_id,
            selectedPerson?.contact_person_id
          );
        }
      } else if (type === "journey") {
        setIsSideNavOpenIcon2(newState === "journey");
        setIsSideNavOpenIcon1(false);

        if (newState === "journey") {
          customerJourney(currentBrand?.brand_id, selectedPerson?.id);
        }
      }

      return newState;
    });
  };

  return (
    <div className="w-full h-full">
      <div className="w-100 m-0 h-full flex">
        <div className="w-30 border-r-[0.1vw] border-[var(--border-50)]  flex-column gap-y-[0.1vw]">
          <div
            className="w-100  flex-column space-between p-20 p-relative overflow-hidden"
            style={{
              background: "white",
              boxShadow: "box-shadow: red",
            }}
          >
            <div className="w-100 flex-row align-center space-between">
              <div className="flex-row align-center">
                {activeTabItem?.icon1 && (
                  <img src={activeTabItem?.icon1} alt="icon" className="w-10" />
                )}
                {activeTabName === "All Conversations" && (
                  <AllConversations
                    color={
                      activeTabName ? "var(--textBlack)" : "var(--textBlack)"
                    }
                    fill={activeTabName ? "var(--textBlack)" : "none"}
                    stroke={activeTabName ? "white" : "var(--font-600)"}
                  />
                )}

                {activeTabName !== "All Conversations" &&
                  activeTabItem?.icon &&
                  activeTabName && (
                    <activeTabItem.icon
                      color={
                        activeTabName ? "var(--textBlack)" : "var(--textBlack)"
                      }
                      applyFill={activeTabName ?? "var(--a)"}
                      fill={activeTabName ? "var(--textBlack)" : "none"}
                      stroke={activeTabName ? "white" : "var(--font-600)"}
                      fillColor={activeTabName ? "none" : "none"}
                      lineColor={
                        activeTabName ? "var(--textBlack)" : "var(--font-600)"
                      }
                    />
                  )}
                {activeTabItem?.assignee !== undefined && (
                  <span
                    style={{
                      color: colors.color,
                      backgroundColor: colors.backgroundColor,
                    }}
                    className="px-1.5 py-0.8 h-30 rounded-full"
                  >
                    {firstLetter}
                  </span>
                )}
                {activeTabName !== "All Conversations" &&
                  activeTabName !== "All Conversations" &&
                  activeTabName !== "Your Inbox" &&
                  activeTabName !== "Favorited" &&
                  activeTabName !== "Resolved" &&
                  activeTabName !== "Unread" && (
                    <img src={ICONS?.whatsappInbox} alt="whatsApp" />
                  )}

                <span className="w-100 pl-10 new-header">{activeTabName}</span>
              </div>
              <span className="text-end w-100 relative hidden">hii</span>

              <div className="overflow-hidden right-6 p-absolute z-50">
                <SearchBar
                  searchKey={searchKey}
                  setSearchKey={setSearchKey}
                  onChange={(value) => onChange(value)}
                  search="inbox-search"
                  searchCancel="inboxSearchCancel"
                  expandable="true"
                  // className={`${expandable ? "w-[20vw]" : ""}`}
                />
              </div>
            </div>
            <ConversationsTypes
              loading={primaryLoading ? true : loading}
              conversations={selectedConversationType}
              selectedConversationType={selectedConversationType}
              onConversationTypeChange={onConversationTypeChange}
              onFilterChange={onFilterChange}
              selectedFilterType={selectedFilterType}
              setSelectedFilterType={setSelectedFilterType}
              activeTabName={activeTabName}
              phoneNumberCount={phoneNumberCount}
              clearType={clearType}
            />
          </div>

          <CustomerPhoneNumber
            primaryLoading={primaryLoading}
            loading={primaryLoading ? true : loading}
            searchKey={searchKey}
            conversationList={conversationList}
            selectedPerson={selectedPerson}
            selectedConversationType={selectedConversationType}
            onSelectPerson={onSelectPerson}
            onFavoriteClick={onFavoriteClick}
            onResolvedClick={onResolvedClick}
            onConversationStarChange={onConversationStarChange}
            onCloseConversation={onCloseConversation}
            count={phoneNumberCount}
            loadNextPage={loadNextPage}
            favorite={favorite}
            resolved={resolved}
          />
        </div>
        {selectedPerson ? (
          <>
            <div className="w-full border-r-[0.1vw] border-[var(--border-50)]">
              <ConversationContainer
                scrollTop={scrollTop}
                loading={isConversationPrimary}
                setLoading={setIsConversationPrimary}
                secondaryLoading={isConversationSecondary}
                setSecondaryLoading={setIsConversationSecondary}
                selectedPhoneNumber={selectedPhoneNumber}
                selectedPerson={selectedPerson}
                selectedConversationType={selectedConversationType}
                conversation={conversation}
                onConversationStarChange={onConversationStarChange}
                onCloseConversation={onCloseConversation}
                alert={alert}
                getConversations={getConversations}
                onFavoriteClick={onFavoriteClick}
                onResolvedClick={onResolvedClick}
                setOpenTemplateList={setOpenTemplateList}
                openTemplateList={openTemplateList}
                lastWindow={lastWindow}
                conversationList={conversationList}
                favorite={favorite}
                resolved={resolved}
                lastReplied={lastReplied}
                online={online}
                c={c}
                loadNext={() => {
                  // if (conversation?.length < count) {
                  //   // page += 1;
                  //   limit += 20;
                  //   getConversations(
                  //     "scroll",
                  //     false,
                  //     currentBrand?.brand_id,
                  //     limit,
                  //     page,
                  //     selectedPerson?.id
                  //   );
                  // } else if (conversation?.length > count) {
                  //   setAlert(true);
                  // }
                }}
                count={count}
              />
            </div>
            <div
              className={`h-full   ${
                isSideNavOpen ? "translate-x-0 w-30" : "translate-x-full w-0"
              }`}
              style={{
                transition: "transform 0.3s linear, width 0.3s linear",
              }}
            >
              {isSideNavOpen && (
                <RightSideBar
                  selectedPerson={selectedPerson}
                  setIsSideNavOpen={setIsSideNavOpen}
                  contactPopover={contactPopover}
                  setContactPopover={setContactPopover}
                  data={data}
                  type={isSideNavOpen === "contact" ? "contact" : "journey"}
                  journeyLists={journeyLists}
                  setIsSideNavOpenIcon2={setIsSideNavOpenIcon2}
                  setIsSideNavOpenIcon1={setIsSideNavOpenIcon1}
                  profession={profession}
                />
              )}
            </div>
            <div
              className="m-[15px] bg-white p-relative h-full pointer"
              // onClick={handleToggleSideNav}
              style={{ zIndex: 1000 }}
            >
              {!isSideNavOpenIcon1 ? (
                <img
                  src={ICONS?.contactRightInbox}
                  alt="contactRightInbox"
                  className="mb-20 ml-1"
                  onClick={() => handleToggleSideNav("contact")}
                  // style={{width: "20px"}}
                />
              ) : (
                <img
                  src={ICONS?.contactDetailsOpen}
                  alt="contactRightInbox"
                  className="mb-20 w-7"
                  onClick={() => handleToggleSideNav("contact")}
                />
              )}
              {!isSideNavOpenIcon2 ? (
                <img
                  src={ICONS?.customerJourneyInbox}
                  alt="customerJourneyInbox"
                  // className={`${
                  //   !isSideNavOpenIcon1 ? "pt-20 pl-[25px]" : "pt-20 pl-[40px]"
                  // }}`}
                  className="mt-10 ml-1"
                  onClick={() => handleToggleSideNav("journey")}
                />
              ) : (
                <img
                  src={ICONS?.customerJourneyOpen}
                  alt="contactRightInbox"
                  className="w-7 mt-10"
                  onClick={() => handleToggleSideNav("journey")}
                />
              )}
            </div>
          </>
        ) : (
          <div className="w-full h-[75vh]">
            <NoData
              icon={ICONS?.inboxEmpty}
              title="This is a place to chat to your contacts in all of the connected channels"
              content={
                "Every time somebody sends you a message, it will appear here. You can change this and more in Live Chat Settings"
              }
              iconStyle={"w-[10vw]"}
              style={"max-w-[25vw]"}
              titleClassName="!font-largest"
            />
          </div>
        )}
      </div>
    </div>
  );
}
