import React from "react";

// utils
import { useNavigate } from "react-router-dom";

// constant
import { WhatsappFilterList } from "../../../../../../constant/app/Filter/Filter";

// redux
import { useAspDispatch } from "../../../../../../test/jest-redux-hooks";
import { resetTemplateDetails } from "../../../../../../reduxToolkit/templateSlice";

// components
import SearchBar from "components/commonComponents/searchBar";
import Filters from "components/commonComponents/filter/index";

const TemplateListHeader = ({
  inputRef,
  loading,
  searchKey,
  setSearchKey,
  onChange,
  onFilterChange,
  selectedStatus,
  clearAllFilter,
}) => {
  const navigate = useNavigate();
  const dispatch = useAspDispatch();

  return (
    <div className="pt-[1.2vw] flex-row items-center space-between !px-[1.3vw]">
      <div className="w-[16vw]">
        <SearchBar
          ref={inputRef}
          // loading={loading}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          onChange={onChange}
          expandable={true}
        />
      </div>
      <div className="flex gap-[1vw]">
        <Filters
          options={WhatsappFilterList}
          selectedOptions={selectedStatus}
          loading={loading}
          onChange={(data, item) => {
            onFilterChange(data, item);
          }}
          onClearAll={clearAllFilter}
        />
        <button
          className={`bg-[var(--primary)] text-[var(--white)] px-[0.8vw] py-[0.52vw] font-md weight-medium rounded-[0.37vw] ${
            loading ? "cursor-not-allowed" : "cursor-pointer"
          }`}
          onClick={() => {
            if (!loading) {
              dispatch(resetTemplateDetails());
              navigate("/user/channels/whatsapp/create-template");
            }
          }}
          data-testid="Create-template"
        >
          Create Template
        </button>
      </div>
    </div>
  );
};

export default TemplateListHeader;
