import { createSlice } from '@reduxjs/toolkit';

import { getLanguageName } from 'helper/setLanguageCode';

import {
  authenticationTypes,
  marketingTypes,
  utilityTypes,
} from 'modules/channels/constant';

const initialState = {
  category: '',
  categoryType: '',
  name: '',
  language: [],
  id: null,
  details: null,
  continueDetails: null,
  data: null,
};

export const authSlice = createSlice({
  name: 'Template',
  initialState,
  reducers: {
    update: (state, action) => {
      const { key, value } = action.payload;
      // it will update the category
      if (key === 'category') {
        state[key] =
          marketingTypes.includes(value) || value === 'MARKETING'
            ? 'MARKETING'
            : utilityTypes.includes(value) || value === 'UTILITY'
            ? 'UTILITY'
            : (authenticationTypes.includes(value) ||
                value === 'AUTHENTICATION') &&
              'AUTHENTICATION';
        state.categoryType = value;
      }
      // update or replace the generic value to the state based on the key passed
      else {
        state[key] = value;
      }
    },
    updateLanguage: (state, action) => {
      const value = action.payload;
      if (value) {
        const formatedValue = { [value]: {} };
        state.currentLanuage = value;
        state.language = [formatedValue];
        const language = state?.language?.[0]
          ? Object?.keys(state?.language?.[0])
          : '';
        const converted = getLanguageName(language);
        state.currentLanuage = [converted];
      } else {
        state.language = [];
      }
    },
    updateTemplateDetails: (state, action) => {
      const { data } = action.payload;

      state.details = data;
      state.category = data.category;
      state.categoryType = data?.subcategory_type?.toUpperCase();
      state.name = data.name;
      state.language = [data.language];
      state.id = data?.id;
    },

    resetTemplateDetails: (state, action) => {
      state.category = '';
      state.categoryType = '';
      state.name = '';
      state.language = [];
      state.details = null;
      state.continueDetails = null;
      state.data = null;
      // state.components = [
      //   {
      //     type: 'BODY',
      //     text: '',
      //   },
      // ];
      // state.currentLanuage = '';
      // state.variables = [];
      // state.buttons = [];
    },
  },
});

export const {
  update,
  updateLanguage,
  updateTemplateDetails,
  resetTemplateDetails,
} = authSlice.actions;

export default authSlice.reducer;
