import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import FilterHome from "../../../../components/commonComponents/filterHome/FilterHome";
import { ICONS } from "../../../../assets/icons";
import {
  broadcastPerformanceFilter,
  HomeFilterCampaign,
} from "../../../../constant/app/Filter/Filter";
import { getBroadcastPerformanceCount } from "../../api/index";
import { useAspSelector } from "test/jest-redux-hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BroadcastPerformance = () => {
  const { currentBrand } = useAspSelector((state) => state?.app);
  const { id } = useParams();
  const campaignId = id;

  const navigate = useNavigate();
  const location = useLocation();
  const campaignOverviewData = location?.state?.data;

  const [broadcastCount, setBroadcastCount] = useState(3);
  const [broadcastPerformanceList, setBroadcastPerformanceList] = useState([]);

  const BroadcastPerformnList = (brandId) => {
    if (campaignId && brandId) {
      getBroadcastPerformanceCount(campaignId, brandId, broadcastCount).then(
        (res) => {
          if (res?.data?.length > 0) {
            setBroadcastPerformanceList(res?.data);
          }
        }
      );
    }
  };

  useEffect(() => {
    if (location?.pathname === `/user/campaign/view/${campaignId}/overview`) {
      BroadcastPerformnList(currentBrand?.brand_id, campaignId, broadcastCount);
    }
  }, [currentBrand, campaignId, broadcastCount, location]);

  const handleFilterChange = (filter) => {
    let recent_broadcast_count;

    switch (filter) {
      case "Last 3 Broadcasts":
        recent_broadcast_count = 3;
        break;
      case "Last 5 Broadcasts":
        recent_broadcast_count = 5;
        break;
      case "Last 7 Broadcasts":
        recent_broadcast_count = 7;
        break;
      case "Last 10 Broadcasts":
        recent_broadcast_count = 10;
        break;

      default:
        recent_broadcast_count = 3;
    }

    setBroadcastCount(recent_broadcast_count);
  };

  // const labels = broadcastPerformanceList?.map(
  //   (item) => `${item?.broadcast_name}`
  // );

  const labels = broadcastPerformanceList?.map((item) => {
    const name = item?.broadcast_name || ""; 
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  });


  // const labels = salesData.map((item, index) => (
  //   <div key={index} style={{ color: item.label_bg }}>
  //     {item.name}
  //   </div>
  // ));

  const sentCount = broadcastPerformanceList?.map(
    (item) => item?.sent_count || 0
  );

  const deliveredCount =
    broadcastPerformanceList?.map((item) => item?.delivered_count || 0) || []; // Default to empty array if undefined

  const openedCount =
    broadcastPerformanceList?.map((item) => item?.opened_count || 0) || [];

  const bouncedCount =
    broadcastPerformanceList?.map((item) => item?.bounced_count || 0) || [];

  // Ensure `deliveredCount` is an array before using `Math.max`
  const maxSalesValue =
    deliveredCount.length > 0 ? Math.max(...deliveredCount) : 0;

  const maxAccountsValue = Math?.max(...openedCount);

  const maxCombinedValue = Math?.max(maxSalesValue, maxAccountsValue);

  // const yAxisMax = Math.ceil((maxCombinedValue * 1.1) / 100) * 100;

  const magnitude = Math.pow(2, Math.floor(Math.log10(maxCombinedValue)));
  const stepSize = magnitude;
  const yAxisMax = Math.ceil(maxCombinedValue / stepSize) * stepSize + stepSize;

  const paddedDeliveredCount =
    deliveredCount?.length < 4
      ? [...deliveredCount, ...new Array(4 - deliveredCount?.length).fill(0)]
      : deliveredCount;

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Sent",
        data:
          sentCount.length < 4
            ? [...sentCount, ...new Array(4 - sentCount.length).fill(0)]
            : sentCount,
        fill: false,
        backgroundColor: sentCount.map((value) =>
          value === 0 ? "#A175FF" : "#A175FF"
        ),
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: "Bounced",
        data:
          bouncedCount.length < 4
            ? [...bouncedCount, ...new Array(4 - bouncedCount.length).fill(0)]
            : bouncedCount,
        fill: false,
        backgroundColor: bouncedCount.map((value) =>
          value === 0 ? "red" : "red"
        ),
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: "Delivered",
        data: paddedDeliveredCount,
        fill: false,
        backgroundColor: paddedDeliveredCount?.map((value) =>
          value === 0 ? "#02D1C5" : "#02D1C5"
        ),
        // backgroundColor: "#EDAB40",
        borderWidth: 1,
        borderRadius: 4,
      },
      {
        label: "Opened",
        data:
          openedCount.length < 4
            ? [...openedCount, ...new Array(4 - openedCount.length).fill(0)]
            : openedCount,
        fill: false,
        backgroundColor: openedCount.map((value) =>
          value === 0 ? "#1778F2" : "#1778F2"
        ),
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "bottom",
        pointRadius: 6,
        layout: {
          padding: {
            top: 70,
            bottom: 10,
          },
        },
        labels: {
          type: "category",
          usePointStyle: true,
          pointStyle: "circle",
          padding: 30,
          pointRadius: 10,
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        padding: 10,
        callbacks: {
          label: function (tooltipItem) {
            const value = tooltipItem.raw; // Get the value of the current item
            if (value === 0) {
              return null; // Hide this item from the tooltip
            }
            // Customize the label as needed
            return `${tooltipItem.dataset.label}: ${value}`;
          },
        },
      },
    },

    barPercentage: 0.9,
    categoryPercentage: 0.13,
    scales: {
      x: {
        grid: {
          color: "#E8E8EA", // Change the X-axis grid line color
          borderColor: "#E8E8EA", // Change the X-axis line color
        },
        ticks: {
          font: {
            size: 16,
            weight: "500",
            color: "var(--font-600)",
          },
          // callback: function (value, index, ticks) {
          //   const labels = this.chart.data.labels;
          //   const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4"];
          //   return "● " + labels[index];
          // },
          // callback: function (value, index, ticks) {
          //   const labels = this.chart.data.labels;
          //   return "● " + labels[index];

          //   // return `\u2022 ${labels[index]}`;
          // },
          // color: function (context) {
          //   const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4"];
          //   return colors[context.index % colors.length];
          // },
          render: function (value, index, ticks) {
            const labels = this.chart.data.labels;
            // const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4"];

            // Wrap the bullet point in a styled span
            // return `<span class="colored-bullet" style="color:${
            //   colors[index % colors.length]
            // };background-color:${colors[index % colors.length]}">●</span> ${
            //   labels[index]
            // }`;
          },
          padding: 1,
        },
        // ticks: {
        //   font: {
        //     size: 14,
        //     weight: "bold", // Change font weight
        //     color: "#333",
        //   },
        // },
      },
      y: {
        beginAtZero: true,
        // max: yAxisMax,
        grid: {
          color: "#E8E8EA", // Change the X-axis grid line color
          borderColor: "#E8E8EA", // Change the X-axis line color
        },

        ticks: {
          stepSize: 2,
          font: {
            size: 16,
            weight: "500",
            color: "var(--font-600)",
          },

          callback: function (value) {
            if (value >= 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
          maxTicksLimit: 10,
        },
      },
    },
  };

  return (
    <div className="bg-white rounded-[1vw] px-[2vw] py-[1vw] w-100">
      <div className="flex-row justify-between items-center pb-4 border-b-[1px]">
        <p className="flex-row items-center chart-headers">
          Broadcast Performance
          <span
            className="ml-2 pointer"
            onClick={() => {
              navigate(`/user/campaign/view/${id}/execution`, {
                state: { data: campaignOverviewData },
              });
            }}
            data-testid="navigate"
          >
            <img src={ICONS?.homeDownload} className="w-[18px] h-[18px]" />
          </span>
        </p>

        {broadcastPerformanceList?.length > 0 && (
          <FilterHome
            filterList={broadcastPerformanceFilter}
            fetchApiByFilter={handleFilterChange}
            dataTestId="broadcastPerformance_filter_test"
            dataTestIdClick="filter_click"
          />
        )}
      </div>

      <div style={{ height: "36vh", padding: "1vw 1vw" }}>
        {broadcastPerformanceList?.length > 0 ? (
          <Bar data={data} options={options} />
        ) : (
          <div className="h-full flex flex-col items-center justify-center">
            <img src={ICONS?.noChartData} alt="noChartData" />
            <p className="text-[#616874]">There’s no data yet</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BroadcastPerformance;
