import React from 'react';

const Message = ({ width = 22, height = 22, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.59971 8.88291V8.80019M10.9997 8.88291V8.80019M15.3997 8.88291V8.80019M10.7128 15.2089L6.12145 19.8002V15.2089H4.39971C3.18468 15.2089 2.19971 14.2239 2.19971 13.0089V4.4002C2.19971 3.18517 3.18468 2.2002 4.39971 2.2002H17.5997C18.8147 2.2002 19.7997 3.18517 19.7997 4.4002V13.0089C19.7997 14.2239 18.8147 15.2089 17.5997 15.2089H10.7128Z'
        stroke={color}
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Message;
