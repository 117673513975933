import AllConversations from "../../../assets/customSVG/inbox/AllConversations";
import Unassigned from "../../../assets/customSVG/inbox/Unassigned";
import { ICONS } from "../../../assets/icons/index";
import Resolved from "../../../assets/customSVG/inbox/Resolved";
import Favourite from "../../../assets/customSVG/inbox/Favourite";
import Unread from "../../../assets/customSVG/inbox/Unread";

export const leftInboxList = [
  {
    id: 1,
    icon: ICONS?.whatsApp,
    name: "Gochew Grill",
    num: "+91 9876543210",
    count: 1100,
  },
];

// export const conversationTypes = [
//   {
//     id: 1,
//     type: "opened",
//     icon: "inboxMessageTyping",
//     msg: "Opened conversations.",
//     icon: MessageTyping,
//   },
//   {
//     id: 2,
//     type: "closed",
//     icon: "inboxMessagePlus",
//     msg: "Closed conversations.",
//     icon: MessagePlus,
//   },
//   {
//     id: 3,
//     type: "starred",
//     icon: "inboxStar",
//     msg: "Starred conversations.",
//     icon: Star,
//   },
// ];

export const conversationTypes = [
  {
    id: 1,
    type: "open",
    icon1: ICONS?.inboxOpen,
    msg: "Open",
    isSelected: true,
    // icon: MessageTyping,
  },
  {
    id: 2,
    type: "closed",
    // icon: ICONS?.,
    msg: "Closed",
    icon: Resolved,
    isSelected: false,
  },
];

export const filetrTypes = [
  {
    id: 1,
    type: "unread",
    msg: "Unread",
    icon: Unread,
    isSelected: false,
  },
  {
    id: 2,
    type: "resolved",
    msg: "Resolved",
    icon: Resolved,
    isSelected: false,
  },
  {
    id: 3,
    type: "favorited",
    // icon: ICONS?.,
    msg: "Favorited",
    icon: Favourite,
    isSelected: false,
  },
];

export const conversationMessage = [
  { id: 1, icon: ICONS?.conversationMessage, list: "10 Open conversations." },
  { id: 2, icon: ICONS?.conversationMessage, list: "10 Closed conversations." },
  {
    id: 3,
    icon: ICONS?.conversationMessage,
    list: "10 Starred conversations.",
  },
];

export const conversationMessageList = [
  {
    id: 1,
    icon: ICONS?.inboxProfile,
    icon1: ICONS?.whatsApp,
    name: "Riya Magar",
    phoneNumber: "(+91) 984 304 3000",
    content:
      "Lorem ipsum dolor sit amet consectetur. Commodo habitant enim vivamus eu elementum nulla platea velit.",
    time: "2024-03-03T19:37:00.000Z",
    isStarred: true,
    isClose: false,
  },
  {
    id: 2,
    icon: ICONS?.inboxProfile,
    icon1: ICONS?.whatsApp,
    name: "Shweta Nigam",
    phoneNumber: "(+91) 984 304 3000",
    content:
      "Lorem ipsum dolor sit amet consectetur. Commodo habitant enim vivamus eu elementum nulla platea velit.",
    time: "2024-04-03T19:37:00.000Z",
    isStarred: false,
    isClose: true,
  },
  {
    id: 3,
    icon: ICONS?.inboxProfile,
    icon1: ICONS?.whatsApp,
    name: null,
    phoneNumber: "(+91) 984 304 3000",
    content:
      "Lorem ipsum dolor sit amet consectetur. Commodo habitant enim vivamus eu elementum nulla platea velit.",
    time: "2024-04-03T21:08:00.000Z",
    isStarred: false,
    isClose: false,
  },
];

// export const conversationMessageMiddle = [
//   {
//     id: 1,
//     icon: ICONS?.inboxProfile,
//     icon1: ICONS?.Whatsapp,
//     name: 'Riya Magar',
//     content: 'Last replied Oct, 18 - 10:30AM',
//     time: '2024-04-03T21:08:00.000Z',
//     isStarred: true,
//   },
// ];

export const templatePopover = [
  {
    id: 1,
    name: "exclusive_dinner_deal",
    language: "English",
    active: "Active - Quality pending",
  },
  {
    id: 2,
    name: "exclusive_breakfast_deal",
    language: "English",
    active: "Active - Quality pending",
  },
  {
    id: 3,
    name: "exclusive_diwali_offer",
    language: "English",
    active: "Active - Quality pending",
  },
  {
    id: 4,
    name: "exclusive_dinner_deal",
    language: "English",
    active: "Active - Quality pending",
  },
  {
    id: 5,
    name: "exclusive_dinner_deal",
    language: "English",
    active: "Active - Quality pending",
  },
];

export const inboxChat = [
  {
    id: 1,
    content:
      "Hello! I heard you have a special food offer. Can you tell me more about it?",
  },
  {
    id: 2,
    content:
      "Hello! I heard you have a special food offer. Can you tell me more about it?",
  },
  {
    id: 3,
    content:
      "Hello! I heard you have a special food offer. Can you tell me more about it?",
  },
];

export const inboxVariables = [
  {
    id: 1,
    placeholder: "{{first name}}",
  },
  {
    id: 2,
    placeholder: "{{email}}",
  },
  {
    id: 3,
    placeholder: "{{product 1}}",
  },

  {
    id: 4,
    placeholder: "{{orders}}",
  },
  {
    id: 5,
    placeholder: "{{custom variable240}}",
  },
];

export const inboxVariablesSample = [
  {
    id: 1,
    placeholder: "Ex:- joyel",
  },
  {
    id: 2,
    placeholder: "Ex:- abc@mail.com",
  },
  {
    id: 3,
    placeholder: "Ex:- text",
  },

  {
    id: 4,
    placeholder: "Ex:- text",
  },
  {
    id: 5,
    placeholder: "Ex:- text",
  },
];

export const inboxTemplateLink = [
  {
    id: 1,
    link: "View offer",
    icon: ICONS?.ReplayIcon,
  },
  {
    id: 2,
    link: "Order Now",
  },
  {
    id: 3,
    link: "More Details",
  },
];

export const inboxButtonList = [
  {
    id: 1,
    button: "Button1",
  },
  {
    id: 2,
    button: "Button2",
  },
  {
    id: 3,
    button: "Button3",
  },
  {
    id: 4,
    button: "Button4",
  },
  {
    id: 5,
    button: "Button5",
  },
  {
    id: 6,
    button: "Button6",
  },
];

export const inboxSidebar = [
  {
    id: 1,
    name: "Conversations",
    icon: ICONS?.sidebarChevronDown,
    subMenu: [
      {
        id: 1,
        icon: AllConversations,
        name: "All Conversations",
        // key: "all-conversations",
        isSelected: false,
        // count: 0,
      },
      // {
      //   id: 2,
      //   assignee: "",
      //   name: "Your Inbox",
      //   // key: "youer-inbox",
      //   isSelected: false,
      //   // count: 0,
      // },
      {
        id: 3,
        name: "Unread",
        key: "unread",
        isSelected: false,
        icon: Unread,
        count: 0,
      },
      // {
      //   id: 4,
      //   name: "Unassigned",
      //   key: "unassigned",
      //   isSelected: false,
      //   icon: Unassigned,
      // },
      {
        id: 5,
        name: "Resolved",
        key: "resolved",
        isSelected: false,
        icon: Resolved,
        count: 0,
      },
      {
        id: 6,
        name: "Favorited",
        key: "favorited",
        isSelected: false,
        icon: Favourite,
        count: 0,
      },
      // {
      //   id: 6,
      //   // icon: ICONS?.oldestFirst,
      //   name: "Mentioned",
      //   key: "mentioned",
      //   isSelected: false,
      //   icon: Unassigned,
      // },
    ],
  },
  // {
  //   id: 2,
  //   name: "Channels",
  //   icon: ICONS?.sidebarChevronDown,
  //   subMenu: [
  //     {
  //       id: 1,
  //       // icon: ICONS?.newestFirst,
  //       send_from_phone_name: "",
  //       key: "all-conversations",
  //       isSelected: false,
  //       icon1: ICONS?.whatsappInbox,
  //     },
  //   ],
  // },
];
