import moment from "moment";
import React, { useState } from "react";
import Chart from "react-apexcharts";

const AreaChart = ({ data, color, name, startDate, endDate }) => {
  const startDates = moment(startDate);
  const endDates = moment(endDate);

  const labels = [];
  let currentDate = startDates.clone();

  while (currentDate.isBefore(endDates) || currentDate.isSame(endDates)) {
    labels.push(currentDate.format("DD MMM"));
    currentDate.add(1, "days");
  }

  const dataMap = data.reduce((acc, { date, count }) => {
    const formattedDate = moment(date).format("DD MMM");
    acc[formattedDate] = count;
    return acc;
  }, {});

  const seriesData = labels.map((label) => dataMap[label] || 0);

  const options = {
    chart: {
      type: "area",
      height: 0,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 0,
        right: 0,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      padding: {
        left: 0,
        right: 0,
      },
    },

    fill: {
      colors: [color],
    },
    grid: {
      show: false,
    },
  };

  const series = [
    {
      name: name,
      data: seriesData,
      // data: data?.map((item) => item?.count),
    },
  ];

  return (
    <>
      {data?.length > 0 ? (
        <Chart
          options={options}
          series={series}
          type="area"
          width="100%"
          height={0}
        />
      ) : (
        <div
          className="flex items-center justify-center"
          style={{
            transform: "translate(0px, 5vw)",
            border: `1px solid ${color}`,
          }}
        ></div>
      )}
    </>
  );
};

export default AreaChart;
