import React, { useState } from "react";

const Download = ({ color = "var(--font-600)", className }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M2.5 11.667L2.69488 12.2192C3.45333 14.3681 3.83257 15.4426 4.69785 16.0548C5.56313 16.667 6.70258 16.667 8.98148 16.667H11.0186C13.2974 16.667 14.4369 16.667 15.3022 16.0548C16.1674 15.4426 16.5467 14.3681 17.3052 12.2192L17.5 11.667"
          stroke={color}
          strokeWidth="1.76"
          strokeLinecap="round"
        />
        <path
          d="M10.0003 11.6673V3.33398M10.0003 11.6673C9.41681 11.6673 8.3266 10.0054 7.91699 9.58398M10.0003 11.6673C10.5838 11.6673 11.6741 10.0054 12.0836 9.58398"
          stroke={color}
          strokeWidth="1.76"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Download;

