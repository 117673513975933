import {
  checkVariable,
  getVariablesFromStr,
  removeDoubleStyles,
} from "helper/templates";

import {
  allowedCtaButtonTypes,
  allowedCustomButtonTypes,
  getFirtstTypeOfButton,
} from "components/commonComponents/templates/Buttons/utils";

import { updateVariablesFromText } from "./variables";

export const allowedMaxLine = 3;

export const checkSpaceAtStart = (text) => {
  return text?.length !== 0 ? text?.toString()?.startsWith(" ") : true;
};

export const checkSpaceAtEnd = (text) => {
  return text?.length !== 0 ? text?.toString()?.endsWith(" ") : true;
};

export const checkMultipleNewLines = (str) =>
  str?.length > 0 ? str?.match(/\n{2,}(?!\S)/) : str;

export const checkMaxNewLines = (str) =>
  str?.split("\n").length > allowedMaxLine ? true : false;

export const filterData = (arr, key) => {
  let filteredData = null;
  if (arr?.length > 0) filteredData = arr.filter((a) => a.type === key);
  else filteredData = null;

  return filteredData;
};

export const formatCustomDataFromAPI = (data) => {
  const header = filterData(data?.components, "HEADER");
  const body = filterData(data?.components, "BODY");
  const footer = filterData(data?.components, "FOOTER");
  const buttons = filterData(data?.components, "BUTTONS");

  const updatedButtons = buttons?.[0]?.buttons?.map((b, i) => {
    if (b.type === "PHONE_NUMBER")
      return {
        ...b,
        id: i + 1,
        format: b?.type,
        code: b?.phone_number?.split(" ")?.[0],
        phone_number: b?.phone_number?.split(" ")?.[1],
      };
    else
      return {
        id: i + 1,
        format:
          b.type === "QUICK_REPLY"
            ? b.text !== "Stop promotions"
              ? "CUSTOM"
              : "OPT_OUT"
            : b.type,
        ...b,
      };
  });

  let headerVariable = null;
  let bodyVariables = null;
  let updateButtonOrder = null;
  let updateCtaButton = [];
  let updateCustomButton = [];
  let customButtonsWithOptout = [];

  if (body?.[0]?.example?.body_text?.[0]?.length > 0) {
    bodyVariables = getVariablesFromStr(
      body?.[0].text,
      body?.[0]?.example?.body_text?.[0]
    );
  }

  if (header?.[0]?.example?.header_text?.[0]?.length > 0) {
    const res = checkVariable(header?.[0]?.text);
    if (res?.length > 0) {
      const check = bodyVariables?.filter((v) => v.title === res?.[0]);
      headerVariable = {
        title: res?.[0],
        id: check?.length > 0 ? check?.[0]?.id : 1,
        value:
          check?.length > 0
            ? check?.[0]?.value
            : header?.[0]?.example?.header_text?.[0],
      };
    } else headerVariable = null;
  }

  if (updatedButtons?.length > 0) {
    updateButtonOrder = getFirtstTypeOfButton(updatedButtons);
    updateCtaButton = updatedButtons?.filter((b) =>
      allowedCtaButtonTypes.includes(b?.type)
    );
    updateCustomButton = updatedButtons?.filter(
      (b) =>
        allowedCustomButtonTypes?.includes(b.type) &&
        b.text !== "Stop promotions"
    );
    customButtonsWithOptout = updatedButtons?.filter(
      (b) =>
        allowedCustomButtonTypes?.includes(b.type) &&
        b.text === "Stop promotions"
    );

    const customButtons = [...updateCustomButton, ...customButtonsWithOptout];

    if (updateButtonOrder === "ctaButtons" && updateCtaButton?.length > 0)
      updateButtonOrder = "ctaButtons";
    if (updateButtonOrder === "custom" && customButtons?.length > 0)
      updateButtonOrder = "custom";
  }

  const customButtons = [...updateCustomButton, ...customButtonsWithOptout];

  return {
    header: header?.length > 0 ? header?.[0] : null,
    headerVariable: headerVariable,
    body: body?.length > 0 ? body?.[0]?.text : null,
    bodyVariables: bodyVariables,
    footer: footer?.length > 0 ? footer?.[0] : null,
    buttons: updatedButtons?.length > 0 ? updatedButtons : [],
    ctaButtons: updatedButtons?.length > 0 ? updateCtaButton : [],
    customButtons: updatedButtons?.length > 0 ? customButtons : [],
    buttonsOrder: updatedButtons?.length > 0 ? updateButtonOrder : null,
  };
};

export const formatCustomDataToAPI = (data) => {
  const { custom, authentication, carousel } = data;
  let header = {};

  let components = [];

  const newBodyVariables = updateVariablesFromText(
    custom?.body,
    custom?.bodyVariables,
    custom?.headerVariable
  );

  const newHeaderVariables = updateVariablesFromText(
    custom?.heade?.text,
    custom?.headerVariable,
    custom?.bodyVariables
  );

  if (custom?.header) {
    header = {
      ...custom?.header,
    };

    if (newHeaderVariables?.length > 0) {
      header["example"] = {
        header_text: [newHeaderVariables?.[0]?.value],
      };
    }

    components?.push(header);
  }

  if (newBodyVariables?.length > 0) {
    components.push({
      type: "BODY",
      text: custom?.body,
      example: {
        body_text: [
          newBodyVariables?.map((b) => {
            return b.value;
          }),
        ],
      },
    });
  } else {
    components.push({
      type: "BODY",
      text: removeDoubleStyles(custom?.body),
    });
  }

  if (custom?.footer) components?.push(custom?.footer);
  if (custom?.buttons?.length > 0)
    components?.push({
      type: "BUTTONS",
      buttons: custom?.buttons,
    });

  return {
    id: data?.id,
    name: data?.name,
    category: data?.category,
    subcategory_type: data?.categoryType,
    language: data?.language,
    status: data?.status,
    components,
  };
};

export const formateAuthenticationDataFromAPI = (data) => {
  const BODY = filterData(data?.components, "BODY");
  const FOOTER = filterData(data?.components, "FOOTER");
  const BUTTONS = filterData(data?.components, "BUTTONS");
  const validity_period = data?.validity_period
    ? data?.validity_period !== -1
      ? true
      : false
    : true;

  const updatedButtons = BUTTONS?.[0]?.buttons?.map((b, i) => {
    return {
      ...b,
      id: i + 1,
      text: b?.text,
      placeholder: b?.otp_type,
    };
  });

  return {
    body: BODY?.[0]
      ? BODY?.[0]?.add_security_recommendation
        ? "{{otp-number}} is your verification code. For your security, do not share this code."
        : "{{otp-number}} is your verification code."
      : "{{otp-number}} is your verification code. For your security, do not share this code.",
    content: {
      securityRecommendation:
        BODY?.length > 0 ? BODY?.[0]?.add_security_recommendation : true,
      expiryTimeForCode: FOOTER?.[0]?.code_expiration_minutes ? true : false,
      expiryTime: FOOTER?.[0]?.code_expiration_minutes
        ? FOOTER?.[0]?.code_expiration_minutes
        : 10,
    },
    buttons:
      updatedButtons?.length > 0
        ? updatedButtons
        : [
            {
              id: 1,
              label: "Copy code",
              placeholder: "Copy code",
              text: "Copy code",
              type: "OTP",
              otp_type: "COPY_CODE",
            },
          ],
    messageValidity: {
      validity: validity_period,
      period: validity_period ? data?.validity_period || 10 : 10,
    },
  };
};

export const updateCustomButtons = ({
  buttons,
  data,
  updateKey,
  buttonsOrder,
}) => {
  let order = null;
  const cta = buttons?.filter((b) => allowedCtaButtonTypes.includes(b?.type));

  const custom = buttons?.filter((b) =>
    allowedCustomButtonTypes.includes(b?.type)
  );

  const newUpdateCustomButton = custom?.filter(
    (b) =>
      allowedCustomButtonTypes.includes(b.type) && b.text !== "Stop promotions"
  );
  const newCustomButtonsWithOptout = custom?.filter(
    (b) =>
      allowedCustomButtonTypes.includes(b.type) && b.text === "Stop promotions"
  );

  const newCustomButtons = [
    ...newUpdateCustomButton,
    ...newCustomButtonsWithOptout,
  ];

  if (!buttonsOrder) {
    order = allowedCtaButtonTypes.includes(data?.type)
      ? "ctaButtons"
      : allowedCustomButtonTypes?.includes(data?.type)
      ? "custom"
      : "";
  } else {
    if (buttonsOrder === "ctaButtons" && cta.length > 0) order = "ctaButtons";
    else if (buttonsOrder === "ctaButtons" && cta.length === 0)
      order = "custom";
    else if (buttonsOrder === "custom" && custom.length > 0) order = "custom";
    else if (buttonsOrder === "custom" && custom.length === 0)
      order = "ctaButtons";
  }

  return {
    buttons:
      order === "custom"
        ? [...newCustomButtons, ...cta]
        : [...cta, ...newCustomButtons],
    ctaButtons: cta,
    customButtons: newCustomButtons,
    buttonsOrder: order,
  };
};
