import React, { useEffect, useState } from "react";
import "../../styles/Api.css";

import CampaignHeader from "../campaigns/CampaignHeader";
import ApiOverView from "./ApiOverView";
import ContactSelectView from "../BroadcastSetting/BroadcastView/ContactSelectView";
import Paginations from "../BroadcastSetting/BroadcastView/Paginations";
import ContactViewFilter from "../../../../components/commonComponents/ListViewHeader/ContactViewFilter";
import SearchBar from "../../../../components/commonComponents/searchBar";
import { ApiContactList } from "../../api/Api";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import { useLocation, useParams } from "react-router-dom";
import { ICONS } from "../../../../assets/icons";
import { Skeleton } from "antd";
import ApiContactTable from "./ApiContactTable";
import Loader from "../../../../components/commonComponents/Loader/Index";

import * as XLSX from "xlsx";

const ContactApi = () => {
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isFilter, setIsFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [contactDetails, setContactDetails] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  const { currentBrand } = useAspSelector((state) => state.app);
  const location = useLocation();
  const campaignDetails = location?.state?.item;

  const brandId = currentBrand?.brand_id;
  const { id } = useParams();
  const campaignId = id;

  const fetchApiContactList = (brandId, campaignId, payload) => {
    setIsLoading(true);
    if (brandId && campaignId) {
      ApiContactList(brandId, campaignId, payload).then((res) => {
        if (res && res?.data) {
          const updatedData = res?.data?.conversations_contacts?.map(
            (item) => ({
              ...item,
              subcategory_type: "CUSTOM",
            })
          );
          setContactList(updatedData);
          setCount(res.data.conversations_contacts_count);
          setIsLoading(false);
        }
      });
    }
  };

  const loadNextPage = (page) => {
    if (contactList?.length < count) {
      fetchApiContactList(currentBrand?.brand_id, campaignId, {
        page: page,
        limit: pageSize,
      });
    }
  };

  const handlePageChange = (page) => {
    loadNextPage(page);
    setPage(page);
    if (selectedStatus) {
      fetchApiContactList(currentBrand?.brand_id, campaignId, {
        page: page,
        limit: pageSize,
        status: selectedStatus,
      });
    }
  };

  const handlePageChangeRow = (pageSize) => {
    const pageSizeLimit = parseInt(pageSize);
    loadNextPage(pageSize);
    setPage(page);
    setPageSize(pageSizeLimit);
  };

  useEffect(() => {
    if (brandId) {
      fetchApiContactList(brandId, campaignId, {
        page: page,
        limit: pageSize,
      });
    }
  }, [brandId, campaignId, pageSize, page]);

  const templateSearch = (value) => {
    setIsFilter(value);
    fetchApiContactList(
      currentBrand?.brand_id,
      campaignId,

      {
        filter: value,
        name: value,
      }
    );
  };
  const onFilterChange = (status) => {
    const updatedStatus = selectedStatus.includes(status)
      ? selectedStatus.filter((s) => s !== status)
      : [...selectedStatus, status];
    setSelectedStatus(updatedStatus);
    fetchApiContactList(currentBrand?.brand_id, campaignId, {
      status: updatedStatus,
    });
  };

  const handleContactClick1 = (item) => {
    setContactDetails(item);
    setPageSize(parseInt(item?.value));

    fetchApiContactList(currentBrand?.brand_id, campaignId, {
      limit: parseInt(item?.value),
      page: 1,
    });
  };

  const downloadBroadcastContacts = async (payload) => {
    setIsLoader(true);
    try {
      let allContacts = [];

      if (brandId && campaignId) {
        const limit = contactList?.conversations_contacts_count;
        const res = await ApiContactList(brandId, campaignId, {
          ...payload,
          limit,
        });

        if (res && res?.data) {
          allContacts = res?.data?.conversations_contacts;
        }

        function getStatusLabel(status) {
          switch (status) {
            case "BOUNCED":
              return "Bounced";
            case "SENDING":
              return "Sending";
            case "SENT":
              return "Sent";
            case "DELIVERED":
              return "Delivered";
            case "READ":
              return "Opened";
            case "REPLIED":
              return "Lead";
            default:
              return "Unknown";
          }
        }

        const formattedContacts = allContacts.map((contact) => ({
          "From Number": contact.from_number,
          "To Number": contact.to_number,
          Status: getStatusLabel(contact.status),
          "Campaign Type": "API",
          "Send At": new Date(contact.send_at).toLocaleString("en-IN", {
            timeZone: "Asia/Kolkata",
            dateStyle: "medium",
            timeStyle: "medium",
          }),
        }));

        // Generate Excel file
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(formattedContacts);
        XLSX.utils.book_append_sheet(wb, ws, "Broadcast Contacts");

        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

        function s2ab(s) {
          setIsLoader(false);

          const buf = new ArrayBuffer(s.length);
          const view = new Uint8Array(buf);
          for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        }

        saveAs(
          new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
          `${campaignDetails?.campaign_name}.xlsx`
        );
      }
    } catch (error) {
      console.error("Error fetching broadcast contacts:", error);
      // Handle error, show user-friendly message or log the error for debugging
    }
  };
  return (
    <div>
      <CampaignHeader campaignDetails={campaignDetails} />
      <div className="contacts-main-layout">
        <ApiOverView />

        <div className="main-headings-broadcast mt-20 pt-10">Contacts</div>

        <div className="w-100 flex-row space-between search-main-container mt-10 mb-10">
          <div className="api-search_container">
            <SearchBar
              searchKey={isFilter}
              setSearchKey={setIsFilter}
              onChange={(text) => templateSearch(text)}
              expandable={true}
            />
          </div>
          <div className="flex align-center">
            <ContactViewFilter
              onFilterChange={onFilterChange}
              selectedStatus={selectedStatus}
            />

            <div
            // className={`${
            //   campaignDetails?.status !== 12 && "download_disabled"
            // }`}
            >
              {isLoader ? (
                <Loader Width={20} Height={20} loaderBg="white" />
              ) : (
                <img
                  src={ICONS?.Download}
                  alt="download"
                  className="cursor-pointer"
                  onClick={() => downloadBroadcastContacts(contactList)}
                />
              )}
            </div>
          </div>
        </div>
        {contactList?.length > 0 ? (
          <>
            <ApiContactTable
              contactList={contactList}
              isFilter={isFilter}
              selectedStatus={selectedStatus}
              isLoading={isLoading}
            />
            <div className="flex-row flex-start w-100 my-4 pl-10">
              <Paginations
                handlePageChange={handlePageChange}
                page={page}
                setPage={setPage}
                count={count}
                loadNextPage={loadNextPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                handlePageChangeRow={handlePageChangeRow}
                handleContactClick1={handleContactClick1}
                contactDetails={contactDetails}
              />
            </div>
          </>
        ) : (
          <div style={{ background: "#f0f1f2" }}>
            <Skeleton active loading={isLoading} rootClassName="w-[98.5%]">
              <ContactSelectView />
            </Skeleton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactApi;
