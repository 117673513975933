import React from 'react';

import IconContainer from 'assets/customSVG/sequence/actions/IconContainer';
import FullArrow from 'assets/customSVG/FullArrow';

export default function TriggerListContainer({ triggers, onTriggerClick }) {
  return (
    <div className='w-full h-auto flex flex-col items-center gap-2.5 p-4 py-2.5'>
      {triggers?.map((trigger) => {
        const Icon = trigger?.icon;
        return (
          <div
            key={trigger?.id}
            className='w-full h-12 flex items-center justify-between gap-2.5 border border-[var(--border-50)] rounded-lg p-2.5 cursor-pointer'
            onClick={() => {
              if (onTriggerClick) onTriggerClick(trigger);
            }}
          >
            <div className='w-full flex items-center gap-2.5'>
              {Icon && (
                <IconContainer color={trigger?.bg}>
                  <Icon />
                </IconContainer>
              )}
              <p className='text-sm weight-medium text-[var(--contentText)]'>
                {trigger?.label}
              </p>
            </div>
            <div className='size-6 flex items-center justify-center'>
              <FullArrow width={16} height={16} color={'#898E99'} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
