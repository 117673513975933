import React from "react";

// constants
import { INITIAL_TRIGGER_DATA } from "../../../constants/sequence";

// helpers
import { isObjectEmpty } from "../../../helpers";

// components
import BroadcastTrigger from "../edit/broadcast";
import PreviewNodeContainer from "../preview/PreviewNodeContainer";
import TriggerList from "../triggers/TriggerList";
import Node from "./Node";
import NodeDetails from "./NodeDetails";
import TriggerModel from "../triggers/TriggerModel";
import NodeEditMode from "../edit/Index";

const NodeElements = ({ nodes, handleMouseDown, onChange }) => {
  const onSelectTrigger = ({ id, node, data }) => {
    onChange({
      type: "UPDATE_NODE",
      id: id,
      data: {
        ...node,
        ...INITIAL_TRIGGER_DATA[data?.label?.toLowerCase()],
        data: {},
        settings: {
          ...node.settings,
          defaults: {
            ...node.settings.defaults,
            isEditMode: true,
          },
        },
      },
    });
  };

  const onTriggerClick = ({ data, node }) => {
    onChange({
      type: "UPDATE_NODE",
      id: node?.id,
      data: {
        label: "Whatsapp",
        component_type: data?.key,
        data: {},
        // data: demo_data[data?.key?.toUpperCase()],
      },
    });

    onChange({
      type: "UPDATE_NODE",
      id: node?.id,
      primaryKey: "settings",
      secondaryKey: "defaults",
      key: "isEditMode",
      data: true,
    });
  };

  return nodes?.map((node) => {
    return (
      <div
        key={node?.id}
        className="relative w-24 h-12 text-white flex items-center cursor-grab"
        style={{
          left: node?.coordinates?.x,
          top: node?.coordinates?.y,
        }}
      >
        <div
          className="w-24 h-12 text-white flex items-center justify-center z-20"
          onMouseDown={(e) => handleMouseDown(e, node?.id)}
        >
          <Node
            id={node?.id}
            node_type={node?.node_type}
            is_trigger={node?.is_trigger}
            component_type={node?.component_type}
            label={node?.label}
            order={node?.order}
            data={node.data}
            settings={node?.settings}
            isEditMode={node?.isEditMode}
            status={node?.status}
            onChange={onChange}
          />
        </div>
        <div className="absolute left-5">
          {node?.data &&
          !isObjectEmpty(node?.data) &&
          !node?.settings?.defaults?.isEditMode ? (
            <div
              className="relative"
              style={{
                left: 100,
                top: 0,
              }}
            >
              <PreviewNodeContainer node={node}>
                <NodeDetails node={node} onChange={onChange} />
              </PreviewNodeContainer>
            </div>
          ) : (
            <div className="w-full min-w-[400px] text-black relative left-20">
              {node?.settings?.defaults?.isEditMode ? (
                <NodeEditMode
                  node={node}
                  onChange={onChange}
                  onTriggerClick={onTriggerClick}
                />
              ) : node?.is_trigger ? (
                <div className="relative w-full min-w-96 cursor-default">
                  <TriggerList
                    onSelectTrigger={(data) =>
                      onSelectTrigger({ id: node?.id, node, data })
                    }
                  />
                </div>
              ) : (
                <div className="">
                  <TriggerModel
                    type="tab"
                    onTriggerClick={(data) => {
                      onTriggerClick({ data, node });
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  });
};

export default NodeElements;
