import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, Popover } from "antd";

// constants
import { CREATION_TYPES, SORT, STATUS, TYPES } from "../constants/Execution";

// helpers
import { cn } from "../../../helper/cn";

// hooks
import useToggle from "../../../hooks/useToggle";
import { useSearch } from "../../../hooks/useSearch";

// redux
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";

// components
import SearchBar from "../../../components/commonComponents/searchBar";
import ClearFilters from "../../../assets/customSVG/ClearFilters";
import CheckBoxSqure from "../../../components/commonComponents/checkbox/CheckBoxSqure";
import List from "../components/executions/List";
import Header from "../../../components/commonComponents/modals/Header";
import { broadCastListApi } from "../api";
import { reset } from "../../../reduxToolkit/SequenceSlice";

let page = 1;
let limit = 10;
let count = 0;

export default function Execution() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const broadcastdata = location?.state?.data;

  const { currentBrand } = useAspSelector((state) => state.app);
  const dispatch = useAspDispatch();

  const [list, setList] = useState(null);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isFilters, setIsFilters] = useToggle(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useToggle(false);

  const [sort, setSort] = useState(null);
  const [sortLabel, setSortLabel] = useState("");

  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const { searchKey, setSearchKey, onChange: onSearchChange } = useSearch("");
  const [selectedTypes, setSelectedTypes] = useState(null);
  const [statusTrue, setStatusTrue] = useState(false);

  const [selectedSortId, setSelectedSortId] = useState(null);
  const [selectedGoalIds, setSelectedGoalIds] = useState(null);
  const [selectedStatusId, setSelectedStatusId] = useState(null);

  const [isCreate, setIsCreate] = useToggle(false);

  let data = {};
  sort?.map((s) => {
    if (s?.id === 1) {
      s?.options?.map((o) => {
        if (o?.isSelected) data["sort_by"] = o?.status;
      });
    }

    if (s?.id === 2) {
      s?.options?.map((o) => {
        if (o?.isSelected) data["order_by"] = o?.status;
      });
    }
  });

  useEffect(() => {
    setType(TYPES);
    setStatus(STATUS);
    setSort(SORT);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const payload = {
        page: page,
        limit: limit,
        name: searchKey,
        status: getSelectedStatus(),
      };
      getCampaignList(false, currentBrand?.brand_id, id, payload);
    }, 500);

    return () => clearTimeout(timeout);
  }, [searchKey]);

  useEffect(() => {
    const payload = {
      page: page,
      limit: limit,
      name: searchKey,
      status: getSelectedStatus(),
    };
    getCampaignList(false, currentBrand?.brand_id, id, payload);
  }, [currentBrand, status]);

  useEffect(() => {
    getSelectedTypesId();
  }, [type]);

  useEffect(() => {
    getSelectedStatusId();
  }, [status]);

  useEffect(() => {
    getSelectedSortId();
  }, [sort]);

  // sort filter
  const onSortChange = (id, data) => {
    const res = sort?.map((s) => {
      if (s.id === id) {
        const updatedOptions = s?.options?.map((o) => {
          if (o?.id === data?.id) return { ...o, isSelected: true };
          return { ...o, isSelected: false };
        });
        return { ...s, options: updatedOptions };
      } else {
        const updatedOptions = s?.options?.map((o) => {
          return { ...o, isSelected: false };
        });
        return { ...s, options: updatedOptions };
      }
    });

    const selectedOptions = res
      .flatMap((s) => s?.options)
      .filter((option) => option?.isSelected);
    setSortLabel(selectedOptions?.[0]?.label);

    setSort(res);
  };

  const clearSort = () => {
    const res = sort?.map((s) => {
      return { ...s, isSelected: false };
    });
    setSort(res);
  };

  const getSelectedSortId = useCallback(
    () => setSelectedSortId(sort?.filter((t) => t?.isSelected)),
    [sort]
  );

  // type filter
  const onTypeChange = (data) => {
    const res = type?.map((t) => {
      if (t?.id === data?.id) return { ...t, isSelected: !t?.isSelected };
      return t;
    });
    const filters = res?.filter((r) => r?.isSelected === true && r.id);
    const getFilteresId = filters?.map((f) => f?.id);
    setSelectedTypes(getFilteresId);
    setType(res);
  };

  const clearType = () => {
    const res = type?.map((t) => {
      return { ...t, isSelected: false };
    });
    setType(res);
    setSelectedTypes(null);
  };

  const getSelectedTypesId = useCallback(
    () => setSelectedGoalIds(type?.filter((t) => t?.isSelected)),
    [type]
  );

  // status filter
  const onStatusChange = (data) => {
    const res = status?.map((s) => {
      if (s?.id === data?.id) return { ...s, isSelected: !s?.isSelected };
      return s;
    });
    setStatus(res);
    setStatusTrue(true);
  };

  const clearStatus = () => {
    const res = status?.map((t) => {
      return { ...t, isSelected: false };
    });
    setStatus(res);
    setStatusTrue(true);
  };

  const getSelectedStatus = useCallback(() => {
    const res = status?.filter((s) => s?.isSelected === true);
    const ids = res?.map((r) => r?.status);
    return ids?.length === STATUS?.length ? null : ids;
  }, [status]);

  const getSelectedStatusId = useCallback(
    () => setSelectedStatusId(status?.filter((t) => t?.isSelected)),
    [status]
  );

  // modals
  const onCreateModal = (open) => setIsCreate(open);

  const handleCancel = () => setIsCreate(false);

  // API
  const getCampaignList = async (loading, brand_id, id, payload) => {
    // if (loading) setIsLoading(true);
    // else setIsFilters(true);
    setIsLoadingSkeleton(true);

    try {
      const res = await broadCastListApi(brand_id, id, payload);
      setIsLoadingSkeleton(false);

      count = res?.data?.broadcast_count;
      setList(res?.data?.broadcasts);
    } catch (error) {
      console.warn(error);
    } finally {
      // if (loading) setIsLoading(false);
      // else setIsFilters(false);
      setIsLoadingSkeleton(false);
    }
  };

  const loadNextPage = async () => {
    limit += 10;
    const payload = {
      page: page,
      limit: limit,
      name: searchKey,
      status: getSelectedStatus(),
    };
    await getCampaignList(isLoading, currentBrand?.brand_id, id, payload);
  };

  const allowAllClear = useCallback(() => {
    return selectedGoalIds?.length > 0 || selectedStatusId?.length > 0;
  }, [selectedGoalIds, selectedStatusId]);

  return (
    <div className="w-full h-full bg-white rounded-t-2xl px-5">
      <div className="w-full py-5 flex items-center justify-between gap-5 border-b border-[var(--border-50)]">
        <h4 className="new-header3">
          {list?.length > 0
            ? `${list?.length} Execution List`
            : `0 Execution List`}
        </h4>
        <div className="flex items-center gap-5">
          <div>
            <SearchBar
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              onChange={onSearchChange}
              loading={false}
              expandable={true}
            />
          </div>
          <Popover
            trigger={["click"]}
            arrow={false}
            placement="bottom"
            content={
              <div className="min-w-56 px-3.5 py-2.5">
                <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                  <div className="text-[var(--font-400)]">Type</div>
                  <div
                    className={cn(
                      "text-[#F96056]",
                      selectedGoalIds?.length > 0
                        ? "cursor-pointer opacity-100"
                        : "cursor-not-allowed opacity-50"
                    )}
                    onClick={() => {
                      if (selectedGoalIds?.length > 0) clearType();
                    }}
                  >
                    Clear
                  </div>
                </div>
                {type?.map((t) => {
                  return (
                    <div
                      key={t?.id}
                      className={cn(
                        "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer",
                        { "cursor-not-allowed": t?.id === 2 } // conditionally add 'cursor-not-allowed' class
                      )}
                      onClick={() => {
                        if (t?.id === 1) onTypeChange(t);
                      }}
                    >
                      <CheckBoxSqure
                        value={t?.isSelected}
                        onChange={(e) => {}}
                        className={`size-4 ${
                          t?.id === 2 ?? "cursor-not-allowed"
                        }`}
                        disabled={t?.id === 2 ?? "cursor-not-allowed"}
                      />
                      {t?.icon && <img src={t?.icon} alt={t?.label} />}
                      <p className={t?.id === 2 ? "opacity-50" : ""}>
                        {t?.label}
                      </p>
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-5 py-1.5 rounded-lg cursor-pointer">
              <div className="campaign-filters flex item-center">
                Type
                <div
                  className={`ml-1 ${
                    selectedGoalIds?.length === TYPES?.length ||
                    selectedGoalIds?.length === 0
                      ? ""
                      : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
                  }`}
                >
                  {selectedGoalIds?.length === TYPES?.length ||
                  selectedGoalIds?.length === 0
                    ? "(All)"
                    : selectedGoalIds?.length > 0 &&
                      selectedGoalIds?.length !== TYPES?.length
                    ? selectedGoalIds?.length
                    : type?.filter((t) => t?.isSelected)?.[0]?.label}
                </div>
              </div>
              <div
                className={cn(
                  "transition-transform duration-200 ease-linear",
                  false ? "-rotate-180" : "rotate-0",
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                ></div>
              </div>
            </div>
          </Popover>
          <Popover
            trigger={["click"]}
            arrow={false}
            placement="bottom"
            content={
              <div className="min-w-56 px-3.5 py-2.5">
                <div className="w-full h-8 flex items-center justify-between gap-5 py-2.5 text-sm weight-medium">
                  <div className="text-[var(--font-400)]">Status</div>
                  <div
                    className={cn(
                      "text-[#F96056]",
                      selectedStatusId?.length > 0
                        ? "cursor-pointer opacity-100"
                        : "cursor-not-allowed opacity-50"
                    )}
                    onClick={() => {
                      if (selectedStatusId?.length > 0) clearStatus();
                    }}
                  >
                    Clear
                  </div>
                </div>
                {status?.map((s) => {
                  return (
                    <div
                      key={s?.id}
                      className={cn(
                        "w-full h-9 flex items-center gap-2.5 py-1 cursor-pointer"
                      )}
                      onClick={() => {
                        onStatusChange(s);
                      }}
                    >
                      <CheckBoxSqure
                        value={s?.isSelected}
                        onChange={(e) => {}}
                        className="size-4"
                      />
                      {s?.icon && <img src={s?.icon} alt={s.label} />}
                      <p>{s?.label}</p>
                    </div>
                  );
                })}
              </div>
            }
          >
            <div className="group/goal flex items-center gap-2.5 bg-[var(--BG-25)] px-5 py-1.5 rounded-lg cursor-pointer">
              <div className="campaign-filters flex items-center">
                Status
                <div
                  className={`ml-1 ${
                    selectedStatusId?.length === STATUS?.length ||
                    selectedStatusId?.length === 0
                      ? ""
                      : "rounded-full bg-[#6940F2] text-white text-[0.812rem] !w-[1.3vw] !h-[1.3vw] flex justify-center items-center"
                  }`}
                >
                  {selectedStatusId?.length === STATUS?.length ||
                  selectedStatusId?.length === 0
                    ? "(All)"
                    : selectedStatusId?.length}
                </div>
              </div>
              <div
                className={cn(
                  "transition-transform duration-200 ease-linear ",
                  false ? "-rotate-180" : "rotate-0",
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                ></div>
              </div>
            </div>
          </Popover>
          {/* <Popover
            trigger={["click"]}
            placement="bottom"
            arrow={false}
            content={
              <div className="min-w-56 px-3.5 py-2.5">
                {sort?.map((section) => (
                  <div key={section?.heading}>
                    <h3 className="new-header1 mb-2 pt-5">{section.heading}</h3>
                    {section?.options?.map((s) => (
                      <div
                        key={s.id}
                        className={cn(
                          "w-full h-9 flex items-center gap-2.5 py-1 ",
                          !["all-campaigns"]?.includes(goalType) &&
                            !s?.isSelected
                            ? "cursor-not-allowed opacity-50"
                            : "cursor-pointer opacity-100"
                        )}
                        onClick={() => {
                          if (["all-campaigns"]?.includes(goalType)) {
                            onSortChange(section?.id, s);
                          }
                        }}
                      >
                        <div className="w-5 h-9 flex items-center justify-center">
                          <RadioButton
                            value={s?.isSelected}
                            selected={s?.isSelected}
                            onChange={(e) => {}}
                            rootClassName={
                              s?.isSelected && "size-3.5 new-radio"
                            }
                          />
                        </div>
                        {s?.icon && <img src={s?.icon} alt={s.label} />}
                        <p>{s?.label}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            }
          >
            <div className="group/sort flex items-center gap-2.5 bg-[var(--BG-25)] px-3 py-1.5 rounded-[0.4vw] cursor-pointer">
              <div className="campaign-filters">
                {sortLabel || "Newest First"}
              </div>
              <div
                className={cn(
                  "transition-transform duration-200 ease-linear",
                  // group-hover/sort:-rotate-180,
                  false ? "-rotate-180" : "rotate-0",
                  isLoading ? "cursor-not-allowed" : "cursor-pointer"
                )}
              >
                <div
                  className={`border-[0.4vw] rounded-t-[0.2vw] border-transparent border-t-[#777] translate-y-[25%]`}
                ></div>
              </div>
            </div>
          </Popover> */}
          <div
            className={cn(
              "group/status flex items-center gap-2.5 hover:bg-[var(--BG-25)] px-2.5 py-2.5 rounded-lg",
              allowAllClear()
                ? "cursor-pointer opacity-100"
                : "cursor-not-allowed opacity-50"
            )}
            onClick={() => {
              if (allowAllClear()) {
                clearType();
                clearStatus();
              }
            }}
          >
            <ClearFilters
              width={16}
              height={16}
              color={allowAllClear() ? "var(--contentText)" : "var(--font-600)"}
            />
          </div>
          {broadcastdata?.status !== 12 && (
            <div
              className={cn(
                "px-5 py-1.5 bg-[var(--new-primary)] text-white border border-[var(--new-primary)] rounded-lg cursor-pointer"
              )}
              onClick={() => {
                onCreateModal(true);
              }}
            >
              Create New
            </div>
          )}
        </div>
      </div>

      <List
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        clearType={clearType}
        clearStatus={clearStatus}
        selectedTypes={selectedTypes}
        statusTrue={statusTrue}
        isLoading={isLoading}
        list={list}
        count={count}
        loadNextPage={loadNextPage}
        isLoadingSkeleton={isLoadingSkeleton}
        onCreate={() => onCreateModal(true)}
      />

      <Modal
        open={isCreate}
        afterOpenChange={(open) => {
          onCreateModal(open);
        }}
        // onCancel={handleCancel}
        width={600}
        centered
        footer={null}
        closable={false}
        wrapClassName={"bg-[#00000095]"}
        destroyOnClose={true}
      >
        <Header
          title="Create New"
          subTitleStyle={"text-sm"}
          titleStyle="text-lg"
          titleSize="none"
          subTitleSize="none"
          handleCancel={handleCancel}
        />
        <div className="w-full h-full py-5 px-5 flex-column gap-5">
          {CREATION_TYPES?.map((t) => {
            return (
              <div
                key={t?.id}
                className={cn(
                  "group w-100  border border-[var(--border-50)] p-1 rounded-lg flex-row align-center gap-5 transition-all ease-linear duration-150 cursor-pointer hover:border-[#6940F2] hover:shadow-[0px_4px_30px_0px_#2D30361A]",
                  t?.disabled
                    ? "cursor-not-allowed opacity-50"
                    : "cursor-pointer"
                )}
                onClick={() => {
                  if (!t?.disabled && t.key !== "sequence") {
                    navigate(`/user/campaign/time_specified/settings/${id}`, {
                      state: { data: broadcastdata },
                    });
                  }

                  if (!t.disabled && t.key === "sequence") {
                    dispatch(reset());
                    navigate(`/user/campaigns/:type/${id}/sequence`, {
                      state: { data: broadcastdata },
                    });
                  }
                }}
              >
                <img
                  src={t?.icon}
                  alt={`${t?.label} image`}
                  className="w-auto h-20"
                />
                <div className="flex-column">
                  <p className="popover-title">{t?.label}</p>
                  <p className="popover-content">{t?.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}
