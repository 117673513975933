import React from 'react';

const Meta = ({ width = 22, height = 22, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_18989_125722)'>
        <path
          d='M2.53326 13.2376C2.53326 14.0447 2.71429 14.6644 2.9509 15.0392C3.26112 15.5303 3.72383 15.7383 4.19555 15.7383C4.80398 15.7383 5.36058 15.5905 6.43322 14.1388C7.29253 12.9751 8.30508 11.3418 8.98637 10.3178L10.1401 8.58305C10.9416 7.37825 11.8693 6.03894 12.9329 5.13112C13.8012 4.39016 14.7379 3.97852 15.6806 3.97852C17.2633 3.97852 18.7708 4.87605 19.9246 6.55938C21.1873 8.40295 21.8002 10.7251 21.8002 13.1214C21.8002 14.546 21.5133 15.5928 21.025 16.4197C20.5533 17.2195 19.6339 18.0185 18.0873 18.0185V15.7383C19.4115 15.7383 19.7421 14.5475 19.7421 13.1846C19.7421 11.2426 19.2793 9.08731 18.26 7.54732C17.5367 6.45499 16.5992 5.78754 15.5679 5.78754C14.4525 5.78754 13.5548 6.61083 12.5461 8.07878C12.0097 8.8587 11.4591 9.80916 10.841 10.8816L10.1604 12.0614C8.79332 14.4335 8.44704 14.9738 7.7635 15.8655C6.56542 17.4268 5.54236 18.0185 4.19555 18.0185C2.59786 18.0185 1.58756 17.3415 0.961859 16.3212C0.451079 15.4898 0.200195 14.399 0.200195 13.156L2.53326 13.2376Z'
          fill={color}
        />
        <path
          d='M2.04067 6.72036C3.11031 5.10686 4.65391 3.97852 6.42437 3.97852C7.44968 3.97852 8.46899 4.27549 9.53337 5.12597C10.6976 6.05585 11.9385 7.58702 13.4867 10.1105L14.0418 11.0162C15.3818 13.2008 16.1442 14.3247 16.5904 14.8547C17.1643 15.5354 17.5652 15.7383 18.0873 15.7383C19.4115 15.7383 19.7421 14.5475 19.7421 13.1846L21.8002 13.1214C21.8002 14.546 21.5133 15.5928 21.025 16.4197C20.5533 17.2195 19.6339 18.0185 18.0873 18.0185C17.1258 18.0185 16.2749 17.8142 15.333 16.9446C14.6089 16.2771 13.7623 15.0914 13.1111 14.0256L11.1739 10.8588C10.2019 9.26961 9.31028 8.08466 8.79424 7.54806C8.23914 6.97102 7.52555 6.27417 6.38681 6.27417C5.46515 6.27417 4.68246 6.90707 4.02746 7.87517L2.04067 6.72036Z'
          fill={color}
        />
        <path
          d='M6.38681 6.27417C5.46515 6.27417 4.68246 6.90707 4.02746 7.87517C3.10129 9.24315 2.53326 11.2808 2.53326 13.2376C2.53326 14.0447 2.71429 14.6644 2.9509 15.0392L0.961859 16.3212C0.451079 15.4898 0.200195 14.399 0.200195 13.156C0.200195 10.8956 0.835079 8.53968 2.04067 6.72036C3.11031 5.10686 4.65391 3.97852 6.42437 3.97852L6.38681 6.27417Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_18989_125722'>
          <rect
            width='21.6'
            height='21.6'
            fill={color}
            transform='translate(0.200195 0.199219)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Meta;
