import React from "react";

// helper
import { dateConverter } from "../../../../helper/dateConverter";
import { replaceFormating } from "../../helper";
import ImageMessagePreview from "./ImageMessagePreview";
import VideoMessagePreview from "./VideoMessagePreview";
import DocumentMessagePreview from "./DocumentMessagePreview";
import AudioMessagePreview from "./AudioMessagePreview";

export default function LeftSideConversationCard({ data }) {
  const d = dateConverter(data?.created_at, false);

  const parseObj = (obj) => {
    if (typeof obj === "string") return JSON.parse(obj);
    return "";
  };

  const reply_message =
    data?.reply_message &&
    data?.reply_message?.template?.map((item) => item?.text);
  const reply_message_image =
    data?.reply_message && data?.reply_message?.template?.map((item) => item);
  const previewContent = reply_message_image?.map((item) => item);

  return (
    <div className="w-full min-h-[3.5vh] h-full">
      <div className="h-fit bg-white b-radius-6 relative w-fit max-w-[25vw] p-[0.8vw]">
        {/* <div className="w-0 h-0 bg-transparent  border-l-[0.5vw] border-r-[0.5vw] border-b-[1vw] border-l-transparent border-r-transparent border-b-[white] absolute -left-[0.5vw] top-0 rotate-180" /> */}

        {["text", "image", "video", "document", "audio", "button"].includes(
          data?.message?.type
        ) ? (
          data?.message?.type === "image" ? (
            <ImageMessagePreview
              content={reply_message}
              urls={
                previewContent &&
                previewContent?.map((item) => {
                  return (
                    item?.format === "IMAGE" &&
                    item?.example?.header_handle?.[0]
                  );
                })
              }
              url={data?.message?.image?.link}
              caption={data?.message?.image?.caption}
              messageId={data}
              dateTime={d?.timeWithMeridiem}
            />
          ) : data?.message?.type === "video" ? (
            <VideoMessagePreview
              content={reply_message}
              urls={
                previewContent &&
                previewContent?.map((item) => {
                  return (
                    item?.format === "VIDEO" &&
                    item?.example?.header_handle?.[0]
                  );
                })
              }
              url={data?.message?.video?.link}
              caption={data?.message?.video?.caption}
              dateTime={d?.timeWithMeridiem}
            />
          ) : data?.message?.type === "document" ? (
            <DocumentMessagePreview
              content={reply_message}
              urls={
                previewContent &&
                previewContent?.map((item) => {
                  return (
                    item?.format === "DOCUMENT" &&
                    item?.example?.header_handle?.[0]
                  );
                })
              }
              url={data?.message?.document?.link}
              dateTime={d?.timeWithMeridiem}
              // url={`https://docs.google.com/viewer?url=${encodeURIComponent(
              //   data?.message?.document?.link
              // )}&embedded=true`}
              caption={data?.message?.document?.caption}
              fileName={data?.message?.document?.filename}
            />
          ) : data?.message?.type === "audio" ? (
            <AudioMessagePreview
              content={reply_message}
              urls={
                previewContent &&
                previewContent?.map((item) => {
                  return (
                    item?.format === "AUDIO" &&
                    item?.example?.header_handle?.[0]
                  );
                })
              }
              url={data?.message?.audio?.link}
              caption={data?.message?.audio?.caption}
              dateTime={d?.timeWithMeridiem}
            />
          ) : data?.message?.type !== "button" ? (
            <div className="">
              {(reply_message || data?.reply_message?.text?.body) && (
                <div className="w-full flex-row align-center bg-[var(--channelButton)] inbox12px-400 text-[var(--font-600)] b-radius-6 mb-10 ">
                  <div className=" h-[6vh] bg-[#6564CA] w-1" />
                  <div className="flex flex-1 items-center justify-between">
                    {reply_message && (
                      <div className=" max-w-[20ch] truncate ml-10">
                        {reply_message || data?.reply_message?.text?.body}
                      </div>
                    )}
                    {previewContent && (
                      <div>
                        {previewContent?.map((item) => {
                          return (
                            <div key={item?.id}>
                              {item?.format === "IMAGE" && (
                                <img
                                  src={item?.example?.header_handle?.[0]}
                                  className="w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10"
                                />
                              )}
                              {item?.format === "VIDEO" && (
                                <video className="w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10">
                                  <source
                                    src={item?.example?.header_handle?.[0]}
                                    type="video/mp4"
                                  />
                                </video>
                              )}

                              {item?.format === "DOCUMENT" && (
                                <iframe
                                  className="w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10"
                                  src={item?.example?.header_handle?.[0]}
                                ></iframe>
                              )}
                              {item?.format === "AUDIO" && (
                                <audio className="w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10">
                                  <source
                                    src={item?.example?.header_handle?.[0]}
                                    type="audio/mp3"
                                  />
                                </audio>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`w-full break-words h-full new-body-text  rounded-[0.6vw] rounded-tl-none`}
                // style={{ boxShadow: "0px 4px 16px 0px #0000000A" }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceFormating(data?.message?.text?.body),
                  }}
                ></div>
                <div className="flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw] ml-40">
                  <p>{d?.timeWithMeridiem}</p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div>
                {(reply_message?.[1] || data?.reply_message?.text?.body) && (
                  <div className="w-full flex-row align-center bg-[var(--channelButton)] inbox12px-400 text-[var(--font-600)] b-radius-6 mb-10 ">
                    <div className=" h-[6vh] bg-[#6564CA] w-1" />
                    <div className="flex flex-1 items-center justify-between">
                      {reply_message?.[1] && (
                        <div className=" max-w-[20ch] truncate ml-10">
                          {reply_message?.[1]}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="new-body-text  rounded-[0.6vw] rounded-tl-none ">
                {data?.message?.button?.text}
              </div>
              <div className="flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw] ml-40">
                <p>{d?.timeWithMeridiem}</p>
              </div>
            </>
          )
        ) : (
          <div
            className="bg-[#F4F6F8] p-[0.5vw] rounded-[0.6vw] rounded-tl-none"
            // style={{ boxShadow: "0px 4px 16px 0px #0000000A" }}
          >
            {data?.is_customer_msg === true &&
            Array.isArray(data?.message?.template || data?.message) ? (
              <> {/* <ConversationCard data={data} /> */}</>
            ) : (
              (data?.message?.text?.body || data?.message?.body) && (
                <div
                  className={`w-full new-body-text rounded-[0.6vw] rounded-tl-none py-[0vw]`}
                  dangerouslySetInnerHTML={{
                    __html: replaceFormating(
                      data?.message?.text?.body || data?.message?.body
                    ),
                  }}
                >
                  {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                  {/* {!Array?.isArray(data?.message) && parseObj(data?.message)?.body} */}
                </div>
              )
            )}
            <div className="flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw] ml-40">
              <p>{d?.timeWithMeridiem}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
