import React from "react";

export default function GoalTarget({ color, className }) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.36661 1.10054C5.82619 0.48777 6.74534 0.487771 7.20492 1.10054L11.8007 7.22826C12.3688 7.98568 11.8283 9.06657 10.8815 9.06657H1.68998C0.743197 9.06657 0.202755 7.98568 0.770825 7.22825L5.36661 1.10054Z"
        fill={color}
      />
    </svg>
  );
}
