import React from "react";

const AllConversations = ({ color, fill, stroke }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_18604_88478)">
          <path
            d="M7.08594 16.667C7.96125 17.3917 9.01469 17.8535 10.2222 17.9327C11.1736 17.9951 12.1669 17.995 13.1164 17.9327C13.4433 17.9112 13.7997 17.8342 14.1066 17.7095C14.448 17.5707 14.6189 17.5013 14.7057 17.5118C14.7924 17.5223 14.9184 17.614 15.1702 17.7972C15.6142 18.1203 16.1735 18.3524 17.003 18.3325C17.4224 18.3224 17.6322 18.3173 17.726 18.1594C17.8199 18.0014 17.703 17.7827 17.4691 17.3454C17.1448 16.7388 16.9393 16.0444 17.2507 15.488C17.787 14.6932 18.2425 13.7521 18.3091 12.7356C18.3449 12.1894 18.3449 11.6237 18.3091 11.0776C18.2648 10.4002 18.1004 9.75641 17.8369 9.16699"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.2888 14.5727C13.2518 14.3775 15.612 12.0061 15.8061 9.02899C15.8441 8.44641 15.8441 7.84308 15.8061 7.26048C15.612 4.2834 13.2518 1.912 10.2888 1.71687C9.27797 1.6503 8.22258 1.65043 7.21379 1.71687C4.25083 1.912 1.89068 4.2834 1.69647 7.26048C1.65847 7.84308 1.65847 8.44641 1.69647 9.02899C1.7672 10.1133 2.25124 11.1172 2.82107 11.965C3.15194 12.5584 2.93358 13.2992 2.58895 13.9462C2.34047 14.4127 2.21623 14.6459 2.31599 14.8144C2.41574 14.9829 2.63857 14.9883 3.08423 14.9991C3.96557 15.0203 4.55987 14.7727 5.03162 14.4282C5.29917 14.2327 5.43295 14.1349 5.52515 14.1237C5.61735 14.1125 5.7988 14.1865 6.16164 14.3346C6.48775 14.4677 6.8664 14.5497 7.21379 14.5727C8.22258 14.6391 9.27797 14.6392 10.2888 14.5727Z"
            stroke={color}
            stroke-width="1.67"
            stroke-linejoin="round"
            fill={fill}
          />
          <path
            d="M6.25 10.0003H11.25M6.25 6.66699H8.75"
            stroke={stroke}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
            // fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_18604_88478">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default AllConversations;
