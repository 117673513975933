import React, { useState } from "react";
import "./Index.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAspDispatch, useAspSelector } from "test/jest-redux-hooks";
import Chevron from "assets/customSVG/Chevron";
import { Modal } from "antd";
import NewAlert from "../../../components/commonComponents/modal/alert/NewAlert";
import { ICONS } from "../../../assets/icons";
import { draftApi } from "../../../modules/campaign/api/Api";
import { updateToggleToast } from "reduxToolkit/appSlice";

function Index({ list, activeNav = "", onSelect, LeftListClick }) {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const isSubmenuOpen = useAspSelector((state) => state.Channel.isSubmenuOpen);
  const { saveBroadcastDatas } = useAspSelector((state) => state.broadcast);
  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);
  const dispatch = useAspDispatch();
  const location = useLocation();

  const { id } = useParams();
  const header = id;

  const [toggle, setToggle] = useState(false);

  const discardCarousel = () => {
    if (saveBroadcastDatas?.broadcast_id !== null) {
      setDiscardAlertOpen(true);

      draftApi(saveBroadcastDatas).then((createCampaignResponse) => {
        if (createCampaignResponse?.status === 200) {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: "Broadcast saved successfully",
                status: "Success",
                duration: "",
              },
            ])
          );
          setDiscardAlertOpen(false);
          navigate(`/user/campaign/time_specified`);
        } else {
          setDiscardAlertOpen(false);
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content:
                  createCampaignResponse?.response?.data?.error ||
                  "Unable to save Broadcast",
                status: "Error",
                duration: "",
              },
            ])
          );
        }
      });
    }
  };

  const handleItemClick = (item) => {
    if (item?.subMenu) {
      onSelect(item);
      item.link && navigate(item.link);

      if (selectedItem === item) {
        setSelectedItem(null);
        setToggle(true);
      } else {
        setSelectedItem(item);
        setToggle(false);
      }
    } else {
      if (
        location?.pathname ===
          `/user/campaign/time_specified/settings/${header}` &&
        saveBroadcastDatas?.broadcast_id !== null
      ) {
        setDiscardAlertOpen(true);
      } else {
        item.link && navigate(item.link);
        onSelect(item);
      }

      setSelectedItem(item);
      setToggle(false);
    }
  };

  return (
    <div className="leftListLayout">
      {list?.map((item) => (
        <div className="flex-column justify-center" key={item?.id}>
          <div
            className={`listText flex-row align-center space-between ${
              activeNav === item?.name ? "active-listText" : ""
            }`}
            onClick={() => handleItemClick(item)}
            data-testId={LeftListClick}
          >
            <div className="flex-row align-center">
              <span> {item?.name}</span>
            </div>
            {isSubmenuOpen === false &&
              item?.icon1 &&
              currentBrand?.is_whatsapp_connected === true && (
                <div onClick={() => setToggle(!toggle)}>
                  <Chevron
                    className={`rotate-img ${toggle ? "rotate-img1" : ""}`}
                    color={activeNav === item?.name ? "white" : "#0ACD95"}
                  />
                </div>
              )}
          </div>

          {isSubmenuOpen === false &&
            !toggle &&
            currentBrand?.is_whatsapp_connected === true && (
              <div className="submenu-container flex-column ">
                {item?.subMenu?.map((subItem) => (
                  <span
                    className={`listText1 flex-row align-center space-between pointer ${
                      activeNav === subItem?.name ? "active-listText" : ""
                    }`}
                    key={subItem?.id}
                    onClick={() => handleItemClick(subItem)}
                  >
                    {subItem?.name}
                  </span>
                ))}
              </div>
            )}
        </div>
      ))}

      <Modal
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>What would you like to do with the broadcast?</p>
            </div>
          }
          noName="Continue Editing"
          yesName="Draft"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel(header);
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default Index;
