import AspApi from "api/ApiConfig";

export const createCampaignApi = async (brandId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/campaigns?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const updateCampaignApi = async (id, brandId, payLoad) => {
  const res = await AspApi.put(
    `/api/v1/campaigns/${id}?brand_id=${brandId}`,
    payLoad
  );
  return res;
};

export const campaignDelete = async (id, brandId) => {
  const res = await AspApi.delete(
    `/api/v1/campaigns/${id}?brand_id=${brandId}`
  );
  return res;
};

// Campaign Overview

export const getCampaignOverviewCount = async (
  campaignId,
  brandId,
  startDate,
  endDate
) => {
  const res = await AspApi.get(
    `/api/v1/campaign_utils/${campaignId}/campaign_metrics?brand_id=${brandId}&start_date=${startDate}&end_date=${endDate}`
  );
  return res;
};

export const getCampaignPerformanceCount = async (
  campaignId,
  brandId,
  query
) => {
  // const res = await AspApi.get(
  //   `/api/v1/campaign_utils/${campaignId}/campaign_performence_metrics?brand_id=${brandId}&start_date=${startDate}&end_date=${endDate}`
  // );
  let qpm = [];
  if (query?.startDate) qpm.push(`start_date=${query?.startDate}`);
  if (query?.endDate) qpm.push(`end_date=${query?.endDate}`);
  if (query?.filter_action) qpm.push(`filter_action=${query?.filter_action}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/campaign_utils/${campaignId}/campaign_performance_metrics?brand_id=${brandId}&${qpmString}`
  );

  return res;
};

export const getGoalInfoCount = async (
  campaignId,
  brandId,
  startDate,
  endDate
) => {
  const res = await AspApi.get(
    `/api/v1/campaign_utils/${campaignId}/campaign_goal_info?brand_id=${brandId}&start_date=${startDate}&end_date=${endDate}`
  );
  return res;
};

export const getBroadcastPerformanceCount = async (
  campaignId,
  brandId,
  recent_broadcast_count
) => {
  const res = await AspApi.get(
    `/api/v1/campaign_utils/${campaignId}/broadcast_performance?brand_id=${brandId}&recent_broadcast_count=${recent_broadcast_count}`
  );
  return res;
};

// BROADCAST API

export const broadCastListApi = async (brandId, campaignId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/campaign_broadcast/broadcasts?brand_id=${brandId}&campaign_id=${campaignId}&${qpmString}`
  );
  return res;
};
