import React, { useEffect, useState } from "react";

// utils
import "../styles/inbox.css";
import { useNavigate } from "react-router-dom";

// assets
import noData from "../../../assets/images/inbox/noData.svg";

// API
import { inboxListCounts, whatsappBusinessNumbers } from "../api/Api";

// hooks
import useToggle from "hooks/useToggle";

// redux
import { useAspSelector } from "../../../test/jest-redux-hooks";

// components
import SideBar from "../components/sidebar";
import Conversation from "../components/conversation/index";
import NoData from "../../../components/commonComponents/Nodata/index";

const Inbox = () => {
  const { currentBrand } = useAspSelector((state) => state?.app);

  const navigate = useNavigate();

  // loading
  const [numberListLoading, setNumberListLoading] = useToggle(false);
  const [conversationListLoading, setConversationListLoading] =
    useToggle(false);
  const [activeTabName, setActiveTabName] = useState("All Conversations");
  const [activeTabItem, setActiveTabItem] = useState("");

  // phone numbers list
  const [numbersList, setNumbersList] = useState(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [listCounts, setListCounts] = useState(null);

  const inboxCounts = () => {
    const brandId = currentBrand?.brand_id;

    const payLoad = {
      brand_id: brandId,
    };

    if (brandId) {
      inboxListCounts(payLoad?.brand_id).then((res) => {
        if (res && res?.status === 200) {
          setListCounts(res?.data);
        }
      });
    }
  };

  useEffect(() => {
    const intervel = setInterval(() => {
      inboxCounts(currentBrand?.brand_id);
    }, [4000]);

    return () => clearInterval(intervel);
  }, [currentBrand]);

  useEffect(() => {
    fetchWhatsappBusinessNumbers(currentBrand?.brand_id);
  }, []);

  const handleSelect = (item) => setSelectedPhoneNumber(item);

  // fetch whatsapp business numbers
  const fetchWhatsappBusinessNumbers = (brandId) => {
    if (brandId) {
      setNumberListLoading(true);

      whatsappBusinessNumbers(brandId)
        .then((res) => {
          res && res.data?.wapp_business_num?.length > 0
            ? setNumbersList(res?.data?.wapp_business_num)
            : setNumbersList([]);
        })
        .finally(() => {
          setNumberListLoading(false);
        });
    } else {
      setNumbersList([]);
    }
  };

  const handleClick = () => {
    setSelectedRole(numbersList);
  };

  if (!numberListLoading && numbersList?.length === 0)
    return (
      <div className="w-full h-full flex-center flex-col">
        <NoData
          icon={noData}
          title="No whatsapp numbers found."
          content={
            <>
              You have not connected any account yet,
              <br /> click on the button to get started.
            </>
          }
          rootClassName="h-auto"
          iconStyle={"w-[8vw]"}
          style={"max-w-[20vw]"}
        />
        <button
          className="bg-[var(--primary)] text-[var(--white)] font-normal weight-bold px-[0.85vw] py-[0.5vw] rounded-[0.6vw] mt-[0.8vw]"
          onClick={() => {
            navigate("/user/channels/whatsapp");
          }}
        >
          Connect WhatsApp
        </button>
      </div>
    );

  return (
    <div className="flex-row w-full h-full">
      <>
        <SideBar
          loading={numberListLoading}
          list={numbersList}
          selectedPhoneNumber={selectedPhoneNumber}
          handleSelect={handleSelect}
          className=""
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          handleClickNumbers={handleClick}
          activeTabName={activeTabName}
          setActiveTabName={setActiveTabName}
          setActiveTabItem={setActiveTabItem}
          listCounts={listCounts}
        />

        <div className="w-full h-full">
          <div className="w-full h-full bg-[var(--white)]">
            <Conversation
              primaryLoading={numberListLoading}
              loading={conversationListLoading}
              setNumberListLoading={setNumberListLoading}
              setLoading={setConversationListLoading}
              selectedPhoneNumber={selectedPhoneNumber}
              activeTabName={activeTabName}
              activeTabItem={activeTabItem}
            />
          </div>
        </div>
      </>
    </div>
  );
};

export default Inbox;
