import React from 'react';

// assets
import AddIcon from 'assets/customSVG/AddIcon';
import AI from 'assets/customSVG/campaigns/AI';
import FitView from 'assets/customSVG/campaigns/FitView';
import Redo from 'assets/customSVG/campaigns/Redo';
import Undo from 'assets/customSVG/campaigns/Undo';
import VersionHistory from 'assets/customSVG/campaigns/VersionHistory';
import ZoomIn from 'assets/customSVG/campaigns/ZoomIn';
import ZoomOut from 'assets/customSVG/campaigns/ZoomOut';

// helper
import { Popover } from 'antd';
import { cn } from '../../../../../helper/cn';

// constants
import { ZoomInValue, ZoomOutValue } from '../../../constants/defaults';

// hooks
import useToggle from '../../../../../hooks/useToggle';

// components
import TriggerModel from '../triggers/TriggerModel';

export default function Controls({
  scale,
  handleZoomIn,
  handleZoomOut,
  handleFitView,
  handleUndo,
  handleRedo,
  disabled,
}) {
  const [openTrigger, setOpenTrigger] = useToggle(false);
  return (
    <div className='w-fit min-h-[50px] bg-white z-50 rounded-[10px] shadow-[0px_4px_16px_0px_#7B6FA329] py-2.5 flex items-center select-none'>
      <Popover
        trigger={['click']}
        arrow={false}
        open={openTrigger}
        placement='bottomLeft'
        onOpenChange={(open) => {
          setOpenTrigger(open);
        }}
        content={
          <div className='mt-3'>
            <TriggerModel type={'list'} open={true} />
          </div>
        }
      >
        <div className='px-4 border-r border-[var(--border-100)] cursor-pointer'>
          <AddIcon
            width={32}
            height={32}
            stroke='var(--primary)'
            fill='var(--primaryLight)'
          />
        </div>
      </Popover>
      <div className='px-4 border-r border-[var(--border-100)] flex items-center gap-2.5'>
        <div
          className={cn(
            'group/undo w-9 h-8 p-2 flex items-center justify-center rounded-md',
            disabled?.undo
              ? 'cursor-default'
              : 'cursor-pointer hover:bg-[var(--primaryLight)]'
          )}
          onClick={() => {
            handleUndo();
          }}
        >
          <Undo
            width={20}
            height={14}
            color=''
            className={cn(
              '',
              disabled?.undo
                ? 'cursor-default stroke-[#D1D3D8]'
                : 'cursor-pointer stroke-[var(--font-600)] group-hover/undo:stroke-[var(--primary)]'
            )}
          />
        </div>
        <div
          className={cn(
            'group/redo w-9 h-8 p-2 flex items-center justify-center rounded-md',
            disabled?.redo
              ? 'cursor-default'
              : 'cursor-pointer hover:bg-[var(--primaryLight)]'
          )}
          onClick={() => {
            handleRedo();
          }}
        >
          <Redo
            width={20}
            height={14}
            color=''
            className={cn(
              '',
              disabled?.redo
                ? 'cursor-default stroke-[#D1D3D8]'
                : 'cursor-pointer stroke-[var(--font-600)] group-hover/redo:stroke-[var(--primary)]'
            )}
          />
        </div>
      </div>
      <div className='px-4 border-r border-[var(--border-100)] flex items-center gap-2.5'>
        <div
          className={cn(
            'w-9 h-8 p-2 flex items-center justify-center rounded-md',
            scale > ZoomOutValue
              ? 'group/zoomIn cursor-pointer hover:bg-[var(--primaryLight)]'
              : 'cursor-not-allowed'
          )}
          onClick={(e) => {
            if (handleZoomOut && scale > ZoomOutValue) handleZoomOut(e);
          }}
        >
          <ZoomOut
            width={22}
            height={16}
            color=''
            className={cn(
              scale > ZoomOutValue
                ? 'stroke-[var(--font-600)] group-hover/zoomIn:stroke-[var(--primary)] cursor-pointer'
                : 'stroke-[#D1D3D8] cursor-not-allowed'
            )}
          />
        </div>
        <div
          className={cn(
            'group/zoomOut w-9 h-8 p-2 flex items-center justify-center rounded-md',
            scale < ZoomInValue
              ? 'group/zoomOut cursor-pointer hover:bg-[var(--primaryLight)]'
              : 'cursor-not-allowed'
          )}
          onClick={(e) => {
            if (handleZoomIn && scale < ZoomInValue) handleZoomIn(e);
          }}
        >
          <ZoomIn
            width={22}
            height={16}
            color=''
            className={cn(
              scale < ZoomInValue
                ? 'fill-[var(--font-600)] group-hover/zoomOut:fill-[var(--primary)] cursor-pointer'
                : 'fill-[#D1D3D8] cursor-not-allowed'
            )}
          />
        </div>
        <div
          className='group/fitview w-9 h-8 p-2 flex items-center justify-center rounded-md cursor-pointer hover:bg-[var(--primaryLight)]'
          onClick={(e) => {
            handleFitView(e);
          }}
        >
          <FitView
            width={21}
            height={15}
            color=''
            className={cn(
              'stroke-[var(--font-600)] group-hover/fitview:stroke-[var(--primary)]'
            )}
          />
        </div>
        {/* <div className='group/ai w-9 h-8 p-2 flex items-center justify-center rounded-md cursor-pointer hover:bg-[var(--primaryLight)]'>
          <AI width={20} height={14} />
        </div> */}
        <div className='group/version w-9 h-8 p-[7px] flex items-center justify-center rounded-md cursor-pointer hover:bg-[var(--primaryLight)]'>
          <VersionHistory
            width={24}
            height={18}
            color=''
            className={cn(
              'stroke-[var(--font-600)] group-hover/version:stroke-[var(--primary)]'
            )}
          />
        </div>
      </div>
      <div className='px-4'>0/10 Nodes</div>
    </div>
  );
}
