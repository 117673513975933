import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Popover } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

// assets
import { ICONS } from "assets/icons";

// api
import { broadcastList } from "api/apiClients/campaign";

// hooks
import useToggle from "hooks/useToggle";

// redux
import { useAspSelector } from "test/jest-redux-hooks";

// components
import Loader from "../../../../../../components/commonComponents/Loader/Index";

let count = 0;
let page = 1;
let limit = 10;

export default function Existing({
  data,
  onChange,
  selectedBroadcast,
  setSelectedBroadcast,
}) {
  const { id } = useParams();

  const { currentBrand } = useAspSelector((state) => state.app);

  const [isOpen, setIsOpen] = useToggle(false);
  const [isLoading, setIsLoading] = useToggle(false);
  const [isFilter, setIsFilters] = useToggle(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    const payload = {
      page: page,
      limit: limit,
      //   status: 10,
    };
    getCampaignList(false, currentBrand?.brand_id, id, payload);
  }, [currentBrand]);

  const getCampaignList = async (loading, brand_id, id, payload) => {
    if (loading) setIsLoading(true);
    else setIsFilters(true);
    try {
      const res = await broadcastList(brand_id, id, payload);
      count = res?.data?.broadcast_count;
      setList(res?.data?.broadcasts);
    } catch (error) {
      console.warn(error);
    } finally {
      if (loading) setIsLoading(false);
      else setIsFilters(false);
    }
  };

  const loadNextPage = async () => {
    limit += 10;
    const payload = {
      page: page,
      limit: limit,
      //   status: 10,
    };
    await getCampaignList(isLoading, currentBrand?.brand_id, id, payload);
  };

  const selectOption = (option) => {
    if (option !== selectedBroadcast) setSelectedBroadcast(option);
  };

  const isOptionSelected = (option) => {
    return option?.id === selectedBroadcast?.id;
  };

  return (
    <div className="flex flex-col gap-1.5">
      <p>Broadcast</p>
      <Popover
        open={isOpen}
        placement="bottomLeft"
        arrow={false}
        content={
          <ul
            className={`w-full min-w-[25rem] m-0 list-none rounded-2xl flex-col gap-1.5 overflow-y-scroll listScroll bg-[var(--white)] z-50 p-2.5 ${
              isOpen ? "flex" : "hidden"
            }`}
          >
            <InfiniteScroll
              dataLength={list?.length}
              next={() => {
                loadNextPage();
              }}
              hasMore={list?.length < count}
              scrollableTarget="scrollableDiv"
              height={isLoading ? "200px" : "200px"}
              className="!pr-1 mb-2 listScroll"
            >
              {list?.map((option) => {
                return (
                  <li
                    key={option?.id}
                    className="w-full h-[80px] flex items-center justify-between px-2.5 py-1.5 border-b border-[var(--border-100)] last:border-0 cursor-pointer"
                    onClick={() => {
                      selectOption(option);
                      setIsOpen(false);
                    }}
                  >
                    <div className="flex flex-col gap-2.5">
                      <div className="flex items-center justify-between gap-5">
                        <p>{option?.name}</p>
                        {isOptionSelected(option) && (
                          <img
                            src={ICONS?.Selected}
                            alt="selected"
                            className="size-5"
                          />
                        )}
                      </div>
                      <div className="flex items-center gap-5 justify-between">
                        <div className="flex items-center gap-1.5 text-sm weight-medium text-[#616874]">
                          <p>Ph. No:</p>
                          <p>+91 98765 43210</p>
                        </div>
                        <div className="flex items-center gap-1.5 text-xs weight-semibold text-[#616874]">
                          <p>Starts:</p>
                          <p className="px-2.5 py-0.5 bg-[var(--primary)] rounded-lg">
                            24 Jul, 2024
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
              {(isFilter || isLoading) && (
                <div className="w-full h-auto flex items-center justify-center py-2.5">
                  <Loader Width={25} Height={25} />
                </div>
              )}
            </InfiniteScroll>
          </ul>
        }
      >
        <div
          className="w-full h-12 flex items-center justify-between border boder-[var(--border-50)] px-5 py-1 rounded-lg cursor-pointer"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {selectedBroadcast?.name ? (
            <div
              className={`flex-grow flex items-center gap-2.5 text-[var(--contentText)]`}
            >
              {selectedBroadcast?.name}
            </div>
          ) : (
            <div className="w-full flex items-center font-md weight-medium text-[var(--font-300)]">
              Please select
            </div>
          )}

          <div
            className={`transition-transform duration-200 ease-linear cursor-pointer ${
              isOpen ? "-rotate-180" : "rotate-0"
            }`}
          >
            <div
              className={`border rounded-t-1.5 border-transparent border-t-[#777] translate-y-[25%]`}
            ></div>
          </div>
        </div>
      </Popover>
    </div>
  );
}
