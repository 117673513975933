import React from 'react';

export default function VersionHistory({
  width = 21,
  height = 18,
  color = '#616874',
  strokeWidth = 2,
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 21 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M17.5994 9C17.5994 13.4183 14.0167 17 9.59729 17C5.17787 17 1.59521 13.4183 1.59521 9C1.59521 4.58172 5.17787 1 9.59729 1C12.5592 1 15.1452 2.60879 16.5288 5M15.4042 9.87792L17.4047 7.87792L19.4052 9.87792M12.5952 11.1814L9.59521 10.1814V6'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
