import React from 'react';

export default function undo({
  width = 21,
  height = 12,
  color = '#D1D3D8',
  strokeWidth = 2,
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 21 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.31787 7.31836H1.31787M1.31787 7.31836V1.31836M1.31787 7.31836L4.31785 4.31834C5.57652 3.05966 7.18017 2.2025 8.926 1.85523C10.6718 1.50796 12.4814 1.68619 14.126 2.36738C15.7705 3.04857 17.1761 4.20212 18.165 5.68216C19.154 7.16221 19.6818 8.90227 19.6818 10.6823'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
