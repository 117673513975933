export const HEADER_TYPES_INITIAL_VALUES = {
  NONE: null,
  TEXT: {
    type: "HEADER",
    format: "TEXT",
    text: "",
  },
  MEDIA: {
    type: "HEADER",
    format: "MEDIA",
    example: {
      header_handle: [""],
    },
  },
  IMAGE: {
    type: "HEADER",
    format: "IMAGE",
    example: {},
  },
  VIDEO: {
    type: "HEADER",
    format: "VIDEO",
    example: {},
  },
  DOCUMENT: {
    type: "HEADER",
    format: "DOCUMENT",
    example: {},
  },
};

export const ADD_VARIABLE_OPTIONS = [
  {
    id: 1,
    value: "{{first-name}}",
    label: "first-name",
  },
  {
    id: 2,
    value: "{{last-name}}",
    label: "last-name",
  },
  {
    id: 3,
    value: "{{shop-name}}",
    label: "shop-name",
  },
  {
    id: 4,
    value: "{{email-id}}",
    label: "email-id",
  },
  {
    id: 5,
    value: "{{mobile-number}}",
    label: "mobile-number",
  },
  {
    id: 6,
    value: "{{custom-variable}}",
    label: "custom-variable",
  },
];

export const carouselButtonOptions = {
  URL: "Visit Website",
  PHONE_NUMBER: "Call Phone Number",
  CUSTOM: "Quick Reply",
  NONE: "None",
};
