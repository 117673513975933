import React from 'react';
import { addLineBreake, replaceFormating } from '../../helper';
import { cn } from '../../../../helper/cn';

export default function VideoMessagePreview({
  url,
  caption,
  urls,
  content,
  rootClassName,
  className,
  dateTime,
}) {
  return (
    // <div className="flex flex-col gap-2.5">
    <div
      className={cn(
        "flex flex-col relative w-auto max-w-[26vw] min-h-[3.5vh] rounded-[0.6vw] rounded-tr-none",
        rootClassName
      )}
      // style={{ boxShadow: "0px 4px 16px 0px #0000000A" }}
    >
      {content && (
        <div className="w-full flex-row align-center bg-[var(--channelButton)] inbox12px-400 text-[var(--font-600)] b-radius-6 mb-10">
          <div className=" h-[7vh] bg-[#6564CA] w-1" />
          <div className="flex flex-1 items-center justify-between">
            <div className="truncate max-w-[25ch] ml-10">{content}</div>
            {urls?.[0] && (
              <video className="w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10">
                <source src={urls?.[0]} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
      )}
      <div className={cn("w-[18vw] h-full", className)}>
        <video
          className="w-full h-full object-cover rounded-[0.4vw]"
          controls
          // autoplay='autoplay'
          // loop
        >
          <source src={url} type="video/mp4" />
        </video>
      </div>

      <p
        className="new-body-text px-[0.5vw] pt-[0.5vw] break-words"
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
      <div className="flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw] ml-20">
        <p>{dateTime}</p>
      </div>
    </div>
  );
}
