import React, { useEffect, useState } from "react";
import "./campaign.css";

import ModalHeader from "../modal/header";
import { Input } from "../../form/Input/Input";
import Select from "../../form/select/Select";
import FooterSection from "../../../modules/campaign/components/campaigns/FooterSection";
import {
  campaignTypes,
  inputCalendar,
} from "../../../constant/app/campaign/campaign";
import { ICONS } from "../../../assets/icons";
import { Popover } from "antd";
import Calendar from "../calendar/Calendar";
import { Button } from "../../form/Button/Button";
import {
  checkCampaignExits,
  createCampaignApi,
  updateCampaignApi,
} from "../../../modules/campaign/api/Api";
import { updateToggleToast } from "../../../reduxToolkit/appSlice";
import { useAspDispatch, useAspSelector } from "../../../test/jest-redux-hooks";
import moment from "moment";
import useToggle from "../../../hooks/useToggle";

const popup = {
  padding: "10px 10px",
};

const campaignTypeId = {
  Unspecified: 1,
  "Time Specified": 2,
  Perpetual: 3,
};

const CampaignModal = ({
  type,
  tableList,
  setTableList,
  handleCancel,
  item,
}) => {
  const [startPopoverVisible, setStartPopoverVisible] = useState(false);
  const [inputCalenderData, setInputCalender] = useState(null);
  const [value, toggleValue] = useToggle(false);
  const [handleRangeReset, setHandleRangeReset] = useState(null);
  const [campaignName, setCampaignName] = useState("");

  useEffect(() => {
    if (type === "edit") setCampaignName(item?.campaign_name);
    else setCampaignName("");
  }, [type]);

  useEffect(() => {
    if (campaignName) {
      handleCreateCampaign("campaign_name", campaignName);
    }
  }, [campaignName]);

  const resetRangeCalender = () => {
    setHandleRangeReset(true);
    setTimeout(() => {
      setHandleRangeReset(false);
    }, 0);
  };

  const campaignTypeLabel =
    campaignTypes.find((type) => type.label === item?.campaign_type_id)
      ?.value || "";

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);

  const dispatch = useAspDispatch();

  const [campaignErrors, setCampaignErrors] = useState({
    campaign_name: "",
  });

  const [campaignError, setCampaignError] = useState({
    campaign_type_id: null,
    campaign_name: "",
    campaign_color: null,
    start_date: null,
    end_date: null,
  });

  const [campaign, setCampaign] = useState({
    campaign_color: null,
    campaign_type_id: null,
    start_date: null,
    end_date: null,
    campaign_name: "",
  });

  const handleColorChange = (newColor) => {
    const roundedR = Math?.floor(newColor?.metaColor?.r);
    const roundedG = Math?.floor(newColor?.metaColor?.g);
    const roundedB = Math?.floor(newColor?.metaColor?.b);
    setCampaign((prev) => {
      return {
        ...prev,
        campaign_color: `rgb(${roundedR},${roundedG},${roundedB})`,
      };
    });
  };

  useEffect(() => {
    if (item) {
      const {
        id,
        campaign_color,
        campaign_type_id,
        start_date,
        end_date,
        campaign_name,
      } = item;
      const rgbRegex = /^rgb\((\d+),(\d+),(\d+)\)$/;
      const match = campaign_color.match(rgbRegex);

      const formattedCampaignColor = match
        ? campaign_color
        : `rgb(${Math.floor(item?.metaColor?.r)},${Math.floor(
            item?.metaColor?.g
          )},${Math.floor(item?.metaColor?.b)})`;

      setCampaign({
        campaign_id: id,
        campaign_color: formattedCampaignColor,
        campaign_type_id: campaign_type_id,
        start_date,
        end_date,
        campaign_name,
      });
    } else {
      resetState();
    }
  }, [item]);

  const handleCreateCampaign = (key, value) => {
    if (key === "name") {
      const updatedTableList = tableList.map((item) => {
        return {
          ...item,
          campaign_name: value,
          start_date: value,
          end_date: value,
          campaign_color: value,
          campaign_type_id: value,
        };
      });
      setTableList(updatedTableList);
    } else {
      setCampaign((prev) => ({
        ...prev,
        campaign_type_id: campaignTypeId[key?.value] || prev?.campaign_type_id,
        [key]: value,
      }));
    }

    const isMatch = tableList?.some(
      (item) => item?.campaign_name?.toLowerCase() === value?.toLowerCase()
    );

    if (isMatch) {
      if (value.trim() !== "" && !item) {
        checkCampaignExits(currentBrand?.brand_id, value?.toLowerCase()).then(
          (res) => {
            if (res?.status === 200) {
              if (res?.data?.exists) {
                setCampaignErrors({
                  campaign_name: "Campaign name already exists",
                });
              } else {
                setCampaignErrors({
                  campaign_name: "",
                });
              }
            }
          }
        );
      }
    } else {
      // Clear error if no match is found
      setCampaignErrors({
        campaign_name: "",
      });
    }

    if (
      (type === "edit" &&
        campaignName?.toLowerCase() !== item?.campaign_name?.toLowerCase()) ||
      (type !== "edit" && campaignName && isMatch)
    ) {
      checkCampaignExits(currentBrand?.brand_id, value?.toLowerCase()).then(
        (res) => {
          if (res?.status === 200) {
            if (res?.data?.exists) {
              setCampaignErrors({
                campaign_name: "Campaign name already exists",
              });
            } else {
              setCampaignErrors({
                campaign_name: "",
              });
            }
          }
        }
      );
    } else {
      // Clear error if no match is found
      setCampaignErrors({
        campaign_name: "",
      });
    }
  };

  const setFromAndToValues = (from, to) => {
    const parseDate = (dateString) => {
      if (typeof dateString === "object") {
        const parsedDate = moment(dateString, "MMM D, YYYY");
        const newDate = parsedDate.isValid() ? parsedDate.toDate() : null;
        return newDate;
      } else {
        return null;
      }
    };

    const startTimestamp = parseDate(from);
    const endTimestamp = parseDate(to);

    if (startTimestamp && endTimestamp && startTimestamp > endTimestamp) {
      console.error("Start date cannot be after end date.");
      return;
    }

    const currentDate = new Date();

    setCampaign((prev) => {
      const updatedEndDate = endTimestamp || prev.end_date;

      if (prev.start_date && new Date(prev.start_date) < currentDate) {
        return {
          ...prev,
          end_date: updatedEndDate,
        };
      } else {
        return {
          ...prev,
          start_date: startTimestamp || prev.start_date,
          end_date: updatedEndDate,
        };
      }
    });

    setInputCalender((prev) => {
      const newState = prev?.map((item) => {
        if ("Duration") {
          return {
            ...item,
            value:
              startTimestamp && endTimestamp
                ? `${moment(startTimestamp).format("ll")} - ${moment(
                    endTimestamp
                  ).format("ll")}`
                : startTimestamp
                ? `${moment(startTimestamp).format("ll")} - `
                : endTimestamp
                ? ` - ${moment(endTimestamp).format("ll")}`
                : "",
          };
        }
        return item;
      });
      return newState;
    });

    if (startTimestamp && endTimestamp) {
      setStartPopoverVisible(false);
    }
  };

  useEffect(() => {
    if (!item && !campaign?.campaign_color) {
      setCampaign((prev) => ({
        ...prev,
        campaign_color: getRandomColor(),
      }));
    }
  }, []);
  function getRandomColor() {
    const roundedR = Math.floor(Math.random() * 256);
    const roundedG = Math.floor(Math.random() * 256);
    const roundedB = Math.floor(Math.random() * 256);
    return `rgb(${roundedR},${roundedG},${roundedB})`;
  }

  const handleRemove = () => {
    setCampaign((prev) => ({
      ...prev,
      start_date: null,
      end_date: null,
    }));

    setInputCalender((prev) => {
      const newState = prev?.map((item) => {
        if ("Duration") {
          return {
            ...item,
            value: "",
          };
        }
        return item;
      });
      return newState;
    });
    resetRangeCalender();
    setStartPopoverVisible(false);
  };

  const handleCreateTask = () => {
    let isValid = true;
    toggleValue(true);

    if (!campaign?.campaign_type_id) {
      setCampaignError((prev) => ({
        ...prev,
        campaign_type_id: "Campaign type is required",
      }));
      isValid = false;
    } else {
      setCampaignError((prev) => ({ ...prev, campaign_type_id: "" }));
    }

    if (!campaign?.campaign_name?.trim()) {
      setCampaignError((prev) => ({
        ...prev,
        campaign_name: "Campaign name is required",
      }));
      isValid = false;
    } else {
      setCampaignError((prev) => ({ ...prev, campaign_name: "" }));
    }

    if (!campaign?.start_date) {
      setCampaignError((prev) => ({
        ...prev,
        start_date: "Please choose date",
      }));
      isValid = false;
    } else {
      setCampaignError((prev) => ({ ...prev, start_date: "" }));
    }

    if (isValid) {
      let campaignPayload = {
        ...campaign,
        brand_id: currentBrand?.brand_id,
      };
      const campaignId = item?.id;
      const apiCall = campaignId
        ? () => updateCampaignApi(campaignId, campaignPayload)
        : () => createCampaignApi(campaignPayload);

      apiCall().then((createCampaignResponse) => {
        if (createCampaignResponse?.status === 200) {
          toggleValue(false);
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content:
                  item && campaignId
                    ? "Campaign updated successfully"
                    : "Campaign created successfully",
                status: "Success",
                duration: "",
              },
            ])
          );

          if (tableList && tableList.length > 0) {
            const updatedTableList = tableList.map((campaignItem) => {
              if (campaignItem.id === campaignId) {
                return { ...campaignItem, ...campaignPayload };
              }
              return campaignItem;
            });

            setTableList(updatedTableList);
          }
          window?.location?.reload();
          handleCancel();
        } else {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content:
                  createCampaignResponse?.response?.data?.error ||
                  "Campaign creation failed",
                status: "Error",
                duration: "",
              },
            ])
          );
        }
        handleCancel();
      });
    }
  };

  const resetState = () => {
    setCampaign({
      campaign_color: "",
      campaign_type_id: null,
      start_date: null,
      end_date: null,
      campaign_name: "",
    });

    setInputCalender(null);
    setCampaignErrors({
      campaign_name: "",
    });
    setCampaignError({
      campaign_type_id: null,
      campaign_name: "",
      campaign_color: "",
      start_date: null,
      end_date: null,
    });
  };

  const handleCancels = (event) => {
    resetState();
    handleCancel();
  };
  const handleDateSelection = (date) => {
    const parsedDate = moment(date, "MMM D, YYYY");
    const selectedDate = parsedDate.isValid() ? parsedDate.toDate() : null;

    if (selectedDate) {
      setCampaign((prev) => ({
        ...prev,
        end_date: selectedDate,
      }));

      setInputCalender((prev) => {
        const newState = prev?.map((item) => {
          if (item.key === "Duration") {
            return {
              ...item,
              value: `${moment(selectedDate).format("ll")}`,
            };
          }
          return item;
        });
        return newState;
      });

      setStartPopoverVisible(false);
    }
  };

  const currentDate = new Date();

  const formattedCurrentDate = moment(currentDate).format("YYYY-DD-MM");
  const campaignStartDate =
    type === "edit" && typeof campaign?.start_date === "string"
      ? campaign.start_date.split(" ")[0]
      : null;
  // const campaignStartDate =
  //   type === "edit" && campaign?.start_date?.split(" ")[0];


  const content =
    campaignStartDate < formattedCurrentDate ? (
      <Calendar
        heading="Select duration"
        singleSelect={true}
        clickedDateFunc={handleDateSelection}
        style={{ width: "100%", minWidth: "350px", height: "220px" }}
      />
    ) : (
      <Calendar
        heading="Select duration"
        fromTo={setFromAndToValues}
        resetRangeCalender={resetRangeCalender}
        start_date={campaign.start_date}
        end_date={campaign.end_date}
        style={{ width: "100%", minWidth: "350px", height: "220px" }}
      />
    );

  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-CA"); // 'YYYY-MM-DD'

  // Check if start_date exists and is either a string or a Date object
  const startDate =
    typeof campaign?.start_date === "string"
      ? campaign.start_date.split(" ")[0] // If string, split to get the date part
      : campaign?.start_date instanceof Date
      ? campaign.start_date.toLocaleDateString("en-CA") // Format as 'YYYY-MM-DD'
      : null;

  const removeIcon =
    item &&
    startDate &&
    (startDate === formattedDate || startDate < formattedDate);

  return (
    <div className="create_campaig">
      <ModalHeader
        handleCancel={handleCancels}
        title={type === "edit" ? "Edit Campaign" : "Create Campaign"}
        style={{
          height: 64,
        }}
        titleStyle={{
          fontSize: "1vw",
        }}
      />
      <div className="" style={{ padding: "15px 26px" }}>
        <div className="createCampaignLabel">
          <span>Campaign Name</span>
          <span className="required">*</span>
        </div>
        <div
          className=""
          style={{
            width: "100%",
            padding: "10px 7px",
          }}
        >
          <Input
            placeholder="Enter campaign name"
            style={popup}
            maxLength="35"
            pattern=""
            onChange={(e) => {
              const newValue = e.target.value;
              setCampaignName(newValue);
            }}
            value={campaignName}
            required
          />
        </div>
        <div className="explain-error">{campaignError?.campaign_name}</div>
        <div className="explain-error">{campaignErrors?.campaign_name}</div>
      </div>
      <div className="" style={{ padding: "5px 26px" }}>
        <span className="select-campaign">Campaign Type</span>
        <span className="required">*</span>

        <div className="" style={{ padding: "15px 7px" }}>
          <Select
            className="pointer !h-[50px]"
            placeholder="-- Select type --"
            options={campaignTypes?.map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            value={campaignTypeLabel}
            onChange={handleCreateCampaign}
            style={{ height: 46 }}
            dataTestId="campaign-type"
            optionClick="campaign-type-option"
            dropDownId="campaign-type-dropdown"
          />
        </div>
      </div>
      <div className="explain-error">{campaignError?.campaign_type_id}</div>

      <div className="" style={{ padding: "5px 26px" }}>
        <div className="flex-column ">
          <div className="createCampaignLabel1 pl-10">
            <span>{"Duration"}</span>
            <span className="required">*</span>
          </div>
          <div className="w-full">
            <Popover
              content={content}
              trigger="click"
              arrow={false}
              open={startPopoverVisible}
              overlayClassName="date-popover"
              onOpenChange={(open) => setStartPopoverVisible(open)}
            >
              <div className="flex-column pointer">
                <div className="create-input flex-row align-center pointer border !border-[var(--card-border)] !pl-[10px]">
                  <img src={ICONS.CalendarIcon} alt="CalendarIcon" />
                  <input
                    readOnly={true}
                    style={popup}
                    placeholder="-- Select duration --"
                    value={
                      campaign.start_date || campaign.end_date
                        ? `${
                            campaign.start_date
                              ? moment(campaign.start_date).format("ll")
                              : ""
                          } - ${
                            campaign.end_date
                              ? moment(campaign.end_date).format("ll")
                              : ""
                          }`
                        : ""
                    }
                    className="create-inputBox pointer"
                  />
                  {campaign?.start_date &&
                    campaign?.end_date &&
                    !removeIcon && (
                      <img
                        className="popup-exit pointer mr-10"
                        src={ICONS.popupX}
                        alt="popup"
                        onClick={() => handleRemove()}
                        data-id="handleRemove"
                      />
                    )}
                </div>

                <div className="explain-error">{campaignError?.start_date}</div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
      <div className="w-full px-[26px]">
        <FooterSection
          onColorChange={handleColorChange}
          campaignColorError={campaignError?.campaign_color}
          campaign_color={campaign?.campaign_color}
          item={item}
        />
      </div>
      <div className="popup-footer flex-row flex-end align-center !px-[31px]">
        {campaign?.campaign_type_id &&
        campaign?.campaign_name &&
        campaign?.start_date &&
        campaign?.end_date &&
        !campaignErrors.campaign_name &&
        (campaignName !== item?.campaign_name ||
          `${moment(item.start_date).format("ll")}` !==
            `${moment(campaign.start_date).format("ll")}` ||
          `${moment(item.end_date).format("ll")}` !==
            `${moment(campaign.end_date).format("ll")}` ||
          campaign?.campaign_color !== item?.campaign_color) ? (
          <Button
            label={type === "edit" ? "Update Campaign" : "Create Campaign"}
            size={"small"}
            loading={value}
            onClick={() => handleCreateTask()}
            data-testid="button"
          />
        ) : (
          <span className="disable-button">
            {type === "edit" ? "Update Campaign" : "Create Campaign"}
          </span>
        )}
      </div>
    </div>
  );
};

export default CampaignModal;
