import React from "react";
import Template from "../preview/Template";
import MediaMessage from "../preview/MediaMessage";

const NodeDetails = ({ node, onChange }) => {
  const {
    id,
    node_type,
    is_trigger,
    type,
    component_type,
    trigger_type,
    label,
    order,
    data,
    settings,
    isEditMode,
    status,
  } = node;
  const { type: data_type, category } = data;

  return (
    <div className="text-black">
      {data_type === "template" ? (
        <Template
          data={data}
          category={data?.category}
          categoryType={component_type}
        />
      ) : ["TEXT", "MEDIA"]?.includes(component_type) ? (
        <div>
          <MediaMessage node={node} />
        </div>
      ) : data_type === "media" ? (
        <div>hi</div>
      ) : data_type === "interactive" ? (
        <div>hi</div>
      ) : data_type === "list" ? (
        <div>hi</div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NodeDetails;
