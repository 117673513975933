// api/v1/templates/metrics?brand_id=4

import AspApi from "api/ApiConfig";

// campaign list
export const campaignList = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/campaigns/metrics?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

// overall metrix
export const metrix = async (brandId) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/template_utils/template_metrics?brand_id=${brandId}`
  );
  return res;
};

// conversation metrix
export const conversationMetrix = async (brandId, query) => {
  let qpm = [];

  if (brandId) qpm.push(`brand_id=${brandId}`);
  if (query.year) qpm.push(`year=${query.year}`);
  if (query.month) qpm.push(`month=${query.month}`);

  let qpmString = "";
  if (qpm.length > 0) qpmString = "?" + qpm?.join("&");

  const res = await AspApi.get(`/api/v1/home/conversation/metrics${qpmString}`);
  return res;
};
