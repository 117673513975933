import React, { useEffect } from 'react';

// assets
import { ICONS } from '../../../../assets/icons';
import Delete from '../../../../assets/customSVG/Delete';
import DocSvg from '../../../../assets/customSVG/DocSvg';
import ImageSvg from '../../../../assets/customSVG/ImageSvg';
import VideoSvg from '../../../../assets/customSVG/VideoSvg';

// constants
import { HEADER_OPTIONS, MEDIA_TYPES } from '../../constant';
// helper
import { replaceVariableCount } from 'helper/templates';

// hooks
import { useFileUploader } from 'hooks/useFileUploader';

// components
import Select from 'components/commonComponents/select/index';
import RadioButton from 'components/form/radio/RadioButton';
import Loader from 'components/commonComponents/Loader/Index';

import { checkVariableExist } from '.';
import AddVariable from './AddVariable';
import VariableComponent from './VariableComponent';

export default function TemplateHeader({
  header,
  headerVariable,
  dispatch,
  allow,
  onChange,
  setIsHeaderError,
}) {
  const variableExist = checkVariableExist(header?.text);

  const {
    uploading,
    selectedFile,
    setSelectedFile,
    uploadFiles,
    error,
    clearFiles,
  } = useFileUploader();

  useEffect(() => {
    if (error) setIsHeaderError(true);
    else setIsHeaderError(false);
  }, [error]);

  // get the file name from uploaded file
  const fileName = () => {
    const names = header?.example?.header_handle?.[0]?.split('/');
    return names?.[names.length - 1];
  };

  const headerFormat = header
    ? ['MEDIA', 'IMAGE', 'VIDEO', 'DOCUMENT'].includes(header?.format)
      ? 'Media'
      : header?.format === 'TEXT'
      ? 'Text'
      : 'None'
    : 'None';

  // max length for header text input
  let maxLength = variableExist?.length > 0 ? 73 : 60;

  // commen styles
  const uploadContainer =
    'h-[2.5vw] flex items-center gap-[0.3vw] bg-[var(--primary)] px-[0.5vw] py-[0.1.5vw] rounded-[0.4vw] cursor-pointer';
  const uploadText = 'font-xs weight-bold text-[var(--white)]';
  const notes = 'font-md weight-medium text-[var(--font-400)] ml-[0.7vw]';
  const errorStyle = 'text-[var(--fontRed)] font-md weight-400 my-[0.5vw]';

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="weight-semibold text-[var(--contentText)] flex items-center gap-[0.2vw]">
            <span className="font-default">Header</span>
            <span className="font-md">(Optional)</span>
          </h3>
          <p className="font-md weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.5vw]">
            Add a title or choose which type of media you'll use for this
            header.
          </p>
        </div>
        {header?.format === "TEXT" && (
          <AddVariable
            type={"Header"}
            disable={headerVariable ? true : false}
            allow={allow}
            onChange={(item) => {
              if (allow) {
                onChange({
                  type: "header",
                  updateKey: "variable",
                  id: item?.id,
                  key:
                    item?.title === "custom-variable"
                      ? "{{custom-variable1}}"
                      : `{{${item?.title}}}`,
                  value: "",
                });
              }
            }}
          />
        )}
      </div>
      <div className="w-full h-[2vw] mt-[0.5vw] flex items-center gap-[1vw]">
        <Select
          options={HEADER_OPTIONS}
          value={headerFormat}
          dropdownIcon={ICONS?.SelectDropdown}
          rootClassName="min-w-[8vw]"
          className={"h-[2.23vw]"}
          position="bottom"
          allow={allow}
          onChange={({ value }) => {
            if (allow) {
              onChange({
                type: "header",
                updateKey: "format",
                value: value?.toUpperCase(),
              });
              clearFiles();
            }
          }}
          dataTestId="custom-select"
          optionClick="select-option"
        />
        {header?.format === 'TEXT' && (
          <div className='w-full h-[2.23vw] font-md weight-small px-[0.5vw] rounded-[0.4vw] border-[0.08vw] border-[var(--card-border)] flex items-center'>
            <input
              type="text"
              value={header?.text ? header?.text : ""}
              placeholder="Enter text"
              maxLength={maxLength}
              className="w-full flex items-center"
              onChange={(e) => {
                if (allow) {
                  onChange({
                    type: "header",
                    updateKey: "text",
                    text: e.target.value,
                  });
                }
              }}
              data-testid="text-content"
            />
            <div className="font-sm weight-medium text-[var(--font-400)]">
              {replaceVariableCount(header?.text)?.length}/60
            </div>
          </div>
        )}
      </div>
      {/* header type is text */}
      {header?.format === "TEXT" && headerVariable && (
        <div className="mt-[1.5vw]">
          <p className="font-md weight-bold text-[var(--contentText)]">
            Fallback text for header content
          </p>
          <div className="mt-[1vw]">
            <VariableComponent
              name={headerVariable?.title}
              value={headerVariable?.value}
              onChange={(e) => {
                if (allow) {
                  onChange({
                    type: 'update_variable',
                    key: headerVariable?.title,
                    value: e.target.value,
                  });
                }
              }}
              // variables="variable_name"
            />
          </div>
        </div>
      )}

      {/* header type is media */}
      {header &&
        ["MEDIA", "IMAGE", "VIDEO", "DOCUMENT"].includes(header?.format) && (
          <>
            {/* media types */}
            <div
              className={`mt-[1vw] flex items-center justify-between gap-[1vw]`}
            >
              {MEDIA_TYPES?.map((t) => {
                return (
                  <div
                    key={`Media-type-${t.format}`}
                    className={`w-[10vw] h-auto flex gap-[0.5vw] px-[0.25vw] py-[0.25vw] border-[0.08vw] rounded-[0.6vw]  ${
                      !allow
                        ? "cursor-not-allowed"
                        : allow
                        ? !t.disabled
                          ? header?.format === t?.format
                            ? "bg-[var(--white)] border-[var(--white)] shadow-[0px_4px_30px_0px_#2D30361A]"
                            : "bg-transparent border-[var(--border-50)]"
                          : "!cursor-not-allowed opacity-50"
                        : "!cursor-pointer"
                    }`}
                    onClick={() => {
                      if (allow) {
                        if (!t.disabled) {
                          if (header?.format !== t?.format) {
                            // dispatch({
                            //   type: 'header',
                            //   updateType: 'format',
                            //   format: t.format,
                            // });

                            onChange({
                              type: "header",
                              updateKey: "format",
                              value: t.format,
                            });
                            clearFiles();
                          }
                        }
                      }
                    }}
                    data-testid="media-choose"
                  >
                    <div
                      className={`w-[7.9vw] px-[0.3vw] py-[0.25vw] flex items-center gap-[0.7vw] bg-[var(--white)]`}
                    >
                      <div
                        className={`flex items-center justify-center rounded-[0.4vw] p-[0.3vw] ${
                          header?.format === t?.format
                            ? "bg-[#F0FDF4]"
                            : "bg-[#f0f1f2]"
                        }`}
                      >
                        <t.icon
                          color={`${
                            header?.format === t?.format ? "#25C277" : "#2D3036"
                          }`}
                          width={"1.5vw"}
                          height={"1.5vw"}
                        />
                      </div>
                      <div
                        className={`font-md weight-semibold text-[var(--contentText)]`}
                      >
                        {t.label}
                      </div>
                    </div>
                    <div className="">
                      {header?.format === t?.format && (
                        <RadioButton selected={true} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>

            {["IMAGE", "VIDEO", "DOCUMENT"].includes(header?.format) && (
              <div className="">
                <h2 className="font-md weight-bold my-[1vw] text-[var(--contentText)]">
                  Samples for header content
                </h2>

                {/* file upload component based on the selected media type */}
                {["IMAGE", "VIDEO", "DOCUMENT"]?.includes(header?.format) &&
                  !header?.example?.header_handle?.[0] && (
                    <div className="flex align-center">
                      <input
                        type="file"
                        accept={
                          header?.format === "IMAGE"
                            ? ".jpg, .png, .jpeg"
                            : header?.format === "VIDEO"
                            ? ".mp4"
                            : header?.format === "DOCUMENT" && ".pdf"
                        }
                        onChange={async (e) => {
                          const res = await uploadFiles(e, header?.format);
                          onChange({
                            type: "header",
                            updateKey: "mediaUrl",
                            value: res,
                          });
                        }}
                        id="uploadImg"
                        hidden
                      />

                      <label for="uploadImg" className="">
                        {uploading ? (
                          <div className="flex-row align-center justify-center ">
                            <Loader Width={20} Height={20} />
                          </div>
                        ) : (
                          <div className={`${uploadContainer}`}>
                            {header?.format === "IMAGE" && (
                              <ImageSvg
                                color="#fff"
                                width={"1.5vw"}
                                height={"1.5vw"}
                              />
                            )}
                            {header?.format === "VIDEO" && (
                              <VideoSvg
                                color="#fff"
                                width={"1.5vw"}
                                height={"1.5vw"}
                              />
                            )}
                            {header?.format === "DOCUMENT" && (
                              <DocSvg
                                color="#fff"
                                width={"1.5vw"}
                                height={"1.5vw"}
                              />
                            )}
                            <span className={`${uploadText}`}>
                              {header?.format === "IMAGE" && (
                                <>Choose jpg or png</>
                              )}
                              {header?.format === "VIDEO" && <>Choose mp4</>}
                              {header?.format === "DOCUMENT" && <>Choose pdf</>}
                            </span>{" "}
                          </div>
                        )}
                      </label>

                      <div className={`${notes}`}>
                        {header?.format === "IMAGE" && (
                          <>Image size should be less than 5MB</>
                        )}
                        {header?.format === "VIDEO" && (
                          <>Video size should be less than 16MB</>
                        )}
                        {header?.format === "DOCUMENT" && (
                          <>Document size should be less than 100MB</>
                        )}
                      </div>
                    </div>
                  )}
                {/* file upload component based on the selected media type */}

                {/* uploaded file preview section starts here */}
                <div className="flex align-center">
                  {header?.format === "VIDEO" &&
                    header?.example?.header_handle?.[0] && (
                      <video
                        className="size-[2.5vw] rounded-[0.6vw] object-cover"
                        src={header?.example?.header_handle?.[0]}
                      />
                    )}
                  {header?.format === "IMAGE" &&
                    header?.example?.header_handle?.[0] && (
                      <img
                        src={header?.example?.header_handle?.[0]}
                        className="size-[2.5vw] rounded-[0.6vw] object-cover"
                        alt="preview"
                      />
                    )}
                  {header?.format === "DOCUMENT" &&
                    header?.example?.header_handle?.[0] && (
                      <div className="bg-[#F0F1F2] p-[0.5vw] rounded-[0.6vw]">
                        <DocSvg
                          color="#2D3036"
                          width={"1.5vw"}
                          height={"1.5vw"}
                        />
                      </div>
                    )}
                  {(selectedFile ||
                    header?.example?.header_handle?.[0] ||
                    fileName()) && (
                    <div
                      className={`font-md weight-medium text-[#616874] ml-[0.6vw]`}
                    >
                      {selectedFile?.name ?? fileName()}
                    </div>
                  )}
                  {(selectedFile || fileName()) && (
                    <div className="ml-[1vw]">
                      <div
                        className={`group p-[0.2vw] rounded-[0.4vw] bg-transparent ${
                          allow
                            ? "cursor-pointer hover:bg-[#F9605620]"
                            : "cursor-not-allowed"
                        }`}
                        onClick={() => {
                          if (allow) {
                            // dispatch({
                            //   type: 'header',
                            //   updateType: 'format',
                            //   format: header?.format,
                            // });

                            onChange({
                              type: "header",
                              updateKey: "format",
                              value: header?.format,
                            });
                            clearFiles();
                          }
                        }}
                        data-testid="delete-media"
                      >
                        <Delete
                          width={"1vw"}
                          height={"1vw"}
                          color=""
                          className={`stroke-[#898E99] ${
                            allow ? "group-hover:stroke-[#f00] " : ""
                          }`}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* uploaded file preview section ends here */}

                {/* Error message for the the uploaded data format incorrect based on the selected media type */}
                {error && (
                  <div className={`${errorStyle}`}>
                    {header?.format === "IMAGE" && (
                      <>Image size should be less than 5MB</>
                    )}
                    {header?.format === "VIDEO" && (
                      <>Video size should be less than 16MB</>
                    )}
                    {header?.format === "DOCUMENT" && (
                      <>Document size should be less than 100MB</>
                    )}
                  </div>
                )}
                {/* Error messages ends here */}
              </div>
            )}
          </>
        )}
    </div>
  );
}
