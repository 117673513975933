import React from 'react';

const List = ({
  width = 22,
  height = 22,
  color = 'white',
  strokeWidth = '1.83333',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_19016_134246)'>
        <path
          d='M18.3337 16.4997V6.41634C18.3337 4.67467 17.142 3.29967 15.5837 2.84134V2.74967C15.5837 2.19967 15.217 1.83301 14.667 1.83301C14.117 1.83301 13.7503 2.19967 13.7503 2.74967H11.917C11.917 2.19967 11.5503 1.83301 11.0003 1.83301C10.4503 1.83301 10.0837 2.19967 10.0837 2.74967H8.25033C8.25033 2.19967 7.88366 1.83301 7.33366 1.83301C6.78366 1.83301 6.41699 2.19967 6.41699 2.74967V2.84134C4.85866 3.29967 3.66699 4.67467 3.66699 6.41634V16.4997C3.66699 18.5163 5.31699 20.1663 7.33366 20.1663H14.667C16.6837 20.1663 18.3337 18.5163 18.3337 16.4997ZM16.5003 16.4997C16.5003 17.508 15.6753 18.333 14.667 18.333H7.33366C6.32533 18.333 5.50033 17.508 5.50033 16.4997V6.41634C5.50033 5.68301 5.86699 5.13301 6.50866 4.85801C6.60033 5.22467 6.87533 5.49967 7.33366 5.49967C7.88366 5.49967 8.25033 5.13301 8.25033 4.58301H10.0837C10.0837 5.13301 10.4503 5.49967 11.0003 5.49967C11.5503 5.49967 11.917 5.13301 11.917 4.58301H13.7503C13.7503 5.13301 14.117 5.49967 14.667 5.49967C15.1253 5.49967 15.4003 5.22467 15.492 4.85801C16.1337 5.13301 16.5003 5.68301 16.5003 6.41634V16.4997Z'
          fill={color}
        />
        <path
          d='M10.084 12.8337H8.25065C7.70065 12.8337 7.33398 13.2003 7.33398 13.7503C7.33398 14.3003 7.70065 14.667 8.25065 14.667H10.084C10.634 14.667 11.0007 14.3003 11.0007 13.7503C11.0007 13.2003 10.634 12.8337 10.084 12.8337ZM12.834 9.16699H8.25065C7.70065 9.16699 7.33398 9.53366 7.33398 10.0837C7.33398 10.6337 7.70065 11.0003 8.25065 11.0003H12.834C13.384 11.0003 13.7507 10.6337 13.7507 10.0837C13.7507 9.53366 13.384 9.16699 12.834 9.16699Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_19016_134246'>
          <rect
            width='22'
            height='21.6'
            fill={color}
            transform='translate(0.000488281 0.200195)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default List;
