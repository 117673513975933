import AspApi from "../../../api/ApiConfig";

export const createTemplatelStep1 = async (payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/whatsapp_templates`,
    payLoad
  );
  return res;
};

export const getTemplate = async (id, payLoad) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/whatsapp_templates/${id}?brand_id=${payLoad?.brandId}`
  );

  return res;
};

export const templateUpdate = async (id, payLoad) => {
  const res = await AspApi.patch(
    `/api/v1/svc/whatsapp/whatsapp_templates/${id}`,
    payLoad
  );
  return res;
};

export const templateApprovedEdit = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/whatsapp_templates/${id}/initiate_edit`,
    payLoad
  );
  return res;
};

export const duplicateTemplate = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/whatsapp_templates/${id}/duplicate_template`,
    payLoad
  );
  return res;
};

export const whatsappBusinessNumbers = async (brandId) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/whatsapp_numbers?brand_id=${brandId}`
  );
  return res;
};

export const templateLists = async (brandId, query) => {
  let qpm = [];

  if (brandId) qpm.push(`brand_id=${brandId}`);
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);
  if (query?.category) qpm.push(`category=${query?.category}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "?" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/template_utils/whatsapp_template_list${qpmString}`
  );
  return res;
};

export const templateDiscard = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/whatsapp_templates/${id}/discard_edit`,
    payLoad
  );
  return res;
};
// export const UpdateTemplate = async (payLoad) => {
//   const res = await AspApi.patch(
//     `/api/v1/svc/whatsapp/whatsapp_templates/${id}`,
//     payLoad
//   );
//   return res;
// };

export const checkTemplateExits = async (query) => {
  let qpm = [];
  if (query.brandId) qpm.push(`brand_id=${query.brandId}`);
  if (query.name) qpm.push(`name=${query.name}`);
  let qpmString = "";
  if (qpm?.length > 0) qpmString = `?${qpm.join("&")}`;

  if (query?.name?.length > 0) {
    const res = await AspApi.get(
      `/api/v1/svc/whatsapp/template_utils/check_template_name${qpmString}`
    );
    return res;
  }

  return null;
};

export const deleteTemplate = async (id, brandId) => {
  const res = await AspApi.delete(
    `/api/v1/svc/whatsapp/whatsapp_templates/${id}?brand_id=${brandId}`
  );
  return res;
};

export const templateSyncApi = async (brandId) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/template_utils/template_sync?brand_id=${brandId}`
  );
  return res;
};

export const synctSingleTemplate = async (brandId, templateId) => {
  const res = await AspApi.put(
    `/api/v1/svc/whatsapp/template_utils/single_template_sync?brand_id=${brandId}&meta_template_id=${templateId}`
  );
  return res;
};

export const templateMetrix = async (brandId) => {
  const res = await AspApi.get(
    `api/v1/svc/whatsapp/template_utils/template_overview?brand_id=${brandId}`
  );
  return res;
};

export const createCarouselStep1 = async (payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/carousel_templates`,
    payLoad
  );
  return res;
};

export const carouselAddCard = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/template_cards`,
    payLoad
  );
  return res;
};

export const carouselSaveDraft = async (id, payLoad) => {
  const res = await AspApi.put(
    `/api/v1/svc/whatsapp/carousel_templates/${id}`,
    payLoad
  );
  return res;
};

export const carouselCardUpdate = async (id, cardId, payLoad) => {
  const res = await AspApi.put(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/template_cards/${cardId}`,
    payLoad
  );

  return res;
};

export const carouselDelete = async (id, payload) => {
  const res = await AspApi.delete(
    `/api/v1/svc/whatsapp/carousel_templates/${id}`,
    {
      data: payload,
    }
  );
  return res;
};

export const carouselCardDelete = async (id, cardId, payLoad) => {
  const res = await AspApi.delete(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/template_cards/${cardId}`,
    {
      data: payLoad,
    }
  );
  return res;
};

export const carouselCardDrag = async (id, payLoad) => {
  const res = await AspApi.put(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/update_card_order`,
    payLoad
  );
  return res;
};

export const carouselApprovedEdit = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/initiate_edit`,
    payLoad
  );
  return res;
};

// End point should be changed for all templates using this get api....

export const carouselApprovedGet = async (id, brandId) => {
  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/carousel_templates/${id}?brand_id=${brandId}`
  );
  return res;
};

export const carouselDiscard = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/discard_edit`,
    payLoad
  );
  return res;
};

export const carouselDuplicate = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/whatsapp/carousel_templates/${id}/duplicate_template`,
    payLoad
  );
  return res;
};
