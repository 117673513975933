import React, { useEffect, useState } from "react";

// assets
import { ICONS } from "assets/icons";

// helper
import { cn } from "helper/cn";

// API
import { metrix } from "../api/APi";

// redux
import { useAspSelector } from "../../../test/jest-redux-hooks";

// constants
const CardsList = [
  {
    id: 1,
    title: "Total Templates",
    icon: ICONS?.Message,
    count_name: "template_count",
  },
  {
    id: 2,
    title: "Marketing",
    icon: ICONS?.Marketing,
    count_name: "marketing_count",
  },
  {
    id: 3,
    title: "Utility",
    icon: ICONS?.Bell,
    count_name: "utility_count",
  },
  {
    id: 4,
    title: "Authentication",
    icon: ICONS?.Services,
    count_name: "authentication_count",
  },
];

export default function CountCards() {
  // redux
  const { currentBrand } = useAspSelector((state) => state?.app);

  // state
  const [metrixData, setMetrixData] = useState(null);

  useEffect(() => {
    getMetrix(currentBrand?.brand_id);
  }, [currentBrand]);

  const getMetrix = async (brandId) => {
    if (brandId) {
      try {
        const res = await metrix(brandId);
        setMetrixData(res?.data);
      } catch (e) {
        console.warn(e);
      }
    }
  };

  return (
    <div className="flex items-center justify-between pt-[1.7vw] gap-[1.2vw]">
      {CardsList?.map((card) => {
        const newData = {
          ...card,
          count: metrixData?.[card?.count_name] || 0,
        };
        return <Card key={card?.id} data={newData} />;
      })}
    </div>
  );
}

function Card(props) {
  const { data, className, ...prop } = props;
  return (
    <div
      className={cn(
        "w-full max-w-[13vw] h-[6.05vw] bg-[#FFFFFFDB] backdrop-blur-sm shadow-[0px_4px_30px_0px_#0000001F] rounded-[1vw] px-[1.2vw] pt-[1vw]",
        className
      )}
      {...prop}
    >
      <div className="weight-semibold">
        <h1 className="text-[var(--font-600)] font-md weight-semibold">
          {data?.title}
        </h1>
        <p className="pt-[1vw] font-large text-[var(--font-600)]">
          {data?.count ?? 0}
        </p>
      </div>
      <div className="w-full flex justify-end -mt-[0.8vw]">
        <img
          src={data?.icon}
          alt={`${data?.title} icon`}
          className="w-[2.2vw] h-[2.2vw]"
        />
      </div>
    </div>
  );
}
