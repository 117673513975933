import React from "react";

// assets
import { ICONS } from "../../../assets/icons";
import Whatsapp from "../../../assets/customSVG/Whatsapp";

// helper
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";
import { dateConverter } from "../../../helper/dateConverter";
import { useNavigate } from "react-router-dom";

export default function BroadcastListCard({ broadcast }) {
  const navigate = useNavigate();
  const data = { ...broadcast, id: broadcast?.id };

  return (
    <div
      key={broadcast?.id}
      className="flex items-center gap-2 p-2.5 border border-[#F0F1F2] rounded-2xl shadow-[0px_1.5px_2px_0px_#1018281A] cursor-pointer"
      onClick={() => {
        navigate(
          `/user/campaign/time_specified/segment_list/${broadcast?.id}`,
          {
            state: {
              item: data,
            },
          }
        );
      }}
    >
      <div
        className={`contents-[""] w-[0.25vw] h-[2.3vw] rounded-full`}
        style={{
          backgroundColor: broadcast?.color,
        }}
      />

      <div className="w-full flex flex-col justify-center gap-[0.3vw]">
        <div className="w-full font-md weight-semibold text-ellipsis line-clamp-1 flex items-center gap-[0.5vw]">
          <div className="w-[1vw] h-[1vw] bg-[var(--primary)] flex-center rounded-full">
            <Whatsapp width={"0.7vw"} height={"0.7vw"} />
          </div>
          {/* {capitalizeFirstLetter(broadcast?.name)} */}
          {broadcast?.name}
        </div>

        <div className="w-full flex items-center justify-between text-[var(--font-400)] font-xs weight-semibold">
          <div className="flex items-center gap-[0.5vw]">
            <img
              src={ICONS?.Calender}
              alt="icon"
              className="w-[0.75vw] h-[0.75vw]"
            />
            {dateConverter(broadcast?.schedule_on, false)?.timeWithMeridiem}
          </div>
          <div className="flex items-center gap-[0.4vw]">
            <div className="flex items-center gap-[0.2vw]">
              <img src={ICONS?.uploadFile} className="size-[0.9vw]" />
              <p className="">{broadcast?.uploaded_files_count || 0}</p>
            </div>
            <div className="flex items-center gap-[0.2vw]">
              <img
                src={ICONS?.BroadcastSegmentIcons}
                className="size-[0.9vw]"
              />
              <p className="">{broadcast?.segments_count || 0}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
