import Website from 'assets/customSVG/sequence/Website';
import Instagram from 'assets/customSVG/sequence/Instagram';
import Meta from 'assets/customSVG/sequence/Meta';
import QRCode from 'assets/customSVG/sequence/QRCode';
import FB from 'assets/customSVG/sequence/FB';
import Broadcast from 'assets/customSVG/sequence/Broadcast';

export const TRIGGERS_ICONS = {
  broadcast: {
    icon: Broadcast,
    color: '#FFFFFF',
    bg: '#04B8A2',
  },
  qr_code: {
    icon: QRCode,
    color: '#FFFFFF',
    bg: '#FFC261',
  },
  fb: {
    icon: FB,
    color: '#FFFFFF',
    bg: '#0082FB',
  },
  meta: {
    icon: Meta,
    color: '#FFFFFF',
    bg: '#0082FB',
  },
  instagram: {
    icon: Instagram,
    color: '#FFFFFF',
    bg: 'linear-gradient(45deg, #FFC107 9.15%, #F44336 50.57%, #9C27B0 90.03%)',
  },
  website: {
    icon: Website,
    color: '#FFFFFF',
    bg: '#0082FB',
  },
};

export const TRIGGERS = [
  {
    id: 1,
    title: 'Triggers',
    list: [
      {
        id: 1,
        label: 'Broadcast',
        text: 'Engage your audience with sequences',
        key: 'broadcast',
        disabled: false,
        warning: false,
        error: false,
        bg: 'var(--primary)',
      },
    ],
  },
  {
    id: 2,
    title: 'Suggested Triggers',
    list: [
      {
        id: 1,
        label: 'QR Code scan',
        text: 'Bring users from the QR code scan',
        key: 'qr_code',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 2,
        label: 'Message via Post',
        text: 'Response when a user message from Post',
        key: 'fb',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 3,
        label: 'Message via Profile',
        text: 'Lorem ipsum dolor sit amet consectetu',
        key: 'instagram',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 4,
        label: 'Website',
        text: 'Bring users from the website.',
        key: 'website',
        disabled: true,
        warning: false,
        error: false,
      },
      {
        id: 5,
        label: 'Message via Page',
        text: 'Response when a user message from Page',
        key: 'fb',
        disabled: true,
        warning: false,
        error: false,
      },
    ],
  },
  {
    id: 3,
    title: 'Ads Trigger',
    list: [
      {
        id: 1,
        label: 'Meta Ads (CTWA)',
        text: 'Bring users from Facebook ads',
        key: 'meta',
        disabled: true,
        warning: false,
        error: false,
      },
    ],
  },
];

export const NODE_TYPES = {};

export const INITIAL_TRIGGER_DATA = {
  broadcast: {
    trigger_type: 'CAMP_WAPP_BCAST',
    label: 'Broadcast',
    component_type: 'CUSTOM',
    isExisting: true,
  },
};

export const INITIAL_NODE_COMPONENT_DATA = {
  TEXT: {
    type: 'text',
    text: {
      link: '',
    },
  },
  VIDEO: {
    type: 'video',
    video: {
      link: '',
    },
  },
  interactive: {},
  list: {},
  template: {},
  nudge: {},
  follow_up: {},
};

export const ALLOWED_HEADER_NODE_TYPES = ['MEDIA'];
export const ALLOWED_BODY_NODE_TYPES = ['TEXT'];
export const ALLOWED_FOOTER_NODE_TYPES = [];
export const ALLOWED_BUTTONS_NODE_TYPES = [];

export const INITIAL_NEW_BROADCAST_DATA = {
  isExisting: false,
  file: null,
  fileUrl: null,
  sendFrom: null,
  template: null,
  settings: null,
};

export const INITIAL_EXISTING_BROADCAST_DATA = {
  selectedBroadcast: null,
  isExisting: true,
};

export const SEND_MESSAGE_TYPES = [
  {
    id: 1,
    label: 'Text Message',
    key: 'text',
  },
  {
    id: 2,
    label: 'Media Message',
    key: 'media',
  },
  {
    id: 3,
    label: 'Interactive Message',
    key: 'interactive',
  },
  {
    id: 4,
    label: 'List Message',
    key: 'list',
  },
  {
    id: 5,
    label: 'Template Message',
    key: 'template',
  },
];

export const NONE_TYPE = {
  id: 1,
  label: 'None',
  key: 'NONE',
};

export const MEDIA_TYPES = [
  // {
  //   id: 2,
  //   label: 'Text',
  //   key: 'text',
  // },
  {
    id: 2,
    label: 'Image',
    key: 'IMAGE',
  },
  {
    id: 3,
    label: 'Video',
    key: 'VIDEO',
  },
  {
    id: 4,
    label: 'Audio',
    key: 'AUDIO',
  },
  {
    id: 5,
    label: 'Document',
    key: 'DOCUMENT',
  },
];

export const FOOTER_TYPES = [
  NONE_TYPE,
  {
    id: 2,
    label: 'Text',
    key: 'text',
  },
];

export const BUTTON_TYPES = [
  NONE_TYPE,
  {
    id: 2,
    label: 'Quick Reply',
    key: 'quick_replay',
  },
];
