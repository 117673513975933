import React from "react";
import { Bar, Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { ICONS } from "../../../assets/icons";
import FilterHome from "../../../components/commonComponents/filterHome/FilterHome";
import { HomeFilterList } from "../../../constant/app/Filter/Filter";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ConversationSource = () => {
  const fetchApiByFilter = (filter) => {
    switch (filter) {
      case "Last 7 Days":
        break;
      case "This Month":
        break;
      default:
        break;
    }
  };
  const salesData = [
    { date: "WhatsApp", sales: 5500, accounts: 3500, bg: "#A175FF" },
    { date: "Facebook", sales: 3000, accounts: 3000, bg: "#A175FF" },
    { date: "Instagram", sales: 2000, accounts: 2000, bg: "#A175FF" },
    { date: "Website", sales: 270, accounts: 2700, bg: "#A175FF" },
    { date: "QR Code", sales: 190, accounts: 1900, bg: "#A175FF" },
  ];

  const labels = salesData.map((item) => item.date);
  const salesValues = salesData.map((item) => item.sales);
  const accountsValues = salesData.map((item) => item.accounts);

  const maxAccountsValue = Math.max(...salesValues);
  const xAxisMax = Math.ceil((maxAccountsValue * 1.1) / 100) * 100;

  const data = {
    labels: labels,
    datasets: [
      {
        data: salesValues,
        fill: false,
        backgroundColor: "#25C277",
        borderColor: "#25C277",
        borderWidth: 1,
        borderRadius: 5,
        // barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
    },
    barPercentage: 0.5,
    categoryPercentage: 0.5,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        beginAtZero: true,
        max: xAxisMax,
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
          stepSize: 1000,
        },
      },
    },
    animation: {
      onComplete: (chart) => {
        const chartInstance = chart.chart;
        const ctx = chartInstance.ctx;

        ctx.font = "12px Arial";
        ctx.fillStyle = "#000";
        ctx.textAlign = "left";
        ctx.textBaseline = "middle";

        chartInstance.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = dataset.data[index];
            const xPosition = bar.x + 5;
            const yPosition = bar.y;

            ctx.fillText(data, xPosition, yPosition);
          });
        });
      },
    },
  };

  return (
    <div className="bg-white rounded-[1vw] py-[1vw] px-[2vw] w-100">
      <div className="flex-row justify-between items-center pb-4">
        <p className="flex-row items-center text-[1.09rem] font-[500]">
          Conversation Source
          <span className="ml-2">
            <img src={ICONS?.homeDownload} className="w-[18px] h-[18px]" />
          </span>
        </p>

        <FilterHome
          fetchApiByFilter={fetchApiByFilter}
          filterList={HomeFilterList}
        />
      </div>

      <div style={{ height: "30vh", padding: "0vw 1vw" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default ConversationSource;
