import React, { useEffect, useState } from "react";

import { images } from "../../../../../assets/images";

import SegmentHeader from "./SegmentHeader";

import { useLocation, useNavigate } from "react-router-dom";
import {
  useAspDispatch,
  useAspSelector,
} from "../../../../../test/jest-redux-hooks";
import { setPageNavigate } from "../../../../../reduxToolkit/CampaignSlice";

const SegmentList = () => {
  const location = useLocation();
  const broadcastList = location?.state?.item;
  const campaignOverviewData = location?.state?.data;
  const navigate = useNavigate();

  const header = location?.state?.item?.campaign_id;

  const handleBackClick = (header) => {
    const backPath = `/user/campaign/view/${header}/execution`;
    navigate(backPath, { state: { data: campaignOverviewData } });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const period = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${month} ${day}, ${hours}:${minutes} ${period}`;
  };

  const timestamp = broadcastList?.created_at;
  const formattedDate = formatDate(timestamp);

  return (
    <div className="campaignHeader-container w-100">
      <div className="campaignHeader-wrapper flex-row align-center">
        <div className="flex-row align-center">
          <img
            src={images?.CampaignBackIcon}
            alt="backIcon"
            onClick={() => handleBackClick(header, campaignOverviewData)}
            className="pr-10 pl-10 pointer"
          />
        </div>

        <div className="border-left flex-column p-10">
          <div className="flex-row align-center pointer">
            <span className="campaignHeader-campaign">
              {broadcastList?.name}
            </span>
            <div className="Awareness flex-row align-center">
              <span className="">Broadcast</span>
            </div>
          </div>
          <div className=" campaignUpdates flex-row">
            <span>Created @</span>
            <span className="pl-5">{formattedDate}</span>
          </div>
        </div>
      </div>
      <SegmentHeader broadcastList={broadcastList} />
    </div>
  );
};

export default SegmentList;
