import React, { useEffect, useState } from 'react';

// constants
import { ACTIONS_LIST, OPERATIONS_LIST } from '../../../constants/triggers';

// helpers
import { cn } from '../../../../../helper/cn';

// components
import TriggerListContainer from './TriggerListContainer';

const tabsList = ['actions', 'operations'];

export default function TriggerModel({
  type = 'list',
  open,
  setOpen,
  position = { top: 0, left: 0 },
  onTriggerClick,
}) {
  const [selectedTab, setSelectedTab] = useState('actions');

  useEffect(() => {
    if (!open) onTabChange(tabsList?.[0]);
  }, [open]);

  const onTabChange = (tab) => setSelectedTab(tab);

  const tabStyle = 'capitalize text-sm weight-medium text-[var(--font-600)]';

 

  return (
    <>
      {type === 'list' ? (
        <div className=''>
          <div>
            <h3 className={cn('px-4 pt-2.5', tabStyle)}>Actions</h3>
            <TriggerListContainer triggers={ACTIONS_LIST} />
          </div>
          <div>
            <h3 className={cn('px-4 pt-2.5', tabStyle)}>Operations</h3>
            <TriggerListContainer triggers={OPERATIONS_LIST} />
          </div>
        </div>
      ) : (
        <div
          className={cn(
            'w-fit min-w-[21rem] h-fit absolute bg-white shadow rounded-lg',
            type === 'modal' &&
              `top-[${position.top}px] left-[${position.left}px]`
          )}
        >
          <div>
            <div className='flex items-center gap-2.5 px-4 pt-4 border-b border-[var(--border-50)]'>
              {tabsList?.map((tab) => {
                return (
                  <div
                    key={tab}
                    className={cn(
                      'py-0.5 px-1 capitalize border-b-2 text-base cursor-pointer',
                      tabStyle,
                      selectedTab === tab
                        ? 'border-[#05805C] text-[#05805C]'
                        : 'border-transparent text-[#898E99]'
                    )}
                    onClick={() => {
                      onTabChange(tab);
                    }}
                  >
                    {tab}
                  </div>
                );
              })}
            </div>
            <div className='p-1.5'>
              {selectedTab === 'actions' ? (
                <TriggerListContainer
                  triggers={ACTIONS_LIST}
                  onTriggerClick={onTriggerClick}
                />
              ) : (
                <TriggerListContainer
                  triggers={OPERATIONS_LIST}
                  onTriggerClick={onTriggerClick}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
