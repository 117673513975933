import { addCtaButton } from "components/commonComponents/templates/Buttons/utils";

import { getVariablesFromStr, removeDoubleStyles } from "helper/templates";
import { isValidHttpsUrl } from "helper/checkUrls";

import { updateVariablesOrder } from "../components/templates";
import { carouselButtonOptions } from "../constant/template";

import { checkMaxNewLines, checkMultipleNewLines } from "./common";
import { getVariableValue, updateVariablesFromText } from "./variables";

export let buttonLabels = {
  PHONE_NUMBER: "Call Phone Number",
  URL: "Visit website",
  CUSTOM: "Quick Reply",
  NONE: "None",
};

export const isCard = (cards, key) =>
  cards?.filter((c) => c[key] === "" || c[key] === null);

export const isCarouselValid = (carousel) => {
  const isCardHeader = isCard(carousel?.cards, "image");
  const isCardBody = isCard(carousel?.cards, "bodyText");

  const lineError = [];
  const maxLine = [];

  const emptyButtons = [];
  carousel?.cards?.map((c) => {
    const checkLine = checkMultipleNewLines(c?.bodyText);
    const checkMaxLine = checkMaxNewLines(c?.bodyText);
    if (checkLine?.length > 0) {
      lineError?.push(true);
    }

    if (checkMaxLine) {
      maxLine?.push(checkMaxLine);
    }
    c?.buttons?.map((b) => {
      if (b?.type === "PHONE_NUMBER")
        if (b?.text === "") emptyButtons?.push(b);
        else if (b?.phone_number === "") emptyButtons?.push(b);
      if (b?.type === "URL")
        if (b?.text === "") emptyButtons?.push(b);
        else if (b?.example?.[0] === "") emptyButtons?.push(b);
        else if (!isValidHttpsUrl(b.example?.[0])) emptyButtons?.push(b);
      if (b.text === "") emptyButtons?.push(b);
    });
  });

  const isCarouselVariables = carousel?.bodyVariables?.filter(
    (v) => v.value === ""
  );

  return (
    isCardHeader?.length === 0 &&
    isCardBody?.length === 0 &&
    carousel?.cards?.length > 1 &&
    emptyButtons?.length === 0 &&
    isCarouselVariables?.length === 0 &&
    lineError?.length === 0 &&
    maxLine?.length === 0
  );
};

export const updateCarouselCards = (cards, id, key, value, data = null) => {
  return cards?.map((d) => {
    if (id !== null) {
      if (d?.id === id)
        if (key === "buttons")
          return {
            ...d,
            [key]: updateCardButtons(d?.buttons, data?.id, data),
          };
        else return { ...d, [key]: value || true };
      return { ...d, [key]: value || false };
    } else return { ...d, [key]: value };
  });
};

export const updateCardButtons = (buttons, id, data) => {
  return buttons?.map((b) => {
    if (b?.id === id) return { ...b, ...data, id: b?.id };
    return b;
  });
};

// export const updateCarouselButtons = (cards, button1, button2, key, value) => {
//   let newButton1 =
//     key === 'button1' ? (value ? value?.format : button1) : button1;

//   let newButton2 =
//     key === 'button2' ? (value ? value?.format : 'NONE') : button2;

//   const res = cards?.map((c) => {
//     let buttons = [];
//     if (key === 'button1' && value) {
//       buttons.push({
//         id: 1,
//         ...c.buttons[0],
//         ...value,
//         label:
//           value?.format === 'QUICK_REPLY'
//             ? 'CUSTOM'
//             : carouselButtonOptions[value?.format],
//       });
//     } else if (key !== 'button1') {
//       buttons.push(c?.buttons?.[0]);
//     }

//     if (key === 'button2' && value) {
//       buttons.push({
//         id: 2,
//         ...c.buttons[1],
//         ...value,
//         label:
//           value?.format === 'CUSTOM'
//             ? 'CUSTOM'
//             : carouselButtonOptions[value?.format],
//       });
//     } else if (key !== 'button2' && c.buttons?.length > 1) {
//       buttons.push(c?.buttons?.[1]);
//     }
//     return { ...c, buttons: buttons };
//   });

//   return { button1: newButton1, button2: newButton2, cards: res };
// };

export const updateCarouselButtons = (cards, button1, button2, key, value) => {
  let newButton1 =
    key === "button1" ? (value ? value?.format : button1) : button1;

  let newButton2 =
    key === "button2" ? (value ? value?.format : "NONE") : button2;

  const res = cards?.map((c) => {
    let buttons = [];
    if (key === "button1" && value) {
      const obj = {
        id: 1,
        ...c.buttons[0],
        ...value,
        label:
          value?.format === "QUICK_REPLY"
            ? "CUSTOM"
            : carouselButtonOptions[value?.format],
      };
      if (value?.format) obj["code"] = "+91";
      buttons.push(obj);
    } else if (key !== "button1") {
      buttons.push(c?.buttons?.[0]);
    }

    if (key === "button2" && value) {
      const obj = {
        id: 2,
        ...c.buttons[1],
        ...value,
        label:
          value?.format === "CUSTOM"
            ? "CUSTOM"
            : carouselButtonOptions[value?.format],
      };
      if (value?.format) obj["code"] = "+91";
      buttons.push(obj);
    } else if (key !== "button2" && c.buttons?.length > 1) {
      buttons.push(c?.buttons?.[1]);
    }
    return { ...c, buttons: buttons };
  });

  return { button1: newButton1, button2: newButton2, cards: res };
};
const getId = (items) => {
  return items?.length === 0
    ? 0
    : items?.reduce((maxId, item) => (item > maxId ? item : maxId), 0);
};

export const createNewCardButtons = (data) => {
  let buttons = [];
  buttons?.push({
    ...addCtaButton(null, data?.button1),
    id: 1,
    slug: "",
    label:
      data?.button1 === "QUICK_REPLY"
        ? "CUSTOM"
        : carouselButtonOptions[data?.button1],
  });
  if (data?.button2 !== "NONE")
    buttons?.push({
      ...addCtaButton(null, data?.button2),
      id: 2,
      slug: "",
      label: data?.button2 === "CUSTOM" ? "CUSTOM" : data?.button2,
    });

  const names = data?.cards?.map((c) => c?.name);
  const numbers = names?.map((name) => {
    return +name?.split(" ")[1];
  });

  const id = getId(numbers) + 1;

  const newCard = {
    id: id,
    card_id: id,
    image: null,
    name: `Card ${id}`,
    slug: "",
    bodyText: "",
    display_order: id,
    whatsapp_carousel_template_id: null,
    bodyVariables: [],
    buttons: buttons,
    isSelected: false,
  };

  return newCard;
};

export const updateCarouseOrder = (data) => {
  return data?.sort((a, b) =>
    a.display_order > b.display_order
      ? 1
      : b.display_order > a.display_order
      ? -1
      : 0
  );
};

// variables
export const updateCarouselVariables = (
  cards,
  bodyVariables,
  id,
  key,
  value,
  data
) => {
  const variables = [];
  const updatedCards = cards?.map((c) => {
    if (id === c?.id) {
      if (key === "bodyText") {
        const cardBodyVariables = updateVariablesFromText(value, bodyVariables);
        cardBodyVariables?.map((v) => {
          const checkArr = variables?.filter((a) => a?.title === v?.title);
          if (checkArr?.length === 0) variables.push({ ...v, value: v?.value });
        });
        return { ...c, bodyVariables: cardBodyVariables };
      } else if (key === "buttons") {
        return { ...d, [key]: updateCardButtons(d?.buttons, data?.id, data) };
      } else {
        return { ...d, [key]: value || true };
      }
    }
    return c;
  });

  return { cards: updatedCards, bodyVariables: variables };
};

export const updateCarouselCard = (
  cards,
  bodyVariables1,
  bodyVariables2,
  id,
  key,
  value,
  data = null
) => {
  const variables = [];
  const updatedCards = cards?.map((c) => {
    if (id === c?.id) {
      const text = key === "bodyText" ? value : c.bodyText;
      const cardBodyVariables = updateVariablesFromText(
        text,
        bodyVariables1,
        bodyVariables2
      );
      cardBodyVariables?.map((v) => {
        const checkArr = variables?.filter((a) => a?.title === v?.title);
        if (checkArr?.length === 0) variables.push(v);
      });
      if (key === "bodyText") {
        const cardBodyVariables = updateVariablesFromText(
          c?.bodyText,
          bodyVariables1,
          bodyVariables2
        );
        cardBodyVariables?.map((v) => {
          const checkArr = variables?.filter((a) => a?.title === v?.title);
          if (checkArr?.length === 0) variables.push(v);
        });
        return {
          ...c,
          bodyText: value,
          bodyVariables: cardBodyVariables,
        };
      } else if (key === "buttons") {
        return {
          ...c,
          bodyVariables: cardBodyVariables,
          [key]: updateCardButtons(c?.buttons, data?.id, data),
        };
      }
      // else if (key === "image") {
      //   let header = c?.components?.filter((com) => com?.type === "HEADER")
      //   let balanceComponents = c?.components?.filter(
      //     (com) => com?.type === "HEADER"
      //   )

      //   header = {
      //     ...header?.[0],
      //     example: {
      //       ...header?.[0]?.example,
      //       header_handle: [value],
      //     },
      //   }
      //   return {
      //     ...c,
      //     bodyVariables: cardBodyVariables,
      //     components: [header, ...balanceComponents],
      //   }
      // }
      else {
        return { ...c, bodyVariables: cardBodyVariables, [key]: value || true };
      }
    }

    const cardBodyVariables = updateVariablesFromText(
      c.bodyText,
      bodyVariables1,
      bodyVariables2
    );
    cardBodyVariables?.map((v) => {
      const checkArr = variables?.filter((a) => a?.title === v?.title);
      if (checkArr?.length === 0) variables.push(v);
    });

    return { ...c, bodyVariables: cardBodyVariables };
  });

  return { cards: updatedCards, bodyVariables: variables };
};

//  format api carousel data (need to optimize)
const updateCradsVariables = (cards, variables, order = "default") => {
  let arr = [];

  const res = cards?.map((c, i) => {
    c?.bodyVariables?.map((v) => {
      const checkArr = arr?.filter((a) => a?.title === v?.title);
      const bodyVariable = getVariableValue(variables, v?.title);
      if (checkArr?.length === 0)
        arr.push({ ...v, value: bodyVariable?.value || v?.value });
    });
    if (order === "asc") return { ...c, id: i + 1 };
    return c;
  });
  return {
    cards: res,
    variables: arr,
  };
};

export const formatCarouelDataFromAPI = (data) => {
  const body =
    data?.components?.length > 0 &&
    data?.components?.filter((c) => c?.type === "BODY");
  let carouselBodyVariables = null;

  if (body?.[0]?.example?.body_text?.[0]?.length > 0) {
    carouselBodyVariables = updateVariablesOrder(
      body?.[0].text,
      body?.[0]?.example?.body_text?.[0]
    );
  }

  let cards = data?.cards;
  // if (!cards || cards?.length === 0) {
  //   const newData = data?.message?.template?.filter(
  //     (c) => c?.type === 'CAROUSEL'
  //   );
  //   cards = newData?.[0]?.cards;
  // } else cards = [];

  const header =
    cards?.[0]?.components?.length > 0 &&
    cards?.[0]?.components?.filter((c) => c?.type === "HEADER");

  const updatedCards = cards?.map((c, i) => {
    const header =
      c?.components?.length > 0 &&
      c?.components?.filter((c) => c?.type === "HEADER");
    const buttons =
      c?.components?.length > 0 &&
      c?.components?.filter((c) => c?.type === "BUTTONS");
    const body =
      c?.components?.length > 0 &&
      c?.components?.filter((c) => c?.type === "BODY");
    const bodyVariables = getVariablesFromStr(
      body?.[0]?.text,
      body?.[0]?.example?.body_text?.[0]
    );

    const newButtons = buttons?.[0]?.buttons?.map((b, i) => {
      if (b?.type === "PHONE_NUMBER")
        return {
          ...b,
          format: b?.type,
          id: i + 1,
          label: buttonLabels[b?.type],
          code: b?.phone_number?.split(" ")[0],
          phone_number: b?.phone_number?.split(" ")[1],
        };

      return {
        ...b,
        format: b?.type,
        id: i + 1,
        label: buttonLabels[b?.type],
      };
    });

    return {
      ...c,
      image: header?.[0]?.example?.header_handle?.[0] || "",
      bodyText: body?.[0]?.text || "",
      bodyVariables: bodyVariables?.length > 0 ? bodyVariables : [],
      buttons: newButtons || [
        {
          id: 1,
          type: "QUICK_REPLY",
          format: "CUSTOM",
          text: "",
          label: "CUSTOM",
          slug: "",
        },
      ],
      isSelected: i === 0 ? true : false,
      card_id: i + 1,
    };
  });

  const { cards: newCards, variables } = updateCradsVariables(
    updatedCards,
    carouselBodyVariables
  );

  const buttons =
    cards?.[0]?.components?.length > 0 &&
    cards?.[0]?.components?.filter((c) => c?.type === "BUTTONS");

  const changeButtonsType = (type) => {
    return type === "QUICK_REPLY" ? "CUSTOM" : type;
  };

  return {
    body: body?.length > 0 ? body?.[0]?.text : null,
    bodyVariables: carouselBodyVariables,
    carousel: {
      header: header?.[0]?.format || "IMAGE",
      button1: changeButtonsType(buttons?.[0]?.buttons?.[0]?.type) || "CUSTOM",
      button2: changeButtonsType(buttons?.[0]?.buttons?.[1]?.type) || "NONE",
      bodyVariables: variables,
      cards: newCards || [],
    },
  };
};

export const formatCarouelDataToAPI = ({ body, bodyVariables }) => {
  let component = [];
  if (bodyVariables?.length > 0) {
    component.push({
      type: "BODY",
      text: body,
      example: {
        body_text: [
          bodyVariables?.map((b) => {
            return b.value;
          }),
        ],
      },
    });
  } else {
    component.push({
      type: "BODY",
      text: removeDoubleStyles(body),
    });
  }

  return {
    components: [...component, { type: "CAROUSEL", cards: [] }],
  };
};
