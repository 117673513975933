import React, { useRef } from 'react';

// helpers
import { cn } from '../../../../../helper/cn';

const BodyMessage = ({ placeholder, value, onChange, labelClassName }) => {
  const textAreaRef = useRef(value ?? '');

  return (
    <>
      <h5 className={cn('pb-2.5', labelClassName)}>Body</h5>
      <div className='w-full border border-[#E8E8EA] rounded-md'>
        <textarea
          className='w-full h-32 resize-none bg-transparent text-sm weight-medium px-2.5 py-1'
          placeholder={placeholder ?? 'Enter text'}
          value={value}
          ref={textAreaRef}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
        />
      </div>
      <p className='text-sm weight-medium mt-1'>
        You can send manual messages within the{' '}
        <span className='text-[#6564CA]'>
          24-hour
          <br /> conversation window.
        </span>
      </p>
    </>
  );
};

export default BodyMessage;
