import React from 'react';

// assets
import { ICONS } from 'assets/icons';

// components
import AddButtons from 'components/commonComponents/templates/Buttons/AddButtons';
import CalltoAction from 'components/commonComponents/templates/Buttons/CalltoAction';
import QuickReplay from 'components/commonComponents/templates/Buttons/QuickReplay';

export default function TemplateButtons({
  buttons,
  ctaButtons,
  customButtons,
  order,
  updateButtons,
  allow,
  category,
}) {
  return (
    <>
      <div>
        <h3 className='w-full weight-semibold text-[var(--contentText)] flex items-center gap-[0.2vw]'>
          <span className='font-default'>Button</span>
          <span className='font-md'>(Optional)</span>
        </h3>
        <p className='font-md weight-medium text-[var(--font-400)] pt-[0.03vw] pb-[0.5vw]'>
          Create buttons that let customers respond to your message or take
          action.
        </p>
      </div>
      <>
        <div className='w-[7.5vw] pt-[0.7vw]'>
          <AddButtons
            allow={allow}
            buttons={buttons}
            updateButtons={updateButtons}
            category={category}
          />
        </div>
        <div className='font-md weight-medium text-[var(--darkgrey)] py-[0.8vw] flex items-center gap-[0.8vw]'>
          <img
            src={ICONS?.step2PopupIcon}
            alt='info'
            className='w-[1vw] h-[1vw]'
          />
          <p>If you add more than three buttons, they will appear in a list.</p>{' '}
        </div>
      </>
      {order && (
        <div className='w-full flex flex-col gap-[1vw] py-[1vw]'>
          {order === 'ctaButtons' ? (
            <>
              {ctaButtons?.length > 0 && (
                <CalltoAction
                  ctaButtons={ctaButtons}
                  updateButtons={updateButtons}
                  allow={allow}
                />
              )}
              {customButtons?.length > 0 && (
                <QuickReplay
                  customButtons={customButtons}
                  updateButtons={updateButtons}
                  allow={allow}
                  category={category}
                />
              )}
            </>
          ) : (
            <>
              {customButtons?.length > 0 && (
                <QuickReplay
                  customButtons={customButtons}
                  updateButtons={updateButtons}
                  allow={allow}
                  category={category}
                />
              )}
              {ctaButtons?.length > 0 && (
                <CalltoAction
                  ctaButtons={ctaButtons}
                  updateButtons={updateButtons}
                  allow={allow}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
