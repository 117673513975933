import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function CampaignsRoot() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/user/campaign")
      navigate("/user/campaign/all-campaigns");
  }, [location]);

  return (
    <>
      <Outlet />
    </>
  );
}
