import React from 'react';

const Key = ({
  width = 24,
  height = 24,
  color = 'white',
  strokeWidth = '2',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='1'
        y='1'
        width='22'
        height='21.9999'
        rx='11'
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d='M14.0674 14.4921C14.9718 14.4938 15.8564 14.2272 16.609 13.7258C17.3617 13.2245 17.9487 12.511 18.2956 11.6758C18.6425 10.8406 18.7337 9.92123 18.5577 9.03414C18.3817 8.14705 17.9464 7.33216 17.3069 6.69267C16.6674 6.05317 15.8525 5.61786 14.9654 5.44185C14.0784 5.26585 13.159 5.35707 12.3238 5.70397C11.4886 6.05087 10.7751 6.63784 10.2738 7.39053C9.77242 8.14322 9.50573 9.02778 9.50748 9.93215C9.50779 10.4051 9.58049 10.8752 9.72307 11.3261L5.5382 15.5051C5.4799 15.5625 5.43367 15.6311 5.40223 15.7066C5.37078 15.7822 5.35476 15.8633 5.35509 15.9451V18.0125C5.35352 18.0952 5.36849 18.1773 5.39914 18.2541C5.42979 18.3309 5.47549 18.4008 5.53355 18.4597C5.59161 18.5186 5.66086 18.5653 5.73723 18.597C5.8136 18.6288 5.89555 18.6449 5.97825 18.6445H8.04558C8.12752 18.6449 8.20873 18.629 8.28451 18.5978C8.36029 18.5667 8.42914 18.5208 8.48709 18.4628C8.54503 18.4049 8.59092 18.336 8.62209 18.2603C8.65327 18.1845 8.66912 18.1033 8.66873 18.0213V16.9847H9.70831C9.87334 16.9839 10.0314 16.918 10.1481 16.8013C10.2648 16.6846 10.3307 16.5266 10.3315 16.3616V15.322H11.3681C11.4499 15.3223 11.531 15.3063 11.6066 15.2748C11.6822 15.2434 11.7507 15.1972 11.8081 15.1389L12.6735 14.2765C13.1244 14.4191 13.5945 14.4918 14.0674 14.4921ZM15.107 7.8471C15.3127 7.8471 15.5138 7.90814 15.6849 8.02251C15.8559 8.13687 15.9891 8.2994 16.0677 8.48953C16.1463 8.67966 16.1667 8.88883 16.1264 9.09057C16.086 9.29231 15.9867 9.47754 15.841 9.62281C15.6954 9.76807 15.5098 9.86685 15.308 9.90663C15.1061 9.94641 14.897 9.9254 14.7071 9.84626C14.5172 9.76713 14.3551 9.63342 14.2412 9.46208C14.1273 9.29073 14.0668 9.08945 14.0674 8.88372C14.0674 8.74659 14.0946 8.61082 14.1474 8.48427C14.2003 8.35773 14.2777 8.24292 14.3752 8.14651C14.4727 8.05009 14.5884 7.97398 14.7155 7.92259C14.8427 7.8712 14.9787 7.84554 15.1159 7.8471H15.107Z'
        fill={color}
      />
    </svg>
  );
};

export default Key;
