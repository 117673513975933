import React from "react";

export default function BtnInput({
  type = "text",
  value,
  placeholder,
  count,
  onChange,
  onBlur,
  error,
  ButtonDataTestId,
  disabled = false,
}) {
  return (
    <div
      className={`w-full flex items-center gap-[0.3vw] pl-[0.4vw] py-[0.4vw] border-[0.08vw] rounded-[0.4vw] ${
        value && !error ? "border-[var(--border-100)]" : "border-red-400"
      } ${
        disabled
          ? "bg-[var(--BG-25)] cursor-not-allowed"
          : "cursor-text bg-transparent"
      }`}
    >
      <input
        type={type}
        value={value ?? ""}
        maxLength={count}
        placeholder={placeholder ?? "Button text"}
        className="w-full font-md weight-medium"
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
        onBlur={(e) => {
          if (onBlur) onBlur();
        }}
        disabled={disabled}
        data-testid={ButtonDataTestId}
      />
      <p className="w-auto font-sm weight-medium text-[var(--font-400)] text-end pr-[0.3vw] ">
        {value?.length ?? 0}/{count}
      </p>
    </div>
  );
}
