import React from "react";

const Resolved = ({ color, fillColor, lineColor }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_19285_96186)">
          <path
            d="M18.3346 10.0003C18.3346 5.39795 14.6036 1.66699 10.0013 1.66699C5.39893 1.66699 1.66797 5.39795 1.66797 10.0003C1.66797 14.6027 5.39893 18.3337 10.0013 18.3337C14.6036 18.3337 18.3346 14.6027 18.3346 10.0003Z"
            stroke={color}
            stroke-width="1.67"
            fill={fillColor}
          />
          <path
            d="M6.66797 10.4167L8.7513 12.5L13.3346 7.5"
            stroke={lineColor}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_19285_96186">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Resolved;
