import { findMaxId } from "..";

export const addNode = ({ nodes, type = "", data = {} }) => {
  const id = findMaxId(nodes);

  const newNode = {
    id: id + 1,
    label: "Add Node",
    is_trigger: false,
    component_type: type,
    type: "",
    trigger_type: "",
    order: 1,
    data: data,
    settings: {
      defaults: {
        isEditMode: false,
        isExpand: false,
        isSelected: false,
      },
    },
    status: "NEW",
    coordinates: {
      x: 800,
      y: 300,
    },
  };

  return [...nodes, newNode];
};

export const updateNode = ({
  nodes,
  id,
  data,
  primaryKey,
  secondaryKey,
  key,
}) => {
  return nodes?.map((n) => {
    if (id === n?.id)
      if (primaryKey && secondaryKey && key)
        return {
          ...n,
          [primaryKey]: {
            ...n[primaryKey],
            [secondaryKey]: {
              ...n[primaryKey][secondaryKey],
              [key]: data,
            },
          },
        };
      else if (primaryKey && !secondaryKey && key)
        return {
          ...n,
          [primaryKey]: {
            ...n[primaryKey],
            [key]: data,
          },
        };
      else if (!primaryKey && !secondaryKey && key)
        return {
          ...n,
          [key]: data,
        };
      else if (!primaryKey && !secondaryKey && !key) return { ...n, ...data };
    return n;
  });
};

export const updateNodesCoordination = ({ nodes, id, data }) => {
  return nodes?.map((n) => {
    if (id === n?.id)
      return {
        ...n,
        coordinates: {
          x: n.coordinates.x + data?.x,
          y: n.coordinates.y + data?.y,
        },
      };
    return n;
  });
};
