import React, { useState } from "react";

// assets
import { ICONS } from "assets/icons";
import Avatar from "assets/customSVG/Avatar";

// helper
import { timeSince } from "helper/timeConversion";
import { abbrNum } from "helper/abbrNum";
import { replaceFormating } from "../../helper";

// components
import ConversationStar from "../conversation/ConversationStar";
import ConversationClose from "../conversation/ConversationClose";
import {
  FEATURE_FLAG_ADD_TO_STAR,
  FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION,
} from "../../constants/FeatureFlag";
import { Popover, Tooltip } from "antd";
import moment from "moment";
import { CONTACT_FILTERS } from "../../constants";
import { cn } from "../../../../helper/cn";
import CheckBoxSqure from "../../../../components/commonComponents/checkbox/CheckBoxSqure";
import Favourite from "../../../../assets/customSVG/inbox/Favourite";
import Resolved from "../../../../assets/customSVG/inbox/Resolved";
import Sent from "../../../../assets/customSVG/inbox/Sent";
import Delivered from "../../../../assets/customSVG/inbox/Delivered";
import RadioButton from "../../../../components/form/radio/RadioButton";

const mediaIcons = {
  image: ICONS?.imageInbox,
  IMAGE: ICONS?.imageInbox, // Optional, if you need both cases
  video: ICONS?.videoInbox,
  VIDEO: ICONS?.videoInbox,
  document: ICONS?.documentInbox,
  DOCUMENT: ICONS?.documentInbox,
  audio: ICONS?.audioInbox,
  AUDIO: ICONS?.audioInbox,
  gif: ICONS?.gifInbox,
  GIF: ICONS?.gifInbox,
};

export default function CustomerPhoneNumberCard({
  item,
  selectedPerson,
  onSelectPerson,
  onFavoriteClick,
  onResolvedClick,
  onConversationStarChange,
  onCloseConversation,
  favorite,
  resolved,
}) {
  const {
    from_number,
    to_number,
    message,
    body,
    updated_at,
    last_send_at,
    unread_count,
    first_name,
    last_name,
    profile_name,
    resolve_status,
    is_customer_msg,
    status,
  } = item;

  // const newdate = moment(updated_at).format("YYYY-MM-DD");
  // const newdate = timeSince(updated_at, "single", false);

  // const newdate = new Date(last_send_at).toLocaleDateString("en-US", {
  //   month: "short",
  //   day: "numeric",
  //   year: "numeric",
  // });

  function formatRelativeTime(timestamp) {
    const currentTime = new Date();
    const pastTime = new Date(timestamp);
    const diffMs = currentTime - pastTime;
    const diffMinutes = Math.floor(diffMs / (1000 * 60));
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60));

    if (diffMinutes < 60) {
      return `${diffMinutes} min`;
    }

    if (diffHours < 24) {
      return `${diffHours} hr`;
    }

    return pastTime.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  }

  // Usage
  const lastSent = last_send_at; // Replace with your timestamp
  const newdate = formatRelativeTime(lastSent);

  const name =
    first_name && last_name
      ? `${first_name} ${last_name}`
      : first_name !== "UNKNOWN"
      ? first_name
      : last_name
      ? last_name
      : first_name === "UNKNOWN"
      ? profile_name
      : profile_name && profile_name;

  const dotStyle =
    'size-1.5 bg-[var(--BG-100)] contents-[""] rounded-full group-hove/dotsr:!bg-[var(--BG-100)]';

  const [hoveredDate, setHoveredDate] = useState(false);

  const handleMouseOver = () => {
    setHoveredDate(true);
  };

  const handleMouseOut = () => {
    setHoveredDate(false);
  };
  const messageTemplate = message?.template?.map((item) => item);

  // Extract only the BODY text
  const bodyText = messageTemplate
    ?.filter((item) => item.type === "BODY")
    ?.map((item) => item.text);

  const imageIcons = messageTemplate?.filter((item) => item.type === "HEADER");

  return (
    <div
      key={to_number}
      className={`w-full  border-[var(--border-50)] b-radius-6 flex-row align-center cursor-pointer transition-colors ease-linear duration-200  gap-x-[0.6vw] group   ${
        selectedPerson?.to_number === item?.to_number
          ? "bg-[#25C27729] border-l-2 !border-[#25C277]"
          : "bg-transparent border-b-[0.08vw] hover:bg-[#F4F6F8] hover:rounded-lg hover:border-b-[0]"
      }`}
      onMouseOver={() => handleMouseOver()}
      onMouseOut={handleMouseOut}
      onClick={() => {
        onSelectPerson(item);
      }}
    >
      <div
        className="flex items-center gap-x-[0.8vw]  p-[15px]"
        data-testid="singlePerson"
      >
        <div className="relative top-0 left-0">
          <div className="size-[2.18vw] rounded-full flex items-center justify-center font-largest weight-medium">
            <Avatar color="#ffffff" />
          </div>
          <img
            src={ICONS?.whatsApp}
            alt="inbox"
            className="w-[1.2vw] h-[1.2vw] border-[0.2vw] border-[var(--white)] rounded-full z-20 absolute -bottom-[0vw] -right-[0.4vw]"
          />
        </div>
        <div className=" flex flex-col">
          <div className="w-[13vw] flex-row align-center space-between">
            {name?.length > 10 ? (
              <Tooltip title={name} placement="top">
                <span className="w-[13vw] truncate  capitalize sidebar-header">
                  {name ?? to_number}
                </span>
              </Tooltip>
            ) : (
              <div className="sidebar-header w-[8vw] capitalize">
                {name ?? to_number}
              </div>
            )}
            {!hoveredDate && (
              <span className="inbox-date gap-1 w-100 flex justify-end">
                {newdate}
              </span>
            )}
            {hoveredDate && (
              <div
                onClick={() => {
                  onFavoriteClick(item);
                }}
              >
                <Favourite
                  color={
                    favorite === item?.id || item?.is_favourite === true
                      ? "var(--a)"
                      : "var(--font-600)"
                  }
                  applyFill={
                    (favorite === item?.id || item?.is_favourite === true) ??
                    "var(--a)"
                  }
                />
              </div>
            )}
          </div>

          <div className="flex-row align-center space-between w-100">
            <div className="flex-row align-center">
              <div className="pt-5">
                {!hoveredDate &&
                  item?.message_status &&
                  item?.message_status !== "bounced" && (
                    <>
                      {item?.message_status === "sent" ? (
                        <Sent color={"var(--font-600)"} />
                      ) : (
                        <Delivered
                          color={
                            item?.message_status === "delivered"
                              ? "var(--font-600)"
                              : "#1778F2"
                          }
                        />
                      )}
                    </>
                  )}
              </div>
              <div className="flex-row align-center pt-5">
                {mediaIcons[imageIcons?.[0]?.format] && (
                  <img
                    src={mediaIcons[imageIcons?.[0]?.format]}
                    alt={message?.type}
                    className="media-icon"
                  />
                )}

                <span
                  className="w-full max-w-[25ch] pl-5 inbox-conversation capitalize  text-ellipsis line-clamp-1"
                  dangerouslySetInnerHTML={{
                    __html:
                      replaceFormating(message?.text?.body) ||
                      replaceFormating(message?.body) ||
                      bodyText,
                  }}
                ></span>
              </div>

              {!["button"].includes(message?.type) && (
                <span className="inbox-conversation flex-row align-center gap-1 pt-5">
                  {mediaIcons[message?.type] && (
                    <img
                      src={mediaIcons[message?.type]}
                      alt={message?.type}
                      className="media-icon"
                    />
                  )}
                  {(message?.image?.caption ||
                    message?.video?.caption ||
                    message?.document?.caption ||
                    message?.gif?.caption ||
                    message?.audio?.caption) && (
                    <span className="w-[18ch] truncate">
                      {["image", "video", "document", "audio", "gif"].includes(
                        message?.type
                      )
                        ? message?.type === "video"
                          ? message?.video?.caption
                          : message?.type === "image"
                          ? message?.image?.caption
                          : message?.type === "document"
                          ? message?.document?.caption
                          : message?.type === "audio"
                          ? message?.audio?.caption
                          : message?.gif?.caption
                        : null}
                    </span>
                  )}
                  {!(
                    message?.image?.caption ||
                    message?.video?.caption ||
                    message?.document?.caption ||
                    message?.gif?.caption ||
                    message?.audio?.caption
                  ) && (
                    <span>
                      {["image", "video", "document", "audio", "gif"].includes(
                        message?.type
                      ) &&
                        message.type.charAt(0).toUpperCase() +
                          message.type.slice(1)}
                    </span>
                  )}
                </span>
              )}

              {["button"].includes(message?.type) && (
                <span className="Capitalize inbox-conversation">
                  {["button"].includes(message?.type) && message.button?.text}
                </span>
              )}
            </div>
            {hoveredDate && (
              <div
                onClick={() => {
                  onResolvedClick(item);
                }}
              >
                <>
                  {item?.resolve_status === false ? (
                    <div className="mr-[6px] mt-5">
                      <RadioButton
                        selected={true}
                        // disabled={item?.resolve_status === false}
                      />
                    </div>
                  ) : (
                    <Resolved
                      color={"var(--font-600)"}
                      lineColor={"var(--font-600)"}
                    />
                  )}
                </>
              </div>
            )}
            {!hoveredDate && item?.unread_count > 0 && (
              <span className="py-0.45 px-2 flex items-center justify-center rounded-full bg-[#6940F2] border-[var(--white)] text-[white] text-[0.65rem] font-[600]">
                {item?.unread_count > 99 ? "99+" : item?.unread_count}
              </span>
            )}
          </div>
        </div>
      </div>
      {/* <div className="min-w-[0.2vw] h-full overflow-hidden relative top-0 left-0">
        <div className="w-full h-full flex flex-col items-center gap-y-[0.3vw] py-[0.758vw] absolute top-0 left-0 opacity-100 transition-opacity ease-linear duration-200">
          <span className="text-[0.55vw] weight-semibold text-[var(--font-400)]"></span>
          {unread_count > 0 && (
            <span className="max-w-full h-[1.14vw] px-[0.4vw] font-xs weight-medium text-[var(--white)] bg-[var(--primary)] flex-center rounded-full">
              {abbrNum(unread_count, 2)}
            </span>
          )}
        </div>
        {(FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION ||
          FEATURE_FLAG_ADD_TO_STAR) && (
          <div className="w-full h-full flex flex-col items-center justify-center py-[0.2vw] gap-y-[0.5vw] bg-[var(--BG-25)] absolute top-0 left-0 opacity-0 group-hover:opacity-100  transition-opacity ease-linear duration-200">
            {FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION && (
              <ConversationClose
                className={"w-[0.9vw] h-[0.9vw]"}
                onChange={onCloseConversation}
                data={item}
              />
            )}
            {FEATURE_FLAG_ADD_TO_STAR && (
              <ConversationStar
                data={item}
                width={"0.95vw"}
                height={"0.95vw"}
                onChange={onConversationStarChange}
              />
            )}
          </div>
        )}
      </div> */}
    </div>
  );
}

{
  /* <Popover
              trigger="click"
              placement="bottom"
              content={
                <>
                  {hoveredDate && (
                    <div className="p-[15px]">
                      <div className="text-[var(--font-400)]">Options</div>

                      {CONTACT_FILTERS?.map((item) => (
                        <div
                          className={cn(
                            "w-full flex align-center  cursor-pointer new-body-text gap-2 p-10"
                          )}
                          key={item?.id}
                          // onClick={() => {
                          //   if (!loading) onConversationTypeChange(s);
                          // }}
                        >
                          <div className="w-5 h-8 flex items-center justify-center">
                            <CheckBoxSqure
                              value={item?.isSelected}
                              className="size-4"
                              onChange={() => {}}
                            />
                          </div>

                          {item?.icon && (
                            <item.icon color={"var(--textBlack)"} />
                          )}
                          <p>{item?.label}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              }
            >
              {hoveredDate && (
                <div className="group/dots  flex items-center justify-end gap-0.5 hover:cursor-pointer hover:bg-gray-100 rounded-md">
                  <span className={`${dotStyle}`}></span>
                  <span className={`${dotStyle}`}></span>
                  <span className={`${dotStyle}`}></span>
                </div>
              )}
           
            </Popover> */
}
