import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  overviewList: "Last 7 days",
};

export const overViewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    setoverviewList: (state, action) => {
      state.overviewList = action.payload;
    },
  },
});

export const { setoverviewList } = overViewSlice.actions;

export default overViewSlice.reducer;
