import React, { useEffect, useRef, useState } from "react";
import "../../styles/profile.css";
import ProfileInfoCard from "../../../inbox/components/profile/ProfileInfoCard";

import {
  AGE_RANGE,
  GENDER,
  ORIGINS,
} from "../../reducers/singleContactReducer";
import { updateSingleContactDetails } from "../../../../reduxToolkit/crmSlice";
import {
  crmContactTypes,
  crmPhoneDelete,
  crmProfessions,
  crmSingleContactView,
  crmTagRemove,
  crmTagSingleAssign,
} from "../../api/Api";
import { optedOut } from "../../constants";
import { ageRange, gender } from "../../../../constant/app/crm/crm";
import Select from "../../../../components/commonComponents/select";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Label from "../commonComponents/label";
import Tags from "../main/contact/Tags";
import AddCircle from "../../../../assets/customSVG/AddCircle";
import { DatePicker, Modal, Switch, Tooltip } from "antd";
import { hexToRgba } from "../../../../helper/colors";
import moment from "moment";
import { ICONS } from "../../../../assets/icons";
import dayjs from "dayjs";
import CustomDelete from "../../../../assets/customSVG/CustomDelete";
import { useParams } from "react-router-dom";
import { useAspSelector } from "../../../../test/jest-redux-hooks";
import { validateFirstName } from "../../helpers";
import CountryCode from "../../../../components/commonComponents/countryCode";
import { countryList } from "../../../../constant/app/countryList";
import AlertModal from "../../../../components/commonComponents/modal/alert/AlertModal";
import { updateToggleToast } from "../../../../reduxToolkit/appSlice";

const ProfileInformation = ({ data, onInfoChange }) => {
  const [contactType, setContactType] = useState([]);
  const [newContactActive, setNewContactActive] = useState(false);
  const [activeContactType, setActiveContactType] = useState(false);
  const [professionActive, setProfessionActive] = useState(false);
  const [hoveredComponent, setHoveredComponent] = useState(null);
  const [showAlert, setShowAlert] = useState(true);
  // const [origins, setOrigins] = useState([]);
  const [alertOpen, setAlertOpen] = useState({
    email: false,
    phone: false,
  });
  const [hoverState, setHoverState] = useState({
    phoneHover: false,
    emailHover: false,
  });

  const [profession, setProfession] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [currentTags, setCurrentTags] = useState(data?.tags);
  const datePickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const { singleContactDetails, tagList } = useSelector((store) => store.crm);
  const information = data?.information;

  const isHovered = (componentId) => hoveredComponent === componentId;
  const dispatch = useDispatch();

  const id = useParams();

  useEffect(() => {
    const filteredPhoneNumbers =
      data?.person_contacts?.filter(
        (contact) => contact?.contact_type === "MOBILE_PHONE"
      ) || [];

    setPhoneNumbers(
      filteredPhoneNumbers?.length > 0
        ? filteredPhoneNumbers
        : [{ phone_country_code: "", phone_number: "" }]
    );
  }, [data]);

  useEffect(() => {
    setCurrentTags(data?.tags);
  }, [data]);

  useEffect(() => {
    const filteredEmails =
      data?.person_contacts?.filter(
        (contact) => contact?.contact_type === "EMAIL"
      ) || [];
    setEmails(
      filteredEmails?.length > 0
        ? filteredEmails
        : [
            {
              contact_id: "",
              contact_category: "",
              contact_category_name: "",
              contact_source: "",
              contact_source_name: "",
              contact_type: "EMAIL",
              contact_type_name: "Email address",
              email: "",
              phone_country_code: "",
              phone_number: null,
              priority: 2,
            },
          ]
    );
  }, [data]);

  const addDiv = () => {
    setShowAlert(false);
    setEmails(emails);
    if (phoneNumbers?.length < 2) {
      onInfoChange({
        type: "update_phone_number",
        key: "add",
      });
    }
  };

  const addEmail = () => {
    onInfoChange({
      type: "update_email",
      key: "add_email",
    });
  };

  const handleNo = (type) => {
    setAlertOpen((prevState) => ({
      ...prevState,
      [type]: false,
    }));
  };

  const handleCancel = (type) => {
    setAlertOpen((prevState) => ({
      ...prevState,
      [type]: false,
    }));
  };

  const handleDeletePhone = (contactId) => {
    if (contactId) {
      setAlertOpen((prevState) => ({
        ...prevState,
        phone: true,
      }));
    }
  };

  const handleDeleteEmail = (contactId) => {
    if (contactId) {
      setAlertOpen((prevState) => ({
        ...prevState,
        email: true,
      }));
    }
  };

  const cp = id;

  const handleDelete = (contactId, key) => {
    crmPhoneDelete(cp?.id, contactId, currentBrand?.brand_id).then((res) => {
      if (res && res?.status === 200) {
        setAlertOpen(false);
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.data?.msg,
              status: "Success",
              duration: 3000,
            },
          ])
        );
        if (key === "email") {
          crmSingleContactView(currentBrand?.brand_id, cp?.id);
          setEmails((prevEmails) =>
            prevEmails?.filter((email) => email?.contact_id !== contactId)
          );
          window.location.reload();
        } else if (key === "phone") {
          crmSingleContactView(currentBrand?.brand_id, cp?.id);

          setPhoneNumbers((prevPhones) =>
            prevPhones?.filter((phone) => phone?.contact_id !== contactId)
          );
          window.location.reload();
        }
      } else {
        dispatch(
          updateToggleToast([
            ...toggleToast,
            {
              id: toggleToast?.length + 1,
              content: res?.response?.data?.error,
              status: "Error",
              duration: 3000,
            },
          ])
        );
      }
    });
  };

  const maxVisibleTags = 8;

  const visibleTags = currentTags?.slice(0, maxVisibleTags);
  const additionalTagCount = currentTags?.length - maxVisibleTags;

  const cp_id = id;

  const closeAction = async (id) => {
    const brandId = currentBrand?.brand_id;

    const updatedTags = singleContactDetails["selected_tags"].filter(
      (item) => item !== id
    );

    dispatch(
      updateSingleContactDetails({
        access_name: "selected_tags",
        value: updatedTags,
      })
    );

    setCurrentTags((prevTags) => {
      const newTags = prevTags.filter((item) => item.id !== id);
      return newTags;
    });

    if (brandId) {
      try {
        const response = await crmTagRemove(cp_id.id, id, brandId);
        if (response?.status === 200) {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: response?.data?.message,
                status: "Success",
                duration: 3000,
              },
            ])
          );
          crmSingleContactView(brandId, cp_id.id);
        } else {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: response?.response?.data?.error,
                status: "Error",
                duration: 3000,
              },
            ])
          );
        }
      } catch (error) {}
    }
  };

  const addAction = async (id) => {
    const brandId = currentBrand?.brand_id;

    if (!singleContactDetails["selected_tags"].includes(id)) {
      if (brandId) {
        try {
          const payLoad = { cp_id: cp_id.id };
          const response = await crmTagSingleAssign(cp_id?.id, id, brandId);
          if (response && response.status === 200) {
            const newTag = tagList.find((tag) => tag.id === id);

            setCurrentTags((prevTags) => [...prevTags, newTag]);
            dispatch(
              updateSingleContactDetails({
                access_name: "selected_tags",
                value: [...singleContactDetails.selected_tags, id],
              })
            );

            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: response?.data?.message,
                  status: "Success",
                  duration: 3000,
                },
              ])
            );
          } else {
            dispatch(
              updateToggleToast([
                ...toggleToast,
                {
                  id: toggleToast?.length + 1,
                  content: response?.response?.data?.error,
                  status: "Error",
                  duration: 3000,
                },
              ])
            );
          }
        } catch (error) {}
      }
    }
  };
  const handleProfession = () => {
    setProfessionActive(true);
  };
  // const contactOrigins = () => {
  //   contactOriginApi().then((res) => {
  //     if (res && res?.data?.contact_origin_types?.length > 0) {
  //       setOrigins(res?.data?.contact_origin_types);
  //     }
  //   });
  // };

  const contactTypes = () => {
    crmContactTypes().then((res) => {
      if (res && res?.data?.contact_types?.length > 0) {
        setContactType(res?.data?.contact_types);
      }
    });
  };

  const professions = () => {
    crmProfessions().then((res) => {
      if (res && res.data?.professions?.length > 0) {
        const updatedData = res?.data?.professions.map((p) => {
          return { ...p, label: p.name };
        });
        setProfession(updatedData);
      }
    });
  };

  useEffect(() => {
    // if (newContactActive) {
    //   contactOrigins();
    // }

    if (activeContactType) {
      contactTypes();
    }

    if (professionActive) {
      professions();
    }
  }, [activeContactType, professionActive]);

  const handleOrigin = () => {
    setNewContactActive(true);
  };
  const handleContactType = () => {
    setActiveContactType((prev) => !prev);
  };

  const [a, setA] = useState("");

  const ageR = {
    0: "2 - 17",
    1: "2 - 17",
    2: "18 - 24",
    3: "25 - 34",
    4: "35 - 44",
    5: "45 - 54",
    6: "54 - 64",
    7: "65+",
  };

  useEffect(() => {
    if (information?.dob) {
      crmSingleContactView(currentBrand?.brand_id, cp_id.id).then((res) => {
        setA(res?.data?.overview?.[0]?.age_range_code);
      });
    }
  }, [information?.dob]);

  const handleDateChange = (date) => {
    setIsOpen(false);
    if (date && date.$d) {
      const formattedDate = moment(date.$d).format("DD/MM/YYYY");

      onInfoChange({
        type: "update_info",
        id: information?.id,
        key: "dob",
        value: formattedDate,
      });
    }
  };

  const openDatePicker = () => {
    setIsOpen(!isOpen);
    if (datePickerRef.current) {
      datePickerRef.current.focus();
    }
  };

  return (
    <div className="flex-column">
      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Profile Information
      </h3>
      <div className="grid grid-cols-3 gap-5 mx-[30px] ">
        <ProfileInfoCard
          label={"First Name"}
          value={information?.first_name || ""}
          labelClassName="font-semibold font-large"
          inputContainerClassName="information-value"
          inputClassName="information-value"
          validation="textOnly"
          required
          count={25}
          onInputChange={(value) => {
            const isValid = validateFirstName(value);
            if (isValid) {
              onInfoChange({
                type: "update_info",
                id: information?.id,
                key: "first_name",
                value: value,
              });
            }
          }}
        />
        <ProfileInfoCard
          label={"Last Name"}
          value={information?.last_name || ""}
          labelClassName="font-semibold font-large"
          inputContainerClassName="information-value"
          inputClassName="information-value"
          validation
          required
          count={25}
          onInputChange={(value) => {
            const isValid = validateFirstName(value);
            if (isValid) {
              onInfoChange({
                type: "update_info",
                id: information?.id,
                key: "last_name",
                value: value,
              });
            }
          }}
        />
        <div className="flex-column ">
          <span className="info-headings mt-[8px]">Primary Location</span>
          <h3 className="!text-[.9vw] !font-[400] !text-[var(--font-600)] capitalize  mb-[1.5vw] bg-[var(--channelButton)] b-radius-6 mt-5 p-[5px]">
            {information?.province && information?.country
              ? `${information?.province}, ${information?.country}`
              : "--"}
          </h3>
        </div>

        <div className="flex-column">
          <label className="info-headings">Date of Birth</label>
          <div
            onMouseEnter={() => setHoveredComponent("dob")}
            onMouseLeave={() => setHoveredComponent(null)}
            className="mt-5"
          >
            <input
              type="text"
              value={information?.dob || ""}
              readOnly
              placeholder="MM / DD / YYYY"
              className="p-relative date-picker-input"
              onClick={openDatePicker}
            />
            <div ref={datePickerRef}>
              <DatePicker
                format="MM/DD/YYYY"
                onChange={handleDateChange}
                open={isOpen}
                allowClear={false}
                placeholder={false}
                suffixIcon={null}
                picker="date"
                showTime={false}
                inputReadOnly
                className="date-picker-crm"
                data-testid="Date-select"
              />
            </div>
          </div>
        </div>

        <div className="flex-column">
          <label htmlFor="Age_range" className="info-headings">
            Age Range
          </label>
          <div
            onMouseEnter={() => setHoveredComponent("ageRange")}
            onMouseLeave={() => setHoveredComponent(null)}
            className=""
          >
            {information?.dob ? (
              <div
                className="!text-[.9vw] !text-[#616874] !font-[400] mt-5"
                style={{ transform: "translate(0,7px)" }}
              >
                {ageR[a]}
              </div>
            ) : (
              <Select
                value={
                  AGE_RANGE[information?.age_range_code] ||
                  ageR[information?.age_range_code] ||
                  "--"
                }
                options={ageRange}
                onChange={(value) => {
                  onInfoChange({
                    type: "update_info",
                    key: "age_range_code",
                    value: value.id,
                  });
                }}
                rootClassName={`profile-contact border-[var(--border-50)] mt-5 br-6 transition-all duration-150 ease-linear ${
                  isHovered("ageRange") ? "p-[0.5vw]" : "!p-0"
                }`}
                placeholder={"Select"}
                position="bottom"
                optionClassName="p-20 border-b last:border-none"
                labelClassName="information-value"
                dropDownIcon={isHovered("ageRange")}
              />
            )}
          </div>
        </div>
        <div className="flex-column">
          <label htmlFor="Gender" className="info-headings">
            Gender
          </label>

          <div
            onMouseEnter={() => setHoveredComponent("gender")}
            onMouseLeave={() => setHoveredComponent(null)}
          >
            <Select
              value={GENDER[information?.gender] || ""}
              options={gender}
              onChange={(value) => {
                const newGender = value?.id;
                onInfoChange({
                  type: "update_info",
                  key: "gender",
                  value: newGender,
                });
              }}
              rootClassName={`profile-contact border-[var(--border-50)] mt-5 br-6 transition-all duration-150 ease-linear ${
                isHovered("gender") ? "p-[0.5vw]" : "!p-0"
              }`}
              placeholder={"Select"}
              position="bottom"
              optionClassName="p-20 border-b last:border-none"
              labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
              dropDownIcon={isHovered("gender")}
              dataTestId="gender-click"
              optionClick="option-click"
            />
          </div>
        </div>
        <div className="flex-column my-[28px]">
          <label className="info-headings">Contact Origin</label>

          {/* <div
            onMouseEnter={() => setHoveredComponent("origin")}
            onMouseLeave={() => setHoveredComponent(null)}
          >
            <Select
              value={
                ORIGINS[information?.contact_origin] ||
                information?.contact_origin ||
                "--"
              }
              // options={origins}
              // onChange={(value) => {
              //   if (value)
              //     onInfoChange({
              //       type: "update_info",
              //       key: "contact_origin",
              //       value: value.id,
              //     });
              // }}
              placeholder={"Select"}
              rootClassName={`profile-contact border-[var(--border-50)] mt-5 br-6 transition-all duration-150 ease-linear ${
                isHovered("origin") ? "p-[0.5vw]" : "!p-0"
              }`}
              position="bottom"
              optionClassName="profile-contact-options p-20"
              labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
              dropDownIcon={isHovered("origin")}
            />
          </div> */}
          <span className="!text-[.9vw] !text-[#616874] !font-[400]  mt-10">
            {ORIGINS[information?.contact_origin] ||
              information?.contact_origin ||
              "--"}
          </span>
        </div>

        <div className="flex-column mt-[25px]">
          <label className="info-headings ">Last Contacted</label>
          <span className="text-[.9vw] text-[#616874] mt-10">
            {information?.last_contacted_at
              ? dayjs(information?.last_contacted_at).format("MMM DD hh:mm a")
              : "--"}
          </span>
        </div>
      </div>

      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Tags
      </h3>
      <div className="mx-[30px]">
        <div className="flex-row align-center  gap-2">
          {Array.isArray(visibleTags) && visibleTags?.length > 0 ? (
            visibleTags?.map((tag) => {
              return (
                <Label
                  id={tag?.id}
                  key={tag?.id}
                  label={tag?.tag}
                  color={tag?.display_color}
                  style={{
                    backgroundColor: hexToRgba(tag?.display_color, 0.2),
                  }}
                  mainClassNames="w-40"
                  closeAction={() => closeAction(tag?.id)}
                  disableHover={true}
                />
              );
            })
          ) : (
            <span></span>
          )}
          {additionalTagCount > 0 && (
            <span className="text-[0.85vw] text-[var(--font-400)] bg-[var(--channelButton)] rounded-full p-1">{`+${additionalTagCount}`}</span>
          )}
        </div>
        <Tags
          tagList={tagList}
          addAction={addAction}
          closeAction={closeAction}
          Button={
            <p className="flex text-[#616874] text-[.8vw] items-center font-[500] gap-[.4vw] cursor-pointer mt-10 w-20">
              <AddCircle width="1vw" strokeWidth="2.5" stroke="#616874" />
              <span data-testid="Tag-click">Add a Tag</span>
            </p>
          }
        />
      </div>

      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Opt Out
      </h3>
      <div className="grid grid-cols-3 mx-[30px]">
        {optedOut?.map((opt) => {
          return (
            <div className="flex-column">
              <div key={opt?.name} className="flex-row align-center gap-2">
                <Switch
                  checked={data?.opted_out?.[opt?.type]}
                  size="small"
                  className=""
                  onChange={(checked) => {
                    let updatedValue = {
                      opted_out: {
                        [opt?.type]: checked,
                        // [`${opt?.type}_last_update`]: new Date().toISOString(),
                      },
                    };

                    if (updatedValue.value) {
                      delete updatedValue.value;
                    }

                    onInfoChange({
                      type: "update_info",
                      key: opt?.type,
                      value: updatedValue,
                    });
                  }}
                />

                <span className="font-[500] text-[0.9vw]">{opt?.name}</span>
                {data?.opted_out?.[opt?.type] ? (
                  <span className="opted_in">{opt?.in}</span>
                ) : (
                  <span className="opted_out">{opt?.out}</span>
                )}
              </div>
              {data?.opted_out?.[opt?.type] ? (
                <span className="text-[.7vw] font-[400] text-[--font-600] mt-5">
                  {`Opted in on
                ${dayjs(data?.opted_out?.[opt?.updateField])?.format(
                  "MMM DD hh:mm a"
                )}`}
                </span>
              ) : (
                <span className="text-[.7vw] font-[400] text-[--font-600] mt-5">
                  {/* Opted out on {data?.opted_out?.[opt?.updateField]} */}
                  {data?.opted_out?.[opt?.updateField]
                    ? `Opted out on ${dayjs(
                        data?.opted_out?.[opt?.updateField]
                      )?.format("MMM DD hh:mm a")}`
                    : ""}
                </span>
              )}
            </div>
          );
        })}
      </div>

      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Contact Information
      </h3>
      <div className="">
        <div className="grid grid-cols-4 gap-x-10  info-bottom mx-[30px]">
          <span className="info-headings mb-[15px]">Phone Number</span>
          <span
            className="info-headings mb-[15px] "
            style={{ transform: "translate(75px)" }}
          >
            Priority
          </span>
          <span
            className="info-headings ml-[14px] mb-[15px]"
            style={{ transform: "translate(32px)" }}
          >
            Contact Category
          </span>
          <span className="info-headings ml-[15px] mb-[15px]">
            Contact Source
          </span>
        </div>
        {phoneNumbers?.map((phoneNumber, index) => {
          return (
            <div className="grid grid-cols-4" key={phoneNumber?.id || index}>
              <div className="flex-row align-center space-between w-100 mx-[30px]">
                <div className="mt-5">
                  <CountryCode
                    value={"+91"}
                    options={countryList}
                    onChange={({ value }) => {
                      onInfoChange({
                        type: "update_phone_number",
                        key: "update",
                        index: index,
                        id: phoneNumber?.id,
                        value: {
                          contact_type: "MOBILE_PHONE",
                          contact_id: phoneNumber?.contact_id,
                          phone_number: "",
                          phone_country_code: value || 91,
                        },
                      });
                    }}
                    className="!min-w-[vw] !min-h-[1vh] mr-10 mt-5 p-[3px]"
                  />
                </div>

                <ProfileInfoCard
                  key={index}
                  count={10}
                  icon={ICONS?.infoPhone}
                  value={phoneNumber?.phone_number || ""}
                  validation="numberOnly"
                  labelClassName="font-semibold font-large"
                  inputContainerClassName="information-value1 w-[12vw]"
                  editIconsClassName="px-1"
                  showAlert={showAlert}
                  handleYes={(value) => {
                    if (showAlert) {
                      if (phoneNumber?.phone_number) {
                        dispatch(
                          updateToggleToast([
                            ...toggleToast,
                            {
                              id: toggleToast?.length + 1,
                              content: "Phone Number updated successfully",
                              status: "Success",
                              duration: 3000,
                            },
                          ])
                        );
                      }
                      onInfoChange({
                        type: "update_phone_number",
                        key: "update",
                        index: index,
                        id: phoneNumber?.id,
                        value: {
                          contact_type: "MOBILE_PHONE",
                          contact_id: phoneNumber?.contact_id,
                          phone_number: value,
                          phone_country_code:
                            phoneNumber?.phone_country_code || 91,
                        },
                      });
                    }
                  }}
                  onInputChange={(value) => {
                    if (!showAlert) {
                      onInfoChange({
                        type: "update_phone_number",
                        key: "update",
                        index: index,
                        id: phoneNumber?.id,
                        value: {
                          contact_type: "MOBILE_PHONE",
                          contact_id: phoneNumber?.contact_id,
                          phone_number: value,
                          phone_country_code:
                            phoneNumber?.phone_country_code || 91,
                        },
                      });
                    }
                  }}
                />

                {phoneNumber?.priority !== 1 && (
                  <span
                    onMouseOver={() =>
                      setHoverState({ ...hoverState, phoneHover: true })
                    }
                    onMouseLeave={() =>
                      setHoverState({ ...hoverState, phoneHover: false })
                    }
                    className="pointer mt-5"
                    style={{
                      background: hoverState.phoneHover
                        ? "#FFEEED"
                        : "transparent",
                      borderRadius: hoverState.phoneHover ? "6px" : "6px",
                    }}
                    onClick={() => handleDeletePhone(phoneNumber?.contact_id)}
                  >
                    <CustomDelete
                      color={hoverState?.phoneHover ? "#F96056" : "#898E99"}
                    />
                  </span>
                )}

                <Modal
                  open={alertOpen?.phone}
                  footer={null}
                  className={"asp-modal-popup-small"}
                  closable={false}
                  centered
                  mask={true}
                  wrapClassName={"bg-[#00000065]"}
                  destroyOnClose
                  style={{
                    width: "100%",
                    borderRadius: 20,
                    boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
                    overflow: "hidden",
                  }}
                >
                  <AlertModal
                    handleCancel={() => handleCancel("phone")}
                    handleYes={() => {
                      handleDelete(phoneNumber?.contact_id, "phone");
                    }}
                    handleNo={() => handleNo("phone")}
                    content={
                      <p>
                        If you Edit or Delete this contact, the chat history
                        will be lost?
                      </p>
                    }
                    yesName="No, Keep it"
                    noName="Yes, delete"
                    // loading={value}
                  />
                </Modal>
              </div>
              <div
                className="flex-column  "
                style={{ transform: "translate(100px)" }}
              >
                <div
                  className="pointer mt-[22px]"
                  onClick={() => {
                    if (phoneNumber?.phone_number !== null) {
                      onInfoChange({
                        type: "update_phone_number",
                        key: "update_priority_phonenumber",
                        index: index,
                        id: phoneNumber?.id,
                        value: {
                          contact_id: phoneNumber?.contact_id,
                          priority: 1,
                        },
                      });
                    }
                  }}
                >
                  {phoneNumber && phoneNumber?.priority === 1 ? (
                    <span className="contact-primary w-30">Primary</span>
                  ) : (
                    <div
                      className={`mark-primary flex-row align-center ${
                        phoneNumber?.phone_number === null
                          ? "cursor-not-allowed"
                          : ""
                      }`}
                    >
                      <img src={ICONS?.markPrimary} alt="markPrimary" />
                      <span>Mark as primary</span>
                    </div>
                  )}
                </div>
              </div>

              <div
                className="flex-column mt-[18px]"
                onClick={
                  phoneNumber?.phone_number !== null
                    ? handleContactType
                    : undefined
                }
                onMouseEnter={() => setHoveredComponent(phoneNumber?.id)}
                onMouseLeave={() => setHoveredComponent(null)}
                style={{ marginLeft: "65px" }}
              >
                <Select
                  value={phoneNumber?.contact_category_name || "Select"}
                  options={contactType}
                  // rootClassName="z-1000"
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      onInfoChange({
                        type: "update_phone_number",
                        key: "update",
                        index: index,
                        id: phoneNumber?.id,
                        value: {
                          // ...phoneNumber,
                          contact_category_name: selectedOption.name,
                          contact_type: phoneNumber?.contact_type,
                          contact_id: phoneNumber?.contact_id || "",
                          priority: phoneNumber?.priority,
                        },
                      });
                    }
                  }}
                  rootClassName={`profile-contact border-[var(--border-50)] mt-5 br-6 transition-all duration-150 ease-linear w-100 ${
                    isHovered(phoneNumber?.id) ? "p-[0.5vw]" : "!p-0"
                  }`}
                  dropDownIcon={isHovered(phoneNumber?.id)}
                  labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
                  placeholder="Select"
                  position="bottom"
                  optionClassName="p-20 border-b last:border-none"
                />
              </div>
              <div className="flex-column justify-center align-center mt-[18px]">
                <Tooltip title={phoneNumber?.contact_source_name}>
                  <h3 className="!text-[.9vw] !font-[400] !text-[var(--font-600)] capitalize  bg-[var(--channelButton)] b-radius-6 mt-5 p-[5px] truncate w-70">
                    {phoneNumber?.contact_source_name
                      ? phoneNumber?.contact_source_name
                      : ""}
                  </h3>
                </Tooltip>
              </div>
            </div>
          );
        })}

        {phoneNumbers?.length < 2 && (
          <div
            className={`add-number flex-row align-center p-5 pointer mt-10 mb-[20px] mx-[30px] ${
              phoneNumbers?.some(
                (phoneNumber) =>
                  !phoneNumber?.phone_country_code || !phoneNumber?.phone_number
              )
                ? "disabled-row bg-[var(--lightGrey)]"
                : ""
            }`}
            onClick={
              !phoneNumbers.some(
                (phoneNumber) =>
                  !phoneNumber?.phone_country_code || !phoneNumber?.phone_number
              )
                ? addDiv
                : undefined
            }
            data-testid="add-phone"
          >
            {!phoneNumbers?.some(
              (phoneNumber) =>
                !phoneNumber?.phone_country_code || !phoneNumber?.phone_number
            ) && <img src={ICONS?.addWhitePlus} alt="plus icon" />}
            <span
              className={`${
                phoneNumbers?.some(
                  (phoneNumber) =>
                    !phoneNumber?.phone_country_code ||
                    !phoneNumber?.phone_number
                )
                  ? "disabled-row pl-10"
                  : ""
              }`}
            >
              Add Phone Number
            </span>
          </div>
        )}

        <div className="grid grid-cols-4 info-bottom mt-[20px]">
          <span className="info-headings mb-[15px] mx-[30px]">Email</span>
          <span
            className="info-headings  mb-[15px] text-center"
            style={{ transform: "translate(10px)" }}
          >
            Priority
          </span>
          <span
            className="info-headings ml-[15px] mb-[15px]"
            style={{ transform: "translate(50px)" }}
          >
            Contact Category
          </span>
          <span
            className="info-headings ml-[15px] mb-[15px]"
            style={{ transform: "translate(15px)" }}
          >
            Contact Source
          </span>
        </div>

        {emails?.map((email, index) => {
          return (
            <div
              className=" grid grid-cols-4 gap-x-5 mt-10 mx-[28px]"
              key={email?.id || index}
            >
              <div className=" flex-row align-center">
                <ProfileInfoCard
                  key={index}
                  icon={ICONS?.infoMail}
                  value={email?.email || ""}
                  validation
                  count={40}
                  tooltipKey={true}
                  labelClassName="font-semibold font-large"
                  inputContainerClassName="information-value w-[15vw]"
                  inputClassName="information-value"
                  // inputValueClass="truncate w-50"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "15ch",
                  }}
                  onInputChange={(value) => {
                    onInfoChange({
                      type: "update_email",
                      key: "updated_email",
                      index: index,
                      id: email?.id || null,
                      value: {
                        contact_type: "EMAIL",
                        contact_id: email?.contact_id || "",
                        email: value,
                      },
                    });
                  }}
                />

                {email?.priority !== 1 && (
                  <span
                    onMouseOver={() =>
                      setHoverState({ ...hoverState, emailHover: true })
                    }
                    onMouseLeave={() =>
                      setHoverState({ ...hoverState, emailHover: false })
                    }
                    className="pointer mt-5"
                    style={{
                      background: hoverState?.emailHover
                        ? "#FFEEED"
                        : "transparent",
                      borderRadius: hoverState?.emailHover ? "6px" : "6px",
                      transform: "translate(60px)",
                    }}
                    onClick={() => handleDeleteEmail(email?.contact_id)}
                  >
                    <CustomDelete
                      color={hoverState?.emailHover ? "#F96056" : "#898E99"}
                    />
                  </span>
                )}

                <Modal
                  open={alertOpen?.email}
                  footer={null}
                  className={"asp-modal-popup-small"}
                  closable={false}
                  centered
                  mask={true}
                  wrapClassName={"bg-[#00000065]"}
                  destroyOnClose
                  style={{
                    width: "100%",
                    borderRadius: 20,
                    boxShadow: "0px 10px 24px 0px rgba(79, 92, 128, 0.15)",
                    overflow: "hidden",
                  }}
                >
                  <AlertModal
                    handleCancel={() => handleCancel("email")}
                    handleYes={() => {
                      handleDelete(email?.contact_id, "email");
                    }}
                    handleNo={() => handleNo("email")}
                    content={
                      <p>
                        If you Edit or Delete this contact, the chat history
                        will be lost?
                      </p>
                    }
                    yesName="No, Keep it"
                    noName="Yes, delete"
                    // loading={value}
                  />
                </Modal>
              </div>

              <div className="flex-column  align-center ">
                <div
                  className="mt-[22px] pointer"
                  onClick={() => {
                    if (email?.email !== null) {
                      onInfoChange({
                        type: "update_email",
                        key: "update_priority_email",
                        index: index,
                        id: email?.id || null,
                        value: {
                          contact_id: email?.contact_id || null,
                          priority: 1,
                        },
                      });
                    }
                  }}
                >
                  {email.priority === 1 ? (
                    <span
                      className="contact-primary w-30 ml-20"
                      // style={{ transform: "translate(55px)" }}
                    >
                      Primary
                    </span>
                  ) : (
                    <div
                      className={`mark-primary2 flex-row align-center gap-2 ${
                        email?.email === null ? "cursor-not-allowed" : ""
                      }`}
                      style={{ transform: "translate(38px)" }}
                    >
                      <img src={ICONS?.markPrimary} alt="markPrimary" />
                      <span>Mark as primary</span>
                    </div>
                  )}
                </div>
              </div>

              <div
                onMouseEnter={() => setHoveredComponent(email?.id)}
                onMouseLeave={() => setHoveredComponent(null)}
                className="flex-column mt-[17px]"
                style={{ marginLeft: "55px" }}
                onClick={handleContactType}
              >
                <Select
                  value={email?.contact_category_name || "--"}
                  options={contactType}
                  onChange={(value) => {
                    onInfoChange({
                      type: "update_email",
                      key: "updated_email",
                      index: index,
                      id: email?.id || null,
                      value: {
                        contact_category_name: value.name,
                        contact_type: email?.contact_type,
                        contact_id: email?.contact_id || null,
                        priority: email?.priority,
                      },
                    });
                  }}
                  rootClassName={`profile-contact border-[var(--border-50)] mt-5 br-6 transition-all duration-150 ease-linear w-100 ${
                    isHovered(email?.id) ? "p-[0.6vw]" : "!p-0"
                  }`}
                  dropDownIcon={isHovered(email?.id)}
                  labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
                  placeholder={"Select"}
                  position="bottom"
                  optionClassName="p-20 border-b last:border-none"
                />
              </div>
              <div
                className="flex-column mt-[18px]"
                style={{ transform: "translate(30px)" }}
              >
                <Tooltip title={email?.contact_source_name}>
                  <h3 className="!text-[.9vw] !font-[400] !text-[var(--font-600)] capitalize  bg-[var(--channelButton)] b-radius-6 mt-5 p-[5px] truncate w-80">
                    {email?.contact_source_name
                      ? email?.contact_source_name
                      : "--"}
                  </h3>
                </Tooltip>
              </div>
            </div>
          );
        })}

        {emails?.length < 2 && (
          <div
            className={`add-number flex-row align-center p-5 pointer mt-10 mb-[20px] mx-[30px] ${
              emails?.some((email) => !email?.email)
                ? "disabled-row bg-[var(--lightGrey)]"
                : ""
            }`}
            onClick={
              !emails?.some((email) => !email?.email) ? addEmail : undefined
            }
          >
            {!emails?.some((email) => !email?.email) && (
              <img src={ICONS?.addWhitePlus} alt="plus icon" />
            )}
            <span
              className={`${
                emails?.some((email) => !email?.email)
                  ? "disabled-row pl-10"
                  : ""
              }`}
              data-testid="Add-email"
            >
              Add Email
            </span>
          </div>
        )}
      </div>

      <h3 className="font-[600] capitalize text-black mb-[1.5vw] text-[.95vw] bg-[var(--channelButton)] b-radius-6 pl-10 pt-5 pb-5 m-[20px]">
        Profession Information
      </h3>
      <div className="grid grid-cols-3 mx-[30px] gap-10">
        {data?.profession
          ?.sort((d1, d2) => d1?.priority - d2?.priority)
          ?.map((p) => {
            const professionKey = `profession_${p?.id}`;

            return (
              <div className="flex-column">
                <label
                  className="info-headings"
                  data-testid={
                    p?.priority === 1 ? "profession" : "secondary-profession"
                  }
                >
                  {p?.priority === 1 ? "Profession" : "Secondary Profession"}
                </label>

                <div
                  onMouseEnter={() => setHoveredComponent(professionKey)}
                  onMouseLeave={() => setHoveredComponent(null)}
                  onClick={handleProfession}
                >
                  <Select
                    value={p?.name || "--"}
                    options={profession}
                    onChange={(value) => {
                      const isDuplicate = data?.profession?.some(
                        (prof) =>
                          prof?.id !== p?.id && prof?.name === value?.name
                      );

                      if (isDuplicate) {
                        dispatch(
                          updateToggleToast([
                            ...toggleToast,
                            {
                              id: toggleToast?.length + 1,
                              content:
                                "This profession is already associated with another contact person.",
                              status: "Error",
                              duration: 1000,
                            },
                          ])
                        );
                        return;
                      }
                      onInfoChange({
                        type: "profession",
                        id: p?.id,
                        key: "update_profession",
                        value: {
                          id: value?.id,
                          contact_person_profession_id:
                            p?.contact_person_profession_id || "",
                          priority: p?.priority,
                          name: value?.name,
                        },
                      });
                    }}
                    placeholder={"Select"}
                    rootClassName={`profile-contact border-[var(--border-50)] mt-5 br-6 transition-all duration-150 ease-linear ${
                      isHovered(professionKey) ? "p-[0.5vw]" : "!p-0"
                    }`}
                    position="bottom"
                    optionClassName="profile-contact-options p-20"
                    labelClassName="!text-[.9vw] !font-[400] !text-[var(--font-600)]"
                    dropDownIcon={isHovered(professionKey)}
                  />
                </div>

                <div className="mt-[15px]  pointer">
                  {p?.priority === 1 ? (
                    <span className="contact-primary w-25">Primary</span>
                  ) : (
                    <div
                      className={`mark-primary1 flex-row align-center ${
                        p?.name ? "" : "cursor-not-allowed"
                      }`}
                      onClick={
                        p?.name
                          ? () => {
                              onInfoChange({
                                key: "update_professions_primary",
                                type: "profession",
                                id: p?.id,
                                value: {
                                  id: p?.id,
                                  contact_person_profession_id:
                                    p?.contact_person_profession_id,
                                  priority: 1,
                                },
                              });
                            }
                          : null
                      }
                    >
                      <img
                        src={ICONS?.markPrimary}
                        alt="markPrimary"
                        className="pr-5"
                      />
                      <span>Mark as primary</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProfileInformation;
