import { ICONS } from "../../../assets/icons/index";

export const conversationSummaryList = [
  {
    id: 1,
    label: "Total Conversations",
    key: "total_conversations",
    color: "#5C3BBF",
    img: ICONS?.totalConversations,
  },
  {
    id: 2,
    label: "Active",
    key: "active",
    color: "#32CDCD",
    img: ICONS?.active,
  },
  {
    id: 3,
    label: "Unread",
    key: "unread",
    color: "#AC2F82",
    img: ICONS?.unread,
  },
  {
    id: 4,
    label: "Resolved",
    key: "resolved",
    color: "#0ACD95",
    img: ICONS?.resolved,
  },
];

export const connectWhatsapp = [
  {
    id: 1,
    title: "Connect WhatsApp Business API",
    subtitle: " Connect WhatsApp Business Platform (API)",
    description:
      "To apply WhatsApp Business API, you'll need a registered business and a functioning website",
    button: "Connect with Facebook",
  },
  {
    id: 2,
    title: "Start Business Verification",
    subtitle: "WhatsApp Business Verification",
    description:
      "Lorum To apply WhatsApp Business API, you'll need a registered business and a functioning website 2",
    button: "Start Business Verification",
  },
  {
    id: 3,
    title: "Add Payment Method",
    subtitle: "Payment Method",
    description:
      "To apply WhatsApp Business API, you'll need a registered business and a functioning website 3",
    button: "Manage Payment",
  },
  {
    id: 4,
    title: "Send Broadcast",
    subtitle: "Send Broadcast",
    description:
      "To apply WhatsApp Business API, you'll need a registered business and a functioning website 4",
    button: "Create Broadcast",
  },
  {
    id: 5,
    title: "Apply Green Tick Verification",
    subtitle: "Green Tick Verification",
    description:
      "Complete your Facebook manager verification to proceed with green tick for your business",
    button: "Verify now",
  },
];
