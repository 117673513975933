export const updateCommonReducerData = ({
  state,
  primaryKey,
  secondaryKey,
  key,
  data,
}) => {
  if (primaryKey && secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [secondaryKey]: {
        ...state[primaryKey][secondaryKey],
        [key]: { ...state[primaryKey][secondaryKey][key], ...data },
      },
    };
  else if (primaryKey && !secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [key]: { ...state[primaryKey][key], ...data },
    };
  else if (!primaryKey && !secondaryKey && key)
    state[key] = { ...state[key], ...data };

  return state;
};

export const updateCommonReducerValue = ({
  state,
  primaryKey,
  secondaryKey,
  key,
  value,
}) => {
  if (primaryKey && !secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [key]: value,
    };
  else if (primaryKey && secondaryKey && key)
    state[primaryKey] = {
      ...state[primaryKey],
      [secondaryKey]: {
        ...state[primaryKey][secondaryKey],
        [key]: value,
      },
    };
  else if (!primaryKey && !secondaryKey && key) state[key] = value;

  return state;
};

export const findMaxId = (arr) => {
  if (arr.length === 0) return null; // Return null if the array is empty

  return arr.reduce((max, current) => {
    return current.id > max ? current.id : max;
  }, arr[0].id); // Initial value is the id of the first element
};

export const isObjectEmpty = (obj) => {
  if (typeof obj !== 'object' || obj === null) return false;

  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
