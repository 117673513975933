import React, { useCallback } from 'react';

// assets
import { ICONS } from '../../../../assets/icons';

// utils
import { getCTAButtons, updateOptoutTolast } from './utils';

// constant
import { CUSTOM_BUTTONS_LIST } from 'constant/app/channel/templates';

// redux
import { useAspSelector } from '../../../../test/jest-redux-hooks';

// components
import QuickReplayBtn from './QuickReplayBtn';

export default function QuickReplay({
  customButtons,
  updateButtons,
  allow,
  disabled,
  category,
}) {
  const { currentBrand } = useAspSelector((state) => state.app);

  const buttonOptions = useCallback(() => {
    return CUSTOM_BUTTONS_LIST?.map((b) => {
      if (b?.type === 'OPT_OUT') {
        const opt_out = getCTAButtons(customButtons, 'OPT_OUT')?.length;
        return {
          ...b,
          disabled:
            category === 'CUSTOM' ? (opt_out === 1 ? true : false) : true,
        };
      }
      return b;
    });
  }, [customButtons, category]);

  return (
    <div className='w-full border-[0.08vw] border-[var(--card-border)] rounded-[0.4vw] px-[0.4vw] py-[0.5vw]'>
      <h3 className='text-[var(--contentText)] font-default weight-semibold'>
        Quick reply
      </h3>
      <div className=''>
        {customButtons?.map((d, i) => {
          return (
            <div className='w-full h-auto py-[0.5vw]'>
              <div
                key={`quick-replay-${i}`}
                className='w-full h-[2.4vw] flex items-center gap-[0.4vw] my-[0.6vw] rounded-[0.4vw] last:mb-0'
              >
                {/* <div className='w-[1vw] h-[1vw]'>
                  <img
                    src={ICONS?.dragVertical}
                    alt='drag'
                    style={{
                      width: '1.2vw',
                      height: '1.2vw',
                    }}
                  />
                </div> */}
                <QuickReplayBtn
                  options={buttonOptions()}
                  data={d}
                  index={i}
                  updateButtons={updateButtons}
                  allow={allow}
                />
              </div>
              {d?.format === 'OPT_OUT' && (
                <div className='flex items-center gap-[0.5vw] !text-[0.65vw] weight-medium text-[var(--font-600)] px-[0.5vw]'>
                  <img
                    src={ICONS?.Selected}
                    alt='selected'
                    className='size-[1.1vw] cursor-not-allowed'
                  />
                  <p>
                    I understand that it's {currentBrand?.brand_name}{' '}
                    responsibility to stop sending marketing messages to
                    customers who opt out.
                  </p>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
