import React from 'react';

export default function ClearFilters({
  width = 20,
  height = 20,
  color = '#D1D3D8',
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M13.447 9.28598L12.0436 7.88258L15.5644 3.40152H7.5625L5.5928 1.43182H17.5833C17.9943 1.43182 18.2898 1.61237 18.4697 1.97348C18.6509 2.33394 18.6181 2.67864 18.3712 3.00758L13.447 9.28598ZM18.3466 19.75L12.6591 14.0625V16.2045C12.6591 16.4829 12.5649 16.7167 12.3764 16.9058C12.2849 16.9984 12.1754 17.0714 12.0546 17.1201C11.9339 17.1689 11.8044 17.1925 11.6742 17.1894H9.70455C9.57451 17.1923 9.44525 17.1687 9.32467 17.1199C9.2041 17.0712 9.09475 16.9983 9.00333 16.9058C8.91081 16.8143 8.83793 16.705 8.78916 16.5844C8.74039 16.4638 8.71675 16.3346 8.7197 16.2045V10.1231L0.25 1.65341L1.62879 0.25L19.75 18.3712L18.3466 19.75Z'
        fill={color}
      />
    </svg>
  );
}
