import AspApi from "api/ApiConfig";

export const campaignListApi = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/campaigns?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

export const campaignBroadcastListApi = async (brandId, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/campaigns/broadcast_campaigns?brand_id=${brandId}&${qpmString}`
  );
  return res;
};

export const createCampaignApi = async (payLoad) => {
  const res = await AspApi.post(`/api/v1/campaigns`, payLoad);
  return res;
};

export const updateCampaignApi = async (id, payLoad) => {
  const res = await AspApi.patch(`/api/v1/campaigns/${id}`, payLoad);
  return res;
};

export const checkCampaignExits = async (id, params) =>
  await AspApi.get(
    `/api/v1/campaigns/check_campaign_exists?brand_id=${id}&name=${params}`
  );

export const createSendApi = async (payLoad) => {
  const res = await AspApi.post(
    `api/v1/svc/whatsapp/conversations/send_template`,
    payLoad
  );
  return res;
};

// export const createSendApi = async (params) => {
//   // query params
//   // let qpm = [];
//   // if (params?.brandId) qpm.push(`brand_id=${params?.brandId}`);
//   // if (params?.metaTemplateId)
//   //   qpm.push(`meta_template_id=${params?.metaTemplateId}`);

//   // let qpmString = "";
//   // if (qpm?.length > 0) qpmString = "?" + qpm.join("&");

//   // body params
//   let bodyParams = {};
//   if (params?.sendFrom) bodyParams["from_number"] = params?.sendFrom;
//   if (params?.sendTo) bodyParams["to_number"] = params?.sendTo;
//   if (params?.brandId) bodyParams["brand_id"] = params?.brandId;
//   if (params?.metaTemplateId)
//     bodyParams["template_id"] = params?.metaTemplateId;
//   if (params?.subcategory_type)
//     bodyParams["subcategory_type"] = params?.subcategory_type;
//   if (params?.variables) bodyParams["variables"] = params?.variables;

//   const res = await AspApi.post(
//     `api/v1/svc/whatsapp/conversations/send_template`,
//     bodyParams
//   );
//   return res;
// };

export const campaignDelete = async (id, brandId) => {
  const res = await AspApi.delete(
    `/api/v1/campaigns/${id}?brand_id=${brandId}`
  );
  return res;
};

export const campaignDeleteBulk = async (payLoad) => {
  const res = await AspApi.delete(
    `/api/v1/campaigns/bulk_delete?brand_id=${payLoad?.brand_id}&bulk_delete=${payLoad?.bulk_delete}&except_campaign_ids=${payLoad?.except_campaign_ids}`
  );
  return res;
};

// CAMPAIGN/API - API

export const campaignAPI = async (brandId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");
  const res = await AspApi.get(
    `/api/v1/campaigns/api_campaigns?brand_id=${brandId}&${qpmString}`
  );

  return res;
};

export const getApiCount = async (brandId, campaignId) => {
  const res = await AspApi.get(
    `/api/v1/conversations/metrics?brand_id=${brandId}&campaign_id=${campaignId}`
  );
  return res;
};

export const ApiContactList = async (brandId, campaignId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/conversations/contacts?brand_id=${brandId}&campaign_id=${campaignId}&${qpmString}`
  );
  return res;
};

// BROADCAST - API ----------------------------------------

export const broadCastCreateApi = async (brandId, id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_broadcast/broadcasts?brand_id=${brandId}&campaign_id=${id}`,
    payLoad
  );
  return res;
};

export const broadCastListApi = async (brandId, campaignId, query) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/campaign_broadcast/broadcasts?brand_id=${brandId}&campaign_id=${campaignId}&${qpmString}`
  );
  return res;
};

export const broadcastSingleApi = async (brandId, campaignId, broadcastId) => {
  const res = await AspApi.get(
    `/api/v1/svc/campaign_broadcast/broadcasts/${broadcastId}?brand_id=${brandId}&campaign_id=${campaignId}`
  );
  return res;
};

export const broadcastDeleteApi = async (brandId, campaignId, id) => {
  const res = await AspApi.delete(
    `/api/v1/svc/campaign_broadcast/broadcasts/${id}?brand_id=${brandId}&campaign_id=${campaignId}`
  );

  return res;
};
export const broadcastSegmentApi = async (id, brandId, campaignId) => {
  const res = await AspApi.get(
    `/api/v1/svc/campaign_broadcast/broadcasts/${id}?brand_id=${brandId}&campaign_id=${campaignId}`
  );
  return res;
};

export const getBroadcastCountApi = async (brandId, campaignId) => {
  const res = await AspApi.get(
    `/api/v1/campaigns/${campaignId}/metric?brand_id=${brandId}`
  );
  return res;
};

export const broadCastContactListApi = async (
  brandId,
  broadcastId,
  broadcastPartId,
  query
) => {
  let qpm = [];
  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `api/v1/broadcast/contacts?brand_id=${brandId}&broadcast_id=${broadcastId}&broadcast_part=${broadcastPartId}&${qpmString}`
  );
  return res;
};

// BROADCAST PART - API ----------------------------------------

export const broadcastPartApi = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_broadcast/broadcasts/${id}/broadcast_parts`,
    payLoad
  );
  return res;
};

export const broadcastPartFileData = async (id, broadcastPartId, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_broadcast/broadcasts/${id}/broadcast_parts/${broadcastPartId}/file_data`,
    payLoad
  );
  return res;
};

export const getBroadcastPartApi = async (id) => {
  const res = await AspApi.get(
    `/api/v1/svc/campaign_broadcast/broadcasts/${id}/broadcast_parts`
  );
  return res;
};

export const broadcastPartDeleteApi = async (responseId, responseFromApi) => {
  const res = await AspApi.delete(
    `/api/v1/svc/campaign_broadcast/broadcasts/${responseId}/broadcast_parts/${responseFromApi}`
  );
  return res;
};
export const broadcastTemplateListApi = async (brandId, status, query) => {
  let qpm = [];

  if (query?.page) qpm.push(`page=${query?.page}`);
  if (query?.limit) qpm.push(`limit=${query?.limit}`);
  if (query?.name) qpm.push(`name=${query?.name}`);
  if (query?.status) qpm.push(`status=${query?.status}`);

  let qpmString = "";
  if (qpm?.length > 0) qpmString = "" + qpm.join("&");

  const res = await AspApi.get(
    `/api/v1/svc/whatsapp/template_utils/whatsapp_template_list?brand_id=${brandId}&status=${status}&${qpmString}`
  );
  return res;
};
export const createActivateApi = async (id, payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_broadcast/broadcasts/${id}/activate`,
    payLoad
  );
  return res;
};

export const templateList = async (brandId, templateId) => {
  const res = await AspApi.get(
    `api/v1/svc/whatsapp/template_utils/${templateId}?brand_id=${brandId}`
  );
  return res;
};

export const draftApi = async (payLoad) => {
  const res = await AspApi.post(
    `/api/v1/svc/campaign_broadcast/broadcasts/draft`,
    payLoad
  );
  return res;
};
