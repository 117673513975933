import { ICONS } from "assets/icons";

export const CAMPAIGN_STATUS = {
  0: {
    icon: ICONS?.draft,
    text: "Draft",
    color: "#566F8F",
    background: "#EDEFF1",
    border: "#566F8F",
  },
  10: {
    icon: ICONS?.BroadCastActive,
    text: "Scheduled",
    color: "var(--secondary)",
    background: "var(--primaryOpacity)",
    border: "var(--secondary)",
  },
  11: {
    icon: ICONS?.CampaignRunningIcon,
    text: "Running",
    color: "#0ACD95",
    background: "var(--opacityBackground3)",
    border: "var(--primary)",
  },
  12: {
    icon: ICONS?.CampaignCompleteIcon,
    text: "Completed",
    color: "#4C90F5",
    background: "var(--opacityBackground2)",
    border: "var(--reviewStatus)",
  },
};

 export  const goalTypeConvertion = {
    1: "Engagement",
    2: "Leads",
    3: "Awareness",
  };
