import React from "react";
import { ICONS } from "../../../assets/icons";
import { conversationSummaryList } from "../constants/index";
import FilterHome from "../../../components/commonComponents/filterHome/FilterHome";
import { HomeFilterList } from "../../../constant/app/Filter/Filter";

const ConversationSummary = () => {
  const fetchApiByFilter = (filter) => {
    switch (filter) {
      case "Last 7 days":
        break;
      case "This Month":
        break;
      case "This Year":
        break;
      case "Custom":
        break;
      default:
        break;
    }
  };
  return (
    <div className="bg-white rounded-[1vw]  py-[1vw] px-[2vw] max-h-auto">
      <div className="flex-row justify-between items-center border-b-[1px] pb-2">
        <p className="flex-row items-center text-[1.09rem] font-[500]">
          Conversation Summary
          <span className="ml-2">
            <img src={ICONS?.homeDownload} className="w-[18px] h-[18px]" />
          </span>
        </p>

        <FilterHome
          fetchApiByFilter={fetchApiByFilter}
          filterList={HomeFilterList}
        />
      </div>

      <div className="flex-row align-center !px-0 py-[1.5vw] text-[var(--textBlack)]">
        {conversationSummaryList?.map((item) => (
          <div
            key={item?.id}
            className="w-full flex-col items-center gap-[1.3vw] border-r-[1px] last:border-r-0"
          >
            <div className="flex-row justify-center items-center">
              <img
                src={item?.img}
                alt="summary"
                className="w-[1.8vw] h-[1.8vw]"
              />
              <span className="text-[#616874] text-[0.9rem] font-[500] px-2">
                {item?.label}
              </span>
              <img
                src={ICONS?.conversationAction}
                alt="conversationAction"
                className="w-[18px] h-[18px]"
              />
            </div>
            <div className="flex-row justify-center">
              <span className="!mt-[0.8vw] text-[1.275rem] weight-semibold">
                3200
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConversationSummary;
