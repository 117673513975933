import React, { useState } from 'react';
import Switch from '../../../../../components/commonComponents/step/switch';
import { Popover } from 'antd';
import useToggle from '../../../../../hooks/useToggle';

const messageTypes = [
  { id: 1, label: 'Repeat this message', key: 'REPEAT_MESSAGE' },
  { id: 1, label: 'Send error message', key: 'ERROR_MESSAGE' },
];

const DefaultReplay = () => {
  const [isChecked, setIsChecked] = useToggle(false);
  const [isPopupOpen, setIsPopupOpen] = useToggle(false);

  const toggleSwitch = () => setIsChecked(!isChecked);

  return (
    <div className='mb-6'>
      <div className='flex items-center gap-5'>
        <Switch isChecked={isChecked} toggleSwitch={toggleSwitch} />
        <div className='text-sm weight-medium flex items-center gap-1'>
          <p>If replied other answers</p>
          <Popover
            trigger={['click']}
            open={isPopupOpen}
            onOpenChange={(open) => {
              setIsPopupOpen(open);
            }}
            content={
              <div className='w-full min-w-52 flex flex-col p-4'>
                {messageTypes?.map((t) => {
                  return (
                    <div className='h-11 flex items-center justify-center cursor-pointer border-b border-gray-100 last:border-b-0'>
                      {t?.label}
                    </div>
                  );
                })}
              </div>
            }
          >
            <div
              className='text-[#6940F2] weight-semibold cursor-pointer'
              onClick={() => {
                setIsPopupOpen(!isPopupOpen);
              }}
            >
              Send error message
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default DefaultReplay;
