import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  brandsList: [],
  currentBrand: null,
  conversationList: [],
  currentConversation: null,
  openTemplateList: false,
};

export const authSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    updateBrandsList: (state, action) => {
      state.brandsList = action.payload;
    },
    updateCurrentBrand: (state, action) => {
      state.currentBrand = action.payload;
    },
    updateConversationList: (state, action) => {
      state.conversationList = action.payload;
    },
    updateCurrentConversation: (state, action) => {
      state.currentConversation = action.payload;
    },
    setOpenTemplateList: (state, action) => {
      state.openTemplateList = action.payload;
    },
  },
});

export const {
  updateBrandsList,
  updateCurrentBrand,
  updateConversationList,
  updateCurrentConversation,
  setOpenTemplateList,
} = authSlice.actions;

export default authSlice.reducer;
