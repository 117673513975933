import React, { useState } from "react";
import "./search.css";

const Search = ({
  placeholder = "Search or create new tag",
  onSubmit,
  templateSearch,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState("");
  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  // const handleChange = (e) => {
  //   setValue((prev) => {
  //     if (e.target.value.length <= 20) {
  //       return e.target.value;
  //     } else {
  //       return prev;
  //     }
  //   });
  // };
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 20) {
      setValue(newValue);
      templateSearch(newValue);
    }
  };

  const handleKeyDown = (e, value) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      // if (value.trim()) {
      onSubmit(e, value.trim());
      setValue(""); // Clear the input after submission
      // }
    }
  };
  return (
    <form
      className={`flex items-center border ${
        !isFocus ? "border-white" : "border-slate-300"
      } overflow-hidden rounded-xl crm-search bg-slate-100 my-[2vh] px-[1vw] flex items-center`}
    >
      <input
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        onChange={(e) => handleChange(e)}
        type="text"
        name="tagSearch"
        className="w-full ring-0 border-0 py-[.5vw] bg-transparent placeholder:text-gray-400 sm:text-sm sm:leading-6 text-gray-600"
        placeholder={placeholder}
        data-testid="Search-tag"
        onKeyDown={(e) => handleKeyDown(e, value)}
      />

      <span className="text-[.75vw] text-gray-500">{value.length}/20</span>
    </form>
  );
};

export default Search;
