import React from 'react';

const IconContainer = ({
  width = 36,
  height = 36,
  color = '#0ACD95',
  children,
}) => {
  return (
    <div className='flex items-center justify-center relative'>
      <svg
        width={width}
        height={height}
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18 36C31.2894 36 36 31.2894 36 18C36 4.7106 31.2894 0 18 0C4.7106 0 0 4.7106 0 18C0 31.2894 4.7106 36 18 36Z'
          fill={color}
        />
      </svg>
      <div className='absolute'>{children}</div>
    </div>
  );
};

export default IconContainer;
