import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { formatDate, formatDateFilter } from "../../../helper/convertingDate";
import { ICONS } from "../../../assets/icons";

const FilterCalendar = ({
  setCustomDateVisible,
  customStartDate,
  customEndDate,
  setCustomDateRange,
  setStartDate,
  setEndDate,
  customDateRange,
}) => {
  const CalendarHeader = ({
    currentMonth,
    currentYear,
    setCurrentMonth,
    setCurrentYear,
    allowedMonths,
    startYear,
    endMonth,
    endYear,
  }) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const handlePrevMonth = () => {
      if (currentMonth === allowedMonths[0] && currentYear === startYear)
        return;
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    };

    const handleNextMonth = () => {
      if (currentMonth === endMonth && currentYear === endYear) return;
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    };

    return (
      <div className="calendar-header">
        <div className="border border-[#E8E8EA] rounded-lg p-2">
          <img
            src={ICONS?.chevronLeft}
            alt="left"
            onClick={handlePrevMonth}
            disabled={
              currentMonth === allowedMonths[0] && currentYear === startYear
            }
            style={{
              cursor:
                currentMonth === allowedMonths[0] && currentYear === startYear
                  ? "not-allowed"
                  : "pointer",
            }}
            className="w-[20px] h-[20px]"
          />
        </div>

        <h2>
          {months[currentMonth]} {currentYear}
        </h2>
        <div className="border border-[#E8E8EA] rounded-lg p-2">
          <img
            src={ICONS?.chevronRight}
            alt="left"
            onClick={handleNextMonth}
            disabled={currentMonth === endMonth && currentYear === endYear}
            style={{
              cursor:
                currentMonth === endMonth && currentYear === endYear
                  ? "not-allowed"
                  : "pointer",
            }}
            className="w-[20px] h-[20px]"
          />
        </div>
      </div>
    );
  };

  const CalendarDays = ({ daysOfWeek }) => (
    <div className="calendar-days">
      {daysOfWeek.map((day) => (
        <div key={day} className="day-name">
          {day}
        </div>
      ))}
    </div>
  );

  const CalendarGrid = ({
    currentMonth,
    currentYear,
    onDateSelect,
    startDates,
    endDates,
    startLimit,
    endLimit,
  }) => {
    //today
    const today = new Date();
    const isToday = (date) =>
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    const getDaysInMonth = (month, year) =>
      new Date(year, month + 1, 0).getDate();
    const getStartDay = (month, year) => new Date(year, month, 1).getDay();

    const daysInMonth = getDaysInMonth(currentMonth, currentYear);
    const startDay = getStartDay(currentMonth, currentYear);
    const totalSlots = daysInMonth + startDay;

    return (
      <div className="calendar-grid">
        {Array.from({ length: totalSlots }).map((_, index) => {
          const day = index - startDay + 1;
          const date = new Date(currentYear, currentMonth, day);

          // Check if the date is within the selectable range
          const isSelectable =
            (isNaN(startLimit) ||
              isNaN(endLimit) ||
              (date >= startLimit && date <= endLimit)) &&
            day > 0;

          const isTodayDate = isToday(date);

          // const isSelectable =
          //   date >= startLimit && date <= endLimit && day > 0;
          const isSelectedStart =
            startDates && date.toDateString() === startDates.toDateString();
          const isSelectedEnd =
            endDates && date.toDateString() === endDates.toDateString();
          const isInRange =
            startDates && endDates && date > startDates && date < endDates;

          // return (
          //   <div
          //     key={index}
          //     className={`calendar-day ${day > 0 ? "selectable" : ""} ${
          //       isSelectable ? "" : "unselectable"
          //     } ${isSelectedStart || isSelectedEnd ? "selected" : ""} ${
          //       isInRange ? "in-range" : ""
          //     }`}
          //     onClick={() => isSelectable && onDateSelect(day)}
          //     style={{
          //       cursor: isSelectable ? "pointer" : "not-allowed",
          //       color: isSelectable ? "inherit" : "#ccc",
          //     }}
          //   >
          //     {day > 0 ? day : ""}
          //   </div>
          // );

          return (
            <div
              key={index}
              className={`calendar-day ${day > 0 ? "selectable" : ""} ${
                isSelectable ? "" : "unselectable"
              } ${isTodayDate ? "bg-[#05805C14] !text-[#05805C]" : ""} ${
                isTodayDate && !isInRange ? "rounded-lg" : ""
              } ${isSelectedStart ? "start-date" : ""} ${
                isSelectedEnd ? "end-date" : ""
              } ${isInRange ? "in-range" : ""}`}
              onClick={() => isSelectable && onDateSelect(day)}
              style={{
                cursor: isSelectable ? "pointer" : "not-allowed",
                color: isSelectable ? "inherit" : "#ccc",
              }}
            >
              {day > 0 ? day : ""}
            </div>
          );
        })}
      </div>
    );
  };

  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [startDates, setStartDates] = useState(null);
  const [endDates, setEndDates] = useState(null);

  const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  const handleDateSelect = (day) => {
    const selected = new Date(currentYear, currentMonth, day);

    if (!startDates || (startDates && endDates)) {
      setStartDates(selected);
      setEndDates(null);
    } else if (selected < startDates) {
      setStartDates(selected);
    } else {
      setEndDates(selected);
    }
  };

  const formattedStartDate = formatDate(startDates);
  const formattedEndDate = formatDate(endDates);
  const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

  // Parse the campaign dates to set the selection range
  const startLimit = new Date(customStartDate);
  const endLimit = new Date(customEndDate);

  const startMonth = startLimit.getMonth();
  const startYear = startLimit.getFullYear();
  const endMonth = endLimit.getMonth();
  const endYear = endLimit.getFullYear();

  const allowedMonths =
    startYear === endYear
      ? Array.from(
          { length: endMonth - startMonth + 1 },
          (_, i) => startMonth + i
        )
      : [startMonth, endMonth];

  const formattedStartCustom = formatDateFilter(startDates);
  const formattedEndDateCustom = formatDateFilter(endDates);

  useEffect(() => {
    if (customDateRange) {
      const [start, end] = customDateRange
        .split(" - ")
        .map((date) => new Date(date));
      setStartDates(start);
      setEndDates(end);
    }
  }, [customDateRange]);

  return (
    <div className="calendar">
      <CalendarHeader
        currentMonth={currentMonth}
        currentYear={currentYear}
        setCurrentMonth={setCurrentMonth}
        setCurrentYear={setCurrentYear}
        allowedMonths={allowedMonths}
        startYear={startYear}
        endMonth={endMonth}
        endYear={endYear}
      />
      <CalendarDays daysOfWeek={daysOfWeek} />
      <CalendarGrid
        currentMonth={currentMonth}
        currentYear={currentYear}
        onDateSelect={handleDateSelect}
        startDates={startDates}
        endDates={endDates}
        startLimit={startLimit}
        endLimit={endLimit}
      />

      <div className="w-full px-3">
        {/* <div className="flex-row items-center">
          <input
            placeholder="Start date"
            type="text"
            value={startDates?.toLocaleDateString()}
            readOnly
            className="input-type"
          />
          <span className="text-[#2D3036] font-[700] mx-4">To</span>
          <input
            placeholder="End date"
            type="text"
            value={endDates?.toLocaleDateString()}
            readOnly
            className="input-type"
          />
        </div> */}
        <div className="flex-row justify-between font-[600] py-3">
          <button
            className="border px-8 py-2 border-[#E8E8EA] rounded-lg text-[#2D3036]"
            onClick={() => {
              setStartDates(null);
              setEndDates(null);
            }}
          >
            Clear
          </button>
          <button
            className={`border ml-10 px-7 border-[#E8E8EA] rounded-lg ${
              startDates && endDates
                ? "bg-[#05805C] text-white cursor-pointer"
                : "bg-[#E8E8EA] text-gray-400 cursor-not-allowed"
            }`}
            onClick={() => {
              setCustomDateVisible(false);
              setCustomDateRange(formattedDateRange);
              setStartDate(formattedStartCustom);
              setEndDate(formattedEndDateCustom);
            }}
            disabled={!(startDates && endDates)}
          >
            Set Date
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterCalendar;
