import React, { useEffect, useMemo, useState } from "react";
import "../../../styles/contact.css";
import { Button } from "../../../../../components/form/Button/Button";
import { useSelector } from "react-redux";
import {
  handleSingleColsAction,
  updateSingleContactDetails,
} from "../../../../../reduxToolkit/crmSlice";
import { Popover } from "antd";
import {
  convertName,
  hexToRgba,
  validateEmoji,
  validateFirstName,
} from "../../../helpers";
import { useDispatch } from "react-redux";
import Dropdown from "../../commonComponents/dropdown";
import Input from "../../commonComponents/input/Input";
import "../../../../../components/form/checkBox/checkBox.css";
import { ICONS } from "../../../../../assets/icons";
import { validateEmail } from "../../../../../helper/validation";
import Tags from "../contact/Tags";
import Label from "../../commonComponents/label/index";
import AddCircle from "../../../../../assets/customSVG/AddCircle";
import { checkingApi, saveSingleContactApi } from "../../../api/Api";
import { useAspSelector } from "../../../../../test/jest-redux-hooks";
import { updateToggleToast } from "../../../../../reduxToolkit/appSlice";
import CountryCode from "../../../../../components/commonComponents/countryCode";
import { countryList } from "../../../../../constant/app/countryList";
import RadioButton from "../../../../../components/form/radio/RadioButton";
import { getPhoneNumberLength } from "../../../../../helper/phoneNumber";
import ListViewSearch from "../../../../../components/commonComponents/ListViewHeader/ListViewSearch";
import { Address } from "../../commonComponents/location/Address";
import { Province } from "../../commonComponents/location/Province";
import { City } from "../../commonComponents/location/City";

const AddSingleContact = ({
  countryListss,
  stateList,
  popoverVisible,
  setPopoverVisible,
  handleLocationClick,
  selectedLocation,
  setActive,
  resetForm,
  contactSources,
  setContactSources,
  lifeCycle,
  setLifeCycle,
  value1,
  setValue1,
  isButtonDisabled,
  setIsButtonDisabled,
  cityList,
}) => {
  const {
    singleContactUploadDetails: { contact, address },
    singleContactDetails,
  } = useSelector((store) => store.crm);

  const { currentBrand, toggleToast } = useAspSelector((state) => state.app);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedTags = useMemo(() => {
    return singleContactDetails["selected_tags"];
  }, [singleContactDetails?.selected_tags]);
  const [checkingPhone, setCheckingPhone] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [campaignErrors, setCampaignErrors] = useState("");
  const [checkingEmailErrors, setCheckingEmailErrors] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenSelect, setIsDropdownOpenSelect] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  const { tagList = [] } = useSelector((store) => store.crm);

  const [fileLoader, setFileLoader] = useState(false);

  const contacts = Object.entries(contact);
  const locationDetails = Object.entries(address);

  const dispatch = useDispatch();

  let cleanedNumber = contact?.phone_number_code.replace("+", "");

  // const createChecker = (contactType, errorSetter) => (brandId) => (params) => {
  //   const queryParams = {
  //     contact_type: contactType,
  //     ...params,
  //   };

  //   return checkingApi(brandId, queryParams).then((res) => {
  //     if (res?.status === 200) {
  //       errorSetter(res?.data?.exists ? `${contactType} already exists` : "");
  //     }
  //   });
  // };

  // const checkPhone = createChecker("Mobile phone number", setCampaignErrors);
  // const checkEmail = createChecker("Email address", setCheckingEmailErrors);

  // // Usage
  // const checkPhoneForCurrentBrand = checkPhone(currentBrand?.brand_id);
  // const checkEmailForCurrentBrand = checkEmail(currentBrand?.brand_id);

  // // In your useEffect or wherever you're calling these:
  // useEffect(() => {
  //   if (checkingPhone && contact?.phone_number) {
  //     const timeout = setTimeout(() => {
  //       checkPhoneForCurrentBrand({
  //         phone_number: contact?.phone_number,
  //         phone_country_code: cleanedNumber || "91",
  //       });
  //     }, 500);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [checkingPhone, contact]);

  // useEffect(() => {
  //   if (checkingEmail && contact?.email !== "") {
  //     const timeout = setTimeout(() => {
  //       checkEmailForCurrentBrand({ email: contact?.email });
  //     }, 500);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [checkingEmail, contact]);

  const checkApi = (brandId, params) => {
    const queryParams = {
      contact_type: "Mobile phone number",
      phone_number: contact?.phone_number,
      phone_country_code: cleanedNumber || "91",
    };

    checkingApi(brandId, queryParams).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.exists) {
          setCampaignErrors("Phone number already exists");
        } else {
          setCampaignErrors("");
        }
      }
    });
  };

  useEffect(() => {
    if (checkingPhone && contact?.phone_number) {
      const timeout = setTimeout(() => {
        checkApi(currentBrand?.brand_id, {
          contact_type: "Mobile phone number",
          phone_number: contact?.phone_number,
          phone_country_code: cleanedNumber || "91",
        });
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [checkingPhone, contact]);

  const checkApis = (brandId, params) => {
    const queryParams = {
      contact_type: "Email address",
      email: contact?.email,
    };

    checkingApi(brandId, queryParams).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.exists) {
          setCheckingEmailErrors("Email already exists");
        } else {
          setCheckingEmailErrors("");
        }
      }
    });
  };

  useEffect(() => {
    if (checkingEmail && contact?.email !== "") {
      const timeout = setTimeout(() => {
        checkApis(currentBrand?.brand_id, {
          contact_type: "Email address",
          email: contact?.email,
        });
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [checkingEmail, contact]);

  const handleChange = (type, key, value, id) => {
    if (key === "contact_origin") {
      setValue1(value);
    }
    const optOutMapping = {
      whatsApp_opt_out: "whatsapp",
      email_opt_out: "email",
      SMS_opt_out: "sms",
    };

    // const addressPattern =
    //   /^((?:2|4|6|8|1[02468]|[2468][048]|[13579][048]|\d*[02468][048])\d*)\s?([A-Za-z](?=\s))?[\s,]*([^ ]+)?[\s,]*(APT)?[\s,]*(\d+)?$/;

    if (key === "email") {
      if (!validateEmail(value) && value !== "") {
        setErrorMessage("Enter valid email address");
      } else {
        setErrorMessage("");
      }
      if (value.length > 40) {
        return;
      }
    }

    if (
      (key === "phone_number" || key === "alternative_mobile") &&
      (/\.\d/.test(value) || /\+\d/.test(value))
    ) {
      return;
    }

    // if (
    //   key === "address" &&
    //   (!addressPattern.test(value) || value?.length > 50)
    // ) {
    //   return;
    // }

    if (
      (key === "phone_number" && value?.length > 11) ||
      (key === "age" && value?.length > 3) ||
      (key === "zip_code / postal_code" && value?.length > 6)
    ) {
      return;
    }

    if (validateEmoji(value)) {
      return;
    }

    if (
      (key === "first_name" || key === "last_name") &&
      (!validateFirstName(value) || value?.length >= 30)
    ) {
      return;
    }

    dispatch(
      handleSingleColsAction({
        key: key,
        value: key === "contact_origin" ? id : value,
        field: type,
        optOutValue: optOutMapping[key],
      })
    );

    setFormFields((prev) => ({ ...prev, [key]: value }));

    if (key === "phone_number") {
      setCheckingPhone(value?.length > 9);
    } else {
      setCheckingPhone(false);
    }

    if (key === "email") {
      if (value.includes("@" && ".")) {
        setCheckingEmail(true);
      } else {
        setCheckingEmail(false);
      }
    } else {
      setCheckingEmail(false);
    }
  };

  const handleAlternateMobileNumber = (id, key, value) => {
    if (key === "phone_number" && value?.length > 11) {
      return;
    }
    const res = contact.alternative_mobile.map((n, i) => {
      if (id === i) return { ...n, [key]: value };
      return n;
    });
    handleChange("contact", "alternative_mobile", res);
  };

  const closeAction = (w, id) => {
    dispatch(
      updateSingleContactDetails({
        access_name: "selected_tags",
        value: singleContactDetails["selected_tags"].filter(
          (item) => item !== id
        ),
      })
    );
  };
  const addAction = (id, e) => {
    if (!singleContactDetails["selected_tags"].includes(id)) {
      dispatch(
        updateSingleContactDetails({
          access_name: "selected_tags",
          value: [...singleContactDetails?.selected_tags, id],
        })
      );
    }
  };

  const saveContact = () => {
    setFileLoader(true);

    const payLoad = {
      first_name: contact?.first_name,
      last_name: contact?.last_name,
      email: contact?.email,
      phone_number: contact?.phone_number,
      phone_number_code: cleanedNumber,
      alternative_phone_numbers: contact?.alternative_mobile,
      alternative_email: [],
      contact_origin_id: contact?.contact_origin,
      life_cycle_stage: contact?.life_cycle_stage,
      address_line_1: address?.address,
      city_id: address?.city,
      state_id: address?.state,
      country_id: address?.country,
      postal_code: `${address["zip_code / postal_code"]}`,
      opted_out: contact?.opted_out,
      tags: singleContactDetails.selected_tags,
    };

    saveSingleContactApi(currentBrand?.brand_id, payLoad).then(
      (createCampaignResponse) => {
        if (createCampaignResponse?.status === 200) {
          setFileLoader(false);
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: "Contact saved successfully",
                status: "Success",
                duration: "",
              },
            ])
          );
          setActive(false);
          resetForm();
          window?.location?.reload();
        } else {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content:
                  createCampaignResponse?.response?.data?.error ||
                  "Contact creation failed",
                status: "Error",
                duration: "",
              },
            ])
          );
          setFileLoader(false);
        }
      }
    );
  };
  useEffect(() => {
    const hasMinimumInfo = () => {
      const countryCode = contact?.phone_number_code;
      const requiredLength = getPhoneNumberLength(countryCode);

      return (
        contact?.first_name !== "" &&
        contact?.last_name !== "" &&
        contact?.phone_number?.length === requiredLength
      );
    };

    const noErrors = !campaignErrors && !errorMessage && !checkingEmailErrors;

    const isAddressEmpty = address?.address === "";
    const c = selectedLocation?.country?.name;
    const s = selectedLocation?.state?.name;
    const province = selectedLocation?.city?.name;
    const zip = `${address["zip_code / postal_code"]}`;
    const origin = contact?.contact_origin !== "";

    if (hasMinimumInfo() && noErrors && isAddressEmpty && origin) {
      setIsButtonDisabled(true);
    } else if (
      hasMinimumInfo() &&
      noErrors &&
      !isAddressEmpty &&
      c &&
      s &&
      province &&
      origin &&
      zip?.length >= 6
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [formFields, campaignErrors, address, errorMessage, checkingEmailErrors]);

  const handleVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };
  const handleDropdownClick = (key) => {
    setIsDropdownOpenSelect((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
    setActiveKey(key);
    setPopoverVisible(true);
  };

  const maxVisibleTags = 4;

  const visibleTags = selectedTags.slice(0, maxVisibleTags);
  const additionalTagCount = selectedTags.length - maxVisibleTags;

  return (
    <div className="grid grid-rows-[10fr,1fr] h-[90%]">
      <form className="p-[1vw]">
        <div className="border-b border-b-gray-200 pb-[1vw] mb-[1vw]">
          <p className="text-[.85vw] font-[500]">Contact details:</p>
          <div className="grid grid-cols-2 py-[1vw] gap-x-[3vw] gap-y-[1vw]">
            {contacts.slice(0, contacts.length - 3).map(([key, val]) => {
              if (key.includes("contact_origin")) {
                return (
                  <React.Fragment key={key}>
                    <Dropdown
                      heading=""
                      data={contactSources}
                      containerClassNames="px-[1.6vw] w-[20vw] h-[25vh]"
                      editFilter={(id) => {
                        setContactSources((prev) => {
                          return prev.map((item) => {
                            if (item.id === id) {
                              handleChange(
                                "contact",
                                key,
                                item?.name,
                                item?.id
                              );
                              return { ...item, selected: true };
                            }
                            return { ...item, selected: false };
                          });
                        });
                      }}
                      button={
                        <div className="pointer">
                          <span className="text-[.7vw] font-[500] capitalize">
                            {convertName(key)}
                            <span className="text-red-600">*</span>
                          </span>
                          <div
                            className={`flex justify-between items-center bg-gray-50- border border-solid border-gray-200 text-sm rounded-lg w-full px-[.8vw] py-[.5vw] mt-[.2vw]`}
                            onClick={(e) => setIsDropdownOpen((prev) => !prev)}
                          >
                            <p
                              className={`text-[.8vw]   ${
                                !val
                                  ? "text-[var(--font-400)] font-[400]"
                                  : "text-[var(--textBlack)] font-[500]"
                              }`}
                            >
                              {value1 || "Select"}
                            </p>
                            <div
                              className={`duration-300 ${
                                isDropdownOpen ? "-rotate-180" : "rotate-0"
                              }`}
                              data-testid="dropdown"
                            >
                              <img
                                src={ICONS?.modelDropdownIcon}
                                alt="modelDropdownIcon"
                                className="pointer"
                              />
                            </div>
                          </div>
                        </div>
                      }
                    />
                  </React.Fragment>
                );
              }
              // else if (key.includes("life_cycle_stage")) {
              //   return (
              //     <React.Fragment key={key}>
              //       <Dropdown
              //         heading=""
              //         data={lifeCycle}
              //         containerClassNames="px-[1.6vw] w-[20vw] h-[25vh]"
              //         editFilter={(id) => {
              //           setLifeCycle((prev) => {
              //             return prev.map((item) => {
              //               if (item.id === id) {
              //                 handleChange("contact", key, item?.label);
              //                 return { ...item, selected: true };
              //               }
              //               return { ...item, selected: false };
              //             });
              //           });
              //         }}
              //         button={
              //           <div className="pointer">
              //             <span className="text-[.7vw] font-[500] capitalize">
              //               {convertName(key)}
              //             </span>
              //             <div
              //               className={`flex justify-between items-center bg-gray-50- border border-solid border-gray-200 text-sm rounded-lg w-full px-[.8vw] py-[.5vw] mt-[.2vw]`}
              //               onClick={() => handleDropdownClick(key)}
              //             >
              //               <p
              //                 className={`text-[.8vw]   ${
              //                   !val
              //                     ? "text-[var(--font-400)] font-[400]"
              //                     : "text-[var(--textBlack)] font-[500]"
              //                 }`}
              //               >
              //                 {val || "Select"}
              //               </p>
              //               <div
              //                 className={`duration-300 ${
              //                   isDropdownOpenSelect[key]
              //                     ? "-rotate-180"
              //                     : "rotate-0"
              //                 }`}
              //                 data-testid="dropdown"
              //               >
              //                 <img
              //                   src={ICONS?.modelDropdownIcon}
              //                   alt="modelDropdownIcon"
              //                   className="pointer"
              //                 />
              //               </div>
              //             </div>
              //           </div>
              //         }
              //       />
              //     </React.Fragment>
              //   );
              // }
              else if (["alternative_mobile", "phone_number"].includes(key)) {
                return (
                  <div key={key} className="">
                    <label className="text-[.7vw] font-[500] capitalize">
                      {convertName(key)}
                      {key === "phone_number" && true && (
                        <span className="text-red-600">*</span>
                      )}
                    </label>

                    {key === "phone_number" ? (
                      <>
                        <div className="flex-row align-center">
                          <CountryCode
                            value={"+91"}
                            options={countryList}
                            onChange={({ value }) => {
                              handleChange(
                                "contact",
                                "phone_number_code",
                                value?.replace("+", "")
                              );
                            }}
                            className="!min-w-[5vw] min-h-[3.6vh] mt-[0.2vw] mr-10"
                            modalClassName="!max-w-[11vw]"
                          />
                          <div className="w-100">
                            <Input
                              name={key}
                              type="number"
                              onChange={(e) => {
                                handleChange("contact", key, e.target.value);
                              }}
                              value={val}
                              label={false}
                              // required={key === "phone" && true}
                              showCountryDropDown
                              placeholder={`EX:- 9789 659 872`}
                              data-contact="contact"
                              className={"!w-full flex-1"}
                              onWheel={(e) => e.target.blur()}
                            />
                          </div>
                        </div>
                        {campaignErrors && key === "phone_number" && (
                          <div
                            style={{ color: "red" }}
                            className="text-[.8vw] font-[500] pt-2"
                          >
                            {campaignErrors}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {contact.alternative_mobile?.map((n, i) => {
                          return (
                            <div className="flex-row align-center w-100">
                              <CountryCode
                                value={n.phone_number_code}
                                options={countryList}
                                onChange={({ value }) => {
                                  handleAlternateMobileNumber(
                                    i,
                                    "phone_number_code",
                                    value?.replace("+", "")
                                  );
                                }}
                                className="!min-w-[5vw] min-h-[3.6vh] mt-[0.2vw] mr-10"
                                modalClassName="!max-w-[11vw]"
                              />
                              <div className="w-100">
                                <Input
                                  name={key}
                                  type="number"
                                  onChange={(e) => {
                                    handleAlternateMobileNumber(
                                      i,
                                      "phone_number",
                                      e.target.value
                                    );
                                  }}
                                  value={n.phone_number}
                                  label={false}
                                  // required={key === "phone" && true}
                                  showCountryDropDown
                                  placeholder={`EX:- 9789 659 872`}
                                  data-contact="contact"
                                  className=""
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                );
              } else if (
                !key.includes("phone_number_code") &&
                !key.includes("opted_out")
              ) {
                return (
                  <div key={key}>
                    <Input
                      name={key}
                      label={key}
                      type={key === "email" ? "email" : "text"}
                      onChange={(e) => {
                        handleChange("contact", key, e.target.value);
                      }}
                      value={val}
                      required={key !== "email" && true}
                      placeholder={`Enter ${convertName(key)}`}
                      data-contact="contact"
                    />
                    {errorMessage &&
                      !checkingEmailErrors &&
                      key === "email" && (
                        <div
                          style={{ color: "red" }}
                          className="text-[.8vw] font-[500] pt-2"
                        >
                          {errorMessage}
                        </div>
                      )}
                    {checkingEmailErrors && key === "email" && (
                      <div
                        style={{ color: "red" }}
                        className="text-[.8vw] font-[500] pt-2"
                      >
                        {checkingEmailErrors}
                      </div>
                    )}
                  </div>
                );
              }
            })}

            <div className="flex col-span-2 font-[500] text-slate-500 [&>*:nth-child(2)]:mx-[6vw] mt-[.5vw]">
              {contacts
                .slice(contacts.length - 3, contact.length)
                .map(([key, val]) => {
                  if (key.includes("opt_out")) {
                    return (
                      <div
                        key={key}
                        className="opt_select flex items-center gap-[.5vw]"
                      >
                        <div key={key} className="flex-row align-center">
                          <label className="checkBoxContainer">
                            <input
                              type="checkbox"
                              name={key}
                              id={key}
                              data-contact="contact"
                              checked={val}
                              onChange={(e) => {
                                handleChange("contact", key, e.target.checked);
                              }}
                              data-testid="Radio_button"
                              className={`checkBoxContainer flex items-center`}
                            />
                          </label>
                          <div className="setting-setting flex-column  pointer">
                            <label
                              htmlFor={key}
                              className="text-[.75vw] font-[500] capitalize pl-5 pointer"
                            >
                              {convertName(key)}
                            </label>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <React.Fragment>
          <p className="text-[.85vw] font-[500]">Tags:</p>

          <div className="flex flex-wrap w-full *:min-w-max gap-[1vw] my-[1vw]">
            {Array.isArray(tagList) &&
              visibleTags?.map((tagId) => {
                const tag = tagList?.find((t) => t?.id === tagId);
                if (tag) {
                  return (
                    <Label
                      id={tag?.id}
                      key={tag?.id}
                      label={tag?.tag}
                      color={tag?.display_color}
                      style={{
                        backgroundColor: hexToRgba(tag?.display_color, 0.2),
                      }}
                      closeAction={closeAction}
                      disableHover={true}
                    />
                  );
                }
              })}
            {Array.isArray(tagList) &&
              visibleTags?.map((tagId) => {
                const tag = tagList?.find((t) => t?.id === tagId);
                if (tag) {
                  return (
                    <Label
                      id={tag?.id}
                      key={tag?.id}
                      label={tag?.tag}
                      color={tag?.display_color}
                      style={{
                        backgroundColor: hexToRgba(tag?.display_color, 0.2),
                      }}
                      closeAction={closeAction}
                      disableHover={true}
                    />
                  );
                }
              })}

            {additionalTagCount > 0 && (
              <span className="text-[.75vw] text-[var(--font-400)] bg-[var(--channelButton)] rounded-full p-1">{`+${additionalTagCount}`}</span>
            )}
          </div>
          <Tags
            tagList={tagList}
            addAction={addAction}
            zIndex={500}
            closeAction={closeAction}
            // setTags={setTags}
            Button={
              <p className="flex text-[#616874] text-[.75vw] items-center font-[500] gap-[.4vw] cursor-pointer mb-20">
                <AddCircle width="1vw" strokeWidth="2.5" stroke="#616874" />
                <span>Add a Tag</span>
              </p>
            }
          />
        </React.Fragment>
        <div style={{ borderTop: "1px solid var(--border-50)" }}>
          <p className="text-[.85vw] font-[500] mt-[1vw]">Address details:</p>
          <div className="grid grid-cols-2 py-[1vw] gap-x-[3vw] gap-y-[1vw]">
            {locationDetails.map(([key, val]) => {
              const selectOptions =
                key === "city" || key === "state" || key === "country";

              return (
                <div key={key} className="">
                  {selectOptions ? (
                    <Popover
                      content={
                        key === "country" && activeKey === "country" ? (
                          <Address
                            countryListss={countryListss}
                            selectedLocation={selectedLocation}
                            handleLocationClick={handleLocationClick}
                          />
                        ) : key === "state" &&
                          activeKey === "state" &&
                          selectedLocation.country?.id ? (
                          <Province
                            stateList={stateList}
                            selectedLocation={selectedLocation}
                            handleLocationClick={handleLocationClick}
                          />
                        ) : key === "city" &&
                          activeKey === "city" &&
                          selectedLocation.state?.id ? (
                          <City
                            cityList={cityList}
                            selectedLocation={selectedLocation}
                            handleLocationClick={handleLocationClick}
                          />
                        ) : null
                      }
                      // trigger="click"
                      arrow={false}
                      placement="bottom"
                      open={activeKey === key && popoverVisible}
                      onOpenChange={handleVisibleChange}
                      data-testid=""
                    >
                      <div className="pointer">
                        <span className="text-[.7vw] font-[500] capitalize">
                          {convertName(key)}
                          {/* {(key === "city" ||
                            key === "state" ||
                            key === "country") && (
                            <span className="text-red-600">
                              {isAddressTyped ? "*" : ""}
                            </span>
                          )} */}
                        </span>
                        <div
                          className={`flex justify-between items-center bg-gray-50- border border-solid border-gray-200 text-sm rounded-lg w-full px-[.8vw] py-[.5vw] mt-[.2vw]`}
                          onClick={() => handleDropdownClick(key)}
                          data-testid="list1"
                        >
                          <p
                            className={`text-[.8vw]   ${
                              (key === "country" &&
                                !selectedLocation.country.name) ||
                              (key === "state" &&
                                !selectedLocation.state.name) ||
                              (key === "city" && !selectedLocation.city.name)
                                ? "text-[var(--font-400)] font-[400]"
                                : "text-[var(--textBlack)] font-[500]"
                            }`}
                          >
                            {key === "country" &&
                              (selectedLocation.country.name ||
                                "Select Country")}
                            {key === "state" &&
                              (selectedLocation.state.name || "Select State")}
                            {key === "city" &&
                              (selectedLocation.city.name || "Select City")}
                          </p>

                          <div
                            className={`duration-300 ${
                              isDropdownOpenSelect[key]
                                ? "-rotate-180"
                                : "rotate-0"
                            }`}
                          >
                            <img
                              src={ICONS?.modelDropdownIcon}
                              alt="modelDropdownIcon"
                              className="pointer"
                            />
                          </div>
                        </div>
                      </div>
                    </Popover>
                  ) : (
                    <Input
                      name={key}
                      label={key}
                      type={
                        key === "zip_code / postal_code" ? "number" : "text"
                      }
                      onChange={(e) => {
                        handleChange("address", key, e.target.value);
                      }}
                      value={val}
                      // required={contact?.address_line_1?.length > 0 && true}
                      iconPrefix={
                        key !== "address" &&
                        key !== "zip_code / postal_code" &&
                        ICONS?.modelDropdownIcon
                      }
                      placeholder={`Enter ${convertName(key)}`}
                      data-contact="address"
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </form>
      <div className="border-t sticky bottom-0 py-[.5vw] border-t-gray-200 pe-[1vw] flex-row align-end justify-end">
        {isButtonDisabled ? (
          <Button
            label={"Save Contact"}
            size={"medium"}
            disabled={!isButtonDisabled}
            onClick={() => saveContact()}
            loading={fileLoader}
            data-testid="button_click"
          />
        ) : (
          <span className="disable-button">{"Save Contact"}</span>
        )}
      </div>
    </div>
  );
};

export default AddSingleContact;
