import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

// helpers
import { cn } from '../../../helper/cn';

// constants
import {
  DefaultZoomValue,
  ZoomInValue,
  ZoomOutValue,
} from '../constants/defaults';

// hooks
import useToggle from '../../../hooks/useToggle';

// components
import Controls from '../components/sequence/Controls';
import TriggerModel from '../components/sequence/triggers/TriggerModel';
import Node from '../components/sequence/nodes/Node';
import { INITIAL_STATE, sequenceReducer } from '../reducer';
import NodeDetails from '../components/sequence/nodes/NodeDetails';
import TriggerList from '../components/sequence/triggers/TriggerList';
import NodeElements from '../components/sequence/nodes/NodeElements';
// import EditMessages from '../components/sequence/actions/EditMessages';

const Sequence = () => {
  const [sequence, dispatch] = useReducer(sequenceReducer, INITIAL_STATE);
  const { nodes, edges, undoStack, redoStack } = sequence;

  const [view, setView] = useState({ scale: 1, offsetX: 0, offsetY: 0 });

  const [openTriggerModel, setOpenTriggerModel] = useToggle(false);
  const [triggerModelPosition, setTriggerModelPosition] = useState({
    top: 0,
    left: 0,
  });

  // Refs for undo/redo and mouse tracking
  const undoStackRef = useRef([]);
  const redoStackRef = useRef([]);
  const containerRef = useRef(null);
  const draggingNodeRef = useRef(null);
  const dragStartPosRef = useRef(null);
  const isDraggingCanvasRef = useRef(false);

  const onChange = ({
    type = 'UPDATE',
    key,
    primaryKey,
    secondaryKey,
    value,
    data = null,
    id,
  }) => {
    const payload = { key, value };
    if (type) payload['type'] = type;
    if (type) payload['data'] = data;
    if (type) payload['id'] = id;
    if (type) payload['primaryKey'] = primaryKey;
    if (type) payload['secondaryKey'] = secondaryKey;

    dispatch(payload);
  };

  const saveStateToUndo = () => {
    undoStackRef.current.push({ nodes: [...nodes], edges: [...edges] });
    redoStackRef.current = [];
  };

  const undo = () => {
    if (undoStackRef.current.length > 0) {
      const lastState = undoStackRef.current.pop();
      redoStackRef.current.push({ nodes: [...nodes], edges: [...edges] });
      setNodes(lastState.nodes);
      setEdges(lastState.edges);
    }
  };

  const redo = () => {
    if (redoStackRef.current.length > 0) {
      const lastState = redoStackRef.current.pop();
      undoStackRef.current.push({ nodes: [...nodes], edges: [...edges] });
      setNodes(lastState.nodes);
      setEdges(lastState.edges);
    }
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const zoomDelta = e.deltaY < 0 ? 0.1 : -0.1;
    const newScale = Math.min(Math.max(view?.scale + zoomDelta, 0.2), 3);
    const newOffsetX =
      (view?.offsetX - e.clientX) * (newScale / view?.scale) + e.clientX;
    const newOffsetY =
      (view?.offsetY - e.clientY) * (newScale / view?.scale) + e.clientY;
    setView({ scale: newScale, offsetX: newOffsetX, offsetY: newOffsetY });
  };

  const handleMouseDown = (e, nodeId = null) => {
    if (openTriggerModel) setOpenTriggerModel(false);

    if (nodeId) {
      draggingNodeRef.current = nodeId;
      dragStartPosRef.current = { x: e.clientX, y: e.clientY };
      saveStateToUndo();
    } else {
      isDraggingCanvasRef.current = true;
      dragStartPosRef.current = { x: e.clientX, y: e.clientY };
    }
  };

  const handleMouseMove = (e) => {
    if (draggingNodeRef.current) {
      const nodeId = draggingNodeRef.current;
      const dx = e.clientX - dragStartPosRef.current.x;
      const dy = e.clientY - dragStartPosRef.current.y;
      onChange({
        type: 'UPDATE_NODE_COORDINATES',
        id: nodeId,
        data: { x: dx, y: dy },
      });
      // saveStateToUndo();
      dragStartPosRef.current = { x: e.clientX, y: e.clientY };
    } else if (isDraggingCanvasRef.current) {
      const dx = e.clientX - dragStartPosRef.current.x;
      const dy = e.clientY - dragStartPosRef.current.y;
      setView((prevView) => ({
        ...prevView,
        offsetX: prevView?.offsetX + dx,
        offsetY: prevView?.offsetY + dy,
      }));
      dragStartPosRef.current = { x: e.clientX, y: e.clientY };
    }
  };

  const handleMouseUp = () => {
    if (draggingNodeRef.current) {
      saveStateToUndo();
    }

    draggingNodeRef.current = null;
    isDraggingCanvasRef.current = false;
  };

  const addNode = () => {
    const newNodeId = (nodes.length + 1).toString();
    const newNode = {
      id: newNodeId,
      x: 100,
      y: 100,
      label: `Node ${newNodeId}`,
      type: 'trigger',
    };

    saveStateToUndo();
    setNodes((prevNodes) => [...prevNodes, newNode]);
  };

  const fitView = () => {
    if (nodes.length === 0) {
      console.error('No nodes to fit the view');
      return;
    }

    const allX = nodes.map((node) => node.coordinates.x);
    const allY = nodes.map((node) => node.coordinates.y);

    const minX = Math.min(...allX);
    const minY = Math.min(...allY);
    const maxX = Math.max(...allX);
    const maxY = Math.max(...allY);

    const addValue = 100;

    const width = maxX - minX + addValue;
    const height = maxY - minY + addValue;

    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;

    let scale = Math.min(containerWidth / width, containerHeight / height);
    scale = Math.max(ZoomOutValue, Math.min(scale, ZoomInValue)); // Add min and max zoom level

    const offsetX =
      (containerWidth - width * scale) / 2 - minX * scale + addValue;
    const offsetY =
      (containerHeight - height * scale) / 2 - minY * scale + addValue;

    setView({
      scale: scale,
      offsetX: offsetX,
      offsetY: offsetY,
    });
  };

  const zoomIn = () => {
    const newScale = Math.min(view?.scale + DefaultZoomValue, ZoomInValue);
    const newOffsetX =
      (view?.offsetX - containerRef.current.offsetWidth / 2) *
        (newScale / view?.scale) +
      containerRef.current.offsetWidth / 2;
    const newOffsetY =
      (view?.offsetY - containerRef.current.offsetHeight / 2) *
        (newScale / view?.scale) +
      containerRef.current.offsetHeight / 2;
    setView({ scale: newScale, offsetX: newOffsetX, offsetY: newOffsetY });
  };

  const zoomOut = () => {
    const newScale = Math.max(view?.scale - DefaultZoomValue, ZoomOutValue);
    const newOffsetX =
      (view?.offsetX - containerRef.current.offsetWidth / 2) *
        (newScale / view?.scale) +
      containerRef.current.offsetWidth / 2;
    const newOffsetY =
      (view?.offsetY - containerRef.current.offsetHeight / 2) *
        (newScale / view?.scale) +
      containerRef.current.offsetHeight / 2;
    setView({ scale: newScale, offsetX: newOffsetX, offsetY: newOffsetY });
  };

  const updateTriggerModelPosition = (event) => {
    const popup = document.getElementById('popup');
    if (!popup) return;

    const { offsetWidth: popupWidth, offsetHeight: popupHeight } = popup;
    const { innerWidth, innerHeight, scrollX, scrollY } = window;
    let { pageX, pageY } = event;

    let popupLeft = Math.max(
      scrollX,
      Math.min(pageX, innerWidth - popupWidth + scrollX)
    );

    let popupTop = Math.max(
      scrollY,
      Math.min(pageY, innerHeight - popupHeight + scrollY)
    );

    const currentLeft =
      popupLeft < innerWidth - 350
        ? Math.max(popupLeft - 75, 0)
        : Math.max(popupLeft - 420, 0);

    const currentTop =
      popupTop < innerHeight - 400
        ? Math.max(popupTop - 120, 0)
        : Math.max(popupTop - 480, 0);

    setTriggerModelPosition({ top: currentTop, left: currentLeft });
  };

  const edgeElements = useMemo(() => {
    return edges?.map((edge, index) => {
      const fromNode = nodes?.find((node) => node?.id === edge?.from);
      const toNode = nodes?.find((node) => node?.id === edge?.to);
      const deltaX = toNode?.x - fromNode?.x;
      const deltaY = toNode?.y - fromNode?.y;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
      const angle = Math.atan2(deltaY, deltaX);

      return (
        <div
          key={index}
          className={cn(`absolute h-1 rounded-sm`)}
          style={{
            left: fromNode?.x,
            top: fromNode?.y,
            width: distance,
            transform: `rotate(${angle}rad)`,
            transformOrigin: '0% 0%',
            background: `linear-gradient(to right, ${edge?.colors?.from}, ${edge?.colors?.to})`,
            borderRadius: '1.5px',
          }}
        />
      );
    });
  }, [nodes, edges]);

  return (
    <div
      ref={containerRef}
      className={cn(
        'w-full h-full relative overflow-auto select-none bg-sequence-bg-dot'
      )}
      // onWheel={handleWheel}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onMouseDown={(e) => handleMouseDown(e)}
      onClick={(e) => {}}
      onContextMenu={(event) => {
        event.preventDefault();
        event.stopPropagation();
        updateTriggerModelPosition(event);
        setOpenTriggerModel(!openTriggerModel);
      }}
    >
      <div
        className={cn(
          'absolute top-0 left-0 origin-top-left',
          isDraggingCanvasRef?.current ? 'cursor-grabbing' : 'cursor-grab'
        )}
        style={{
          transform: `translate(${view?.offsetX}px, ${view?.offsetY}px) scale(${view?.scale})`,
        }}
      >
        {edgeElements}
        {/* {nodeElements} */}
        <NodeElements
          nodes={nodes}
          handleMouseDown={handleMouseDown}
          onChange={onChange}
        />
      </div>

      {/* Controls */}
      <div className='w-full h-auto flex items-center justify-center fixed pt-10 z-40'>
        <Controls
          scale={view?.scale}
          handleZoomIn={zoomIn}
          handleZoomOut={zoomOut}
          handleFitView={fitView}
          handleUndo={undo}
          handleRedo={redo}
          disabled={{
            undo: undoStackRef?.current?.length === 0,
            redo: redoStackRef?.current?.length === 0,
          }}
        />
      </div>

      {/* Trigger Modal */}
      {openTriggerModel && (
        <div className={cn('absolute !z-50')}>
          <div id='popup'>
            <TriggerModel
              type={'tab'}
              open={openTriggerModel}
              setOpen={setOpenTriggerModel}
              position={triggerModelPosition}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Sequence;
