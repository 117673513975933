import React from 'react';

// helpers
import { cn } from '../../../../../helper/cn';

const Tabs = ({ tabs, selected, onTabChange }) => {
  return (
    <div className='flex items-center gap-3'>
      {tabs?.map((t) => {
        return (
          <div
            className={cn(
              'rounded-md py-1 px-2.5 border text-[13px] weight-medium cursor-pointer',
              t.key === 'none' || selected?.id === t?.id
                ? 'bg-[#6564CA] text-white border-[#6564CA]'
                : 'border-gray-200 hover:bg-gray-200'
            )}
            onClick={() => {
              onTabChange(t);
            }}
          >
            {t.label}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
