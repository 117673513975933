import React from 'react';

const AddIcon = ({
  fill = '#0000',
  stroke = '#fff',
  width = '30',
  height = '30',
  strokeWidth = '2',
  className = '',
  rect = true,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      {rect && <rect width='30' height='30' rx='5' fill={fill} />}
      <path
        d='M15.0002 10.1992L15.0002 19.7992M19.8002 14.9992L10.2002 14.9992'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default AddIcon;
