import React from 'react';

export default function ZoomIn({
  width = 19,
  height = 20,
  color = '#616874',
  strokeWidth = 2,
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M14.427 15.0396L17.9001 18.3996M5.9001 9.39961H11.9001M16.7801 9.43961C16.7801 13.7695 13.27 17.2796 8.9401 17.2796C4.61019 17.2796 1.1001 13.7695 1.1001 9.43961C1.1001 5.1097 4.61019 1.59961 8.9401 1.59961C13.27 1.59961 16.7801 5.1097 16.7801 9.43961Z'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  );
}
