import React from 'react';

// assets
import AddIcon from '../../../../../assets/customSVG/AddIcon';

// helpers
import { cn } from '../../../../../helper/cn';

// constants
import { INITIAL_TRIGGER_DATA } from '../../../constants/sequence';

// redux
import { useAspDispatch } from '../../../../../test/jest-redux-hooks';
import TriggerList from '../triggers/TriggerList';
import { update } from '../../../../../reduxToolkit/SequenceSlice';

const NewNode = ({
  id,
  is_trigger,
  component_type,
  trigger_type,
  type,
  label,
  order,
  data,
  settings,
  isEditMode,
  status,
  onChange,
}) => {
  const dispatch = useAspDispatch();

  const onSelectTrigger = (data) => {
    onChange({
      type: 'UPDATE_NODE',
      id: id,
      data: {
        ...INITIAL_TRIGGER_DATA[data?.key],
        data: initial_data,
      },
    });
  };

  return (
    <div className='flex items-center gap-5 relative'>
      <div className='relative flex flex-col items-center justify-center gap-2.5 !cursor-pointer'>
        <div
          className={cn(
            'group size-16 flex items-center justify-center rounded-2xl border-[3px] border-[#6940F2] group-hover:transition-all ease-linear duration-150',
            'bg-[#6940F216] hover:bg-[#6940F2]'
          )}
        >
          <AddIcon
            width={50}
            height={50}
            stroke=''
            strokeWidth='2'
            rect={false}
            className='stroke-[#6940F2] group-hover:stroke-[#ffffff] group-hover:transition-all ease-linear duration-150 group-hover:scale-110 group-hover:rotate-180'
          />
        </div>
        <div className='w-fit h-fit !text-xs text-center text-nowrap text-[#2D3036] weight-medium absolute -bottom-5'>
          <p className=''>{label}</p>
        </div>
      </div>
      {/* <div className='w-full min-w-96'>
        <TriggerList onSelectTrigger={onSelectTrigger} />
      </div> */}
    </div>
  );
};

export default NewNode;

/// demo
const initial_data = {
  type: 'template',
  category: 'MARKETING',
  template: [
    {
      type: 'HEADER',
      format: 'IMAGE',
      example: {
        header_handle: [
          'https://storage.googleapis.com/assets-preprod-gainwix/temp/media_1732084198987.jpg',
        ],
      },
    },
    {
      text: 'This is your first marketing template\n Grab cool offers using code {{custom-variable1}}',
      type: 'BODY',
      example: {
        body_text: [['OFFERSUMMER2000']],
      },
    },
    {
      type: 'BUTTONS',
      buttons: [
        {
          id: 'a70ebac28df923ec6b3c',
          text: 'Interested',
          type: 'QUICK_REPLY',
        },
        {
          id: '3004834cf5ea68852b70',
          text: 'Not Interested',
          type: 'QUICK_REPLY',
        },
        {
          id: '4e240bb9fb52e1e57942',
          text: 'Send More like This',
          type: 'QUICK_REPLY',
        },
      ],
    },
  ],
};
