import React from "react";

// helper
import { getLanguageName } from "../../../helper/setLanguageCode";
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";

// components
import InboxPreview from "./InboxPreview";
import { addLineBreake, getDataFromTemplate } from "../../../helper/templates";
import CarouselCardPreview from "./CarouselCardPreview";
import { replaceFormating } from "../../../modules/inbox/helper";

export default function TemplateCard({
  data,
  selected,
  className,
  handlePreviewSelect,
  useTemplate,
  previewSelect,
  useThisTemplate,
  header,
  carousel,
}) {
  const datas = getDataFromTemplate(data);

  const headerFormat = datas?.header?.format;
  const headerText = datas?.header?.text;
  const example = datas?.header?.example?.header_handle;

  // const carouselPreview = data?.map((item) => (
  //   <>{item?.subcategory_type === "CAROUSEL" && item?.cards}</>
  // ));

  const headers = data?.cards?.flatMap((item) =>
    item?.components?.filter((component) => component?.type === "HEADER")
  );

  const example_image = headers
    ?.filter((item) => item?.format === "IMAGE")
    ?.map((item) => item?.example?.header_handle);

  const example_video = headers
    ?.filter((item) => item?.format === "VIDEO")
    ?.map((item) => item?.example?.header_handle);

  return (
    <div
      className={`w-full h-[40vh] flex gap-[1vw] px-[0.5vw] mb-20 border-[1px] border-[#E8E8EA] rounded-[0.6vw] transition-colors ease-linear duration-300 ${className} ${
        selected ? "bg-[var(--BG-25)]" : "#E8E8EA"
      }`}
      onClick={() => {
        if (handlePreviewSelect) handlePreviewSelect(data);
        if (useTemplate) useTemplate();
      }}
      data-testid={useThisTemplate}
    >
      <div
        className="w-full pl-[0.5vw] flex flex-col gap-[0.5vw] cursor-pointer "
        onClick={() => {
          handlePreviewSelect(data);
        }}
        data-testid={previewSelect}
      >
        <div className="h-full flex-column gap-[0.5vw] ptb-10">
          <div className=" new-header capitalize">{data?.name}</div>
          <div className="bg-[#E7F1EE] overflow-hidden  p-10 b-radius-6  mb-10  h-full">
            {headerFormat && (
              <div className=" rounded-[0.7vw] py-[0.5vw] overflow-y-scroll">
                <div className="w-full rounded-[0.4vw] h-full overflow-hidden">
                  {headerFormat === "TEXT" && (
                    <div className="h-full new-body-text break-words">
                      {/* {useTemplates && header?.example?.header_text?.[0] !== 0
                      ? replaceVariables(
                          header?.text,
                          header?.example?.header_text
                        )
                      : header?.text} */}
                      {headerText}
                    </div>
                  )}
                  {headerFormat === "IMAGE" && (
                    <img
                      src={example}
                      className="w-full h-[150px] object-cover rounded-[0.4vw]"
                    />
                  )}
                  {headerFormat === "VIDEO" && (
                    <video
                      className="object-cover h-[150px] w-full rounded-[0.4vw]"
                      controls
                      autoplay="autoplay"
                      // loop
                    >
                      <source src={example} type="video/mp4" />
                    </video>
                  )}
                  {headerFormat === "DOCUMENT" && (
                    <iframe
                      title="cover"
                      className="imagePreview"
                      src={example}
                      type="application/pdf"
                      width="100%"
                      // height="150px"
                    ></iframe>
                  )}
                </div>
              </div>
            )}
            <div
              className={`new-body-text-ellipsis ${
                headerFormat === "IMAGE" ||
                headerFormat === "VIDEO" ||
                headerFormat === "DOCUMENT" ||
                example_image
                  ? "clamp-lines"
                  : "clamp-lines-text"
              }`}
              dangerouslySetInnerHTML={{
                __html: addLineBreake(replaceFormating(datas?.body?.text)),
              }}
            ></div>

            <div className="overflow-scroll mt-10 w-full">
              {data?.subcategory_type === "CAROUSEL" && (
                <div className="flex gap-4 w-[40vw]">
                  {example_image?.map((example, index) => (
                    <img
                      key={index}
                      src={example}
                      className="w-full h-[150px] object-cover rounded-[0.4vw]"
                      alt={`Carousel item ${index + 1}`}
                    />
                  ))}
                  {example_video?.map((example) => (
                    <video
                      className="object-cover h-[150px] w-full rounded-[0.4vw]"
                      controls
                      autoplay="autoplay"
                    >
                      <source src={example} type="video/mp4" />
                    </video>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="w-100 flex-row space-between">
            <div className="flex-row align-center gap-2.5">
              <div
                className={`px-[0.4vw] py-[0.15vw] rounded-[0.25vw] capitalize ${
                  data?.category === "MARKETING"
                    ? "marketing-title"
                    : "utility-title"
                }`}
              >
                {capitalizeFirstLetter(data?.category)}
              </div>
              <div className="px-[0.4vw] py-[0.15vw] language-title rounded-[0.25vw] capitalize">
                {getLanguageName(data?.language)}
              </div>
            </div>
            <span className="approved-title px-[0.5vw] py-[0.15vw] rounded-[0.37vw] mr-5">
              Approved
            </span>
          </div>
        </div>
        {/* <div className="w-fit py-[0.2vw] font-md weight-medium  rounded-[0.25vw] capitalize">
          <TemplateStatus id={data?.name} status={data?.status} />
        </div> */}
      </div>
    </div>
  );
}
