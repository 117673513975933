import React from "react";

const NoData = ({ title, content, modal, icon, className }) => {
  return (
    <div className={`w-full flex-row align-center justify-center ${className}`}>
      <div className="flex-column w-50 gap-6">
        <span className="no-data-header">{title && title}</span>
        <span className="w-80 no-data-body">{content && content}</span>
        <div>{modal && modal}</div>
      </div>
      <>{icon && <img src={icon} alt="icon" />}</>
    </div>
  );
};

export default NoData;
