import React from 'react';

const FB = ({ width = 22, height = 22, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.6496 8.09395H12.1621V5.76895C12.1621 5.46063 12.2846 5.16494 12.5026 4.94693C12.7206 4.72892 13.0163 4.60645 13.3246 4.60645H14.4871V1.7002H12.1621C11.2372 1.7002 10.3501 2.06763 9.69607 2.72166C9.04204 3.37569 8.67461 4.26275 8.67461 5.1877V8.09395H6.34961V11.0002H8.67461V20.3002H12.1621V11.0002H14.4871L15.6496 8.09395Z'
        fill={color}
      />
    </svg>
  );
};

export default FB;
