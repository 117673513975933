import React, { useCallback } from 'react';

// components
import ImageMessagePreview from '../../../../inbox/components/conversation/ImageMessagePreview';
import VideoMessagePreview from '../../../../inbox/components/conversation/VideoMessagePreview';
import DocumentMessagePreview from '../../../../inbox/components/conversation/DocumentMessagePreview';
import AudioMessagePreview from '../../../../inbox/components/conversation/AudioMessagePreview';

const MediaMessage = ({ node }) => {
  const {
    id,
    node_type,
    is_trigger,
    type,
    component_type,
    trigger_type,
    label,
    order,
    data,
    settings,
    isEditMode,
    status,
  } = node;
  const { type: data_type, category } = data;

  const key = useCallback(() => {
    return data_type?.toUpperCase();
  }, [node]);

  return (
    <div className='w-full h-full bg-white p-2 rounded-2xl'>
      {component_type === 'TEXT' ? (
        <div className='text-sm weight-medium text-[#2D3036]'>
          {data?.text?.body}
        </div>
      ) : component_type === 'MEDIA' ? (
        key() === 'IMAGE' ? (
          <ImageMessagePreview
            url={node?.data?.image?.link || ''}
            caption={node?.data?.image?.caption || ''}
            rootClassName={'max-w-64 min-w-full h-fullx !p-0'}
            className={'w-full'}
          />
        ) : key() === 'VIDEO' ? (
          <VideoMessagePreview
            url={node?.data?.video?.link || ''}
            caption={node?.data?.video?.caption || ''}
            rootClassName={'max-w-64 min-w-full h-fullx !p-0'}
            className={'w-full'}
          />
        ) : key() === 'DOCUMENT' ? (
          <DocumentMessagePreview
            url={node?.data?.document?.link || ''}
            caption={node?.data?.document?.caption || ''}
            fileName={node?.data?.document?.filename}
            rootClassName={'max-w-64 min-w-full h-fullx !p-0'}
            className={'w-full'}
          />
        ) : key() === 'AUDIO' ? (
          <AudioMessagePreview
            url={node?.data?.audio?.link || ''}
            caption={node?.data?.audio?.caption || ''}
            rootClassName={'max-w-64 min-w-full h-fullx !p-0'}
            className={'w-full'}
          />
        ) : key() === 'NONE' ? (
          ''
        ) : (
          ''
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default MediaMessage;
