export const isHeaderValid = (header, headerVariable) => {
  const isHeader = header
    ? (header?.format === 'TEXT' && header?.text !== '' && !headerVariable) ||
      (header?.format === 'TEXT' &&
        header?.text !== '' &&
        headerVariable &&
        headerVariable?.value !== '') ||
      (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(header?.format) &&
        header?.example?.header_handle &&
        header?.example?.header_handle?.[0] !== '')
    : true;

  return isHeader;
};
