import { ICONS } from "../../../../src/assets/icons/index";
import { images } from "assets/images";

export const CHANNEL_LIST = [
  {
    id: 1,
    label: (
      <div className="flex items-center gap-2.5">
        <img src={images.WhatsApp} alt={""} className="size-5" />
        <p>WhatsApp</p>
      </div>
    ),
    value: "",
  },
  // {
  //   id: 2,
  //   label: (
  //     <div className="flex items-center gap-2.5">
  //       <img src={images?.Sms} alt={""} className="size-5" />
  //       <p>SMS</p>
  //     </div>
  //   ),
  //   value: "",
  // },
];

export const GOALS_LIST = [
  {
    id: 1,
    label: (
      <div className="flex items-center gap-2.5">
        <img src={ICONS.engagementIcon} alt={""} className="size-7" />
        <p>Engagement</p>
      </div>
    ),
    value: 1,
  },
  {
    id: 2,
    label: (
      <div className="flex items-center gap-2.5">
        <img src={ICONS.leadsIcon} alt={""} className="size-7" />
        <p>Leads</p>
      </div>
    ),
    value: 2,
  },
  {
    id: 3,
    label: (
      <div className="flex items-center gap-2.5">
        <img src={ICONS.awarenessIcon} alt={""} className="size-7" />
        <p>Awareness</p>
      </div>
    ),
    value: 3,
  },
  // {
  //   id: 4,
  //   label: (
  //     <div className="flex items-center gap-2.5">
  //       <img src={images.WhatsApp} alt={""} className="size-7" />
  //       <p>Sales</p>
  //     </div>
  //   ),
  //   value: "",
  // },

  // {
  //   id: 5,
  //   label: (
  //     <div className="flex items-center gap-2.5">
  //       <img src={images.WhatsApp} alt={""} className="size-7" />
  //       <p>Branding</p>
  //     </div>
  //   ),
  //   value: "",
  // },
  // {
  //   id: 6,
  //   label: (
  //     <div className="flex items-center gap-2.5">
  //       <img src={images?.Sms} alt={""} className="size-7" />
  //       <p>Support</p>
  //     </div>
  //   ),
  //   value: "",
  // },
];

export const GOALS_TARGET = [
  {
    id: 1,
    label: 25,
    value: 25,
  },
  {
    id: 2,
    label: 50,
    value: 50,
  },
  {
    id: 3,
    label: 75,
    value: 75,
  },
  {
    id: 4,
    label: 100,
    value: 100,
  },
];

export const GOAL_TARGET_TYPES = [
  {
    id: 1,
    label: (
      <div className="flex items-center">
        {/* <img src={images.WhatsApp} alt={""} className="size-5" /> */}
        <p># &nbsp;Number</p>
      </div>
    ),
    value: "",
  },
  {
    id: 2,
    label: (
      <div className="flex items-center">
        {/* <img src={images?.Sms} alt={""} className="size-5" /> */}
        <p>% &nbsp;Percentage</p>
      </div>
    ),
    value: "",
  },
  {
    id: 3,
    label: (
      <div className="flex items-center">
        {/* <img src={images?.Sms} alt={""} className="size-5" /> */}
        <p>$ &nbsp;Currency</p>
      </div>
    ),
    value: "",
  },
];

export const GOAL_EVALUATION_TYPE = [
  {
    id: 1,
    label: "Overall",
    value: 0,
  },
  {
    id: 2,
    label: "Unique",
    value: 1,
  },
];
