import React from "react";

// assets
import { ICONS } from "../../../assets/icons";
import Whatsapp from "../../../assets/customSVG/Whatsapp";
import Eye from "assets/customSVG/Eye";

// helper
import { capitalizeFirstLetter } from "../../../helper/capitalizeFirstLetter";
import { dateConverter } from "../../../helper/dateConverter";
import { useNavigate } from "react-router-dom";

export default function BroadcastListCard({ broadcast }) {
  const navigate = useNavigate();
  const data = { ...broadcast, id: broadcast?.id };

  return (
    <>
      <div
        key={broadcast?.id}
        className="flex items-center gap-2 p-2.5 border border-[#F0F1F2] rounded-2xl shadow-[0px_1.5px_2px_0px_#1018281A] cursor-pointer"
        onClick={() => {
          navigate(
            `/user/campaign/time_specified/segment_list/${broadcast?.id}`,
            {
              state: {
                item: data,
              },
            }
          );
        }}
      >
        <div className="w-full flex flex-col justify-center gap-[0.3vw]">
          <div className="w-full text-[0.9rem] text-[#616874] text-ellipsis line-clamp-1 flex items-center gap-[0.5vw] justify-between">
            <div className="flex-row items-center gap-2">
              {/* <div
                className={`contents-[""] w-[1vw] h-[1vw] rounded`}
                style={{
                  backgroundColor: broadcast?.display_color,
                }}
              />
              {broadcast?.name} */}
              {broadcast?.display_color ? (
                <div
                  className="w-[1vw] h-[1vw] rounded"
                  style={{
                    backgroundColor: broadcast.display_color,
                  }}
                />
              ) : (
                <span>{broadcast?.display_emoji}</span>
              )}
              {broadcast?.name}
            </div>

            <div>
              <Eye
                width="1.1vw"
                height="1.1vw"
                color=""
                className={`stroke-[#898E99] `}
              />
            </div>
          </div>

          <p className="text-[0.86rem] font-semibold border-b-[1px] pb-2">
            {broadcast?.name}
          </p>

          <div className="w-full flex items-center justify-between text-[var(--font-400)] font-xs py-1">
            <div className="flex items-center gap-[0.5vw]">
              <img
                src={ICONS?.calendarScheduled}
                alt="icon"
                className="w-[1vw] h-[1vw]"
              />
              {dateConverter(broadcast?.schedule_on, false)?.timeWithMeridiem}
            </div>
            <img src={ICONS?.whatsApp} />
          </div>
        </div>
      </div>
    </>
  );
}
