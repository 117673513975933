import React from "react";
import { Bar, Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import FilterHome from "../../../components/commonComponents/filterHome/FilterHome";
import { ICONS } from "../../../assets/icons";
import {
  HomeFilterCampaign,
  HomeFilterList,
} from "../../../constant/app/Filter/Filter";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Tooltip,
//   Legend,
// } from "chart.js";

// // Register necessary components from Chart.js
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Tooltip,
//   Legend
// );

const Chart = () => {
  const salesData = [
    { name: "name", sales: 500, accounts: 3000, label_bg: "#A175FF" },
    { name: "name", sales: 300, accounts: 3000, label_bg: "#A175FF" },
    { name: "name", sales: 200, accounts: 2000, label_bg: "#A175FF" },
    { name: "name", sales: 270, accounts: 2000, label_bg: "#A175FF" },
    { name: "name", sales: 190, accounts: 1000, label_bg: "#A175FF" },
  ];

  const labels = salesData.map((item) => `${item?.label_bg} ${item?.name}`);
  // const labels = salesData.map((item, index) => (
  //   <div key={index} style={{ color: item.label_bg }}>
  //     {item.name}
  //   </div>
  // ));

  const salesValues = salesData.map((item) => item.sales);
  const accountsValues = salesData.map((item) => item.accounts);

  const maxSalesValue = Math.max(...salesValues);
  const maxAccountsValue = Math.max(...accountsValues);
  const maxCombinedValue = Math.max(maxSalesValue, maxAccountsValue);

  // const yAxisMax = Math.ceil((maxCombinedValue * 1.1) / 100) * 100;

  const magnitude = Math.pow(10, Math.floor(Math.log10(maxCombinedValue)));
  const stepSize = magnitude;
  const yAxisMax = Math.ceil(maxCombinedValue / stepSize) * stepSize + stepSize;

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total",
        data: salesValues,
        fill: false,
        backgroundColor: "#A175FF",
        borderColor: "#A175FF",
        borderWidth: 1,
        borderRadius: 10,
      },
      {
        label: "Delivered",
        data: accountsValues,
        fill: false,
        backgroundColor: "#EDAB40",
        borderColor: "#EDAB40",
        borderWidth: 1,
        borderRadius: 10,
      },
      {
        label: "Opened",
        data: accountsValues,
        fill: false,
        backgroundColor: "#1778F2",
        borderColor: "#1778F2",
        borderWidth: 1,
        borderRadius: 10,
      },
      {
        label: "Bounced",
        data: accountsValues,
        fill: false,
        backgroundColor: "#FF543E",
        borderColor: "#FF543E",
        borderWidth: 1,
        borderRadius: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,

    plugins: {
      legend: {
        position: "bottom",
        pointRadius: 6,
        layout: {
          padding: {
            top: 70,
            bottom: 10,
          },
        },
        labels: {
          type: "category",
          usePointStyle: true,
          pointStyle: "circle",
          padding: 30,
          pointRadius: 10,
        },
      },
    },

    barPercentage: 0.5,
    categoryPercentage: 0.4,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 14,
            weight: "bold",
            color: "#333",
          },
          // callback: function (value, index, ticks) {
          //   const labels = this.chart.data.labels;
          //   const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4"];
          //   return "● " + labels[index];
          // },
          // callback: function (value, index, ticks) {
          //   const labels = this.chart.data.labels;
          //   return "● " + labels[index];

          //   // return `\u2022 ${labels[index]}`;
          // },
          // color: function (context) {
          //   const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4"];
          //   return colors[context.index % colors.length];
          // },
          render: function (value, index, ticks) {
            const labels = this.chart.data.labels;
            const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#96CEB4"];

            // Wrap the bullet point in a styled span
            return `<span class="colored-bullet" style="color:${
              colors[index % colors.length]
            };background-color:${colors[index % colors.length]}">●</span> ${
              labels[index]
            }`;
          },
          padding: 10,
        },
        // ticks: {
        //   font: {
        //     size: 14,
        //     weight: "bold", // Change font weight
        //     color: "#333",
        //   },
        // },
      },
      y: {
        beginAtZero: true,
        max: yAxisMax,

        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return `${value / 1000}k`;
            }
            return value;
          },
          stepSize: 1000,
          maxTicksLimit: 6,
        },
      },
    },
  };

  return (
    <div className="bg-white rounded-[1vw] px-[2vw] py-[1vw] w-100">
      <div className="flex-row justify-between items-center pb-4">
        <p className="flex-row items-center text-[1.09rem] font-[500]">
          Conversation Performance
          <span className="ml-2">
            <img src={ICONS?.homeDownload} className="w-[18px] h-[18px]" />
          </span>
        </p>

        <FilterHome filterList={HomeFilterCampaign} />
      </div>

      <div style={{ height: "35vh", padding: "0vw 1vw" }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default Chart;
