import React, { useState } from 'react';

const Switch = ({ label, isChecked, toggleSwitch }) => {
  return (
    <div className='flex items-center'>
      <div
        className={`relative inline-block w-7 h-5 cursor-pointer ${
          isChecked ? 'bg-[var(--primary)]' : 'bg-gray-300'
        } rounded-full`}
        onClick={toggleSwitch}
      >
        <span
          className={`absolute left-[3px] top-[3px] size-3.5 bg-white rounded-full transition-all duration-300 ease-in-out ${
            isChecked ? 'transform translate-x-2' : ''
          }`}
        ></span>
      </div>
      {label && (
        <label htmlFor='toggle' className='mr-2 text-gray-700'>
          {label}
        </label>
      )}
    </div>
  );
};

export default Switch;
