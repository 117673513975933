import React from 'react';

const Media = ({ width = 22, height = 22, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_19016_134225)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.916992 5.95866C0.916992 4.62153 1.44817 3.33916 2.39366 2.39366C3.33916 1.44817 4.62153 0.916992 5.95866 0.916992H16.042C17.3791 0.916992 18.6615 1.44817 19.607 2.39366C20.5525 3.33916 21.0837 4.62153 21.0837 5.95866V16.042C21.0837 17.3791 20.5525 18.6615 19.607 19.607C18.6615 20.5525 17.3791 21.0837 16.042 21.0837H5.95866C4.62153 21.0837 3.33916 20.5525 2.39366 19.607C1.44817 18.6615 0.916992 17.3791 0.916992 16.042V5.95866ZM5.95866 2.75033C5.10776 2.75033 4.2917 3.08835 3.69003 3.69003C3.08835 4.2917 2.75033 5.10776 2.75033 5.95866V16.042C2.75033 16.8929 3.08835 17.709 3.69003 18.3106C4.2917 18.9123 5.10776 19.2503 5.95866 19.2503H16.042C16.8929 19.2503 17.709 18.9123 18.3106 18.3106C18.9123 17.709 19.2503 16.8929 19.2503 16.042V5.95866C19.2503 5.10776 18.9123 4.2917 18.3106 3.69003C17.709 3.08835 16.8929 2.75033 16.042 2.75033H5.95866Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.916992 7.33366C0.916992 7.09054 1.01357 6.85739 1.18548 6.68548C1.35739 6.51357 1.59054 6.41699 1.83366 6.41699H20.167C20.4101 6.41699 20.6433 6.51357 20.8152 6.68548C20.9871 6.85739 21.0837 7.09054 21.0837 7.33366C21.0837 7.57677 20.9871 7.80993 20.8152 7.98184C20.6433 8.15375 20.4101 8.25033 20.167 8.25033H1.83366C1.59054 8.25033 1.35739 8.15375 1.18548 7.98184C1.01357 7.80993 0.916992 7.57677 0.916992 7.33366Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.04392 0.963031C7.15816 0.924962 7.27878 0.909775 7.39889 0.918335C7.519 0.926895 7.63624 0.959036 7.74393 1.01292C7.85161 1.06681 7.94762 1.14138 8.02648 1.23238C8.10533 1.32338 8.16549 1.42903 8.2035 1.54328L10.0368 7.04328C10.1075 7.27222 10.0857 7.51972 9.97618 7.7328C9.86663 7.94588 9.678 8.1076 9.45069 8.18333C9.22338 8.25906 8.97545 8.24279 8.75999 8.13799C8.54454 8.03318 8.37867 7.84819 8.29792 7.62261L6.46459 2.12261C6.38766 1.89202 6.40548 1.64031 6.51413 1.42285C6.62277 1.2054 6.81334 1.03999 7.04392 0.963031ZM12.5439 0.963031C12.6582 0.924962 12.7788 0.909775 12.8989 0.918335C13.019 0.926895 13.1362 0.959036 13.2439 1.01292C13.3516 1.06681 13.4476 1.14138 13.5265 1.23238C13.6053 1.32338 13.6655 1.42903 13.7035 1.54328L15.5368 7.04328C15.6075 7.27222 15.5857 7.51972 15.4762 7.7328C15.3666 7.94588 15.178 8.1076 14.9507 8.18333C14.7234 8.25906 14.4755 8.24279 14.26 8.13799C14.0445 8.03318 13.8787 7.84819 13.7979 7.62261L11.9646 2.12261C11.8877 1.89202 11.9055 1.64031 12.0141 1.42285C12.1228 1.2054 12.3133 1.03999 12.5439 0.963031ZM10.0836 16.4996V10.9996H11.9169V16.4996H10.0836Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.7505 14.6673H8.25049V12.834H13.7505V14.6673Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_19016_134225'>
          <rect
            width='22'
            height='22'
            fill={color}
            transform='translate(0.000488281)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Media;
