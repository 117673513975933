import React from 'react';

// assets
import Icons from '../Icons';

// helper
import { cn } from '../../../../../helper/cn';

const PreviewNodeContainer = ({ children, node }) => {
  return (
    <div className='w-full min-w-[300px] h-full rounded-2xl overflow-hidden select-none bg-[#04B8A2]'>
      <div
        className={cn(
          'flex items-center justify-between gap-5 px-5 py-2 bg-[#04B8A2] text-white'
        )}
      >
        <div className='flex items-center gap-5'>
          <Icons keyProp={node?.label?.toLowerCase()} bgProp={'transparent'} />
          <p className='capitalize'>{node?.label}</p>
        </div>
      </div>
      <div className='p-2.5 rounded-2xl'>{children}</div>
    </div>
  );
};

export default PreviewNodeContainer;
