import React from 'react';
import { TRIGGERS } from '../../../constants/sequence';
import TriggerListCard from './TriggerListCard';

export default function TriggerList({ onSelectTrigger }) {
  return (
    <div className='w-full h-full rounded-3xl'>
      <div className='w-full px-5 py-2.5 rounded-t-xl bg-gradient-to-r from-[#9879FF] to-[#FFAC97]'>
        <div></div>
        <div className='text-white'>Triggers</div>
      </div>
      <div className='w-full h-full px-5 pb-4 rounded-b-3xl bg-white'>
        {TRIGGERS?.map((t) => {
          const show = t?.list?.filter((l) => !l?.disabled);

          if (show?.length === 0) return;

          return (
            <div key={t?.id} className=''>
              <div className='text-xs weight-semibold py-3.5 text-[var(--font-600)]'>
                {t?.title}
              </div>
              <div className='flex flex-col gap-2.5'>
                {t?.list?.map((l) => {
                  if (l?.disabled) return;

                  return (
                    <div
                      key={l?.id}
                      className=''
                      onClick={() => {
                        if (!l?.disabled) onSelectTrigger(l);
                      }}
                    >
                      <TriggerListCard data={l} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
