import { ADD_VARIABLE_OPTIONS } from '../constant/template';

// filter all variables
const variableRegEx = /{{([^}]*)}}/g;

const checkCurlyBrackets = /{{2}([^{}]+){2}(?=\}{2})/g;

// filter only custom variables
const customVariablesRegEx = /{{(custom-variable\d+)}}/g;

// validation patterns
const variableValidationPattern = [
  // /}}{{/g,
  /{{2}[^}]*[^}]}[^}]/g,
  /(.*?){{(.*?)}}}(.*?)/g,
  /(.*?){{{(.*?)}}(.*?)/g,
];

// get variables
export const getVariableValue = (variables, title) => {
  const res = variables?.filter((v) => v?.title === title);
  return res?.length > 0 ? res?.[0] : null;
};

// update variables
export const updateVariablesValue = (
  variables1,
  variables2 = [],
  title,
  value
) => {
  const res = variables1?.map((v) => {
    let variableValue = null;
    if (variables2?.length > 0)
      variableValue = variables2?.filter((v) => v?.title === title);
    if (v?.title === title)
      return { ...v, value: value || variableValue?.[0]?.value || '' };
    return v;
  });
  return res;
};

export const updateVariablesFromText = (value, variables1, variables2) => {
  let result = fliterWithCurlyBrackets(value);
  let list = [];

  result?.map((r, i) => {
    const formatedVariable = `${r}}}`;
    const data1 =
      variables1?.length > 0
        ? variables1?.filter((v) => v?.title === formatedVariable)
        : [];
    const data2 =
      variables2?.length > 0
        ? variables2?.filter((v) => v?.title === formatedVariable)
        : [];
    if (checkIsoriginalVariable(formatedVariable)) {
      const checkList = list?.filter((l) => l?.title === formatedVariable);
      if (data1?.length > 0 && checkList?.length === 0) {
        list.push({ title: formatedVariable, value: data1?.[0]?.value });
      }
      if (
        (data1?.length === 0 || !variables1) &&
        data2?.length > 0 &&
        checkList?.length === 0
      ) {
        list.push({ title: formatedVariable, value: data2?.[0]?.value });
      }

      // add the variable when typing
      else if (
        data1?.length == 0 &&
        data2?.length == 0 &&
        checkList?.length === 0
      ) {
        let variableValue = '';
        if (formatedVariable === data1?.[0]?.title)
          variableValue = data1?.[0]?.value;
        if (formatedVariable === data1?.[0]?.title && data2?.length === 0)
          variableValue = data2?.[0]?.value;

        list.push({
          title: formatedVariable,
          value: variableValue,
        });
      }
    }
  });
  return list;
};

// check variables
export const checkIsoriginalVariable = (variable) => {
  const list = [];
  ADD_VARIABLE_OPTIONS?.map((v) => {
    if (v.label !== 'custom-variable' && v.value === variable) list.push(v);
    else if (variable?.toString()?.match(customVariablesRegEx)) list.push(v);
  });

  return list?.length > 0 ? true : false;
};

export const checkVariable = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const checkVariablesAreValid = (value) => {
  const res = variableValidationPattern?.map((p, i) => {
    var updatedStr = value?.toString().toLowerCase().match(p);
    return updatedStr?.length > 0 ? true : false;
  });
  return res?.filter((r) => r === true)?.length > 0 ? true : false;
};

// curlly brackets
export const fliterWithCurlyBrackets = (value) =>
  value?.toString()?.toLowerCase()?.match(checkCurlyBrackets);

export const addClosingBracket = (str) => {
  const regex = /\{+(?!})*$/g;
  return str.replaceAll(regex, `{{$1}}`);
};

// variables counts
export const getVariableCount = (value) =>
  value?.toString()?.toLowerCase()?.match(variableRegEx);

export const getCustomVariableCount = (value) =>
  value?.toString()?.toLowerCase()?.match(customVariablesRegEx);

export const getReplaceVariablesAndCount = (value) => {
  let count = 1;

  value = value?.replaceAll?.(/{{first-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{last-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{shop-name}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{email-id}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(/{{mobile-number}}/g, function (match, variable) {
    return `${count++}`;
  });
  value = value?.replaceAll?.(customVariablesRegEx, function (match, variable) {
    return `${count++}`;
  });

  return {
    count,
    value,
  };
};

export const replaceCustomVariableCount = (value) => {
  var count = 1;
  if (value?.length > 0)
    return value?.replaceAll(customVariablesRegEx, function (match, variable) {
      return `{{custom-variable${count++}}}`;
    });
  return value;
};
