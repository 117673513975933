import React from 'react';

export default function FitView({
  width = 17,
  height = 18,
  color = '#616874',
  strokeWidth = 2,
  className = '',
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M6 1.5H2.66667C1.74619 1.5 1 2.24619 1 3.16667V6.5M6 16.5H2.66667C1.74619 16.5 1 15.7538 1 14.8333V11.5M11 1.5H14.3333C15.2538 1.5 16 2.24619 16 3.16667V6.5M16 11.5V14.8333C16 15.7538 15.2538 16.5 14.3333 16.5H11'
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
