import React from "react";

export default function VariablesPreviewCard({
  data,
  className,
  titleClassName,
  valueClassName,
  changeVariableText = true,
  onChange,
}) {
  return (
    <div
      className={`flex h-full items-center gap-[1vw] py-[0.6vw] ${className}`}
    >
      <div
        className={`w-[40%] h-[2.23vw] primary-header p-[0.5vw] flex items-center border-[0.08vw] border-[var(--border-50)] rounded-[0.37vw] ${titleClassName}`}
      >
        {data?.title}
      </div>
      <div className="w-[59%] h-[2.23vw] popover-title border-[0.08vw] border-[var(--border-50)] rounded-[0.37vw] flex items-center pl-2">
        {changeVariableText ? (
          <input
            type="text"
            value={data?.value}
            placeholder={
              data?.title === "email" ? "Ex:- abc@mail.com" : "Ex:- text"
            }
            className={`w-full h-full rounded-[0.37vw] p-[0.5vw] ${valueClassName}`}
            onChange={(e) => {
              onChange({ key: data?.title, value: e?.target?.value });
            }}
            data-testId="edit-variables"
          />
        ) : (
          <p>{data?.value}</p>
        )}
      </div>
    </div>
  );
}
