// Feature Flags
const FEATURE_FLAG_STARED_LIST = false;
const FEATURE_FLAG_ADD_TO_STAR = false;
const FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION = false;
const FEATURE_FLAG_CONVERSATION_OPEN = false;
const FEATURE_FLAG_CONVERSATION_CLOSE = false;
const FEATURE_FLAG_BLOCKED_STATUS = false;

const FEATURE_FLAG_TEMPLATE_CHANGE_MEDIA = true;
const FEATURE_FLAG_TEMPLATE_CHANGE_VARIABLE = true;

const FEATURE_FLAG_SEND_ATTACHMENTS = true;
const FEATURE_FLAG_SEND_IMAGE = true;
const FEATURE_FLAG_SEND_VIDEO = true;
const FEATURE_FLAG_SEND_DOCUMENT = true;

const FEATURE_FLAG_VIEW_CONTACT_DETAILS = false;
const FEATURE_FLAG_EDIT_CONTACT_DETAILS = false;
const FEATURE_FLAG_EDIT_CONTACT_ADDRESS_DETAILS = false;

const FEATURE_FLAG_VIEW_TAGS_IN_CONTACT_DETAILS = false;
const FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS = false;
const FEATURE_FLAG_DELETE_TAGS_IN_CONTACT_DETAILS = false;

// NEW DEVELOPMENT

export {
  FEATURE_FLAG_STARED_LIST,
  FEATURE_FLAG_ADD_TO_STAR,
  FEATURE_FLAG_MANUALLY_CLOSE_CONVERSATION,
  FEATURE_FLAG_CONVERSATION_OPEN,
  FEATURE_FLAG_CONVERSATION_CLOSE,
  FEATURE_FLAG_BLOCKED_STATUS,
  FEATURE_FLAG_TEMPLATE_CHANGE_MEDIA,
  FEATURE_FLAG_TEMPLATE_CHANGE_VARIABLE,
  FEATURE_FLAG_SEND_ATTACHMENTS,
  FEATURE_FLAG_SEND_IMAGE,
  FEATURE_FLAG_SEND_VIDEO,
  FEATURE_FLAG_SEND_DOCUMENT,
  FEATURE_FLAG_VIEW_CONTACT_DETAILS,
  FEATURE_FLAG_EDIT_CONTACT_DETAILS,
  FEATURE_FLAG_EDIT_CONTACT_ADDRESS_DETAILS,
  FEATURE_FLAG_ADD_TAGS_TO_CONTACT_DETAILS,
  FEATURE_FLAG_VIEW_TAGS_IN_CONTACT_DETAILS,
  FEATURE_FLAG_DELETE_TAGS_IN_CONTACT_DETAILS,

  // NEW DEVELOPMENT
};
