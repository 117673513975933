import React from "react";

const Unread = ({ color, fill }) => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_19681_101415)">
          <path
            d="M18.3011 9.14225C18.3458 9.83408 18.3458 10.5506 18.3011 11.2424C18.0726 14.7777 15.296 17.5938 11.8101 17.8254C10.6209 17.9045 9.3793 17.9043 8.19247 17.8254C7.78377 17.7983 7.3383 17.7008 6.95464 17.5428C6.52778 17.3669 6.3143 17.279 6.20583 17.2923C6.09736 17.3057 5.93997 17.4218 5.6252 17.6538C5.0702 18.0631 4.37102 18.3571 3.33416 18.3318C2.80985 18.3191 2.5477 18.3127 2.43033 18.1126C2.31297 17.9125 2.45914 17.6355 2.75147 17.0815C3.15692 16.3132 3.41381 15.4336 3.02456 14.7288C2.35416 13.7222 1.78472 12.53 1.7015 11.2424C1.65679 10.5506 1.65679 9.83408 1.7015 9.14225C1.92998 5.60699 4.70662 2.79094 8.19247 2.55923C9.19447 2.49262 9.4023 2.48213 10.418 2.52807"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.08203 12.4997H12.9154M7.08203 8.33301H9.9987"
            stroke={color}
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3333 4.58268C18.3333 6.19352 17.0275 7.49935 15.4167 7.49935C13.8058 7.49935 12.5 6.19352 12.5 4.58268C12.5 2.97185 13.8058 1.66602 15.4167 1.66602C17.0275 1.66602 18.3333 2.97185 18.3333 4.58268Z"
            stroke={color}
            stroke-width="1.67"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_19681_101415">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Unread;
