import React from 'react';

const Template = ({
  width = 22,
  height = 22,
  color = 'white',
  strokeWidth = '1.83333',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_19016_134257)'>
        <path
          d='M12.834 13.7497C12.834 13.2434 13.2444 12.833 13.7507 12.833H17.4173C17.9236 12.833 18.334 13.2434 18.334 13.7497V17.4163C18.334 17.9226 17.9236 18.333 17.4173 18.333H13.7507C13.2444 18.333 12.834 17.9226 12.834 17.4163V13.7497Z'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.66699 4.58366C3.66699 4.0774 4.0774 3.66699 4.58366 3.66699H8.25033C8.75659 3.66699 9.16699 4.0774 9.16699 4.58366V8.25033C9.16699 8.75659 8.75659 9.16699 8.25033 9.16699H4.58366C4.0774 9.16699 3.66699 8.75659 3.66699 8.25033V4.58366Z'
          stroke={color}
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.834 4.58366C12.834 4.0774 13.2444 3.66699 13.7507 3.66699H17.4173C17.9236 3.66699 18.334 4.0774 18.334 4.58366V8.25033C18.334 8.75659 17.9236 9.16699 17.4173 9.16699H13.7507C13.2444 9.16699 12.834 8.75659 12.834 8.25033V4.58366Z'
          stroke={color}
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.66699 13.7497C3.66699 13.2434 4.0774 12.833 4.58366 12.833H8.25033C8.75659 12.833 9.16699 13.2434 9.16699 13.7497V17.4163C9.16699 17.9226 8.75659 18.333 8.25033 18.333H4.58366C4.0774 18.333 3.66699 17.9226 3.66699 17.4163V13.7497Z'
          stroke={color}
          strokeWidth='1.83333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_19016_134257'>
          <rect
            width='22'
            height='21.6'
            fill={color}
            transform='translate(0.000488281 0.200195)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Template;
