import React, { useCallback, useRef, useState } from "react";

// assets
import Loader from "../../../../../components/commonComponents/Loader/Index";

// constants
import { MEDIA_TYPES, NONE_TYPE } from "../../../constants/sequence";

// helpers
import { cn } from "../../../../../helper/cn";
import { isObjectEmpty } from "../../../helpers";

// hooks
import { useFileUploader } from "../../../../../hooks/useFileUploader";

// components
import MediaMessage from "../preview/MediaMessage";
import Tabs from "./Tabs";
import ImageMessagePreview from "../../../../inbox/components/conversation/ImageMessagePreview";
import VideoMessagePreview from "../../../../inbox/components/conversation/VideoMessagePreview";
import DocumentMessagePreview from "../../../../inbox/components/conversation/DocumentMessagePreview";
import AudioMessagePreview from "../../../../inbox/components/conversation/AudioMessagePreview";

const details = {
  IMAGE: {
    label: "Drop a JPG or PNG here",
    note: "Image size should be less than 5MB",
    accept: ".jpg, .jpeg, .png",
  },
  VIDEO: {
    label: "Drop a MP4 video here",
    note: "Video size should be less than 16MB",
    accept: ".mp4",
  },
  DOCUMENT: {
    label: "Drop a PDF file here",
    note: "Document size should be less than 100MB",
    accept: ".pdf, .doc",
  },
  AUDIO: {
    label: "Drop an audio here",
    note: "Audio size should be less than 16MB",
    accept: ".mp3",
  },
};

const HeaderMessage = ({ node, labelClassName, onChange }) => {
  const uploadRef = useRef();
  const {
    uploading,
    setUploading,
    selectedFile,
    selectedFileFormat,
    setSelectedFile,
    responseFile,
    uploadFiles,
    handleDropFiles,
    error,
    setError,
    clearFiles,
    setSelectedFileFormat,
    setResponseFile,
  } = useFileUploader();

  const HEADER_TYPES = []?.includes(node?.component_type)
    ? [NONE_TYPE, ...MEDIA_TYPES]
    : MEDIA_TYPES;

  const [selectedTab, setSelectedTab] = useState(HEADER_TYPES?.[0]);

  const onTabChange = (data) => {
    setSelectedTab(data);
    onChange({
      type: "UPDATE_NODE",
      id: node?.id,
      key: "data",
      data: {
        // type: selectedTab?.key?.toLowerCase(),
        // [`${selectedTab?.key?.toLowerCase()}`]: {
        //   link: '',
        // },
      },
    });
  };

  const key = useCallback(() => {
    return node?.data?.type?.toUpperCase();
  }, [node]);

  return (
    <div className="">
      <div className="">
        <h5 className={cn("", labelClassName)}>Header</h5>
        <div className="flex items-center gap-3.5">
          <Tabs
            tabs={HEADER_TYPES}
            selected={selectedTab}
            onTabChange={onTabChange}
          />
        </div>
      </div>

      {node?.data && !isObjectEmpty(node?.data) ? (
        <div className="w-full flex mt-2.5">
          {key() === "IMAGE" ? (
            <ImageMessagePreview
              url={node?.data?.image?.link || ""}
              caption={node?.data?.image?.caption || ""}
              className={"max-w-64 min-w-full max-h-36 p-0"}
              imageClassName={"w-full"}
            />
          ) : key() === "VIDEO" ? (
            <VideoMessagePreview
              url={node?.data?.video?.link || ""}
              caption={node?.data?.video?.caption || ""}
            />
          ) : key() === "DOCUMENT" ? (
            <DocumentMessagePreview
              url={node?.data?.document?.link || ""}
              caption={node?.data?.document?.caption || ""}
              fileName={node?.data?.document?.filename}
            />
          ) : key() === "AUDIO" ? (
            <AudioMessagePreview
              url={node?.data?.audio?.link || ""}
              caption={node?.data?.audio?.caption || ""}
            />
          ) : key() === "NONE" ? (
            ""
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="mt-6" id="uploadImg">
          {uploading ? (
            <div className="w-full h-36 flex items-center justify-center">
              <Loader Width={20} Height={20} />
            </div>
          ) : (
            <>
              <div
                className="w-full h-36 flex items-center justify-center bg-white border-[1.5px] border-dashed rounded-[10px] border-[#D1D3D8]"
                onClick={() => {
                  uploadRef.current.value = null;
                  uploadRef?.current?.click();
                }}
              >
                {selectedTab?.key === "IMG" ? (
                  <div className=""></div>
                ) : selectedTab?.key === "VIDEO" ? (
                  <div className=""></div>
                ) : selectedTab?.key === "DOCUMENT" ? (
                  <div className=""></div>
                ) : selectedTab?.key === "AUDIO" ? (
                  <div className=""></div>
                ) : selectedTab?.key === "NONE" ? (
                  ""
                ) : (
                  ""
                )}
                <p className="text-sm weight-medium text-[var(--font-700)]">
                  {details[selectedTab?.key]?.label}
                </p>
              </div>
              <p className="mt-2.5 text-sm weight-medium text-[var(--font-700)]">
                {details[selectedTab?.key]?.note}
              </p>
            </>
          )}
        </div>
      )}

      <input
        type="file"
        ref={uploadRef}
        accept={details[selectedTab?.key]?.accept}
        onChange={async (e) => {
          const res = await uploadFiles(e, selectedTab?.key);
          onChange({
            type: "UPDATE_NODE",
            id: node?.id,
            key: "data",
            // key: selectedTab?.key?.toLowerCase(),
            data: {
              type: selectedTab?.key?.toLowerCase(),
              [`${selectedTab?.key?.toLowerCase()}`]: {
                link: res,
              },
            },
          });
        }}
        id="uploadImg"
        hidden
      />
    </div>
  );
};

export default HeaderMessage;
