import React from "react";
import { addLineBreake, replaceFormating } from "../../helper";
import { cn } from "../../../../helper/cn";

export default function ImageMessagePreview({
  url,
  caption,
  content,
  urls,
  rootClassName,
  className,
  dateTime,
}) {
  return (
    <div
      className={cn(
        "flex flex-col relative w-auto max-w-[26vw] min-h-[3.5vh] rounded-[0.6vw] rounded-tr-none",
        rootClassName
      )}
      // style={{ boxShadow: "0px 4px 16px 0px #0000000A" }}
    >
      {content && (
        <div className="w-full flex-row align-center bg-[var(--channelButton)] inbox12px-400 text-[var(--font-600)] b-radius-6 mb-10">
          <div className=" h-[7vh] bg-[#6564CA] w-1" />
          <div className="flex flex-1 items-center justify-between">
            <div className="truncate max-w-[25ch] ml-10">{content}</div>
            {urls?.[0] && (
              <img
                src={urls?.[0]}
                className="w-[50px] h-[50px] object-cover rounded-sm ml-10 mr-10"
              />
            )}
          </div>
        </div>
      )}
      <div className={cn("w-[18vw] h-full", className)}>
        <img src={url} className="w-full h-full object-cover rounded-[0.4vw]" />
      </div>
      <p
        className="new-body-text px-[0.5vw] pt-[0.5vw] break-word"
        dangerouslySetInnerHTML={{
          __html: addLineBreake(replaceFormating(caption)),
        }}
      ></p>
      <div className="flex justify-end text-[0.55vw] weight-semibold text-[var(--font-400)] mt-[0.3vw] ml-40">
        <p>{dateTime}</p>
      </div>
    </div>
  );
}
