import React from 'react';

const Website = ({
  width = 22,
  height = 22,
  color = 'white',
  strokeWidth = 1.8,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_18989_125700)'>
        <path
          d='M20.5421 11.0018C20.5421 16.2715 16.2701 20.5436 11.0003 20.5436M20.5421 11.0018C20.5421 5.73197 16.2701 1.45996 11.0003 1.45996M20.5421 11.0018H1.4585M11.0003 20.5436C5.7305 20.5436 1.4585 16.2715 1.4585 11.0018M11.0003 20.5436C13.387 17.9307 14.7433 14.5398 14.817 11.0018C14.7433 7.46369 13.387 4.07284 11.0003 1.45996M11.0003 20.5436C8.61362 17.9307 7.25728 14.5398 7.18358 11.0018C7.25728 7.46369 8.61362 4.07284 11.0003 1.45996M1.4585 11.0018C1.4585 5.73197 5.7305 1.45996 11.0003 1.45996'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_18989_125700'>
          <rect
            x='0.200195'
            y='0.200195'
            width='21.6'
            height='21.6'
            rx='3.24'
            fill={color}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Website;
