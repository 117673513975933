import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// helpers
import { cn } from "helper/cn";
import { ICONS } from "../../../assets/icons/index";
import { useAspDispatch, useAspSelector } from "test/jest-redux-hooks";
import { Modal } from "antd";

//components
import NewAlert from "../../../components/commonComponents/modal/alert/NewAlert";
import { updateToggleToast } from "reduxToolkit/appSlice";
import { draftApi } from "../../campaign/api/Api";

const list = [
  {
    id: 1,
    type: "list",
    label: "All Campaigns",
    key: "all-campaigns",
    icon: ICONS?.allCampaignsWhite,
    activeIcon: ICONS?.allCampaigns,
    active: false,
  },
  {
    id: 2,
    type: "divider",
    label: "",
    key: "",
    icon: "",
    active: false,
  },
  {
    id: 3,
    type: "list",
    label: "Awareness",
    key: "awareness",
    icon: ICONS?.awarenessSidebar,
    activeIcon: ICONS?.awarenessWhite,
    active: false,
  },
  {
    id: 4,
    type: "list",
    label: "Leads",
    key: "leads",
    icon: ICONS?.leadsSidebar,
    activeIcon: ICONS?.leadsWhite,
    active: false,
  },
  {
    id: 5,
    type: "list",
    label: "Engagement",
    key: "engagement",
    icon: ICONS?.engagementSidebar,
    activeIcon: ICONS?.engagementWhite,
    active: false,
  },
  // {
  //   id: 6,
  //   type: "list",
  //   label: "Branding",
  //   key: "branding",
  //   icon: "",
  //   active: false,
  // },
  // {
  //   id: 7,
  //   type: "list",
  //   label: "Sales",
  //   key: "sales",
  //   icon: "",
  //   active: false,
  // },
  // {
  //   id: 8,
  //   type: "list",
  //   label: "Support",
  //   key: "support",
  //   icon: "",
  //   active: false,
  // },
  // {
  //   id: 9,
  //   type: "divider",
  //   label: "",
  //   key: "",
  //   icon: "",
  //   active: false,
  // },
  {
    id: 6,
    type: "divider",
    label: "",
    key: "",
    icon: "",
    active: false,
  },
  {
    id: 7,
    type: "list",
    label: "Time Specified",
    key: "time-specified",
    icon: ICONS?.timeSpecififed,
    activeIcon: ICONS?.perpetualWhite,
    active: false,
  },
  {
    id: 8,
    type: "list",
    label: "Perpetual",
    key: "perpetual",
    icon: ICONS?.perpetual,
    activeIcon: ICONS?.timeSpecififedWhite,
    active: false,
  },
];

export default function SideBar() {
  const { saveBroadcastDatas } = useAspSelector((state) => state.broadcast);
  const { toggleToast } = useAspSelector((state) => state.app);
  const [discardAlertOpen, setDiscardAlertOpen] = useState(false);

  const dispatch = useAspDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { goalType, id } = useParams();

  const discardCarousel = () => {
    if (saveBroadcastDatas?.broadcast_id !== null) {
      setDiscardAlertOpen(true);

      draftApi(saveBroadcastDatas).then((createCampaignResponse) => {
        if (createCampaignResponse?.status === 200) {
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content: "Broadcast saved successfully",
                status: "Success",
                duration: "",
              },
            ])
          );
          setDiscardAlertOpen(false);
          navigate(`/user/campaign/all-campaigns`);
        } else {
          setDiscardAlertOpen(false);
          dispatch(
            updateToggleToast([
              ...toggleToast,
              {
                id: toggleToast?.length + 1,
                content:
                  createCampaignResponse?.response?.data?.error ||
                  "Unable to save Broadcast",
                status: "Error",
                duration: "",
              },
            ])
          );
        }
      });
    }
  };

  const handleItemClick = () => {};

  return (
    <div
      className="w-full max-w-[14.9vw] h-full p-4 bg-white "
      style={{ borderRight: "1px solid var(--border-50)" }}
    >
      {list?.map((l) => {
        return (
          <div key={l.id} className="transition-all ease-linear duration-300">
            {l.type === "divider" ? (
              <div className='w-full contents-[""] border border-[var(--border-50)] my-1.5'></div>
            ) : (
              l.type === "list" && (
                <div className="py-1.5">
                  <div
                    className={cn(
                      "w-full h-auto px-2.5 py-1.5 transition-all ease-linear duration-150 rounded-md hover:cursor-pointer  flex-row align-center",
                      l?.key === goalType
                        ? "bg-[var(--primary)] text-white"
                        : "hover:bg-[var(--BG-50)]"
                    )}
                    onClick={() => {
                      if (
                        location?.pathname ===
                          `/user/campaign/time_specified/settings/${id}` &&
                        saveBroadcastDatas?.broadcast_id !== null
                      ) {
                        setDiscardAlertOpen(true);
                      } else {
                        navigate(`/user/campaign/${l?.key}`);
                      }
                    }}
                  >
                    {l?.key !== goalType ? (
                      <img src={l?.icon} alt="" className="w-13 pr-5" />
                    ) : (
                      <img src={l?.activeIcon} alt="" className="w-13 pr-5" />
                    )}

                    <div
                      className="new-left-layout"
                      style={{
                        color:
                          l?.key !== goalType ? "var(--textBlack)" : "white",
                      }}
                    >
                      {l.label}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        );
      })}

      <Modal
        footer={null}
        open={discardAlertOpen}
        className={"asp-modal-popup-small"}
        closable={false}
        centered={true}
        wrapClassName={"bg-[#00000095]"}
      >
        <NewAlert
          type="new"
          open={discardAlertOpen}
          icon={ICONS?.AlertIcon}
          setOpen={setDiscardAlertOpen}
          content={
            <div>
              <p>What would you like to do with the broadcast?</p>
            </div>
          }
          noName="Continue Editing"
          yesName="Draft"
          noButtonClassName="!font-md !weight-semibold"
          handleNo={() => {
            discardCarousel();
          }}
          handleYes={() => {
            setDiscardAlertOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
