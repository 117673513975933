import React, { useEffect, useState, useRef } from "react";
import "../../campaign/styles/CampaignModal.css";

import { calendarHeader } from "../../../constant/app/campaign/campaign";
import Calendar from "../../../assets/customSVG/customCalendar";
// import Board from '../../../assets/customSVG/Board';
import { calendarOption } from "../../../constant/app/campaign/campaign";
import DropDown from "../../../components/commonComponents/DropDown/DropDown";

import { useAspSelector, useAspDispatch } from "../../../test/jest-redux-hooks";
import { updateComponentsSwitching } from "../../../reduxToolkit/CampaignSlice";
import CampaignFilter from "../../../components/commonComponents/ListViewHeader/CampaignFilter";
import moment from "moment";
import SearchBar from "../../../components/commonComponents/searchBar";

const HeaderSwitchingButtons = ({
  sticky,
  handlePrevMonth,
  handleNextMonth,
  handlePrevWeek,
  handleNextWeek,
  selectedDate,
  shortMonthName,
  monthNamesArr,
  weekDates,
  setWeekDates,
  setStartOfWeek,
  setEndOfWeek,
  startOfWeek,
  endOfWeek,
  pageNavigate,
  onFilterChange,
  selectedStatus,
  calendarSearch,
  searchisFilter,
  setSearchIsFilter,
}) => {
  const dispatch = useAspDispatch();
  const { switchingComponents } = useAspSelector((state) => state.Campaign);

  // useEffect(() => {
  //   // const startDate = new Date(selectedDate);
  //   const startOfWeekDate = new Date(selectedDate);
  //   startOfWeekDate.setDate(selectedDate.getDate() - selectedDate.getDay());

  //   const endOfWeekDate = new Date(selectedDate);
  //   endOfWeekDate.setDate(selectedDate.getDate() - selectedDate.getDay() + 6);

  //   const datesBetween = [];
  //   const currentDate = new Date(startOfWeekDate);

  //   while (currentDate <= endOfWeekDate) {
  //     datesBetween.push(new Date(currentDate));
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   setStartOfWeek(startOfWeekDate);
  //   setEndOfWeek(endOfWeekDate);
  //   setWeekDates(datesBetween);
  // }, [selectedDate]);

  useEffect(() => {
    const startOfWeekDate = moment(selectedDate).utc().startOf("week");
    const endOfWeekDate = moment(selectedDate).utc().endOf("week");

    const datesBetween = [];
    let currentDate = moment(startOfWeekDate).utc();

    while (currentDate <= endOfWeekDate) {
      datesBetween.push(moment(currentDate).utc());
      currentDate = moment(currentDate).utc().add(1, "day");
    }

    setStartOfWeek(startOfWeekDate);
    setEndOfWeek(endOfWeekDate);
    setWeekDates(datesBetween);
  }, [selectedDate]);

  return (
    <div id="stickyBar" className={"sticky"}>
      <div className="header-switching-button-container">
        <div className="flex-row w-40 pl-10 ml-10 mt-10 mb-10">
          <div>
            <SearchBar
              searchKey={searchisFilter}
              setSearchKey={setSearchIsFilter}
              onChange={(text) => calendarSearch(text)}
              search="search-calender"
              searchCancel="CalendarsearchCancel"
              expandable={true}
            />
          </div>
        </div>

        {pageNavigate === "Month View" && (
          <div className=" w-20">
            {switchingComponents !== "Board" &&
              selectedDate &&
              monthNamesArr &&
              calendarHeader?.map((item) => (
                <div
                  key={item?.id}
                  className="calender-container flex-row align-center"
                >
                  {item?.image && (
                    <img
                      src={item?.image}
                      alt="calendar"
                      className="ml-5 mr-3 pointer !w-[1vw] !h-[1vw]"
                      onClick={() => handlePrevMonth()}
                    />
                  )}
                  <Calendar
                    width={"18"}
                    height={"18"}
                    color={"var(--textBlack)"}
                  />
                  <div className="monthWithYear pl-2">
                    {`${shortMonthName[selectedDate?.getMonth()]?.slice(
                      0,
                      9
                    )} - ${selectedDate?.getFullYear()}`}
                  </div>
                  {/* <div className="monthWithYear">
                    {`${moment(selectedDate)
                      .utc()
                      .format("MMM")
                      .slice(0, 9)} ${moment(selectedDate)
                      .utc()
                      .format("YYYY")}`}
                  </div> */}
                  {item?.icon1 && (
                    <img
                      src={item?.icon1}
                      alt="calendar"
                      className="ml-4 mr-5 pointer !w-[1vw] !h-[1vw]"
                      onClick={() => handleNextMonth()}
                    />
                  )}
                </div>
              ))}
          </div>
        )}

        {pageNavigate === "Week View" && (
          <div className=" w-20">
            {switchingComponents !== "Board" &&
              selectedDate &&
              shortMonthName &&
              calendarHeader?.map((item) => (
                <div
                  key={item?.id}
                  className="calender-container flex-row align-center"
                >
                  {item?.image && (
                    <img
                      src={item?.image}
                      alt="calendar"
                      className="ml-5 mr-3 pointer !w-[1vw] !h-[1vw]"
                      onClick={() => handlePrevWeek()}
                      data-testId="prevWeek"
                    />
                  )}
                  <Calendar
                    width={"18"}
                    height={"18"}
                    color={"var(--textBlack)"}
                  />

                  {/* <div className="monthWithYear">
                    {`${
                      shortMonthName[startOfWeek?.getMonth()]
                    } ${startOfWeek?.getDate()} - ${
                      shortMonthName[endOfWeek?.getMonth()]
                    } ${endOfWeek?.getDate()}`}
                  </div> */}
                  <div className="monthWithYear pl-2">
                    {`${moment(startOfWeek).utc().format("MMM")} ${moment(
                      startOfWeek
                    )
                      .utc()
                      .date()} - ${moment(endOfWeek)
                      .utc()
                      .format("MMM")} ${moment(endOfWeek).utc().date()}`}
                  </div>

                  {item?.icon1 && (
                    <img
                      src={item?.icon1}
                      alt="calendar"
                      className="ml-4 mr-5 pointer !w-[1vw] !h-[1vw]"
                      onClick={() => handleNextWeek()}
                      data-testId="nextWeek"
                    />
                  )}
                </div>
              ))}
          </div>
        )}

        <div className=" w-40 flex-row flex-end">
          <div className="dropDownHover p-relative">
            <div className="dropdownWrapper pointer p-absolute">
              {calendarOption?.map((item) => (
                <div
                  key={item?.id}
                  onClick={() =>
                    dispatch(updateComponentsSwitching(item?.name))
                  }
                  className="dropdown-1"
                >
                  <DropDown title={item?.name} />
                </div>
              ))}
            </div>
          </div>
          {/* <ListView /> */}
          <CampaignFilter
            onFilterChange={onFilterChange}
            selectedStatus={selectedStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderSwitchingButtons;
