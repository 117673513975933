import React from 'react';

const Interactive = ({ width = 22, height = 20, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6673 6.33398H18.334C18.8402 6.33398 19.2507 6.74439 19.2507 7.25065V17.334L16.1951 14.7956C16.0306 14.6588 15.8233 14.584 15.6094 14.584H8.25065C7.74439 14.584 7.33398 14.1736 7.33398 13.6673V10.9173'
        stroke={color}
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.7505 2.66797H3.66715C3.16089 2.66797 2.75049 3.07837 2.75049 3.58464V13.668L5.806 11.1295C5.97058 10.9928 6.1778 10.918 6.39177 10.918H13.7505C14.2567 10.918 14.6672 10.5076 14.6672 10.0013V3.58464C14.6672 3.07837 14.2567 2.66797 13.7505 2.66797Z'
        stroke={color}
        strokeWidth='1.83333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Interactive;
